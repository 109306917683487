import React, { Fragment } from 'react'
import { Switch } from 'react-router';

// list routes constant
import CR from '../constants/RouteConstants';

// list component to render
import ModalSaleCode from                       '../containers/Modals/ModalSaleCode'
import GeneratedCodeRenew from                  '../containers/Modals/GeneratedCodeRenew'
import ModalActionsProductList from             '../containers/Modals/ModalActions/ModalActionProductList'
import ModalGeneratedCode from                  '../containers/Modals/GeneratedCode'
import ModalRoute from                          '../components/Routing/ModalRoute'
import ModalActionsSalesHistories from          '../containers/Modals/ModalActions/ModalActionsSalesHistories'
import ModalActionsSalesHistoriesItem from      '../containers/Modals/ModalActions/ModalActionsSalesHistories/ModalActionsSalesHistoriesItem'
import ModalActionsCodesHistories from          '../containers/Modals/ModalActions/ModalActionsCodesHistories'
import ModalActionsPricePromo from              '../containers/Modals/ModalActions/ModalActionsPricePromo'
import UserConsignementInfo from                '../containers/Modals/UserConsignementInfo'
import ModalAdminUserAction from                "../containers/Modals/ModalActions/ModalAdminUser/ModalAdminUserAction";
const AppRoute = ({match, elem, closeModalHandler, deposit }) => (

        <Fragment>
            <Switch>
                {/* START authenticated user route */}
                <ModalRoute 
                    path={`${match.url}${CR.modal.kioskProduct}`} 
                    component={ModalActionsProductList}
                    elem={elem}
                    deposit={deposit}
                    closeModalHandler={closeModalHandler}
                />
                <ModalRoute 
                    path={`${match.url}${CR.modal.depositSaleCode}`}
                    component={ModalSaleCode}
                    type={true}
                    closeModalHandler={closeModalHandler}
                />
                <ModalRoute 
                    path={`${match.url}${CR.modal.SaleCode}`}
                    component={ModalSaleCode}
                    elem={elem}
                    type={false}
                    closeModalHandler={closeModalHandler}
                />
                <ModalRoute 
                    path={`${match.url}${CR.modal.codeRenew}`}
                    component={GeneratedCodeRenew}
                    elem={elem}
                    type={false}
                    closeModalHandler={closeModalHandler}
                />
                <ModalRoute 
                    path={`${match.url}${CR.modal.generatedCode}`}
                    component={ModalGeneratedCode}
                    closeModalHandler={closeModalHandler}
                    elem={elem}
                />
                <ModalRoute 
                    path={`${match.url}${CR.modal.salesHistoriesAction}`}
                    component={ModalActionsSalesHistories}
                    closeModalHandler={closeModalHandler}
                    elem={elem}
                />
                <ModalRoute 
                    path={`${match.url}${CR.modal.salesHistoriesActionItem}`}
                    component={ModalActionsSalesHistoriesItem}
                    closeModalHandler={closeModalHandler}
                    elem={elem}
                />
                <ModalRoute 
                    path={`${match.url}${CR.modal.codesHistoriesAction}`}
                    component={ModalActionsCodesHistories}
                    closeModalHandler={closeModalHandler}
                    elem={elem}
                />
                <ModalRoute 
                    path={`${match.url}${CR.modal.itemStorePromo}`}
                    component={ModalActionsPricePromo}
                    closeModalHandler={closeModalHandler}
                    elem={elem}
                />
                <ModalRoute 
                    path={`${match.url}${CR.modal.userConsignementInfo}`}
                    component={UserConsignementInfo}
                    closeModalHandler={closeModalHandler}
                    elem={elem}
                />
                <ModalRoute
                    path={`${match.url}${CR.modal.adminUser}`}
                    component={ModalAdminUserAction}
                    closeModalHandler={closeModalHandler}
                    elem={elem}
                />
            </Switch>
        </Fragment>
    );

export default AppRoute