import ActionsTypes from '../../constants/ActionsTypes';

const stockManager = {
    getItemsKiosksStock: ( id ) => ( {
        type: ActionsTypes.GET_ITEMS_KIOSKS_STOCK,
        payload: { id },
    } ),
    getItemsKiosksStockSuccess: ( itemsKiosksStock ) => ( {
        type: ActionsTypes.GET_ITEMS_KIOSKS_STOCK_SUCCESS,
        payload: {
            itemsKiosksStock,
        },
    } ),
    getItemsKiosksStockFailure: ( error ) => ( {
        type: ActionsTypes.GET_ITEMS_KIOSKS_STOCK_FAILURE,
        payload: {
            error,
        },
    } ),
    updateItemsKiosksStock: ( itemsKiosksStock ) => ( {
        type: ActionsTypes.UPDATE_ITEMS_KIOSKS_STOCK,
        payload: { itemsKiosksStock },
    } ),
    updateItemsKiosksStockSuccess: ( itemsKiosksStock ) => ( {
        type: ActionsTypes.UPDATE_ITEMS_KIOSKS_STOCK_SUCCESS,
        payload: {
            itemsKiosksStock,
        },
    } ),
    updateItemsKiosksStockFailure: ( error ) => ( {
        type: ActionsTypes.UPDATE_ITEMS_KIOSKS_STOCK_FAILURE,
        payload: {
            error,
        },
    } ),

    updateStockFamilySelected: ( id ) => ( {
        type: ActionsTypes.UPDATE_STOCK_FAMILY_SELECTED,
        payload: {
            id,
        },
    } ),
    updateStockBrandsSelected: ( id, isChecked ) => ( {
        type: ActionsTypes.UPDATE_STOCK_BRANDS_SELECTED,
        payload: {
            id,
            isChecked,
        },
    } ),

};

export default stockManager;