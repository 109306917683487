import {
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';
import {
    myAccountActionCreators,
    globalLoadingActionCreators,
    errorManagerActionCreators, modalsActionCreators
} from '../actions';
import API from '../../constants/ApiUrl'
import * as R from '../../constants/ApiConstants'
import { selectIdsUser } from '../selectors/user'

const getMyAccount = function* () {
    
    const user          = yield select(selectIdsUser);
    const apiCall       = R.GET_MY_ACCOUNT();

    yield put(globalLoadingActionCreators.startLoading());

    try{
        

        const data = yield API(user.jwt).get(apiCall);

        yield put(myAccountActionCreators.getMyAccountSuccess(data.data));

    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(myAccountActionCreators.getMyAccountFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());

};
const updateMyAccount = function* ({payload}) {
    
    const user          = yield select(selectIdsUser);
    const apiCall       = R.UPDATE_MY_ACCOUNT();
    const dataUser      = payload.user;

    yield put(globalLoadingActionCreators.startLoading());
    
    try{

        const data = yield API(user.jwt).put(apiCall, dataUser);

        yield put(myAccountActionCreators.updateMyAccountSuccess(data.data));

        const modal = {
            level: 'validation',
            title: 'page__myAccount__modalUpdateSuccess_title',
            key: 'page__myAccount__modalUpdateSuccess_body',
            show: true,
            path: ''
        };
        yield put( modalsActionCreators.hydrateModal(modal));

    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(myAccountActionCreators.updateMyAccountFailure(e.response));

    }
    yield put(globalLoadingActionCreators.endLoading());

};



const sagas = function* () {
    yield takeLatest(ActionsTypes.GET_MY_ACCOUNT, getMyAccount);
    yield takeLatest(ActionsTypes.UPDATE_MY_ACCOUNT, updateMyAccount);
};

export default sagas