
import {
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';
import {
    promoItemStoreActionCreators,
    globalLoadingActionCreators,
    errorManagerActionCreators
} from '../actions';
import API from '../../constants/ApiUrl'
import * as R from '../../constants/ApiConstants'
import { selectIdsUser } from '../selectors/user'
import { selectStatusPromoActiveId } from '../selectors/itemsKiosk'

const getPromoItemStore = function* () {
    
    const user      = yield select(selectIdsUser);
    const status    = yield select(selectStatusPromoActiveId);
    const apiCall   = R.GET_PROMO_ITEM_STORE(user);

    yield put(globalLoadingActionCreators.startLoading());

    try{
        
        const data = yield API(user.jwt).post(apiCall, status);
        
        yield put(promoItemStoreActionCreators.getPromoItemStoreSuccess(data.data));
        
    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(promoItemStoreActionCreators.getPromoItemStoreFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());

};
const getPromoItemDetail = function* ({payload}) {
    
    const user      = yield select(selectIdsUser);
    const idItem       = payload.id;


    yield put(globalLoadingActionCreators.startLoading());

    const apiCall   = R.GET_PROMO_ITEM_DETAIL( user, idItem );

    try{
        const data = yield API(user.jwt).get(apiCall);

        yield put(promoItemStoreActionCreators.getPromoItemDetailSuccess(data.data));
        
        yield put(promoItemStoreActionCreators.updatePromoItemDisplay(payload.id))
        
    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(promoItemStoreActionCreators.getPromoItemDetailFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());

};
const getPromoItemDetailAll = function* ({payload}) {
    
    const user      = yield select(selectIdsUser);
    const status    = yield select(selectStatusPromoActiveId);

    const apiCall   = R.GET_PROMO_ITEM_DETAIL_ALL(user);

    yield put(globalLoadingActionCreators.startLoading());

    try{
        const data = yield API(user.jwt).post(apiCall, status);
        
        yield put(promoItemStoreActionCreators.getPromoItemDetailAllSuccess(data.data));
        
        yield put(promoItemStoreActionCreators.updatePromoItemDisplay(payload.id))
        
    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(promoItemStoreActionCreators.getPromoItemDetailAllFailure(e.response));

    }

    yield put(globalLoadingActionCreators.endLoading());

};
const deletePromoItemStore = function* ({payload}) {
    
    const user      = yield select(selectIdsUser);
    const idItem    = payload.id;

    yield put(globalLoadingActionCreators.startLoading());

    const apiCall   = R.DELETE_PROMO_ITEM_STORE( user, idItem );
    
    try{
        const data = yield API(user.jwt).put(apiCall);
        
        yield put(promoItemStoreActionCreators.deletePromoItemStoreSuccess(data.data));
        
    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(promoItemStoreActionCreators.deletePromoItemStoreFailure(e.response));

    }

    yield put(globalLoadingActionCreators.endLoading());

};

const sagas = function* () {
    yield takeLatest(ActionsTypes.GET_PROMO_ITEM_STORE, getPromoItemStore);
    yield takeLatest(ActionsTypes.GET_PROMO_ITEM_DETAIL, getPromoItemDetail);
    yield takeLatest(ActionsTypes.GET_PROMO_ITEM_DETAIL_ALL, getPromoItemDetailAll);
    yield takeLatest(ActionsTypes.DELETE_PROMO_ITEM_STORE, deletePromoItemStore);
};

export default sagas