const RouteConstants = {
    login                       : "/login",
    home                        : "/home",
    kioskProduct                : "/product-list",
    //priceManagment              : "/management-price",
    salesHistories              : "/sales-histories",
    salesHistoriesByItem        : "/sales-histories-by-items/:id",
    salesHistoriesBySales       :
        {
            route: "/sales-histories-by-sale/:id",
            text: "/sales-histories-by-sale"
        },
    codeHistories               : "/code-histories",
    productDetail               :
        {
            route: "/product-detail/:id",
            text: "/product-detail/",
        },
    promoManager                : "/promo-manager",
    updatePromoManager          :
        {
            route: "/update-promo-manager/:id",
            text: "/update-promo-manager"
        },
   /*  telecollection              : "/payment-collection",
    telecollectionDetail        : "/payment-collection/:id",   user for page view privileges */
    mangeStockKiosk             : "/manage-stock-kiosk",
    mangeStockKioskDetail       : "/manage-stock-kiosk/:id",
    deliveryValidation          : "/delivery-validation",
    manualRestock               : "/manual-restock",
    stockCorrection             : "/stock-correction",
    /* warehouseStockManagement    : "/warehouse-stock-management", */
    kioskManagment              : "/kiosk-management",
    adminUser                   : "/user-management",
    userDetail                  : "/user-detail",
    logHistory                  : "/log-history",
    logHistoryUser              :
        {
            route: "/log-history-user/:id",
            text: "/log-history-user"
        },
    logHistoryUsers             : "/log-history-users",
    createUser                  : "/create-user",
    myAccount                   : "/my-account",
    userSetting                 : "/user-setting",
    logOut                      : "/logout",
    app                         : "/",
    criticalError               : "/critical-error",
    receipt                     : {
      merchant                  :
          {
              route: '/receipt-merchant/:id',
              text: '/receipt-merchant'
          },
      customer                  :
          {
              route: '/receipt-customer/:id',
              text: '/receipt-customer'
          },

    },
    printTicket                 : {
        route: '/print-code-ticket/:id',
        text: '/print-code-ticket'
    },
    modal:{
        kioskProduct                    : "/modal-action",
        depositSaleCode                 : "/deposit-modal-sale-code",
        SaleCode                        : "/modal-sale-code",
        generatedCode                   : "/modal-generated-code",
        salesHistoriesAction            : "/modal-sales-histories-action",
        salesHistoriesActionItem        : "/modal-sales-histories-action-item",
        codesHistoriesAction            : "/modal-codes-histories-action",
        codesHistoriesActionNested      : "/modal-codes-histories-action-nested",
        itemStorePromo                  : "/modal-promo",
        userConsignementInfo            : "/modal-user-consignement-info",
        codeRenew                       : "/modal-code-renew",
        adminUser                       : "/modal-admin-user"
    }

};

export default RouteConstants