import { createSelector } from 'reselect'
import { find, forEach, filter, map, orderBy, isEmpty, cloneDeep } from 'lodash'

const getFamilies                   = state => state.families.items;
const getItemsKiosks                = state => state.itemsKiosk.items;
const getFilter                     = state => state.itemsKiosk.filter;
const getItemsPricies               = state => state.itemsStorePrices.items;
const getItemsPromoStatus           = state => state.promoItemStore.statusFilter;
const getCreatePromoItemsStore      = state => state.createPromoItemsStore.items.itemsStore;
const getPromoItemsSelected         = ( state, promoId ) => promoId;
const getPromoItems                 = state => state.promoItemStore.items;

export const selectBrandsFamilies = createSelector(
    [ getFamilies ],
    (families) => {
        const brandsByFamilies = find(families, {active : true});
        if(brandsByFamilies){
           
            return brandsByFamilies.brands
        }
  
        return []
    }
);
export const selectFamilySelected = createSelector(
    [ getFamilies ],
    (families) => (
       
        find(families, {active : true})
       
    )
);

export const selectItemsByBrandsSelected = createSelector(
    [ getItemsKiosks, selectBrandsFamilies, getFamilies ],
    (itemsKiosk, brandsFamily) => {
        const items = filter(itemsKiosk, ( items )=> {
            let flag = false;
            forEach(brandsFamily, ( elem ) => {
                if(
                    elem.active && 
                    elem.id === items.itemBrand.id
                ) {
                    flag = true
                }
            });
            if(flag){
                return items
            }
        });
        return items
    }
);
// input filter product page
export const selectItemsTextFiltering = createSelector(
    [ selectItemsByBrandsSelected, getFilter ],
    (itemsKiosk, userFilter) => {
        const textFilter = userFilter.textFilter.toLowerCase();
  
        if( textFilter ) {

            const kioskItems = filter(itemsKiosk, ( items )=> {
                let toReturn = false;
                const elemToFilter = 
                    [
                        items.itemBrand.label.toLowerCase(),
                        items.label.toLowerCase(),
                        items.mainAccessory ? items.mainAccessory.label.toLowerCase() : '',
                        items.mainAccessory ? items.mainAccessory.standardPrice ? items.mainAccessory.standardPrice.toString() : '' : '',
                        items.standardPrice ? items.standardPrice.toString() : '',
                        items.promoPrice ? items.promoPrice.toString() : '',
                        items.promoDepositPrice ? items.promoDepositPrice.toString() : '',
                    ];
    
                let flagFeature = false;
             
                forEach(elemToFilter, ( item ) => {
    
                    if  (
                            item.includes(textFilter)
                        )
                    {
                        toReturn = true
                    }
                });
                map(items.itemFeatures, ( elem ) => {
                    if  (
                            elem.label.toLowerCase().includes(textFilter) ||
                            elem.value.toLowerCase().includes(textFilter)
                        )
                    {
                        flagFeature = true
                    }
                });
                
                if( flagFeature || toReturn ) {
                    return items
                }
            });
            return kioskItems
            
        }
        return itemsKiosk
    }
);
// sort product page asc desc
export const selectItemsSortBy = createSelector(
    [ selectItemsTextFiltering, getFilter ],
    (itemsKiosk, userFilterSelected) => {

        const userSelectedSordBy = filter(userFilterSelected.sortBy, item  => item.active);
        
        if(userSelectedSordBy.length) {
            switch(userSelectedSordBy[0].id) {
                case 1:
                    return orderBy(itemsKiosk, ['label'], ['asc']);

                case 2:
                    return orderBy(itemsKiosk, ['label'], ['desc']);

                case 3:

                    return orderBy(itemsKiosk, ['actuelPrice'], ['asc']);

                case 4:
                    return orderBy(itemsKiosk, ['actuelPrice'], ['desc']);

                case 5:
                    return orderBy(itemsKiosk, ['lockerStock'], ['asc']);

                case 6:
                    return orderBy(itemsKiosk, ['lockerStock'], ['desc']);

                case 7:
                    return orderBy(itemsKiosk, ['promoPrice'], ['asc']);

                default: return itemsKiosk
            }
        }
        return itemsKiosk
    }
);

export const selectItemsPriciesSelected = createSelector(
    [ getItemsPricies ],
    ( itemsKiosk ) => {
        let items;
        itemsKiosk ?
            items = itemsKiosk.itemsStore.filter( elem => elem.selected )
        : 
            items = false;
        const data = {
            store: itemsKiosk.storeCompany,
            itemsStore: items
        };
        return data
    }
);
export const selectStatusPromoActiveId = createSelector(
    [ getItemsPromoStatus ],
    (statusList) => {
        const statusActive = filter(statusList, {active : true});
        const idsStatus = { idsStatus : map(statusActive, item => item.id)};
        return idsStatus
    }
);
export const selectItemsStorePromoIds = createSelector(
    [ getCreatePromoItemsStore ],
    (itemsStore) => {
        const itemsSotreIds = map(itemsStore, (elem) => {
            return elem.id
        });

        return itemsSotreIds
    }
);
export const selectItemStoreFromPromo = createSelector(
    [ getPromoItems, getPromoItemsSelected, getItemsPricies ],
    (promoItem, promoId, itemsPricies) => {
        if(!isEmpty(promoItem)){
            const promo = find(promoItem, { id: promoId});
            const itemsPriciesSelected = map(
                promo.itemStorePromo,
                (elem) => find(itemsPricies.itemsStore, {id: elem.idItemStore}) );

            const data = {
                store: cloneDeep(itemsPricies.storeCompany),
                itemsStore: cloneDeep(itemsPriciesSelected),
                promo: cloneDeep(promo),
            };
            return data
        }else{
            return null
        }
    }
);
