import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from '../../Buttons'


// assets
import Close from '../../../../assets/icones/global/close-black.png'
import theme from "../../../../theme/theme";

/// //////////////////////////
//  START style
/// /////////////////////////
const PositionComponent = styled.div`
    position: absolute;
    top: 124px;
    overflow: hidden;
    z-index: 1;
    right: 0px;
	box-shadow: -2px 0 6px rgba(0, 0, 0, 0.16);
	transition: width 300ms;
	width: ${ ( { menuTransition } ) => {
		switch ( menuTransition ) {
			case "entering":
				return "0px"
			case "entered":
				return "260px"
			case "exiting":
				return "0px"
			case "exited":
				return "0px"
			default:
				return "0px"
		}
	} };
`;
const ComponentWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 260px;
    background-color: ${ props => props.theme.colors.white };
`;
const HeaderWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 23px;
    padding: 15px;
`;
const BodyWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 7px;
    max-height: 633px;
    overflow-y: auto;
    padding-bottom: 15px;
`;
const HeaderTitle = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 900;
    font-size: ${ props => props.theme.font.fxl };
    color: ${ props => props.theme.colors.primary };
`;
const HeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 900;
`;
const TextColored = styled.span`
    color: ${ props => props.colored };
`;
const HeaderSubTitle = styled.div`
    color: ${ props => props.colored };
    margin: ${ props => props.margin ? '5px 0' : '0' }
`;
const BodyCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: ${ props => props.theme.colors.logAlertBodyCard };
    padding: 10px 15px;
`;
const TitleCard = styled.div`
    font-weight: 900;
`;
const ButtonContainer = styled.div`
    height: 36px;
`;
const TitleImg = styled.img`
    height: 23px;
    width: 20px;
    margin-right: 10px;
`;
const CloseContainer = styled.div`
    position: absolute;
    top: 12px;
    right: 8px;
    cursor: pointer;
`;
const CloseImg = styled.img`
    height: 24px;
    width: 24px;
`;
/// //////////////////////////
//  END style
/// /////////////////////////
/**
 *
 * @param {string} side
 * @param {string} titleImg
 * @param {string} title
 * @param {string} titleContent
 * @param {string} textColor
 * @param {string} subTitle
 * @param {array} content
 * @param {array} refCollection
 * @param {func} closeMenuHandler
 * @param {func} switchMachineHandler
 * @param {object} translations
 * @param {object} menuTransition
 * @returns {jsx}
 */
const SwitchMachineSlider =
	( {
		  titleImg,
		  title,
		  titleContent,
		  textColor,
		  subTitle,
		  content,
		  refCollection,
		  closeMenuHandler,
		  switchMachineHandler,
		  translations,
		  menuTransition,
	  } ) => {
		return (
			<PositionComponent
				ref={ el => ( refCollection['switchMachine'] = el ) }
				menuTransition={ menuTransition }
			>
				<ComponentWrapper>
					<CloseContainer
						onClick={ closeMenuHandler }
					>
						<CloseImg
							src={ Close }
						/>
					</CloseContainer>
					<HeaderWrapper>
						<HeaderTitle>
							<TitleImg
								src={ titleImg }
							/>
							{ title }
						</HeaderTitle>
						<HeaderContent>
							{ titleContent }
							<TextColored
								colored={ theme.colors.validation }
							>
								{ textColor }
							</TextColored>
						</HeaderContent>
						<HeaderSubTitle
							colored={ theme.colors.cancel }
						>
							{ subTitle }
						</HeaderSubTitle>
					</HeaderWrapper>
					<BodyWrapper>
						{
							content.map( ( item, id ) => (
								<BodyCard
									key={ id }
								>
									<TitleCard>
										{ item.idExternOwner }
									</TitleCard>
									<HeaderSubTitle
										colored={ theme.colors.cancel }
										margin
									>
										{ item.idExternStore }
									</HeaderSubTitle>
									<ButtonContainer>
										<Button
											// text="Sélectionner cette machine"
											text={ translations['header__kioskSelection_button_selectKiosk'] }
											actionHandler={ () => switchMachineHandler( item.idKiosk ) }
										/>
									</ButtonContainer>
								</BodyCard>
							) )
						}
					</BodyWrapper>
				</ComponentWrapper>
			</PositionComponent>
		)
	};

SwitchMachineSlider.propTypes = {
	side: PropTypes.string,
	titleImg: PropTypes.string,
	title: PropTypes.string,
	titleContent: PropTypes.string,
	textColor: PropTypes.string,
	subTitle: PropTypes.string,
	content: PropTypes.array,
	refCollection: PropTypes.array,
	closeMenuHandler: PropTypes.func,
	switchMachineHandler: PropTypes.func,
	translations: PropTypes.object
};

export default SwitchMachineSlider
