import store from "../state/configStore";

export function getTranslations() {
    return store.getState().languages.translations
}


export function myAccountValidator( values ) {

    // eslint-disable-next-line no-useless-escape
    const regMail       = RegExp( /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ );
    const regPassword   = RegExp( /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/ );
    const errors        = {};
    const translations  = getTranslations()

    if ( !values.lastName ) {
        errors.lastName = translations['common__text__requiredField']
    } else {
        if ( values.lastName.length > 150 ) {
            errors.lastName = `150 ${ translations['common__text__charMax'] }`
        }
    }
    if ( !values.firstName ) {
        errors.firstName = translations['common__text__requiredField']
    } else {
        if ( values.firstName.length > 150 ) {
            errors.firstName = `150 ${ translations['common__text__charMax'] }`
        }
    }
    if ( !values.phoneNumber ) {
        errors.phoneNumber = translations['common__text__requiredField']
    } else {
        if ( values.phoneNumber.length > 150 ) {
            errors.phoneNumber = `150 ${ translations['common__text__charMax'] }`
        }
    }
    if ( !values.email ) {
        errors.email = translations['common__text__requiredField']
    } else {
        if ( !regMail.test( values.email ) ) {
            errors.email = translations['common__text__invalidEmail']
        }
    }
    if ( values.newPassword ) {
        if ( !values.oldPassword ) {
            errors.oldPassword = translations['common__text__requiredField']
        }
        if ( !values.confirmPassword ) {
            errors.confirmPassword = translations['common__text__requiredField']
        }
        if ( values.confirmPassword !== values.newPassword ) {
            errors.confirmPassword = translations['page__userDetail__userSheet_form_displayMessage_newNew2PasswordAreDifferent']
        }
        if ( values.oldPassword === values.newPassword ) {
            errors.newPassword = translations['page__userDetail__userSheet_form_displayMessage_oldNewPasswordShouldBeDifferent']
        }
        if ( values.newPassword.length < 8 ) {
            errors.newPassword = `8 ${ translations['common__text__charMin'] }`
        }
        if ( !regPassword.test( values.newPassword ) ) {
            errors.newPassword = translations['page__userDetail__userSheet_form_displayMessage_invalidPassword']
        }
    }
    return errors
}

export function userDetailValidator(values) {
    const regMail           = RegExp(/^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const errors            = {};
    const translations      = getTranslations()

    if(!values.kiosks.length){
        // TODO : traduction 'Un kiosk doit etre selectionné'
        errors.kiosks = 'Un kiosk doit etre selectionné'
    }
    if (!values.lastName) {
        errors.lastName = translations['common__text__requiredField']
    }else{
        if(values.lastName.length > 150) {
            errors.lastName = `150 ${translations['common__text__charMax']}`
        }
    }
    if (!values.firstName) {
        errors.firstName = translations['common__text__requiredField']
    }else{
        if(values.firstName.length > 150) {
            errors.firstName = `150 ${translations['common__text__charMax']}`
        }
    }
    if (!values.phoneNumber) {
        errors.phoneNumber = translations['common__text__requiredField']
    }else{
        if(values.phoneNumber.length > 150) {
            errors.phoneNumber = `150 ${translations['common__text__charMax']}`
        }
    }
    if (!values.email) {
        errors.email = translations['common__text__requiredField']
    }else{
        if(!regMail.test(values.email)) {
            errors.email = translations['common__text__invalidEmail']
        }
    }

    return errors
}

export function createUserValidator(values) {
    const translations  = getTranslations()
    const regMail       = RegExp(/^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const errors        = {};
    // TODO: login required
    if(!values.kiosks.length){
        // TODO : traduction 'Un kiosk doit etre selectionné'
        errors.kiosks = 'Un kiosk doit etre selectionné'
    }
    if (!values.lastName) {
        errors.lastName = translations['common__text__requiredField']
    }else{
        if(values.lastName.length > 150) {
            errors.lastName = `150 ${translations['common__text__charMax']}`
        }
    }
    if (!values.firstName) {
        errors.firstName = translations['common__text__requiredField']
    }else{
        if(values.firstName.length > 150) {
            errors.firstName = `150 ${translations['common__text__charMax']}`
        }
    }
    if (!values.phoneNumber) {
        errors.phoneNumber = translations['common__text__requiredField']
    }else{
        if(values.phoneNumber.length > 150) {
            errors.phoneNumber = `150 ${translations['common__text__charMax']}`
        }
    }
    if (!values.email) {
        errors.email = translations['common__text__requiredField']
    }else{
        if(!regMail.test(values.email)) {
            errors.email = translations['common__text__invalidEmail']
        }
    }
    if (!values.userName) {
        errors.userName = translations['common__text__requiredField']
    }

    return errors
}

