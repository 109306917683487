import { createGlobalStyle } from 'styled-components';
import theme from './theme';

// font all format
import LatoRegularEOT from '../assets/fonts/Lato-Regular.eot'
import LatoRegularTTF from '../assets/fonts/Lato-Regular.ttf'
import LatoRegularWOFF from '../assets/fonts/Lato-Regular.woff'
import LatoRegularWOFF2 from '../assets/fonts/Lato-Regular.woff2'

import LatoItalicEOT from '../assets/fonts/Lato-Italic.eot'
import LatoItalicTTF from '../assets/fonts/Lato-Italic.ttf'
import LatoItalicWOFF from '../assets/fonts/Lato-Italic.woff'
import LatoItalicWOFF2 from '../assets/fonts/Lato-Italic.woff2'

import LatoBoldEOT from '../assets/fonts/Lato-Bold.eot'
import LatoBoldTTF from '../assets/fonts/Lato-Bold.ttf'
import LatoBoldWOFF from '../assets/fonts/Lato-Bold.woff'
import LatoBoldWOFF2 from '../assets/fonts/Lato-Bold.woff2'

import LatoBoldItalicEOT from '../assets/fonts/Lato-BoldItalic.eot'
import LatoBoldItalicTTF from '../assets/fonts/Lato-BoldItalic.ttf'
import LatoBoldItalicWOFF from '../assets/fonts/Lato-BoldItalic.woff'
import LatoBoldItalicWOFF2 from '../assets/fonts/Lato-BoldItalic.woff2'

import LatoBlackEOT from '../assets/fonts/Lato-Black.eot'
import LatoBlackTTF from '../assets/fonts/Lato-Black.ttf'
import LatoBlackWOFF from '../assets/fonts/Lato-Black.woff'
import LatoBlackWOFF2 from '../assets/fonts/Lato-Black.woff2'

const { colors } = theme;

const GlobalStyle = createGlobalStyle`

    @font-face {
        font-family: 'Lato';
        src:  url(${LatoRegularWOFF2}) format('woff2'),
              url(${LatoRegularWOFF}) format('woff'),
              url(${LatoRegularEOT}),
              url(${LatoRegularTTF}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'Lato';
        src:  url(${LatoItalicWOFF2}) format('woff2'),
              url(${LatoItalicWOFF}) format('woff'),
              url(${LatoItalicEOT}),
              url(${LatoItalicTTF}) format('truetype');
        font-weight: normal;
        font-style: italic;
    }
    @font-face {
        font-family: 'Lato';
        src:  url(${LatoBoldWOFF2}) format('woff2'),
              url(${LatoBoldWOFF}) format('woff'),
              url(${LatoBoldEOT}),
              url(${LatoBoldTTF}) format('truetype');
        font-weight: bold;
        font-style: normal;
    }
    @font-face {
        font-family: 'Lato';
        src:  url(${LatoBoldItalicWOFF2}) format('woff2'),
              url(${LatoBoldItalicWOFF}) format('woff'),
              url(${LatoBoldItalicEOT}),
              url(${LatoBoldItalicTTF}) format('truetype');
        font-weight: bold;
        font-style: italic;
    }
        @font-face {
        font-family: 'Lato';
        src:  url(${LatoBlackWOFF2}) format('woff2'),
              url(${LatoBlackWOFF}) format('woff'),
              url(${LatoBlackEOT}),
              url(${LatoBlackTTF}) format('truetype');
        font-weight: 900;
        font-style: normal;
    }
   
    html {
      font-family: 'Lato', sans-serif;
      width: 1024px;
      font-size: 14px;
      color: ${ colors.menu.link };
      background-color: ${colors.secondary};
      margin: auto;
      box-sizing: border-box;
    }
    
    body{
      width: 1024px;
      margin: auto;
      color: ${ colors.menu.link };
      padding:0 0 53px 0;
      position: relative;
      overflow-y: scroll;
      -ms-overflow-style: none;
      ::-webkit-scrollbar{
        display: none;
      }
    }
    
    * {
      font-family: 'Lato', sans-serif;
      font-size: 14px;
    }
    .nested-active{
        transform: rotate(-45deg);
    }
`;
export default GlobalStyle;