import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { map, isNumber } from 'lodash'
import moment from 'moment'
import { withRouter } from "react-router";

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { promoItemStoreActionCreators } from '../../../state/actions';

// component import
import TableLayout from '../../TableLayout'
import TableNested from '../../../Hoc/TableNested'
import Button from '../../../components/Ui/Buttons'
import ActionButton from '../../../components/Ui/ActionButton'
import ModalHOC from '../../../Hoc/ModalHOC/'
import CR from '../../../constants/RouteConstants'
import DropdownCheckbox from '../../../components/Ui/DropdownCheckbox'
import UserAuthorizationLayout from "../../../components/UserAuthorizationLayout";
import userPrivileges from "../../../constants/RoleList";
import ExportData from "../../../components/ExportData/ExportData";

//assets import
import Graph from '../../../assets/icones/global/graph.svg'
import Expand from '../../../assets/icones/global/expand.png'

// style
import {
    PageWrapper,
    LineTableHeader,
    TitleHeader,
    ActionWrapper,
    TitleHeaderTable,
    ContentTableWrapper,
    DetailDownArrow,
    DetailActionWrapper,
} from "../../../theme/GlobalCss";
import theme from "../../../theme/theme";

/////////////////////////////
//  START style
////////////////////////////
const RelativePageWrapper = styled(PageWrapper)`
    position: relative;
`;
const ScrollAnchor = styled.div`
    visibility: hidden;
    position: absolute;
    z-index: -1;
    top: -120px;
`;
/////////////////////////////
//  END style
////////////////////////////

export class PriceManager extends Component {
    static propTypes = {
        actionsPromoItemStore:      PropTypes.object,
        promoItemStore:             PropTypes.object,
        dataTable:                  PropTypes.object,
        hydrateStateHandler:        PropTypes.func,
        modalHandler:               PropTypes.func,
        nestedOnClickHandler:       PropTypes.func,
        refCollection:              PropTypes.array,
    };
    ///////////////////////////////////////////////////
    ///////     START INIT COMPONENT    ///////////////
    ///////////////////////////////////////////////////
    state = {
        expendAll: false,
    };
    componentDidMount = () => {
        this.getPromoItemStore()
    };

    componentDidUpdate(prevProps) {
        const {
            promoItemStore,
            hydrateStateHandler,
            translations,
            promoItemStoreSortTable
        } = this.props;
        
        if( promoItemStore.items !== prevProps.promoItemStore.items ) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            
            hydrateStateHandler(data)
        }
        if( promoItemStoreSortTable !== prevProps.promoItemStoreSortTable ) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };

            hydrateStateHandler(data)
        }
        if( promoItemStore.statusFilter !== prevProps.promoItemStore.statusFilter ) {
            this.getPromoItemStore()
        }
        if( translations !== prevProps.translations) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            hydrateStateHandler(data)
        }
    }
    ///////////////////////////////////////////////////
    ///////     END INIT COMPONENT    /////////////////
    ///////////////////////////////////////////////////

    getPromoItemStore = () => {
        const { actionsPromoItemStore } = this.props;
        actionsPromoItemStore.getPromoItemStore();
    };
    getItemStoreDetail = (id) => {
        const { actionsPromoItemStore } = this.props;

        actionsPromoItemStore.getPromoItemDetail(id);
    };

    selectStatusCheckedHandler= (id, isChecked) => {
        const {actionsPromoItemStore} = this.props;
        const { expendAll } = this.state;
        let idExpend;
        
        if( expendAll ) {
            idExpend = 'ALL_NONE';
            actionsPromoItemStore.updatePromoItemDisplay(idExpend);
            this.setState({ expendAll: !expendAll})
        }

        actionsPromoItemStore.updatePromoStatusDisplay(id, isChecked)
    };
    expendAllHandler = () => {
        const { actionsPromoItemStore } = this.props;
        const { expendAll } = this.state;
        let id;

        if( !expendAll ) {
            id = 'ALL';
            actionsPromoItemStore.getPromoItemDetailAll(id);
        }else{
            id = 'ALL_NONE';
            actionsPromoItemStore.updatePromoItemDisplay(id)
        }
        this.setState({ expendAll: !expendAll})
    };

    /**
     * data object to row layout
     */
    bodyData = () => {
        const {
            promoItemStore,
            match,
            modalHandler,
            translations
        } = this.props;

        const underTableHeader = [
            {name: translations['page__priceManagement__tablePromo_expand_tableHeader_brand'], useName: 'itemStorePromo.itemBrand.label', },
            {name: translations['page__priceManagement__tablePromo_expand_tableHeader_item'], useName: 'itemStorePromo.productLabel', },
            {name: translations['page__priceManagement__tablePromo_expand_tableHeader_promoPrice'], useName: 'itemStorePromo.promoPrice', },
            {name: translations['page__priceManagement__tablePromo_expand_tableHeader_depositPromoPrice'], useName: 'itemStorePromo.promoDepositPrice', },
            {name: translations['page__priceManagement__tablePromo_expand_tableHeader_status'], useName: 'itemStorePromo.promoLineStatus.subFlow', },
            {name: translations['page__priceManagement__tablePromo_expand_tableHeader_allocatedQty'], useName: 'itemStorePromo.allocatedStock', },
            {name: translations['page__priceManagement__tablePromo_expand_tableHeader_leftAllocatedQty'], useName: 'itemStorePromo.remainingSstock', }
        ];

        const header = [
            {name: translations['common__text__detail'], useName: 'detail'},
            {name: translations['page__priceManagement__tablePromo_tableHeader_label'], useName: 'label', sortTable: true, },
            {name: translations['page__priceManagement__tablePromo_tableHeader_startDate'], useName: 'startDate', sortTable: true, },
            {name: translations['page__priceManagement__tablePromo_tableHeader_endDate'], useName: 'endDate', sortTable: true, },
            {name: translations['page__priceManagement__tablePromo_tableHeader_status'], useName: 'status', sortTable: true, },
            {name: translations['common__text__actions'], useName: 'actions'},
            
        ];
        return{
            header: header,
            body:   map(promoItemStore.items, (elem,) => {
                
                const date = {
                    startDate: [
                        moment(elem.dateStart).format('DD/MM/YYYY'),
                        moment(elem.dateStart).format('HH:mm:ss')
                    ],
                    endDate:  [
                        moment(elem.dateEnd).format('DD/MM/YYYY'),
                        moment(elem.dateEnd).format('HH:mm:ss'),
                    ],
                };

                return ({
                    id: elem.id,
                    elements: [
                        {name :'detail', 
                            value:
                                <DetailActionWrapper
                                    id={elem.id}
                                    onClick={() => this.getItemStoreDetail(elem.id)}
                                >
                                    <DetailDownArrow/>
                                </DetailActionWrapper>
                        },
                        {name :'label', value: elem.promoLabel, sortValue: elem.promoLabel, },
                        {name :'startDate', value: date.startDate, sortValue: date.startDate, },
                        {name :'endDate', value: date.endDate, sortValue: date.endDate, },
                        {name :'status', value: translations[elem.promoStatus.translationKey], color:elem.promoStatus.colorCode, sortValue: translations[elem.promoStatus.translationKey], },
                        {name:'actions', display: 'flex',
                            value:
                            <UserAuthorizationLayout
                                name={userPrivileges.manage[5]}
                            >
                                <ActionButton
                                    size='30'
                                    link={`${match.url}${CR.modal.itemStorePromo}`}
                                    actionHandler={ () => modalHandler(elem) }
                                />
                            </UserAuthorizationLayout>
                        },
                        {
                            id:elem.id, 
                            nested: true,
                            display: elem.display || 'none',
                            header: underTableHeader,
                            body: map(elem.itemStorePromo, (item,) => {
                                const depositPrice = isNumber(item.promoDepositPrice)
                                    ? `${item.promoDepositPrice.toFixed(2)}${item.currency.symbol}`
                                    : '-';
                                return ({
                                    id: item.id,
                                    elements: [
                                        {name:'brand', value: item.itemBrand.label, },
                                        {name:'label', value:  item.productLabel, },
                                        {name:'promoPrice', value: `${item.promoPrice.toFixed(2)}${item.currency.symbol}` },
                                        {name:'consignePrice', value: depositPrice },
                                        // {name:'status', value: item.promoLineStatus.description, color: item.promoLineStatus.colorCode },
                                        {name:'status', value: translations[item.promoLineStatus.translationKey], color: item.promoLineStatus.colorCode },
                                        {name:'startStock', value:item.allocatedStock },
                                        {name:'currentStock', value:item.remainingSstock, },
                                    ]
                                })
                            })
                            
                        }
                    ]
                })
            })
        };
    };
    
    render() {
        const { 
            refCollection,
            promoItemStore,
            dataTable,
            storeTarget,
            translations,
            initSortTableHandle,
        } = this.props;
        return (
            <RelativePageWrapper
            >
                <ScrollAnchor
                    id='promo'
                />
                <LineTableHeader>
                    <TitleHeader>
                        {translations['page__priceManagement__tablePromo_title']}
                    </TitleHeader>
                    <ActionWrapper>
                        <UserAuthorizationLayout
                                name={userPrivileges.analyze[2]}
                            >
                            <Button
                                img={Graph}
                                text={translations['page__priceManagement__tablePromo_button_analyzePromo']}
                                margin='true'
                            />
                        </UserAuthorizationLayout>
                        <UserAuthorizationLayout
                            name={userPrivileges.export[3]}
                        >
                            <ExportData
                                text={translations['common__text__exportData']}
                                scope={'promotions'}
                                getDateFormat={false}
                            />
                        </UserAuthorizationLayout>
                    </ActionWrapper>
                </LineTableHeader>
                <ContentTableWrapper>
                    <LineTableHeader>
                        <TitleHeaderTable color={theme.colors.primary}>
                            { storeTarget &&
                                storeTarget.storeCompany.companyStoreLblToDisplay
                            }
                        </TitleHeaderTable>
                        <ActionWrapper>

                            <Button 
                                actionHandler={this.expendAllHandler}
                                img={Expand}
                                text={ this.state.expendAll ? translations['common__text__contractAll'] : translations['common__text__expandAll']}
                            />
                            <DropdownCheckbox
                                width='102'
                                PaperTopPosition='50'
                                data={promoItemStore.statusFilter}
                                selectActionsHandler={this.selectStatusCheckedHandler}
                                title={translations['common__text__status']}
                                translations={translations}
                                toTranslate
                            />
                        </ActionWrapper>
                    </LineTableHeader>
                    <TableLayout
                        headerData={dataTable.headerData}
                        bodyData={dataTable.bodyData}
                        column={dataTable.headerData.length}
                        refCollection={refCollection}
                        initSortTableHandle={initSortTableHandle}
                        translations={translations}
                    />
                </ContentTableWrapper>

            </RelativePageWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        promoItemStore:             state.promoItemStore,
        storeTarget:                state.itemsStorePrices.items
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionsPromoItemStore: bindActionCreators(promoItemStoreActionCreators, dispatch),
    };
};

export default withRouter(TableNested(ModalHOC(connect(mapStateToProps, mapDispatchToProps)(PriceManager))))
