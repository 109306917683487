import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import { cloneDeep, findIndex, forEach, assign, uniqBy } from 'lodash';

extendUpdate(update);

const logs = {

    [ActionsTypes.GET_LOGS_ALERT]: (state) => update(state, {
        logs:{

        }
    }),
    [ActionsTypes.GET_LOGS_ALERT_SUCCESS]: (state, payload) => {
        const newLogs = payload.logs;

        return update(state, {
            logs:{
                items: {
                    $set: newLogs
                }
            }

        })
    },
    [ActionsTypes.GET_LOGS_ALERT_FAILURE]: (state, payload) => update(state, {
        logs:{
            error:{
                $set: payload.error
            }
        }
    }),

    [ActionsTypes.PUT_RESOLVED_LOG_ALERT]: (state) => update(state, {
        logs:{

        }
    }),
    [ActionsTypes.PUT_RESOLVED_LOG_ALERT_SUCCESS]: (state, payload) => {
        const newLogs   = payload.logs;
        const oldLogs   = cloneDeep(state.logsHistory.items);
        const idxLog    = findIndex(oldLogs, { id : newLogs.id});
        oldLogs[idxLog] = newLogs;

        return update(state, {
            logsHistory:{
                items: {
                    $set: oldLogs
                }
            }

        })
    },
    [ActionsTypes.PUT_RESOLVED_LOG_ALERT_FAILURE]: (state,) => update(state, {
        // logs:{
        //     error:{
        //         $set: payload.error
        //     }
        // }
    }),

    [ActionsTypes.PUT_RESOLVED_ALL_LOG_ALERT]: (state,) => update(state, {
        logs:{

        }
    }),
    [ActionsTypes.PUT_RESOLVED_ALL_LOG_ALERT_SUCCESS]: (state, payload) => {
        const newLogs   = payload.logs;
        const oldLogs   = cloneDeep(state.logsHistory.items);
        const idxLog    = findIndex(oldLogs, { id : newLogs.id});
        oldLogs[idxLog] = newLogs;

        return update(state, {
            logsHistory:{
                items: {
                    $set: oldLogs
                }
            }

        })
    },
    [ActionsTypes.PUT_RESOLVED_ALL_LOG_ALERT_FAILURE]: (state,) => update(state, {
        // logs:{
        //     error:{
        //         $set: payload.error
        //     }
        // }
    }),





    [ActionsTypes.PUT_REMOTED_LOG_ALERT]: (state) => update(state, {
        logs:{

        }
    }),
    [ActionsTypes.PUT_REMOTED_LOG_ALERT_SUCCESS]: (state, payload) => {
        const newLogs   = payload.logs;
        const oldLogs   = cloneDeep(state.logsHistory.items);
        const idxLog    = findIndex(oldLogs, { id : newLogs.id});
        oldLogs[idxLog] = newLogs;

        return update(state, {
            logsHistory:{
                items: {
                    $set: oldLogs
                }
            }

        })
    },
    [ActionsTypes.PUT_REMOTED_LOG_ALERT_FAILURE]: (state) => update(state, {
        // logs:{
        //     error:{
        //         $set: payload.error
        //     }
        // }
    }),

    [ActionsTypes.GET_LOGS_HISTORY]: (state) => update(state, {
        logsHistory:{
            isLoading: {
                $set : true,
            },
            items: {
                $set : []
            }
        }
    }),
    [ActionsTypes.GET_LOGS_HISTORY_SUCCESS]: (state, payload) => {
        const logsHistory       = payload.logsHistory.logs;
        const logsHistoryfilter = payload.logsHistory.filter;
        let logsHistoryLevelFilter = [];

        forEach(logsHistory, (elem) => {
            logsHistoryLevelFilter.push({
                active:true,
                label:`${state.languages.translations[elem.logLevel.translationKey]}`,
                id:elem.logLevel.id
            })
        })

        forEach(logsHistoryfilter, (elem) => {
            assign(elem, { active: true });
            assign(elem, { label: `${elem.firstName} ${elem.lastName}` })
        });
        logsHistoryLevelFilter = uniqBy(logsHistoryLevelFilter, 'label')
        return update(state, {
            logsHistory:{
                isLoading: {
                    $set : false,
                },
                items: {
                    $set : logsHistory
                },
                filter:{
                  $set : logsHistoryfilter
                },
                filterLevel:{
                    $set : logsHistoryLevelFilter
                }
            }
        })
    },
    [ActionsTypes.GET_LOGS_HISTORY_FAILURE]: (state, payload) => update(state, {
        logsHistory:{
            isLoading: {
                $set : false,
            },
            error: {
              $set : payload.error
            }
        }
    }),

    [ActionsTypes.UPDATE_LOGS_HISTORY_FILTER]: (state, payload) => {
        const idUser            = parseInt(payload.idUser);
        const isChecked         = payload.isChecked;
        const logHistoryFilter  = cloneDeep(state.logsHistory.filter);
        const idxFilter         = findIndex(logHistoryFilter,  {id:idUser});

        if(idxFilter > -1){
            logHistoryFilter[idxFilter]['active'] = isChecked
        }

        return update(state, {
            logsHistory:{
                isLoading: {
                    $set : false,
                },
                filter:{
                    $set : logHistoryFilter
                },
                error: {
                    $set : false
                }
            }
        })
    },

    [ActionsTypes.UPDATE_LOGS_HISTORY_FILTER_LEVEL]: (state, payload) => {
        const idLevel            = parseInt(payload.idLevel);
        const isChecked         = payload.isChecked;
        const logHistoryFilter  = cloneDeep(state.logsHistory.filterLevel);
        const idxFilter         = findIndex(logHistoryFilter,  {id:idLevel});

        if(idxFilter > -1){
            logHistoryFilter[idxFilter]['active'] = isChecked
        }

        return update(state, {
            logsHistory:{
                isLoading: {
                    $set : false,
                },
                filterLevel:{
                    $set : logHistoryFilter
                },
                error: {
                    $set : false
                }
            }
        })
    },
};

export default logs