import ActionsTypes from '../../constants/ActionsTypes';

const itemsStorePrices = {
    getItemsStorePrices: () => ({
        type: ActionsTypes.GET_ITEMS_STORE_PRICES,
        payload: {},
    }),
    getItemsStorePricesSuccess: (itemsStorePrices) => ({
        type: ActionsTypes.GET_ITEMS_STORE_PRICES_SUCCESS,
        payload: {
            itemsStorePrices,
        },
    }),
    getItemsStorePricesFailure: (error) => ({
        type: ActionsTypes.GET_ITEMS_STORE_PRICES_FAILURE,
        payload: {
            error,
        },
    }),
    updateItemsStorePrices: (pricesList) => ({
        type: ActionsTypes.UPDATE_ITEMS_STORE_PRICES_SELECTED,
        payload: {
            pricesList,
        }
    }),
    updateItemsStorePricesSuccess: (pricesList) => ({
        type: ActionsTypes.UPDATE_ITEMS_STORE_PRICES_SELECTED_SUCCESS,
        payload: {
            pricesList,
        }
    }),
    updateItemsStorePricesFailure: (pricesList) => ({
        type: ActionsTypes.UPDATE_ITEMS_STORE_PRICES_SELECTED_FAILURE,
        payload: {
            pricesList,
        }
    }),
    selectedItemsStorePricesUpdate : (id, condition) => ({
        type: ActionsTypes.SELECTED_ITEMS_STORE_PRICES_UPDATE,
        payload: {
            id,
            condition
        }
    }),
};

export default itemsStorePrices;