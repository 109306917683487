import React, { Component } from 'react'
import PropTypes from 'prop-types'

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import {
    codesHistoriesActionCreators,
    salesOrdersActionCreators
} from '../../state/actions';

// helpers
import moment from 'moment'
import { map } from 'lodash'
import StringFormater from '../../helpers/StringFormater';

// selector
import { selectIdsUser } from '../../state/selectors/user'

// component import
import ModalDatePicker from '../../Hoc/ModalDatePicker'
import TableNested from '../../Hoc/TableNested'
import TableLayout from '../TableLayout'
import ActionButton from '../../components/Ui/ActionButton'
import CR from '../../constants/RouteConstants'
import ModalHOC from '../../Hoc/ModalHOC/'
import Button from '../../components/Ui/Buttons'
import UserAuthorizationLayout from "../../components/UserAuthorizationLayout";
import userPrivileges from "../../constants/RoleList";
import ExportData from "../../components/ExportData/ExportData";

//assets import
import Calendar from '../../assets/icones/global/calendarX2.png'
import Graph from '../../assets/icones/global/graph.svg'

// style
import {
    PageWrapper,
    LineTableHeader,
    TitleHeader,
    ActionWrapper,
    TitleHeaderTable,
    ContentTableWrapper,
    CalendarButton,
    CalendarImage,
    CalendarText,
    DetailDownArrow,
    DetailActionWrapper,
} from "../../theme/GlobalCss";
import theme from "../../theme/theme";


/////////////////////////////
//  START style
////////////////////////////
/////////////////////////////
//  END style
////////////////////////////

export class CodesHistories extends Component {
    static propTypes = {
        actionsCodesHistories: PropTypes.object,
        codesHistories: PropTypes.object,
        dataTable: PropTypes.object,
        datePicker: PropTypes.object,
        formatDate: PropTypes.func,
        getDateFormat: PropTypes.func,
        hydrateStateHandler: PropTypes.func,
        modalHandler: PropTypes.func,
        nestedOnClickHandler: PropTypes.func,
        openDateModalHandler: PropTypes.func,
        refCollection: PropTypes.array,
        userKiosk: PropTypes.object,
    };

    ///////////////////////////////////////////////////
    ///////     START INIT COMPONENT    ///////////////
    ///////////////////////////////////////////////////
    componentDidMount = () => {
        this.getCodesOrderByTimeRange()
    };
    ///////////////////////////////////////////////////
    ///////     END INIT COMPONENT    /////////////////
    ///////////////////////////////////////////////////

    /**
     * get codes history by date range
     */
    getCodesOrderByTimeRange = () => {
        const { actionsCodesHistories, getDateFormat } = this.props;
        const dateFormat = getDateFormat();

        actionsCodesHistories.getCodesHistories( dateFormat );
    };

    componentDidUpdate( prevProps ) {
        const {
            codesHistories,
            hydrateStateHandler,
            datePicker,
            translations,
        } = this.props;

        // fetch data if date change
        if ( datePicker !== prevProps.datePicker ) {

            this.getCodesOrderByTimeRange()
        }
        // fetch data if user change kiosk
        if ( this.props.userKiosk !== prevProps.userKiosk ) {

            this.getCodesOrderByTimeRange()
        }
        // // hydrate parent STATE if Table data change
        if ( codesHistories.items !== prevProps.codesHistories.items ) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            hydrateStateHandler( data )
        }

        if ( translations !== prevProps.translations ) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            hydrateStateHandler( data )
        }
    }

    /**
     * data object to row layout
     * @return  {Object}        row object table to display
     */
    bodyData = () => {
        const {
            codesHistories,
            modalHandler,
            match,
            nestedOnClickHandler,
            translations,

        } = this.props;

        const underTableHeader = [
            {
                name: translations['page__codesHistory__codesTable_expand_tableHeader_familly'],
                useName: 'famille'
            },
            {
                name: translations['page__codesHistory__codesTable_expand_tableHeader_brand'],
                useName: 'marque'
            },
            {
                name: translations['page__codesHistory__codesTable_expand_tableHeader_type'],
                useName: 'type'
            },
        ];
        const header = [
            { name: translations['common__text__detail'], useName: 'detail' },
            {
                name: translations['page__codesHistory__codesTable_tableHeader_creationDate'],
                useName: 'creation',
                sortTable: true,
            },
            {
                name: translations['page__codesHistory__codesTable_tableHeader_expirationDate'],
                useName: 'expiration',
                sortTable: true,
            },
            {
                name: translations['page__codesHistory__codesTable_tableHeader_status'],
                useName: 'etat',
                sortTable: true,
            },
            {
                name: translations['page__codesHistory__codesTable_tableHeader_user'],
                useName: 'utilisateur',
                sortTable: true,
            },
            {
                name: translations['page__codesHistory__codesTable_tableHeader_item'],
                useName: 'produit',
                sortTable: true,
            },
            {
                name: translations['page__codesHistory__codesTable_tableHeader_code'],
                useName: 'code',
                sortTable: true,
                isNumber: true,
            },
            { name: translations['common__text__actions'], useName: 'actions' },
        ];

        return{
            header: header,
            body: map( codesHistories.items, ( elem ) => {
                let createDate = [
                    moment( elem.dateCreated ).format( 'DD/MM/YYYY' ),
                    moment( elem.dateCreated ).format( 'HH:mm:ss' )
                ];
                let endDate = [
                    moment( elem.dateExpiration ).format( 'DD/MM/YYYY' ),
                    moment( elem.dateExpiration ).format( 'HH:mm:ss' )
                ];
                return ({
                    id: elem.id,
                    elements: [
                        {
                            name: 'detail',
                            value:
                                <DetailActionWrapper
                                    id={ elem.id }
                                    onClick={ () => nestedOnClickHandler( elem.id ) }
                                >
                                    <DetailDownArrow/>
                                </DetailActionWrapper>
                        },
                        {
                            name: 'creation',
                            value: createDate,
                            sortValue: elem.dateCreated,
                        },
                        {
                            name: 'expiration',
                            value: endDate,
                            sortValue: elem.dateExpiration,
                        },
                        {
                            name: 'etat',
                            // value: elem.salesOrderStatus.description,
                            value: translations[elem.salesOrderStatus.translationKey],
                            bcg: elem.salesOrderStatus.colorCode,
                            type: 'button',
                            color: theme.colors.white,
                            sortValue: translations[elem.salesOrderStatus.translationKey],
                        },
                        {
                            name: 'utilisateur',
                            value: `${ elem.userCreatedLastName } ${ elem.userCreatedFirstName }`,
                            color: theme.colors.primary,
                            sortValue: elem.userCreatedLastName,
                        },
                        {
                            name: 'produit',
                            value: elem.productLabel,
                            sortValue: elem.productLabel,
                        },
                        {
                            name: 'code',
                            value: elem.generatedCode,
                            sortValue: elem.generatedCode,
                            isNumber: true,
                        },
                        {
                            name: 'actions', display: 'flex',
                            value:
                                <ActionButton
                                    size='30'
                                    disabled={
                                        (
                                            ( elem.salesOrderStatus.subFlow >= 100 && elem.salesOrderStatus.subFlow < 200 ) ||
                                            elem.salesOrderStatus.subFlow === "999"
                                        )
                                    }
                                    link={ `${ match.url }${ CR.modal.codesHistoriesAction }` }
                                    actionHandler={ () => modalHandler( elem ) }
                                />
                        },
                        {
                            id: elem.id,
                            nested: true,
                            display: 'none',
                            header: underTableHeader,
                            body: [ {
                                id: elem.id,
                                elements: [
                                    {
                                        id: elem.id,
                                        name: 'famille',
                                        // value: elem.itemFamily && elem.itemFamily.label,
                                        value: translations[elem.itemFamily.translationKey],
                                        color: theme.colors.primary
                                    },
                                    {
                                        id: elem.id,
                                            name: 'marque',
                                        value: elem.itemBrand && elem.itemBrand.label,
                                        color: theme.colors.primary
                                    },
                                    {
                                        id: elem.id,
                                        name: 'type',
                                        // value: elem.saleOrderType.label
                                        value: translations[elem.saleOrderType.translationKey]
                                    },
                                ]
                            } ]

                        }
                    ]
                } )
            } )
        };

    };

    render() {
        const {
            formatDate,
            datePicker,
            openDateModalHandler,
            dataTable,
            refCollection,
            history,
            translations,
            initSortTableHandle,
            getDateFormat,
        } = this.props;
        return (
            <PageWrapper>
                <LineTableHeader>
                    <ActionWrapper>
                        <Button
                            goBack
                            weight='bold'
                            actionHandler={ history.goBack }
                            text={ translations['common__text__previousPage'] }
                        />
                    </ActionWrapper>

                </LineTableHeader>
                <LineTableHeader>
                    <TitleHeader>
                        { translations['page__codesHistory__codesTable_title'] }
                    </TitleHeader>
                    <ActionWrapper>
                        <UserAuthorizationLayout
                            name={ userPrivileges.export[1] }
                        >
                            <ExportData
                                text={ translations['common__text__exportData'] }
                                scope={ 'bycode' }
                                getDateFormat={ getDateFormat }
                            />
                        </UserAuthorizationLayout>
                    </ActionWrapper>
                </LineTableHeader>
                <ContentTableWrapper>
                    <LineTableHeader>
                        <TitleHeaderTable>
                            <CalendarButton
                                onClick={ openDateModalHandler }
                            >
                                <CalendarImage src={ Calendar }/>
                            </CalendarButton>
                            <CalendarText>
                                { StringFormater.ResponseMessageParser(
                                    translations['common__text__datePeriod_noHours'],
                                    [ formatDate( datePicker.startDate ), formatDate( datePicker.endDate ) ]
                                ) }
                            </CalendarText>
                        </TitleHeaderTable>
                        <ActionWrapper>
                            <UserAuthorizationLayout
                                name={ userPrivileges.analyze[1] }
                            >
                                <Button
                                    img={Graph}
                                    text={ translations['page__codesHistory__codesTable_button_analyzeSalesByCode'] }
                                />
                            </UserAuthorizationLayout>
                        </ActionWrapper>
                    </LineTableHeader>
                    <TableLayout
                        headerData={ dataTable.headerData }
                        bodyData={ dataTable.bodyData }
                        column={ dataTable.headerData.length }
                        refCollection={ refCollection }
                        initSortTableHandle={ initSortTableHandle }
                        translations={translations}
                    />
                </ContentTableWrapper>

            </PageWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        codesHistories: state.codesHistories,
        userKiosk: selectIdsUser( state ),
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        actionsCodesHistories: bindActionCreators( codesHistoriesActionCreators, dispatch ),
        actionsSaleOrder: bindActionCreators( salesOrdersActionCreators, dispatch ),
    };
};

export default ModalDatePicker( TableNested( ModalHOC( connect( mapStateToProps, mapDispatchToProps )( CodesHistories ) ) ) )
