import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import { findIndex, cloneDeep, merge, forEach } from 'lodash';

extendUpdate(update);

const salesOrders = {

    [ActionsTypes.POST_SALES_ORDERS]: (state, payload) => update(state, {
        salesOrders: {
            isLoading: {
                $set: true,
            },
            error: {
                $set: false,
            },
            generatedCode: {
                $set: false
            },
            items:{
                $set: payload.salesOrders 
            }
        }
    }),
    [ActionsTypes.POST_SALES_ORDERS_SUCCESS]: (state, payload) => {
        const oldItemKiosk  = cloneDeep(state.itemsKiosk.items);
        const itemsKiosks   = payload.codeGenerated.itemsKiosk;

        forEach(itemsKiosks, (elem,) => {
            const idxElem = findIndex(oldItemKiosk, { id : elem.id });
            oldItemKiosk[idxElem] = elem
        });

        return update(state, {
            salesOrders: {
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: false,
                },
                generatedCode: {
                    $set: payload.codeGenerated
                }
            },
            itemsKiosk: {
                items: {
                    $set: oldItemKiosk
                }
            }
        })
    },
    [ActionsTypes.POST_SALES_ORDERS_FAILURE]: (state, payload) => {
        const error = payload.error;

        return update(state, {
            salesOrders: {
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: error.data,
                },
                generatedCode: {
                    $set: false
                }
            }
        })
    },
    [ActionsTypes.GET_SALES_ORDERS_CODES]: (state) => update(state, {
        salesOrders: {
            isLoading: {
                $set: true,
            },
            generatedCode: {
                $set: false
            },
            error:{
                $set: false
            }
        },
        itemsKiosk:{
            selectedItem:{
                $set: false
            }
        },
    }),
    [ActionsTypes.GET_SALES_ORDERS_CODES_SUCCESS]: (state, payload) => {
        const data = payload.salesOrders;
        // flag to layout correct modal
        data['review'] = true;

        return update(state, {
            salesOrders: {
                isLoading: {
                    $set: false,
                },
                generatedCode: {
                    $set: data
                },
            },
            itemsKiosk:{
                selectedItem:{
                    $set: {...payload.elem}
                }
            },
        })
    },
    [ActionsTypes.GET_SALES_ORDERS_CODES_FAILURE]: (state, payload) => update(state, {
        salesOrders: {
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
            generatedCode: {
                $set: false
            }
        }
    }),
    [ActionsTypes.POST_SALES_ORDERS_CODES_RENEW]: (state) => update(state, {
        salesOrders: {
            isLoading: {
                $set: true,
            },
            generatedCode: {
                $set: false
            },
            error:{
                $set: false
            }
        },
        itemsKiosk:{
            selectedItem:{
                $set: false
            }
        },
    }),
    [ActionsTypes.POST_SALES_ORDERS_CODES_RENEW_SUCCESS]: ( state, payload ) => {
        const oldCodesHistory           = [...state.codesHistories.items];
        const data                      = payload.salesOrders;
        const idToReplace               = findIndex(oldCodesHistory, {'id' : data.id});
        data['review']                  = true;
        oldCodesHistory[idToReplace]    = data;

        return update(state, {
            salesOrders: {
                isLoading: {
                    $set: false,
                },
                generatedCode: {
                    $set: data
                },
                error:{
                    $set: false
                }
            },
            codesHistories: {
              items: {
                  $set: [...oldCodesHistory]
              }
            },
            itemsKiosk:{
                selectedItem:{
                    $set: {...payload.elem}
                }
            },
        })
    },
    [ActionsTypes.POST_SALES_ORDERS_CODES_RENEW_FAILURE]: ( state, payload ) => update( state, {
        salesOrders: {
            isLoading: {
                $set: true,
            },
            generatedCode: {
                $set: false
            },
        },
        codesHistories: {
            error: {
                $set: payload.error
            }
        },
        itemsKiosk: {
            selectedItem: {
                $set: false
            }
        },
    } ),

    [ActionsTypes.UPDATE_SALES_ORDERS]: (state, payload) => {
        return update(state, {
            salesOrders: {
                items: {
                    $set: payload.salesOrders
                }
            }
        })
    },

    [ActionsTypes.UPDATE_USER_CONSIGNMENT_INFO]: (state,) => {
        return update(state, {
            salesHistories: {
                isLoading:{
                    $set: true,
                }
            }
        })
    },
    [ActionsTypes.UPDATE_USER_CONSIGNMENT_INFO_SUCCESS]: (state, payload) => {
        const saleHistory           = payload.salesOrder;
        const oldSaleOrderList      = cloneDeep(state.salesHistories.items);
        const idxSalesOder          = findIndex(oldSaleOrderList, {id: saleHistory.id});
        oldSaleOrderList[idxSalesOder] = saleHistory;
        return update(state, {
            salesHistories: {
                isLoading:{
                    $set: false,
                },
                items: {
                    $set: oldSaleOrderList
                },
                error:{
                    $set: false
                }
            }
        })
    },
    [ActionsTypes.UPDATE_USER_CONSIGNMENT_INFO_FAILURE]: (state, payload) => {
        return update(state, {
            salesHistories: {
                error: {
                    $set: payload.error
                }
            }
        })
    },

    [ActionsTypes.PUT_SALES_ORDERS_DEPOSIT_CONTRACT]: (state,) => {
        return update(state, {
            salesHistories: {
                isLoading:{
                    $set: true,
                },
                contractURL: {
                    items: {
                        $set: ''
                    }
                },
                error:{
                    $set: false
                }
            }
        })
    },
    [ActionsTypes.PUT_SALES_ORDERS_DEPOSIT_CONTRACT_SUCCESS]: (state,) => {

        return update(state, {
            salesHistories: {
                isLoading:{
                    $set: false,
                },
                error:{
                    $set: false
                },
            }
        })
    },
    [ActionsTypes.PUT_SALES_ORDERS_DEPOSIT_CONTRACT_FAILURE]: (state, payload) => {
        return update(state, {
            salesHistories: {
                error: {
                    $set: payload.error
                }
            }
        })
    },

    [ActionsTypes.POST_SEND_SMS]: (state,) => {
        return update(state, {
            salesOrders: {
                isLoading:{
                    $set: true,
                },
                error:{
                    $set: false
                }
            }
        })
    },
    [ActionsTypes.POST_SEND_SMS_SUCCESS]: (state, payload) => {
        const oldSalesOrder         = state.salesOrders.generatedCode;
        const salesOrder            = payload.salesOrder;
        const translations          = state.languages.translations;
        oldSalesOrder['message']    = translations['common__message__SMS_sent_success'];
        oldSalesOrder['sendStatus'] = true;
        const newSalesOrder         = merge(oldSalesOrder, salesOrder);

        return update(state, {
            salesOrders: {
                isLoading:{
                    $set: false,
                },
                error:{
                    $set: false
                },
                generatedCode: {
                    $set: newSalesOrder
                }
            }
        })
    },
    [ActionsTypes.POST_SEND_SMS_FAILURE]: (state, payload) => {
        const error                 = payload.error;
        const salesOrder            = cloneDeep(state.salesOrders.generatedCode);
        const translations          = state.languages.translations;
        salesOrder['message']       = translations[error.data.traductionKey];
        salesOrder['sendStatus']    = true;

        return update(state, {
            salesOrders: {
                isLoading:{
                    $set: false,
                },
                // error: {
                //     $set: error
                // },
                generatedCode: {
                    $set: salesOrder
                }
            }
        })
    },

    [ActionsTypes.POST_SEND_EMAIL]: (state,) => {
        return update(state, {
            salesOrders: {
                isLoading:{
                    $set: true,
                },
                error:{
                    $set: false
                }
            }
        })
    },
    [ActionsTypes.POST_SEND_EMAIL_SUCCESS]: (state, payload) => {
        const oldSalesOrder         = state.salesOrders.generatedCode;
        const translations          = state.languages.translations;
        oldSalesOrder['message']    = translations['modal__forgotPassword__modalSuccess_title'];
        oldSalesOrder['sendStatus'] = true;
        return update(state, {
            salesOrders: {
                isLoading:{
                    $set: false,
                },
                error:{
                    $set: false
                },
                generatedCode: {
                    $set: oldSalesOrder
                }
            }
        })
    },
    [ActionsTypes.POST_SEND_EMAIL_FAILURE]: (state, payload) => {
        const error                 = payload.error;
        const salesOrder            = cloneDeep(state.salesOrders.generatedCode);
        const translations          = state.languages.translations;
        salesOrder['message']       = translations[error.data.traductionKey];
        salesOrder['sendStatus']    = true;

        return update(state, {
            salesOrders: {
                isLoading:{
                    $set: false,
                },
                // error: {
                //     $set: error
                // },
                generatedCode: {
                    $set: salesOrder
                }
            }
        })
    },

    [ActionsTypes.GET_PRINT_CODE_TICKET]: (state) => {
        return update(state, {
            salesHistories: {
                isLoading: {
                    $set: true,
                },
                error: {
                    $set: false,
                },
            },
        })
    },

    [ActionsTypes.GET_PRINT_CODE_TICKET_SUCCESS]: (state, payload) => {
        return update( state, {
            salesHistories: {
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: false,
                },
                receipt: {
                    $set: payload.ticket
                }
            }
        })
    },
    [ActionsTypes.GET_PRINT_CODE_TICKET_FAILURE]: (state, payload) => update(state, {
        salesHistories: {
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
        },
    }),

};

export default salesOrders