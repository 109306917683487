import ActionsTypes from '../../constants/ActionsTypes';

const login = {
    getLogin: (user) => ({
        type: ActionsTypes.GET_LOGIN,
        payload: {
            ...user
        },
    }),
    getLoginSuccess: (user) => ({
        type: ActionsTypes.GET_LOGIN_SUCCESS,
        payload: {
            user,
        },
    }),
    getLoginFailure: (error) => ({
        type: ActionsTypes.GET_LOGIN_FAILURE,
        payload: {
            error,
        },
    }),

    postForgotPassword: ( login ) => ({
        type: ActionsTypes.POST_FORGOT_PASSWORD,
        payload: {
            login
        },
    }),
    postForgotPasswordSuccess: ( response ) => ({
        type: ActionsTypes.POST_FORGOT_PASSWORD_SUCCESS,
        payload: { response },
    }),
    postForgotPasswordFailure: (error) => ({
        type: ActionsTypes.POST_FORGOT_PASSWORD_FAILURE,
        payload: {
            error,
        },
    }),


    getCurrentUser: (token) => ({
        type: ActionsTypes.GET_CURRENT_USER,
        payload: {
            token
        },
    }),

    changePathUser: (path) => ({
        type: ActionsTypes.CHANGE_PATH_USER,
        payload: {
            path,
        }
    }),
    getLogout: () => ({
        type: ActionsTypes.GET_LOGOUT,
        payload: {}
    })

};

export default login;