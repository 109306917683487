import { find, isEmpty, isUndefined, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from "react-redux";
// redux
import { bindActionCreators } from "redux";
import styled from 'styled-components';
import {shortcutPicsConfig} from '../../constants/AssetsPicturesConfigList'
import NotifLineSettings from "../../components/Ui/NotifLineSettings";
import ToggleUserSettings from "../../components/Ui/ToggleUserSettings";
import UserAuthorizationLayout from "../../components/UserAuthorizationLayout/UserAuthorizationLayout";
import userPrivileges from "../../constants/RoleList";
import {
    userActionCreators
} from "../../state/actions";
import {
    selectSettingDelivery,
    selectSettingDeliveryArrival,
    selectSettingDeliveryDeparture,
    selectSettingIssueRaised,
    selectSettingMaintainerArrival,
    selectSettingMaintainerDeparture,
    selectSettingNotifUserConnection,
    selectSettingSalesState,
    selectSettingStockState
} from '../../state/selectors/userSettings';
// import CSS
import {
    PageWrapper
} from '../../theme/GlobalCss';

/////////////////////////////
//  START style
////////////////////////////
const UserSettingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 36px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 68px;
    justify-content: flex-start;
`;
const ComponentWrapper = styled.div`
    margin-bottom: 60px;
`;

const ComponentWrapperTitle = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    color: ${ props => props.theme.colors.cancel };
    height: 36px;
    font-size: ${ props => props.theme.font.fm};
    color: ${ props => props.theme.colors.menu.link };
    font-weight: bold;
    margin-bottom: 35px;
`;
const ComponentWrapperBody = styled.div`
    margin-left: 10px;
    display: grid;
    grid-template-columns: repeat(8, 80px);
    grid-template-rows: auto;
    grid-row-gap: 15px;
    grid-column-gap: 25px ;
    margin-top: 26px;
`;
const ToggleShortcutWrapper = styled.div`
    display: grid;
    grid-template-rows: 26px 50px auto ;
    grid-row-gap: 14px;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    text-align: center;
`;
const ImageWrapper  = styled.div`
    background-color: ${ props => props.theme.colors.wedealBlueOther };
    border-radius: 3px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const NotificationBodyWrapper = styled.div`
    display: grid;
    grid-template-columns: 200px max-content;
    grid-template-rows: 60px;
    grid-row-gap: 30px;
    margin-bottom: 60px;
`;


const ShortcutPicto = styled.img`
   
`


/////////////////////////////
//  END style
////////////////////////////

class UserSetting extends Component {
    static propTypes = {
        actionUser:             PropTypes.object,
        translation:            PropTypes.object,
        userSettings:           PropTypes.object,
        delivery:               PropTypes.array,
        deliveryArrival:        PropTypes.array,
        deliveryDeparture:      PropTypes.array,
        issueRaised:           PropTypes.array,
        maintainerArrival:      PropTypes.array,
        maintainerDeparture:    PropTypes.array,
        notifUserConnection:    PropTypes.array,
        salesState:             PropTypes.array,
        stockState:             PropTypes.array,
    };

    componentDidMount() {
        this.getUserSetting()
      
    }

    getUserSetting = () => {
        const { actionUser } = this.props;
        actionUser.getUserSettings();
    };

    toggleSelectedHandler = (id, checked) => {
        const { actionUser, userSettings } = this.props;

        if( checked && ( userSettings.countIsTrue < 4 || isUndefined(userSettings.countIsTrue) ) ) {
            actionUser.putUserSettings(id, checked)
        }
        if( !checked ) {
            actionUser.putUserSettings(id, checked)
        }
    };

    toggleNotifSelectedHandler = (id, checked) => {
        const { actionUser } = this.props;
        actionUser.putUserNotifSettings(id, checked)

    };

    render() {
        const { 
            translations,
            userSettings,
            deliveryArrival,
            deliveryDeparture,
            delivery,
            maintainerArrival,
            maintainerDeparture,
            stockState,
            salesState,
            notifUserConnection,
            issueRaised,
        } = this.props;

        if(isEmpty(userSettings.items) && isEmpty(deliveryArrival)) {
            return <div></div>
        }

        return (
            <PageWrapper>
                <UserSettingWrapper>
                    <ComponentWrapper>
                        <ComponentWrapperTitle>
                            {
                                translations['page__userSettings__shortcuts_title']+' :'
                            }
                        </ComponentWrapperTitle>
                        <ComponentWrapperBody>
                            {
                                // spelling error between recette (userShorcuts) and dev (userShortcuts)  fouad au courant
                                map(userSettings.items.userShorcuts, ( elem, id ) => {
                                    const pic = find(shortcutPicsConfig, {id:elem.id})
                                    return(
                                    <ToggleShortcutWrapper
                                        key={id}
                                    >
                                        <ToggleUserSettings
                                            checked={elem.isSelected}
                                            onChangeHandler={this.toggleSelectedHandler}
                                            id={elem.id}
                                        />
                                        <ImageWrapper>       
                                            {!isUndefined(pic) && <ShortcutPicto src={`${pic.picture}`} />}
                                        </ImageWrapper>
                                        {
                                            translations[elem.translationKey]
                                        }
                                    </ToggleShortcutWrapper>
                                    )
                                })
                            }
                        </ComponentWrapperBody>
                    </ComponentWrapper>

                    <ComponentWrapper>
                        <ComponentWrapperTitle>
                            {
                                translations['page__userSettings__notif_title']+" :"
                            }
                        </ComponentWrapperTitle>

                        <NotificationBodyWrapper>

                            <div>
                                {
                                    translations['page__userSettings__notif_deliverFollowing_title']+" :"
                                }
                            </div>

                                <NotifLineSettings
                                    translations={translations}
                                    tittle={'page__userSettings__notif_deliverFollowing_onArrival'}
                                    data={deliveryArrival}
                                    onChangeHandler={this.toggleNotifSelectedHandler}
                                />
                            <div>

                            </div>
                                <NotifLineSettings
                                    translations={translations}
                                    tittle={'page__userSettings__notif_deliverFollowing_onFinish'}
                                    data={deliveryDeparture}
                                    onChangeHandler={this.toggleNotifSelectedHandler}
                                />
                            <div>

                            </div>
                            <NotifLineSettings
                                translations={translations}
                                tittle={'page__userSettings__notif_deliverFollowing_summary'}
                                data={delivery}
                                onChangeHandler={this.toggleNotifSelectedHandler}
                            />


                        </NotificationBodyWrapper>
                        <NotificationBodyWrapper>

                            <div>
                                {
                                    translations['page__userSettings__notif_technicianFollowing_title']+" :"
                                }
                            </div>

                            <NotifLineSettings
                                translations={translations}
                                tittle={'page__userSettings__notif_technicianFollowing_onArrival'}
                                data={maintainerArrival}
                                onChangeHandler={this.toggleNotifSelectedHandler}
                            />
                            {/*
                            <div></div>
                            <NotifLineSettings
                                translations={translations}
                                tittle={'page__userSettings__notif_technicianFollowing_onFinish'}
                                data={maintainerDeparture}
                                onChangeHandler={this.toggleNotifSelectedHandler}
                            />
                            */
                            }
                        </NotificationBodyWrapper>

                        {
                            <UserAuthorizationLayout
                                name={userPrivileges.view[13]}
                            >
                                <NotificationBodyWrapper>

                                    <div>
                                        {
                                            translations['page__userSettings__notif_stockStatusSummary_title']+" :"
                                        }
                                    </div>

                                    <NotifLineSettings
                                        translations={translations}
                                        tittle={'page__userSettings__notif_stockStatusSummary_mailSummary'}
                                        data={stockState}
                                        onChangeHandler={this.toggleNotifSelectedHandler}
                                    />

                                </NotificationBodyWrapper>
                            </UserAuthorizationLayout>
                        }

                        {
                            <UserAuthorizationLayout
                                name={userPrivileges.view[0]}
                            >
                                <NotificationBodyWrapper>

                                    <div>
                                        {
                                            translations['page__userSettings__notif_salesSummary_title']+" :"
                                        }
                                    </div>

                                    <NotifLineSettings
                                        translations={translations}
                                        tittle={'page__userSettings__notif_salesSummary_mailSummary'}
                                        data={salesState}
                                        onChangeHandler={this.toggleNotifSelectedHandler}
                                    />

                                </NotificationBodyWrapper>
                            </UserAuthorizationLayout>
                        }

                        {
                            <UserAuthorizationLayout
                                name={userPrivileges.view[22]}
                            >
                                <NotificationBodyWrapper>

                                    <div>
                                        {
                                            translations['page__userSettings__notif_userConnexion_title']+" :"
                                        }
                                    </div>

                                    <NotifLineSettings
                                        translations={translations}
                                        tittle={'page__userSettings__notif_userConnexion_connexionNotif'}
                                        data={notifUserConnection}
                                        onChangeHandler={this.toggleNotifSelectedHandler}
                                    />

                                </NotificationBodyWrapper>
                            </UserAuthorizationLayout>
                        }

                        <NotificationBodyWrapper>

                            <div>
                                {
                                    translations['page__userSettings__notif_issueRaised_title']+" :"
                                }
                            </div>

                            <NotifLineSettings
                                translations={translations}
                                tittle={'page__userSettings__notif_issueRaised_material'}
                                data={issueRaised}
                                onChangeHandler={this.toggleNotifSelectedHandler}
                            />

                        </NotificationBodyWrapper>
                    </ComponentWrapper>
                </UserSettingWrapper>


            </PageWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        userSettings: state.userSettings,
        deliveryArrival: selectSettingDeliveryArrival(state),
        deliveryDeparture: selectSettingDeliveryDeparture(state),
        delivery: selectSettingDelivery(state),
        maintainerArrival: selectSettingMaintainerArrival(state),
        maintainerDeparture: selectSettingMaintainerDeparture(state),
        stockState: selectSettingStockState(state),
        salesState: selectSettingSalesState(state),
        notifUserConnection: selectSettingNotifUserConnection(state),
        issueRaised: selectSettingIssueRaised(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionUser: bindActionCreators( userActionCreators, dispatch ),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(UserSetting)
