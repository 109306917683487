import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TagImgAndText from "../TagImgAndText";
import DropDownStatus from "../../../assets/icones/header/dropdown-status.png";

import { Transition } from "react-transition-group";

//////////////////////////////
// START Component style
/////////////////////////////
const DropdownButton = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 3px;
    background-color: ${ props => props.theme.colors.primary };
    text-transform: ${ props => props.upper ? 'uppercase' : 'none' };
    cursor: pointer;
`;
const Paper = styled.div`
    position: absolute;
    display: grid;
    grid-template-columns: max-content;
    grid-auto-rows: 40px;
    justify-content: stretch;
    align-items: stretch;
    background-color: ${ props => props.theme.colors.white };
    border: 1px solid ${ props => props.theme.colors.borderTable };
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    padding: 0 20px;
    top: ${ props => props.topPosition + "px" };
    left: 50%;
    z-index: 1;
    border-radius: 4px;
    overflow: hidden;
    transition: all 300ms;
    transform-origin: top;
    transform: translate(-50%) scaleY(${ ( { menuTransition } ) => {
        switch ( menuTransition ) {
            case "entering":
                return "0"
            case "entered":
                return "1"
            case "exiting":
                return "0"
            case "exited":
                return "0"
            default:
                return "0"
        }
    } });
`;
const PaperList = styled.span`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const StatusKioskDropDown = styled.div`
    display: flex;
    height: 6px;
    width: 13px;
    box-sizing: border-box;
    transition: all .5s ease;
    transform: rotate( ${ props => props.open ? 180 : 0 }deg );
`;

/////////////////////////////
// END component Style
////////////////////////////

/**
 * @param {array}       data
 * @param {boolean}     upper
 * @param {function}    selectActionsHandler
 * @param {string}      paperTopPosition
 * @param {object}       translations
 */
export class DropdownKioskStatus extends Component {
	static propTypes = {
		PaperTopPosition: PropTypes.string,
		data: PropTypes.object,
		upper: PropTypes.bool,
		selectActionsHandler: PropTypes.func,
		translations: PropTypes.object,
	};

	state = {
		showPaper: false,
	};
	refCollectionDropdown = {};

	showPaperHandler = () => {
		this.setState( {
			showPaper: true
		} );

		document.addEventListener( 'click', this.closePaperHandlerDropdown );
	};

	componentWillUnmount = () => {
		document.removeEventListener( 'click', this.closePaperHandlerDropdown );
	};

	closePaperHandlerDropdown = () => {

		document.removeEventListener( 'click', this.closePaperHandlerDropdown );
		this.setState( {
			showPaper: false
		} )
	};

	render() {
		const { data, PaperTopPosition, upper, translations } = this.props;

		const paperList = (
			<Fragment>
				<PaperList
					id={ 'Imprimante' }
					name={ 'Imprimante' }
				>
					{
						<TagImgAndText
							idLevel={ data.printIntegrity.level }
							text={ translations['page__kioskManagement__kioskTable_tableHeader_printer'] }
							width={ '15' }
						/>
					}
				</PaperList>
				<PaperList
					id={ 'Flux bancaires' }
					name={ 'Flux bancaires' }
				>
					{
						<TagImgAndText
							idLevel={ data.bankFlowIntegrity.level }
							text={ translations['page__kioskManagement__kioskTable_tableHeader_bankFlow'] }
							width={ '15' }
						/>
					}
				</PaperList>
				<PaperList
					id={ 'Carte SIM' }
					name={ 'Carte SIM' }
				>
					{
						<TagImgAndText
							idLevel={ data.simCardIntegrity.level }
							text={ translations['page__kioskManagement__kioskTable_tableHeader_SIMCard'] }
							width={ '15' }
						/>
					}
				</PaperList>
				<PaperList
					id={ 'Autre' }
					name={ 'Autre' }
				>
					{
						<TagImgAndText
							idLevel={ data.otherIntegrity.level }
							text={ translations['page__kioskManagement__kioskTable_tableHeader_others'] }
							width={ '15' }
						/>
					}
				</PaperList>

			</Fragment>

		);
		return (
			<DropdownButton
				onClick={ this.showPaperHandler }
				upper={ upper }
			>
				<TagImgAndText
					idLevel={ data.globalIntegrity.level }
					width={ '34px' }
					margin={ '5px' }
				/>
				<StatusKioskDropDown
					open={ this.state.showPaper }
				>
					<img src={ DropDownStatus }/>
				</StatusKioskDropDown>

				<Transition
					in={ this.state.showPaper }
					timeout={ {
						appear: 0,
						enter: 0,
						exit: 300,
					} }
					mountOnEnter
					unmountOnExit
				>
					{ state => (
						<Paper
							topPosition={ PaperTopPosition }
							ref={ ( element ) => {
								this.dropdown = element
							} }
							menuTransition={ state }
						>
							{ paperList }
						</Paper>
					) }
				</Transition>
			</DropdownButton>
		)
	}
}

export default DropdownKioskStatus
