class StringFormater {
  /**
     *
     * @param   {string}        message to display with flag inside ( e.g. : '{0}' )
     * @param   {array}         parameter list to put into message
     */
  static ResponseMessageParser (message, parameter) {
    let messageFormat = message;
    const wordList = parameter;
    if (messageFormat) {
      for (let i = 0; i < wordList.length; i++) {
        let regEx = new RegExp('\\{' + (i) + '\\}', 'gm');
        messageFormat = messageFormat.replace(regEx, wordList[i])
      }
    }
    return messageFormat
  }
}

export default StringFormater
