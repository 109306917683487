import {
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';
import {
    globalLoadingActionCreators,
    errorManagerActionCreators,
    exportDataActionCreators
} from '../actions';
import { API_TOKEN_DL_FILE } from '../../constants/ApiUrl'
import * as R from '../../constants/ApiConstants'
import { selectIdsUser } from '../selectors/user'

const getExportData = function* ({ payload }) {

    const inputDate     = payload.date;
    const scope         = payload.scope;
    const user          = yield select(selectIdsUser);
    const idInput       = payload.id || null;
    const apiCall       = R.GET_EXPORT_DATA( user, scope, idInput );
    let data;

    yield put(globalLoadingActionCreators.startLoading());

    try{
        if( apiCall ){
            if( scope === 'items' || scope === 'promotions' || scope === 'stock'){
                data = yield API_TOKEN_DL_FILE(user.jwt).get( apiCall);
            }else {
                data = yield API_TOKEN_DL_FILE(user.jwt).post( apiCall, inputDate);
            }
        }

        let fileName    = data.headers['content-disposition'].split('=');
        const type      = data.headers['content-type'];

        const url       = window.URL.createObjectURL(new Blob([data.data], {type: type}));
        const link      = document.createElement('a');
        link.href       = url;

        link.setAttribute('download', fileName[1]);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        yield put(exportDataActionCreators.getExportDataSuccess(data.data));

    } catch (e) {
        let error = e.response;
        if(error.status === 400){
            yield new Promise(resolve => {
                const reader = new FileReader();
                reader.onload = (event) => resolve(JSON.parse(event.target.result));
                reader.readAsText(error.data);
            }).then( result =>{
                error.data = result
            }).catch( error =>{
                error.status = null
            });

            yield put(errorManagerActionCreators.createError(error));

        }else{
            yield put(errorManagerActionCreators.createError(e.response));

            yield put(exportDataActionCreators.getExportDataFailure(e.response));
        }
    }

    yield put(globalLoadingActionCreators.endLoading());

};


const sagas = function* () {
    yield takeLatest(ActionsTypes.GET_EXPORT_DATA, getExportData);
};

export default sagas