import {
    put, select,
    takeLatest,
} from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';
import {
    dashBoardActionCreators,
    errorManagerActionCreators,
    globalLoadingActionCreators
} from '../actions';
import { selectIdsUser } from '../selectors/user'
import { selectDashboardSetting } from '../selectors/dashBoard'
import API from '../../constants/ApiUrl'

import * as R from '../../constants/ApiConstants'

const getDashboardSetting = function* () {

    const user = yield select(selectIdsUser);
    yield put( globalLoadingActionCreators.startLoading() );


    try{
        const data = yield API(user.jwt).get(R.GET_DASHBOARD_SETTING());

        yield put(dashBoardActionCreators.getDashboardSettingSuccess(data.data));
    } catch (e) {

        yield put(errorManagerActionCreators.createError(e.response));
        yield put(dashBoardActionCreators.getDashboardSettingFailure(e.response));
    }

    yield put( globalLoadingActionCreators.endLoading() );

};

const putDashboardSetting = function* ({ payload }) {

    const user = yield select(selectIdsUser);
    const dashboard = {
        'dashBoardSettingInputDtos' : yield select(selectDashboardSetting)
    };
    const dateRange = payload.dateFormat;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    try{
        const data = yield API(user.jwt).put(R.PUT_DASHBOARD_SETTING(), dashboard);
        yield put(dashBoardActionCreators.putDashboardSettingSuccess(data.data));
        yield put(dashBoardActionCreators.getDashboard(dateRange, timeZone));
    } catch (e) {

        yield put(errorManagerActionCreators.createError(e.response));
        yield put(dashBoardActionCreators.putDashboardSettingFailure(e.response));
    }


};

const getDashboard = function* ({ payload }) {

    const user = yield select(selectIdsUser);

    try{
        const data = yield API(user.jwt).post(R.GET_DASHBOARD(user), payload);
        yield put(dashBoardActionCreators.getDashboardSuccess(data.data));
    } catch (e) {

        yield put(errorManagerActionCreators.createError(e.response));
        yield put(dashBoardActionCreators.getDashboardFailure(e.response));
    }


};

const sagas = function* () {
    yield takeLatest(ActionsTypes.GET_DASHBOARD_SETTINGS, getDashboardSetting);
    yield takeLatest(ActionsTypes.PUT_DASHBOARD_SETTINGS, putDashboardSetting);
    yield takeLatest(ActionsTypes.GET_DASHBOARD, getDashboard);
};

export default sagas