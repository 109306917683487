import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import '../../../DatePickerStyle/react-datepicker.css'

import styled from 'styled-components'

// import components
import Modal from '../../../components/Ui/Modals/Modal'
import { fr } from 'date-fns/locale'
import moment from 'moment'
import DateFormat from "../../../helpers/dateFormat";
import theme from "../../../theme/theme";
import Button from "../../../components/Ui/Buttons";

/// //////////////////////////
//  START style
/// /////////////////////////
const ModalDateTimeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 611px;
    padding: 26px;
`;
const CalandarsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: ${ props => props.marginTop ? '23px' : '0' };
    height: ${ props => props.height ? props.height + 'px' : 'inherit' } ;
`;
const InputsWrapper = styled.div`
    width: 611px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;
    font-size: 16px;
`;
const PaireInputWrapper = styled.div`
    display: flex;
    width: 280px;
    justify-content: space-between;
    z-index: 2;
`;
const InputStyle = styled.div`
    width: 86px;
    height: 14px;
    padding: 13px 20px;
    border: 1px solid ${ props => props.theme.colors.cancel };
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const TextAndInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const TextBelowInput = styled.p`
    color: ${ props => props.theme.colors.primary };
    margin: 0;
    padding-bottom: 5px;
`;
const ButtonBottm = styled.div`
    padding: 11px 15px;
    text-align: center;
    background-color: ${ props => props.bcg };
    border-radius: 3px;
    margin-right: ${ props => props.margin ? '22px' : '0' };
    color: ${ props => props.theme.colors.white };
    cursor: pointer;
`;
const BottomButtonWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    height: 39px;
    position: relative;
`;
const ErrorMsg = styled.span`
  display: flex;
  position: absolute;
  top: -17px;
  right: 0px;
  width: max-content;
  direction: rtl;
  color: ${ props => props.theme.colors.error };
`;
/// //////////////////////////
//  END style
/// /////////////////////////

const DateTimePickerFuture = ( {
                                   isStarted,
                                   startDate,
                                   endDate,
                                   startDateHandleChange,
                                   endDateHandleChange,
                                   startTimeHandleChange,
                                   endTimeHandleChange,
                                   formatDate,
                                   cancelUpdateHandler,
                                   updateDate,
                                   translations,
                                   isValidDate
                               } ) => {

    // check if startDate is same of today : boolean
    const isToDay = moment().isSame( moment( startDate ), 'day' );

    // check if startDate and endDate is same day : boolean
    const isSameDay = moment( startDate ).isSame( moment( endDate ), 'day' );

    return (
        <Modal>
            <ModalDateTimeWrapper>
                <InputsWrapper>
                    <PaireInputWrapper>
                        <TextAndInputWrapper>
                            <TextBelowInput>
                                { translations['modal__dateSelection__startDate'] }
                                {/* Date de début */ }
                            </TextBelowInput>
                            <InputStyle>
                                { formatDate( startDate ) }
                            </InputStyle>
                        </TextAndInputWrapper>
                        <TextAndInputWrapper>
                            <TextBelowInput>
                                { translations['modal__dateSelection__startDate'] }
                                {/* Heure de début */ }
                            </TextBelowInput>
                            <DatePicker
                                selected={ startDate }
                                onChange={ startTimeHandleChange }
                                showTimeSelect
                                showTimeSelectOnly
                                minTime={ DateFormat.minStartTimeCalculate( startDate, isToDay ) }
                                maxTime={ DateFormat.maxStartTimeCalculate( startDate, endDate, isSameDay ) }
                                timeIntervals={ 15 }
                                timeCaption='Time'
                                timeFormat='HH:mm'
                                dateFormat='HH:mm'
                                disabled={ isStarted }
                            />
                        </TextAndInputWrapper>
                    </PaireInputWrapper>

                    <PaireInputWrapper>
                        <TextAndInputWrapper>
                            <TextBelowInput>
                                { translations['modal__dateSelection__endDate'] }
                                {/* Date de fin */ }
                            </TextBelowInput>
                            <InputStyle>
                                { formatDate( endDate ) }
                            </InputStyle>
                        </TextAndInputWrapper>
                        <TextAndInputWrapper>
                            <TextBelowInput>
                                { translations['modal__dateSelection__endDate'] }
                                {/* Heure de fin */ }
                            </TextBelowInput>
                            <InputStyle>
                                <DatePicker
                                    selected={ endDate }
                                    onChange={ endTimeHandleChange }
                                    showTimeSelect
                                    showTimeSelectOnly
                                    minTime={ DateFormat.minEndTimeCalculate( startDate, endDate, isSameDay, isStarted ) }
                                    maxTime={ moment( startDate ).endOf( 'day' ).toDate() }
                                    timeIntervals={ 15 }
                                    timeCaption='Time'
                                    timeFormat='HH:mm'
                                    dateFormat='HH:mm'
                                />
                            </InputStyle>
                        </TextAndInputWrapper>
                    </PaireInputWrapper>

                </InputsWrapper>
                <CalandarsWrapper
                    height='320'
                >
                    {/*START DATE CALENDAR*/ }
                    <DatePicker
                        selected={ !isStarted && startDate }
                        onChange={ startDateHandleChange }
                        locale={ fr }
                        minDate={ new Date() }
                        maxDate={
                            isStarted
                                ? moment().subtract( 1, 'days' ).toDate()
                                : moment( endDate ).subtract( 15, 'minutes' ).toDate()
                        }
                        inline
                    />
                    {/*END DATE CALENDAR*/ }
                    <DatePicker
                        selected={ endDate }
                        onChange={ endDateHandleChange }
                        inline
                        locale={ fr }
                        minDate={ DateFormat.minEndDateCalculate(startDate, isStarted) }
                    />
                </CalandarsWrapper>

                <CalandarsWrapper marginTop>
                    <BottomButtonWrapper>

                    </BottomButtonWrapper>
                    <BottomButtonWrapper>
                        <ButtonBottm
                            onClick={ cancelUpdateHandler }
                            bcg={ theme.colors.error }
                            margin
                        >
                            { translations['common__text__cancel'] }
                        </ButtonBottm>
                        <Button
                            bgColor={ theme.colors.validation }
                            actionHandler={ updateDate }
                            text={ translations['common__text__save'] }
                            disabled={ !isValidDate }
                        />
                        {
                            !isValidDate &&
                            <ErrorMsg>
                                { translations['common__message__dateSelection_update_invalidRangeDate'] }
                            </ErrorMsg>
                        }
                    </BottomButtonWrapper>
                </CalandarsWrapper>

            </ModalDateTimeWrapper>
        </Modal>
    )
};

DateTimePickerFuture.propTypes = {
    isStarted: PropTypes.bool,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    startDateHandleChange: PropTypes.func,
    endDateHandleChange: PropTypes.func,
    startTimeHandleChange: PropTypes.func,
    endTimeHandleChange: PropTypes.func,
    formatDate: PropTypes.func,
    cancelUpdateHandler: PropTypes.func,
    updateDate: PropTypes.func,
    translations: PropTypes.object
};

export default DateTimePickerFuture
