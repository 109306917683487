import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import { cloneDeep } from 'lodash'

extendUpdate(update);

const modals = {

    [ActionsTypes.HYDRATE_MODAL]: ( state, payload ) => {
        const translations = state.languages.translations;
        const modalInput = payload.data;
        const modal = {
            key: translations[modalInput.key],
            title: translations[modalInput.title],
            level: modalInput.level,
            show: modalInput.show,
            path: modalInput.path,
            errorMsgParams: modalInput.errorMsgParams || []
        };
        return update( state, {
            modal: {
                $set: {...modal}
            }
        } )
    },
    [ActionsTypes.CLOSE_MODAL]: ( state, ) => {

        return update( state, {
            user: {
                path: {
                    $set: ""
                },
            },
            modal: {
                key: {
                    $set: false
                },
                title: {
                    $set: ''
                },
                level: {
                    $set: ''
                },
                show: {
                    $set: false
                },
                errorMsgParams: {
                    $set : []
                }
            }
        } )
    },
    [ActionsTypes.CLOSE_MODAL_REDIRECT]: ( state, ) => {

        const path = cloneDeep(state.modal.path);

        return update( state, {
            user: {
                path: {
                    $set: path
                }
            },
            modal: {
                key: {
                    $set: false
                },
                title: {
                    $set: ''
                },
                level: {
                    $set: ''
                },
                show: {
                    $set: false
                },
                errorMsgParams: {
                    $set : []
                }
            }
        } )
    },

};
export default modals