import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import ToggleUserSettings from "../../ToggleUserSettings";

import { map } from 'lodash'


//////////////////////////////
///////// START STYLE ////////
//////////////////////////////

const NotifToggleWrapper = styled.div`
    display: grid;
    grid-template-columns: max-content min-content ;
    align-items: center;
    grid-column-gap: 10px;
`;
const UserNotifToggleLine = styled.div`
    display: grid;
    grid-template-columns: repeat(${ props => props.columns }, 1fr);
    grid-column-gap: 50px;
`;
//////////////////////////////
///////// END STYLE //////////
//////////////////////////////

/**
 *
 * @param {array} data
 * @param {object} translations
 * @param {func} onChangeHandler
 * @returns {jsx}
 */
function NotifToggleLine ( { data, translations, onChangeHandler }) {
    return (
            <UserNotifToggleLine
                columns={data.length}
            >
                {
                    map( data, ( elem, id ) => (
                        <NotifToggleWrapper
                            key={id}
                        >
                            {translations[elem.tittle]}
                            <ToggleUserSettings
                                checked={elem.checked}
                                onChangeHandler={ onChangeHandler}
                                id={elem.id}
                            />
                        </NotifToggleWrapper>
                    ) )
                }
            </UserNotifToggleLine>
    );

}

NotifToggleLine.propTypes = {
    data: PropTypes.array,
    translations: PropTypes.object,
    onChangeHandler: PropTypes.func,
};

export default NotifToggleLine;