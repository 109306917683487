import ActionsTypes from '../../constants/ActionsTypes';

const families = {
    getFamiliesAndBrandsByKiosk: () => ({
        type: ActionsTypes.GET_FAMILIES_AND_BRANDS_BY_KIOSK,
        payload: {
            
        },
    }),
    getFamiliesAndBrandsByKioskSuccess: (families) => ({
        type: ActionsTypes.GET_FAMILIES_AND_BRANDS_BY_KIOSK_SUCCESS,
        payload: {
            families,
        },
    }),
    getFamiliesAndBrandsByKioskFailure: (error) => ({
        type: ActionsTypes.GET_FAMILIES_AND_BRANDS_BY_KIOSK_FAILURE,
        payload: {
            error,
        },
    }),
    updateFamilySelected: ( id ) => ({
        type: ActionsTypes.UPDATE_FAMILY_SELECTED,
        payload: {
            id,
        },
    }),
    updateBrandsSelected: (id, isChecked) => ({
        type: ActionsTypes.UPDATE_BRANDS_SELECTED,
        payload: {
            id,
            isChecked,
        },
    }),
};

export default families;