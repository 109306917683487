import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import { forEach, assign, map } from 'lodash';

extendUpdate(update);

const itemsKiosk = {

    [ActionsTypes.GET_ITEM_KIOSK]: (state) => update(state, {
        itemsKiosk:{
            isLoading: {
                $set: true,
            },
        },
    }),
    [ActionsTypes.GET_ITEM_KIOSK_SUCCESS]: (state, payload) => {
        const itemKiosk = payload.itemKiosk;
        
        assign(itemKiosk, {actuelPrice: itemKiosk.promoPrice || itemKiosk.standardPrice});

        return update(state, {
            itemsKiosk:{
                isLoading: {
                    $set: false,
                },
                selectedItem:{
                    $set: {...itemKiosk}
                }

            },
        })
    },
    [ActionsTypes.GET_ITEM_KIOSK_FAILURE]: (state, payload) => update(state, {
        itemsKiosk:{
            isLoading: {
                $set: false,
            },
            error:{
                $set: payload.error
            }
        },
    }),
    [ActionsTypes.POST_ITEM_KIOSK_CHECK_ORDER_VALIDATION]: (state) => update(state, {
        itemsKiosk:{
            isLoading: {
                $set: true,
            },
        },
    }),
    [ActionsTypes.POST_ITEM_KIOSK_CHECK_ORDER_VALIDATION_SUCCESS]: (state, payload) => {
        const itemKiosk = payload.itemKiosk;

        assign(itemKiosk, {actuelPrice: itemKiosk.promoPrice || itemKiosk.standardPrice});

        return update(state, {
            itemsKiosk:{
                isLoading: {
                    $set: false,
                },
                selectedItem:{
                    $set: {...itemKiosk}
                }

            },
        })
    },
    [ActionsTypes.POST_ITEM_KIOSK_CHECK_ORDER_VALIDATION_FAILURE]: (state, payload) => update(state, {
        itemsKiosk:{
            isLoading: {
                $set: false,
            },
            error:{
                $set: payload.error
            }
        },
    }),
    [ActionsTypes.GET_ITEMS_KIOSK_BY_FAMILY]: (state) => update(state, {
        itemsKiosk:{
            isLoading: {
                $set: true,
            },
        },
    }),
    [ActionsTypes.GET_ITEMS_KIOSK_BY_FAMILY_SUCCESS]: (state, payload) => {
        const itemsKiosk = map(payload.itemsKiosk, (item) => {
            assign(item, {actuelPrice: item.promoPrice || item.standardPrice});
            return item
        });

        return update(state, {
            itemsKiosk:{
                isLoading: {
                    $set: false,
                },
                items:{
                    $set: itemsKiosk
                }
            },
        })
    },
    [ActionsTypes.GET_ITEMS_KIOSK_BY_FAMILY_FAILURE]: (state, payload) => update(state, {
        itemsKiosk:{
            isLoading: {
                $set: false,
            },
            error:{
                $set: payload.error
            }

        },
    }),
    [ActionsTypes.UPDATE_ITEMS_FILTER_BY_INPUT]: (state, payload) => {
        const oldState = {...state.itemsKiosk.filter};
        oldState.textFilter = payload.text;
        return update(state, {
            itemsKiosk:{
                filter: {
                    $set: {...oldState}
                }
            },
        })
    },
    [ActionsTypes.UPDATE_ITEMS_SORT_BY]: (state, payload) => {
        const oldState = {...state.itemsKiosk.filter};
        const userSelectId = parseInt(payload.id);
        forEach(oldState.sortBy, (item) => {
            item.active = item.id === userSelectId;
        });

        return update(state, {
            itemsKiosk:{
                filter: {
                    $set: {...oldState}
                }
            },
        })
    },
    [ActionsTypes.UPDATE_SELECTED_ITEM_KIOSK]: (state, payload) => {
        return update(state, {
            itemsKiosk:{
                selectedItem:{
                    $set: payload.itemKiosk
                }
            },
        })
    },
};

export default itemsKiosk