import {
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';
import {
    salesHistoriesActionCreators,
    globalLoadingActionCreators, errorManagerActionCreators
} from '../actions';
import API from '../../constants/ApiUrl'
import * as R from '../../constants/ApiConstants'
import { selectIdsUser } from '../selectors/user'


const getSalesHistories = function* ( { payload } ) {

    const strDate   = payload.date;
    const user      = yield select( selectIdsUser );
    let apiCall;

    yield put( globalLoadingActionCreators.startLoading() );

    if ( payload.id ) {
        apiCall = R.GET_SALES_HISTORIES_BY_ITEM_KIOSK( user, payload.id )
    } else {
        apiCall = R.GET_SALES_HISTORIES( user )
    }

    try {
        const data = yield API( user.jwt ).post( apiCall, strDate );

        yield put( salesHistoriesActionCreators.getSalesHistoriesSuccess( data.data ) );
    } catch ( e ) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put( salesHistoriesActionCreators.getSalesHistoriesFailure( e.response ) );
    }
    yield put( globalLoadingActionCreators.endLoading() );
};// END FUNCTION

const getSalesHistoriesBySale = function* ( { payload } ) {

    const strDate   = payload.date;
    const user      = yield select( selectIdsUser );
    user['idItem']  = payload.id;
    let apiCall;

    yield put( globalLoadingActionCreators.startLoading() );

        apiCall = R.GET_SALES_ORDERS_BY_SALES( user );
    try {
        const data = yield API( user.jwt ).get( apiCall, strDate );

        yield put( salesHistoriesActionCreators.getSalesHistoriesBySaleSuccess( data.data ) );
    } catch ( e ) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put( salesHistoriesActionCreators.getSalesHistoriesBySaleFailure( e.response ) );
    }
    yield put( globalLoadingActionCreators.endLoading() );
};// END FUNCTION

const getSalesHistoriesReceipt = function* ( { payload } ) {

    const scope         = payload.scope;
    const user          = yield select( selectIdsUser );
    user['idItem']      = payload.id;
    let apiCall;

    yield put( globalLoadingActionCreators.startLoading() );

    apiCall = R.GET_SALES_HISTORIES_MERCHANT_TICKET( user, scope );
    try {
        const data = yield API( user.jwt ).get( apiCall );

        yield put( salesHistoriesActionCreators.getSalesHistoriesReceiptSuccess( data.data ) );
    } catch ( e ) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put( salesHistoriesActionCreators.getSalesHistoriesReceiptFailure( e.response ) );
    }
    yield put( globalLoadingActionCreators.endLoading() );
};// END FUNCTION

const sagas = function* () {
    yield takeLatest( ActionsTypes.GET_SALES_HISTORIES, getSalesHistories );
    yield takeLatest( ActionsTypes.GET_SALES_HISTORIES_BY_SALE, getSalesHistoriesBySale );
    yield takeLatest( ActionsTypes.GET_SALES_HISTORIES_RECEIPT, getSalesHistoriesReceipt );
};

export default sagas