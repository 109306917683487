import { find, map, isUndefined } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import LogAlert from '../../../../assets/icones/header/bell.png'
import DropDownKioskPng from '../../../../assets/icones/header/dropdown-kiosk.png'
import Faq from '../../../../assets/icones/header/help.png'
// icone
import IconeMenu from '../../../../assets/icones/header/icone-menu-small.png'
import { shortcutPicsConfig } from '../../../../constants/AssetsPicturesConfigList'
import CR from '../../../../constants/RouteConstants'
// components
import DropdownKioskStatus from "../../DropdownKioskStatus"
/// ///////////////////////////
// START Component style
/// //////////////////////////

const HeaderTopWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: ${ props => props.theme.colors.primary };
    height: 95px;
`;
const MenuIconePosition = styled.div`
    position: absolute;
    top: 37px;
    left: 16px;
    width: 50px;
    height: 50px;
    cursor: pointer;
`;
const UserWrapper = styled.div`
    position: absolute;
    left: 76px;
    top: 43px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
`;
const UserNameText = styled.p`
    font-size: ${ props => props.theme.font.fxl };
    color: ${ props => props.theme.colors.white };
    text-decoration: underline;
    font-weight: 900;
    padding: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 300px;
`;
const UserRoleText = styled.p`
    font-size: ${ props => props.theme.font.fs };
    color: ${ props => props.theme.colors.white };
    padding: 0;
    margin: 0;
`;
const UserPrefMenu = styled.div`
    position: absolute;
    top: 37px;
    left: 412px;
    display: grid;
    grid-template-columns: repeat(4, 50px);
    grid-column-gap: 20px;
    grid-template-rows: 50px;
`;
const MenuShortCut = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    grid-column-start: ${ props => props.start };
    grid-column-end: ${ props => props.end };
    color: ${ props => props.theme.colors.white };
    font-size: ${ props => props.theme.font.fxl };
    cursor: pointer;
    background-color: ${ props => props.theme.colors.wedealBlueOther };
`;
const UserKioskWrapper = styled.div`
    position: absolute;
    top: 45px;
    right: 167px;
    display: flex;
    height: 34px;
`;
const UserKiosk = styled.div`
    padding-right: 10px;
`;
const UserKioskText = styled( UserRoleText )`
    font-size: ${ props => props.theme.font.fm };
    font-weight: 900;
    text-transform: uppercase;
    text-align: right;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 120px;
`;
const DropDownKiosk = styled.div`
    width: 34px;
    height: 34px;
    box-sizing: border-box;
    cursor: pointer;
`;
const StatusKioskWrapper = styled.div`
    position: absolute;
    top: 45px;
    left: 864px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;
const LogFaqWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    top: 40px;
    right: 21px;
    height: 33px;
    width: 60px;
`;
const LogAlertImg = styled.img`
    cursor: pointer;
    height: 24px;
`;
const RedCircleLogAlert = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 23px;
    text-align: center;
    border-radius: 100%;
    background-color: ${ props => props.theme.colors.error };
    color: ${ props => props.theme.colors.white };
    font-weight: 900;
    top: -5px;
    right: -10px;
    cursor: pointer;
`;


const ShortcutPicto = styled.img`
   
`
/// ///////////////////////////
// END Component style
/// //////////////////////////

const HeaderTop = ( { user, toogleMenu, translations, logsAlert, kioskStatusSelected } ) => {
    const completName = user.identity.firstName + ' ' + user.identity.lastName;
    const kioskSelected = find( user.identity.userKiosks, {
        'idKiosk': user.identity.kioskSelected
    } );

    return (
        <HeaderTopWrapper>
            <MenuIconePosition
                onClick={ toogleMenu }
            >
                <img
                    name='showMenu'
                    src={ IconeMenu }
                />
            </MenuIconePosition>
            <UserWrapper>
                <UserNameText>
                    { completName }
                </UserNameText>
                <UserRoleText>
                    { user.identity.rolePrivileges.role.label }
                </UserRoleText>
            </UserWrapper>
            <UserPrefMenu>
                {
                    map( user.identity.shorcuts, ( elem, id ) => {
                        const pic = find(shortcutPicsConfig, {id:elem.id})
                        return (
                            elem.isSelected &&
                                <Link
                                    key={id}
                                    to={ CR[elem.url] }
                                >
                                    <MenuShortCut
                                        start={id + 1}
                                        end={id + 2}
                                    >  
                                        {!isUndefined(pic) && <ShortcutPicto src={`${pic.picture}`} />}
                                    </MenuShortCut>
                                </Link>
                        )
                    } )
                }

            </UserPrefMenu>

            <UserKioskWrapper>
                <UserKiosk>
                    <UserKioskText>
                        { kioskSelected.idExternOwner }
                    </UserKioskText>
                    <UserKioskText>
                        { kioskSelected.idExternStore }
                    </UserKioskText>
                </UserKiosk>
                <DropDownKiosk
                    onClick={ toogleMenu }
                >
                    <img
                        src={ DropDownKioskPng }
                        name='showSwitchMachine'
                    />
                </DropDownKiosk>
            </UserKioskWrapper>

            <StatusKioskWrapper>
                <DropdownKioskStatus
                    data={kioskStatusSelected}
                    PaperTopPosition={'40'}
                    selectActionsHandler={null}
                    translations={translations}
                />
            </StatusKioskWrapper>

      <LogFaqWrapper>
        <LogAlertImg src={Faq} />
        {/* TODO: better handling off CSS */}

        <LogAlertImg
            src={LogAlert}
            onClick={ logsAlert && toogleMenu }
            name='showLogAlert'
        />
        {
            logsAlert &&
                logsAlert.length > 0 &&
                    <RedCircleLogAlert>
                        {logsAlert.length > 98 ? '99+' : `${logsAlert.length}` }
                    </RedCircleLogAlert>
        }


      </LogFaqWrapper>
    </HeaderTopWrapper>

    )
};

HeaderTop.propTypes = {
    user: PropTypes.object,
    toogleMenu: PropTypes.func
};

export default HeaderTop
