import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { map } from 'lodash'

import { Transition } from "react-transition-group";

//////////////////////////////
// START Component style
/////////////////////////////
const DropdownButton = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: ${ props => props.width + "px" };
    border-radius: 3px;
    text-transform: ${ props => props.upper ? 'uppercase' : 'capitalize' };
    background-color: ${ props => props.theme.colors.primary };
    cursor: pointer;
    margin: ${ props => props.margin ? '0 16px' : 'none' };
    padding: ${ props => props.padding ? `0 ${ props.padding }px` : '0 5px' };
`;
const ContentCheckBoxWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr min-content;
    justify-items: center;
    align-items: center;
    grid-column-gap: 5px;
    width: 100%;
    height: 100%;
`;
const TextButton = styled.p`
    margin: 0;
    padding: ${ props => props.paddingText ? `0 ${ props.paddingText }px` : '0 5px' };
    color: ${ props => props.theme.colors.white };
    font-size: ${ props => props.theme.font.fm };
`;
const DownArrow = styled.div`
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid ${ props => props.theme.colors.white };
    transition: all .5s ease;
    transform: rotate( ${ props => props.open ? 180 : 0 }deg );
`;
const Paper = styled.div`
    position: absolute;
    display: grid;
    grid-template-columns: max-content;
    grid-auto-rows: 40px;
    justify-content: stretch;
    align-items: stretch;
    background-color: ${ props => props.theme.colors.white };
    border: 1px solid ${ props => props.theme.colors.borderTable };
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    padding: 0 20px;
    top: ${ props => props.topPosition + "px" };
    right: ${ props => props.rtl ? '0' : 'inherit' };
    left: ${ props => props.rtl ? 'inherit' : '0' };
    z-index: 1;
    overflow: hidden;
    transition: all 300ms;
    transform-origin: top;
    transform: scaleY(${ ( { menuTransition } ) => {
	switch ( menuTransition ) {
		case "entering":
			return "0"
		case "entered":
			return "1"
		case "exiting":
			return "0"
		case "exited":
			return "0"
		default:
			return "0"
	}
} });
`;
const PaperList = styled.span`
    cursor: pointer;
`;
const PaperCheckbox = styled.input`
    cursor: pointer;
`;
const ListWrapper = styled.label`
    display: grid;
    grid-template-columns: min-content max-content;
    grid-column-gap: 10px;
    justify-content: start;
    align-items: center;
    cursor: pointer;
`;

/////////////////////////////
// END component Style
////////////////////////////
export class DropdownCheckbox extends Component {
	static propTypes = {
		selectActionsHandler: PropTypes.func,
		margin: PropTypes.bool,
		data: PropTypes.array,
		width: PropTypes.string,
		PaperTopPosition: PropTypes.string,
		upper: PropTypes.bool,
		title: PropTypes.string,
		translations: PropTypes.object,
		toTranslate: PropTypes.bool,
		padding: PropTypes.string,
		rtl: PropTypes.bool,
	};

	state = {
		showPaper: false,
	};

	refCollectionCheckbox = {};

	showPaperHandler = () => {
		this.setState( {
			showPaper: true
		} );
		document.addEventListener( 'click', this.closePaperHandlerCheckbox );
	};


	closePaperHandlerCheckbox = ( evt ) => {
		if ( !this.dropdownCheckbox ) {
			document.removeEventListener( 'click', this.closePaperHandlerCheckbox );
		} else {
			if ( !this.dropdownCheckbox.contains( evt.target ) ) {
				this.setState( {
					showPaper: false
				} );
				document.removeEventListener( 'click', this.closePaperHandlerCheckbox );
			}
		}
	};

	checkBoxHandler = ( evt ) => {
		const { selectActionsHandler } = this.props;

		if ( this.dropdownCheckbox.contains( evt.target ) ) {
			map( this.refCollectionCheckbox, ( elem ) => {
				if ( elem ) {
					if ( elem.contains( evt.target ) ) {
						selectActionsHandler( elem.id, elem.checked )
					}
				}
			} )
		}
	};

	render() {
		const {
			margin,
			data,
			width,
			PaperTopPosition,
			upper,
			title,
			translations,
			toTranslate,
			padding,
			paddingText,
			rtl,
		} = this.props;
		const paperList = map( data, ( elem, id ) => {
			return (
				<ListWrapper
					key={ id }
					onClick={ this.checkBoxHandler }
				>
					<PaperCheckbox
						id={ elem.id }
						value={ elem.label }
						onChange={ this.checkBoxHandler }
						checked={ elem.active }
						type='checkbox'
						ref={ ( element ) => {
							this.refCollectionCheckbox[elem.label] = element
						} }
					/>
					<PaperList

					>
						{
							toTranslate ?
								translations[elem.label]
								:
								elem.label
						}
					</PaperList>
				</ListWrapper>
			)
		} );
		return (
			<DropdownButton
				width={ width }
				upper={ upper }
				margin={ margin }
				padding={ padding }
			>
				<ContentCheckBoxWrapper
					onClick={ this.showPaperHandler }
				>
					<TextButton
						paddingText={ paddingText }
					>
						{ title }
					</TextButton>
					<DownArrow
						open={ this.state.showPaper }
					/>
				</ContentCheckBoxWrapper>
                <Transition
                    in={ this.state.showPaper }
                    timeout={ {
                        appear: 0,
                        enter: 0,
                        exit: 300,
                    } }
                    mountOnEnter
                    unmountOnExit
                >
                    { state => (
                        <Paper
                            topPosition={ PaperTopPosition }
                            ref={ ( element ) => {
                                this.dropdownCheckbox = element
                            } }
                            menuTransition={ state }
                            rtl={rtl}
                        >
                            { paperList }
                        </Paper>
                    ) }
                </Transition>
			</DropdownButton>
		)
	}
}


export default DropdownCheckbox
