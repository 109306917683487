import moment from 'moment'

class DateFormat {

    static isToday( date ) {
        return moment().isSame( moment( date ), 'day' )
    }

    /**
     *
     * @param   {Date} date              js date time
     * @param   {number} stepTime        step to add to date
     * @returns {Date}
     */
    static toDayWithStepDateTimeInMinutes( date, stepTime ) {
        const newDate = moment( date );
        const dateNow = moment().add( stepTime, 'm' ).toDate();
        return this.roundDateTime( newDate.set( {
            'hour': dateNow.getHours(),
            'minute': dateNow.getMinutes(),
            'second': dateNow.getSeconds(),
        } ).toDate() )
    }

    /**
     * check if dateEnd is inferior or equal of dateStart
     * @param   {Date} dateStart
     * @param   {Date} dateEnd
     * @returns {boolean}
     */
    static dateEndIsBefore( dateStart, dateEnd ) {
        return moment( dateEnd ).isSameOrBefore( moment( dateStart ), 'minute' )
    }

    static roundDateTime( date ) {
        const timeMinutes = date.getMinutes();
        if ( timeMinutes > 45 ) {
            return moment( date ).set( {
                'hour': date.getHours() + 1,
                'minute': 0,
                'second': 0
            } ).toDate()
        } else if ( timeMinutes > 30 ) {
            return moment( date ).minute( 45 ).toDate()
        } else if ( timeMinutes > 15 ) {
            return moment( date ).minute( 30 ).toDate()
        } else if ( timeMinutes > 0 ) {
            return moment( date ).minute( 15 ).toDate()
        }
    }

    static getDateDependOfNow( date ) {
        if ( this.dateIsBeforeNow( date ) ) {
            return this.toDayWithStepDateTimeInMinutes( date, 15 )
        } else {
            return moment( date ).toDate()
        }
    }

    static dateIsBeforeNow( date ) {
        return moment( date ).isBefore( moment() )
    }

    static dateIsAfterNow( date ) {
        return moment( date ).isSameOrAfter( moment(), 'day' )
    }

    /**
     *  calculate minTime for startTime in date picker component
     * @param {Date} startTime selected by user
     * @param {boolean} isToday if statTime is today
     * @return {Date} minTime for datePicker startTime
     */
    static minStartTimeCalculate( startTime, isToday = false ){
        let startTimeCalculate = moment(startTime).startOf('day').toDate()
        if( isToday ) {
            startTimeCalculate = this.toDayWithStepDateTimeInMinutes(new Date(), 0)
        }
        return startTimeCalculate
    }

    /**
     * calculate maxTime for startTime in date picker component
     * @param {Date} startTime selected by user
     * @param {Date} endTime selected by user
     * @param {boolean} isSameDay if startTime and endTime are same day
     * @return {Date} maxTime for date picker startTime
     */
    static maxStartTimeCalculate( startTime, endTime, isSameDay = false) {
        let endTimeCalculate = moment(startTime).endOf('day').toDate()
        if(isSameDay){
            endTimeCalculate = moment(endTime).subtract(15, 'minutes').toDate()
        }
        return endTimeCalculate
    }

    /**
     * calculate minTime for endTime in date picker component
     * @param {Date} startTime selected by user
     * @param {Date} endTime selected by user
     * @param {boolean} isSameDay if startTime and endTime are same day
     * @return {Date} minTime for datePicker endTime
     */
    static minEndTimeCalculate( startTime, endTime, isSameDay = false){
        let startTimeCalculate = moment(endTime).startOf('day').toDate()
        if(isSameDay){
            if ( this.dateIsBeforeNow( startTime ) ) {
                startTimeCalculate = DateFormat.toDayWithStepDateTimeInMinutes( new Date(), 0 )
            } else {
                startTimeCalculate = moment( startTime ).add( 15, 'm' ).toDate()
            }
        }
        if(this.isToday(endTime)){
            startTimeCalculate = this.toDayWithStepDateTimeInMinutes( new Date(), 0 )
        }
        return startTimeCalculate
    }

    /**
     * calculate minDate for endDate in date picker component
     * @param {Date} startDate selected by user
     * @param {boolean} isStarted if promotion is started
     * @return {Date} minDate for date picker end date
     */
    static minEndDateCalculate(startDate, isStarted = false){
        let endDateMin = moment( startDate ).add( 15, 'minutes' ).toDate()
        if(isStarted) {
            endDateMin = this.toDayWithStepDateTimeInMinutes( new Date(), 0 )
        }
        return endDateMin
    }
}

export default DateFormat;