import {
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';
import {
    itemsStorePricesActionCreators,
    globalLoadingActionCreators,
    errorManagerActionCreators, modalsActionCreators
} from '../actions';
import API from '../../constants/ApiUrl'
import * as R from '../../constants/ApiConstants'
import { selectIdsUser } from '../selectors/user'
import { map } from 'lodash'


const getItemsStorePrices = function* ({payload}) {
    
    const strDate   = payload.date;
    const user      = yield select(selectIdsUser);

    yield put(globalLoadingActionCreators.startLoading());

    const apiCall = R.GET_ITEMS_STORE_PRICES(user);

    try{

        const data = yield API(user.jwt).get(apiCall, strDate);
        
        yield put(itemsStorePricesActionCreators.getItemsStorePricesSuccess(data.data));
        
    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(itemsStorePricesActionCreators.getItemsStorePricesFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());

};

const updateItemsStorePrices = function* ({payload}) {

    const pricesList = map(payload.pricesList, (elem) => {
        const depositPrice = elem.depositPrice;
            const item = {
                id: elem.id,
                standardPrice: parseFloat(elem.standardPrice.toFixed(2)),
                depositPrice: !isNaN(parseFloat(depositPrice)) ? parseFloat(depositPrice.toFixed(2)) : null
            };
        return item
    });
    yield put(globalLoadingActionCreators.startLoading());

    const user      = yield select(selectIdsUser);

    const apiCall = R.UPDATE_ITEM_STORE(user);
    
    try{
        const data = yield API(user.jwt).put(apiCall, pricesList);
        yield put(itemsStorePricesActionCreators.updateItemsStorePricesSuccess(data.data));

        if(data.data.error.length){
            yield put(errorManagerActionCreators.createError(data));
        }else{
            if( data.status === 200 ) {
                const modal = {
                    level: 'validation',
                    title: "page__priceManagement__modalUpdateSuccess_title",
                    key: `page__priceManagement__modalUpdateSuccess_body`,
                    show: true,
                    path: ''
                };
                yield put(modalsActionCreators.hydrateModal(modal))
            }
        }

        
    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(itemsStorePricesActionCreators.updateItemsStorePricesFailure(e.response));
    }
    
    yield put(globalLoadingActionCreators.endLoading());

};

const sagas = function* () {
    yield takeLatest(ActionsTypes.GET_ITEMS_STORE_PRICES, getItemsStorePrices);
    yield takeLatest(ActionsTypes.UPDATE_ITEMS_STORE_PRICES_SELECTED, updateItemsStorePrices);
};

export default sagas