import ActionsTypes from '../../constants/ActionsTypes';

const logs = {
    getLogsAlert: ( ) => ({
        type: ActionsTypes.GET_LOGS_ALERT,
        payload: { },
    }),
    getLogsAlertSuccess: (logs) => ({
        type: ActionsTypes.GET_LOGS_ALERT_SUCCESS,
        payload: {
            logs,
        },
    }),
    getLogsAlertFailure: (error) => ({
        type: ActionsTypes.GET_LOGS_ALERT_FAILURE,
        payload: {
            error,
        },
    }),
    closeLogsPolling: () => ({
        type: ActionsTypes.CLOSE_LOGS,
        payload: {},
    }),
    putRemotedLogAlert: ( idLogAlert ) => ({
        type: ActionsTypes.PUT_REMOTED_LOG_ALERT,
        payload: { idLogAlert },
    }),
    putRemotedLogAlertSuccess: (logs) => ({
        type: ActionsTypes.PUT_REMOTED_LOG_ALERT_SUCCESS,
        payload: {
            logs,
        },
    }),
    putRemotedLogAlertFailure: (error) => ({
        type: ActionsTypes.PUT_REMOTED_LOG_ALERT_FAILURE,
        payload: {
            error,
        },
    }),
    putResolvedLogAlert: ( idLogAlert ) => ({
        type: ActionsTypes.PUT_RESOLVED_LOG_ALERT,
        payload: { idLogAlert },
    }),
    putResolvedLogAlertSuccess: (logs) => ({
        type: ActionsTypes.PUT_RESOLVED_LOG_ALERT_SUCCESS,
        payload: {
            logs,
        },
    }),
    putResolvedLogAlertFailure: (error) => ({
        type: ActionsTypes.PUT_RESOLVED_LOG_ALERT_FAILURE,
        payload: {
            error,
        },
    }),

    putResolvedAllLogAlert: ( ids = [] ) => ({
        type: ActionsTypes.PUT_RESOLVED_ALL_LOG_ALERT,
        payload: { ids },
    }),
    putResolvedAllLogAlertSuccess: (logs) => ({
        type: ActionsTypes.PUT_RESOLVED_ALL_LOG_ALERT_SUCCESS,
        payload: {
            logs,
        },
    }),
    putResolvedAllLogAlertFailure: (error) => ({
        type: ActionsTypes.PUT_RESOLVED_ALL_LOG_ALERT_FAILURE,
        payload: {
            error,
        },
    }),

    getLogsHistory: ( date, scope, idUser ) => ({
        type: ActionsTypes.GET_LOGS_HISTORY,
        payload: {
            date,
            scope,
            idUser,
        },
    }),
    getLogsHistorySuccess: ( logsHistory ) => ({
        type: ActionsTypes.GET_LOGS_HISTORY_SUCCESS,
        payload: {
            logsHistory,
        },
    }),
    getLogsHistoryFailure: (error) => ({
        type: ActionsTypes.GET_LOGS_HISTORY_FAILURE,
        payload: {
            error,
        },
    }),

    updateLogsHistoryFilter: ( idUser, isChecked ) => ({
        type: ActionsTypes.UPDATE_LOGS_HISTORY_FILTER,
        payload: {
            idUser,
            isChecked,
        },
    }),
    updateLogsHistoryFilterLevel: ( idLevel, isChecked ) => ({
        type: ActionsTypes.UPDATE_LOGS_HISTORY_FILTER_LEVEL,
        payload: {
            idLevel,
            isChecked,
        },
    })
};

export default logs;