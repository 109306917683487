import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import { findIndex } from "lodash";

extendUpdate(update);

const codesHistories = {
    [ActionsTypes.GET_CODE_HISTORIES]: ( state ) => update( state, {
        codesHistories: {
            isLoading: {
                $set: true,
            },
            error: {
                $set: false,
            },
            items: {
                $set: false
            }
        },
    } ),// END FUNCTION
    [ActionsTypes.GET_CODE_HISTORIES_SUCCESS]: ( state, payload ) => update( state, {
        codesHistories: {
            isLoading: {
                $set: false,
            },
            error: {
                $set: false,
            },
            items: {
                $set: payload.codesHistories
            }
        },
    } ),// END FUNCTION
    [ActionsTypes.GET_CODE_HISTORIES_FAILURE]: ( state, payload ) => {


        return update( state, {
            codesHistories: {
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: payload.error,
                },
                items: {
                    $set: false,
                }
            },
        } )
    },// END FUNCTION
    [ActionsTypes.DELETE_CODE_HISTORIES_ITEM]: ( state ) => {

        return update( state, {
            codesHistories: {
                isLoading: {
                    $set: true,
                },
                error: {
                    $set: false,
                },
            },
        } )
    },// END FUNCTION
    [ActionsTypes.DELETE_CODE_HISTORIES_ITEM_SUCCESS]: ( state, payload ) => {
        const oldCodesHistoriesItems    = [...state.codesHistories.items];
        const item                      = payload.codesHistoriesItem;
        const indexToReplace            = findIndex(oldCodesHistoriesItems, { 'id' : item.id });
        oldCodesHistoriesItems[indexToReplace] = item;
        return update( state, {
            codesHistories: {
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: false,
                },
                items: {
                    $set: oldCodesHistoriesItems
                }
            },
        } )
    },// END FUNCTION
    [ActionsTypes.DELETE_CODE_HISTORIES_ITEM_FAILURE]: ( state, payload ) => {

        return update( state, {
            codesHistories: {
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: payload.error,
                },
            },
        } )
    },// END FUNCTION
};// END GLOBAL
export default codesHistories;
