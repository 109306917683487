import {
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';
import {
    salesOrdersActionCreators,
    globalLoadingActionCreators,
    modalsActionCreators,
    errorManagerActionCreators,
} from '../actions';
import { selectIdsUser } from '../selectors/user'
import API, { API_TOKEN_DL_FILE } from '../../constants/ApiUrl'
import * as R from '../../constants/ApiConstants'

const postSalesOrders = function* ({ payload }) {

    const user = yield select(selectIdsUser);

    yield put(globalLoadingActionCreators.startLoading());

    try {
        const data = yield API(user.jwt).post(R.POST_SALES_ORDERS(user), payload.salesOrders);

        yield put(salesOrdersActionCreators.postSalesOrdersSuccess(data.data));
    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(salesOrdersActionCreators.postSalesOrdersFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());

};
const getSalesOrderCodes = function* ({ payload }) {

    const user = yield select(selectIdsUser);

    yield put(globalLoadingActionCreators.startLoading());

    try {
        const data = yield API(user.jwt).get(R.GET_SALES_ORDERS(user, payload.id));

        yield put(salesOrdersActionCreators.getSalesOrderCodesSuccess(data.data, payload.elem));
    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(salesOrdersActionCreators.getSalesOrderCodesFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());

};
const postSalesOrderCodesRenew = function* ({ payload }) {

    const user = yield select(selectIdsUser);
    const durationDays = {
        durationDays: payload.days
    };
    yield put(globalLoadingActionCreators.startLoading());

    try {
        const data = yield API(user.jwt).put(R.POST_SALES_ORDERS_CODES_RENEW(user, payload.id), durationDays);

        yield put(salesOrdersActionCreators.postSalesOrderCodesRenewSuccess(data.data, payload.elem));
    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(salesOrdersActionCreators.postSalesOrderCodesRenewFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());

};

const updateUserConsignmentInfo = function* ({ payload }) {
    const user = yield select(selectIdsUser);
    const userInfo = payload.userInfo;
    const idSalesOrder = payload.id;

    yield put(globalLoadingActionCreators.startLoading());

    try {
        const data = yield API(user.jwt).put(R.UPDATE_USER_CONSIGNMENT_INFO(user, idSalesOrder), userInfo);

        yield put(salesOrdersActionCreators.updateUserConsignmentInfoSuccess(data.data));

        const modal = {
            key: 'modal__customerInfo__modalUpdateSuccess_body',
            title: 'modal__customerInfo__modalUpdateSuccess_title',
            level: 'validation',
            show: true,
            path: ''
        };

        yield put(modalsActionCreators.hydrateModal(modal));

    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(salesOrdersActionCreators.updateUserConsignmentInfoFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());
};

const postSendSMS = function* ({ payload }) {
    const user = yield select(selectIdsUser);
    const phoneNumber = payload.phoneNumber;
    user['idElem'] = payload.idElem;


    yield put(globalLoadingActionCreators.startLoading());

    try {
        const data = yield API(user.jwt).get(R.GET_SEND_PHONE_NUMBER(user), {
            params: {
                phoneNumber: phoneNumber
            }
        });
        yield put(salesOrdersActionCreators.postSendSMSSuccess(data.data));
    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));
    }
    yield put(globalLoadingActionCreators.endLoading());
};

const postSendEmail = function* ({ payload }) {
    const user = yield select(selectIdsUser);
    const email = payload.email;
    user['idElem'] = payload.idElem;


    yield put(globalLoadingActionCreators.startLoading());

    try {
        const data = yield API(user.jwt).get(R.GET_SEND_EMAIL(user), {
            params: {
                email: email
            }
        });
        yield put(salesOrdersActionCreators.postSendEmailSuccess(data.data));
    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));
    }
    yield put(globalLoadingActionCreators.endLoading());
};

const putSalesOrderDepositContract = function* ({ payload }) {
    const user = yield select(selectIdsUser);
    user['idItem'] = payload.id;
    user['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone

    yield put(globalLoadingActionCreators.startLoading());

    try {
        const data = yield API_TOKEN_DL_FILE(user.jwt).put(R.PUT_SALES_ORDERS_DEPOSIT_CONTRACT(user));
        const type = data.headers['content-type'];
        const url = window.URL.createObjectURL(new Blob([data.data], { type: type }));

        window.open(url, 'Download');

        yield put(salesOrdersActionCreators.putSalesOrderDepositContractSuccess(data.data));


    } catch (e) {
        let error = e.response;
        if (error.status === 400) {
            yield new Promise(resolve => {
                const reader = new FileReader();
                reader.onload = (event) => resolve(JSON.parse(event.target.result));
                reader.readAsText(error.data);
            }).then(result => {
                error.data = result
            }).catch(error => {
                error.status = null
            });

            yield put(errorManagerActionCreators.createError(error));
        } else {
            yield put(errorManagerActionCreators.createError(e.response));

            yield put(salesOrdersActionCreators.putSalesOrderDepositContractFailure(e.response));
        }
    }

    yield put(globalLoadingActionCreators.endLoading());
};

const getPrintCodeTicket = function* ({ payload }) {

    const user = yield select(selectIdsUser);
    user['idItem'] = payload.id;
    let apiCall;

    yield put(globalLoadingActionCreators.startLoading());

    apiCall = R.GET_PRINT_CODE_TICKET(user);
    try {
        const data = yield API(user.jwt).get(apiCall);
        yield put(salesOrdersActionCreators.getPrintCodeTicketSuccess(data.data));
    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(salesOrdersActionCreators.getPrintCodeTicketFailure(e.response));
    }
    yield put(globalLoadingActionCreators.endLoading());
};// END FUNCTION

const sagas = function* () {
    yield takeLatest(ActionsTypes.POST_SALES_ORDERS, postSalesOrders);
    yield takeLatest(ActionsTypes.GET_SALES_ORDERS_CODES, getSalesOrderCodes);
    yield takeLatest(ActionsTypes.POST_SALES_ORDERS_CODES_RENEW, postSalesOrderCodesRenew);
    yield takeLatest(ActionsTypes.UPDATE_USER_CONSIGNMENT_INFO, updateUserConsignmentInfo);
    yield takeLatest(ActionsTypes.PUT_SALES_ORDERS_DEPOSIT_CONTRACT, putSalesOrderDepositContract);
    yield takeLatest(ActionsTypes.POST_SEND_SMS, postSendSMS);
    yield takeLatest(ActionsTypes.POST_SEND_EMAIL, postSendEmail);
    yield takeLatest(ActionsTypes.GET_PRINT_CODE_TICKET, getPrintCodeTicket);
};

export default sagas