import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import { cloneDeep, reject, findIndex } from 'lodash'

extendUpdate(update);

const adminUser = {

    [ActionsTypes.GET_ADMIN_USER]: (state) => update(state, {
        adminUser: {
            isLoading: {
                $set: true,
            },
        }
    }),
    [ActionsTypes.GET_ADMIN_USER_SUCCESS]: (state, payload) => {
        return update(state, {
            adminUser:{
                isLoading: {
                    $set: false,
                },
                items: {
                    $set: {...payload.adminUser}
                },
                error: {
                    $set: false
                },
            },

        })        
    },
    [ActionsTypes.GET_ADMIN_USER_FAILURE]: (state, payload) => {

        return update(state, {
            adminUser:{
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: payload.error
                },
            },

        })
    },
    [ActionsTypes.DELETE_ADMIN_USER]: (state) => update(state, {
        adminUser: {
            isLoading: {
                $set: true,
            },
        }
    }),
    [ActionsTypes.DELETE_ADMIN_USER_SUCCESS]: (state, payload) => {
        const newAdminUser  = payload.adminUser;
        let oldAdminUser    = cloneDeep(state.adminUser.items.Users);
        const scope         = payload.scope;
        const idUser        = payload.idUser;

        if ( scope === 'delete' ) {
            oldAdminUser = reject( oldAdminUser, {'id': idUser} )
        }
        if ( scope === 'disabled' ) {
            const idxAdminUser = findIndex(oldAdminUser, {id: newAdminUser.id});
            oldAdminUser[idxAdminUser] = newAdminUser
        }
        return update(state, {
            adminUser:{
                isLoading: {
                    $set: false,
                },
                items: {
                    Users:{
                        $set: {...oldAdminUser}
                    }
                },
                error: {
                    $set: false
                },
                deleteSuccess: {
                    $set: true
                },
            },

        })
    },
    [ActionsTypes.DELETE_ADMIN_USER_FAILURE]: (state, payload) => {

        return update(state, {
            adminUser:{
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: payload.error
                },
            },

        })
    },

};

export default adminUser