import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import storeApp from './reducers';
import sagas from './sagas';

function configStore() {
    const sagaMiddleware = createSagaMiddleware();

    // check env variable if in production active redux dev tool if not disable it
    // env variable are 'development' or 'production'
    const store = createStore(
        storeApp,
        compose(
            applyMiddleware(sagaMiddleware),
            process.env.NODE_ENV === 'development' &&
            window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
        )
    );

    sagaMiddleware.run(sagas);

    return store;
}

const store = configStore();

export default store;