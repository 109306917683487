import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { withRouter } from "react-router";
import { map, find, isUndefined, isNull } from 'lodash'

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { createPromoActionCreators } from '../../../state/actions';

// component import
import TableNested from '../../../Hoc/TableNested'
import TableLayout from '../../TableLayout'
import CR from '../../../constants/RouteConstants'
import ModalHOC from '../../../Hoc/ModalHOC/'
import Button from '../../../components/Ui/Buttons'
import DatePickerFutureCreate from '../../../Hoc/DatePickerFutureCreate'

//assets import
import Calendar from '../../../assets/icones/global/calendarX2.png'

import StringFormater from '../../../helpers/StringFormater';
import theme from "../../../theme/theme";

/*
// selector
import { selectPromo, selectUpdatePromo } from '../../state/selectors/promotion'
*/


/////////////////////////////
//  START style
////////////////////////////
const SalesHistoriesWrapper = styled.div`
    grid-column: 1 / 13;
`;
const HistoriesHeader = styled.div`
    display: flex;
    height: 36px;
    margin-top: 27px;
    margin-bottom: 14px;
    justify-content: space-between;
`;
const TitleHeader = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    color: ${ props => props.theme.colors.cancel };
    margin-left: 15px;
    width: 100%;
    height: 36px;
`;
const ActionWrapper = styled.div`
    display: flex;
    height: 36px;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
`;
const AddLineHeader = styled.div`
    display: flex;
    align-items: stretch;
    height: 36px;
    width: 100%;
    margin: 14px 0;
    box-sizing: border-box;
`;
const ContentWrapper = styled.div`
    background-color: ${ props => props.theme.colors.white };
    border: 1px solid ${ props => props.theme.colors.borderTable };
    padding: 20px;
`;
const ContentActionHeader = styled(HistoriesHeader)`
    padding: 0;
    margin: 0% 0% 20px;
`;
const TableTitleHeader = styled(TitleHeader)`
    align-items: center;
    color: ${ props => props.color};
    font-weight: bold;
    margin-left: 0;
`;
const TableActionWrapper = styled(ActionWrapper)`
    width: 250px;
    flex-direction: row;
`;
const CalendarLink = styled.div`
    display: flex;
    align-items: center;
    padding: 9px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    font-size: 13px;
    background-color: ${ props => props.theme.colors.primary };
    border-radius: 3px;
    margin-right: 15px;
`;
const CalendarImage = styled.img`
    max-height: 18px;
    margin-right: 9px;
`;
const DateTextWrapper = styled.div`
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const BoxCanceld = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 27px;
    width: 27px;
    color: white;
    font-weight: bold;
    border-radius: 3px;
    cursor: pointer;
`;
const PromoName = styled.input`
    width: 208px;
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 3px;
    display: flex;
    align-items: center;
    text-align: left;
    box-sizing: border-box;
    padding-left: 10px;
    ::first-letter{
        text-transform: capitalize;
    }
    color: ${ props => props.theme.colors.menu.wrapper };
    margin-right: 10px;
`;
const ErrorMessage = styled.p`
    color: red;
`;
/////////////////////////////
//  END style
////////////////////////////

export class UpdatePromo extends Component {
    static propTypes = {
        actionsDatePicker:          PropTypes.object,
        closeDateModalHandler:      PropTypes.func,
        codesHistories:             PropTypes.object,
        dataTable:                  PropTypes.object,
        datePicker:                 PropTypes.object,
        formatDate:                 PropTypes.func,
        getDateFormat:              PropTypes.func,
        hydrateStateHandler:        PropTypes.func,
        modalHandler:               PropTypes.func,
        nestedOnClickHandler:       PropTypes.func,
        openDateModalHandler:       PropTypes.func,
        refCollection:              PropTypes.array,
        userKiosk:                  PropTypes.object,
        editInputHandler:           PropTypes.func,
        formatDateLong:             PropTypes.func,
    };

    state = {
        promotionName: '',
    };

    ///////////////////////////////////////////////////
    ///////     START INIT COMPONENT    ///////////////
    ///////////////////////////////////////////////////
    componentDidMount = () => {
        const {
            actionsCreatePromo,
            match,
            itemsPriceInit,
            history
        } = this.props;
        const promoId = parseInt(match.params.id);
        if( itemsPriceInit.items ) {
            actionsCreatePromo.initUpdatePromo(promoId)
        }else {
            history.push(CR.priceManagment)
        }// end if

    };
    ///////////////////////////////////////////////////
    ///////     END INIT COMPONENT    /////////////////
    ///////////////////////////////////////////////////

    componentDidUpdate(prevProps) {
        const { itemsPromo, hydrateStateHandler, history, itemsPriceInit, translations } = this.props;

        if( itemsPriceInit.items ) {

            if( prevProps.itemsPromo.items.promo !== itemsPromo.items.promo
                && this.state.promotionName === ''
            ) {
                this.setState({
                    promotionName: itemsPromo.items.promo.promoLabel
                })
            }// end if

            if( itemsPromo.items.itemsStore !== prevProps.itemsPromo.items.itemsStore ||
                itemsPromo.checkedItems !== prevProps.itemsPromo.checkedItems
            ) {
                const createTable = this.bodyData();
                const data = {
                    header: createTable.header,
                    body: createTable.body,
                };
                hydrateStateHandler(data)
            }// end if

            if( translations !== prevProps.translations) {
                const createTable = this.bodyData();
                const data = {
                    header: createTable.header,
                    body: createTable.body,
                };
                hydrateStateHandler(data)
            }

        }else{
            history.push(CR.priceManagment)
        }// end if

    }

    /**
     * data object to row layout
     */
    bodyData = () => {
        const {
            itemsPromo,
        } = this.props;

        const header = [
            {name: 'FAMILLE', useName: 'famille'},
            {name: 'MARQUE', useName: 'marque'},
            {name: 'PRODUIT', useName: 'produit'},
            {name: 'TYPE PRODUIT', useName: 'typeProduit'},
            {name: 'PRIX VENTES', useName: 'standardPrice'},
            {name: 'PRIX CONSIGNE', useName: 'depositPrice'},
            {name: 'STOCK DISPO', useName: 'stock'},
            {name: 'CAPACITÉ', useName: 'stockMax'},
        ];
        return{
            header: header,
            body:   map(itemsPromo.items.itemsStore, (elem, id) => {
                const itemChecked               = itemsPromo.checkedItems[id];
                const consignePriceSecondLine   = itemChecked.depositPrice;
                const consignePrice             = elem.depositPrice;
                const prices = {
                    priceItemStore: elem.standardPrice,
                    priceConsigne: !isNull(consignePrice) ? consignePrice.toFixed(2) : '-'
                };
                const pricesSecondLine = {
                    priceItemStore: itemChecked.standardPrice,
                    priceConsigne: !isNull(consignePriceSecondLine) ? consignePriceSecondLine : '-'
                };
                switch (elem.step) {
                    case 0:
                        return ({
                            id: elem.id,
                            elements: [
                                {name :'famille', value: elem.itemFamily.label, bcgAll:'white'},
                                {name :'marque', value: elem.itemBrand.label, bcgAll:'white'},
                                {name :'produit', value: elem.label, bcgAll:'white'},
                                {name :'typeProduit', value: elem.itemType, bcgAll:'white'},
                                {name :'standardPrice', value: prices.priceItemStore.toFixed(2), price: true, currency: itemChecked.currency.symbol, bcgAll:'white'},
                                {name :'depositPrice', value: prices.priceConsigne, price: true, currency: itemChecked.currency.symbol, bcgAll:'white'},
                                {name :'advailableStock', value: elem.allocatedStock, bcgAll:'white' },
                                {name :'remainingSstock', value: elem.remainingSstock, bcgAll:'white'},

                                {name :'famille', value: elem.message, column:'1 / 8', bcgAll: elem.bcg, color: elem.color},
                                {
                                    name: 'actions',
                                    display: 'flex',
                                    bcgAll: elem.bcg,
                                    value:
                                        <BoxCanceld
                                            id={elem.id}
                                            onClick={() => this.deleteItemsHandler( elem.id )}
                                        >
                                            X
                                        </BoxCanceld>
                                },
                            ]
                        });
                    case 1:
                        return ({
                            id: itemChecked.id,
                            elements: [
                                {name :'famille', value: elem.itemFamily.label, bcgAll:'white'},
                                {name :'marque', value: elem.itemBrand.label, bcgAll:'white'},
                                {name :'produit', value: elem.label, bcgAll:'white'},
                                {name :'typeProduit', value: elem.itemType, bcgAll:'white'},
                                {name :'standardPrice', value: prices.priceItemStore.toFixed(2), price: true, currency: itemChecked.currency.symbol, bcgAll:'white'},
                                {name :'depositPrice', value: prices.priceConsigne, price: true, currency: itemChecked.currency.symbol, bcgAll:'white'},
                                {name :'advailableStock', value: elem.advailableStock, bcgAll:'white' },
                                {name :'remainingSstock', value: elem.kioskCapacity, bcgAll:'white'},

                                {name :'famille', value: elem.message, column:'1 / 5', bcgAll: elem.bcg, color: elem.color},
                                {name :'standardPrice', value: pricesSecondLine.priceItemStore, active: elem.step && !itemsPromo.isStarted, currency: elem.currency.symbol, price: true, bcgAll: elem.bcg, color: elem.color, onBlur: (evt)=>this.inputChangeHandler(evt) },
                                {name :'depositPrice', value: pricesSecondLine.priceConsigne, active: elem.step && !itemsPromo.isStarted && !isNull(elem.depositPrice), price: true, currency: elem.currency.symbol, bcgAll: elem.bcg, color: elem.color, onBlur: (evt)=>this.inputChangeHandler(evt) },
                                {name :'advailableStock', value: itemChecked.advailableStock, active: elem.step && !itemsPromo.isStarted,  bcgAll: elem.bcg, color: elem.color, onBlur: (evt)=>this.inputChangeHandler(evt) },
                                {
                                    name: 'actions',
                                    display: 'flex',
                                    bcgAll: elem.bcg,
                                    value:
                                        !itemsPromo.isStarted &&
                                        <BoxCanceld
                                            id={itemChecked.id}
                                            onClick={() => this.deleteItemsHandler( itemChecked.id )}
                                        >
                                            X
                                        </BoxCanceld>
                                },
                            ]
                        })
                }
            })
        };
    };

    changePromoNameHandler = (evt) => {
        const promoName = evt.target.value;
        const oldState = this.state;
        oldState.promotionName = promoName;
        this.setState({...oldState})
    };

    inputChangeHandler = (evt) => {
        const { actionsCreatePromo } = this.props;
        const itemId    = parseInt(evt.target.id);
        const field     = evt.target.name;
        let price       = field === 'advailableStock' ? parseInt(evt.target.value) : Number(Number(evt.target.value).toFixed(2));

        actionsCreatePromo.updateItemPromoPrices(price, itemId, field)
    };
    deleteItemsHandler = ( id ) => {
        const { actionsCreatePromo } = this.props;
        const itemId = parseInt(id);

        actionsCreatePromo.deleteItemPromoPrices(itemId)
    };
    findIfAllItemsIsValidated = () => {
        const { itemsPromo }    = this.props;
        const data              = find(itemsPromo.items.itemsStore, { 'step' : 0 });

        return !isUndefined( data )
    };
    updatePromotionHandler = () => {
        const {
            actionsCreatePromo,
            itemsPromo,
            date,
            match,
        } = this.props;
        const dateToSend = {
            "dateStart" :		date.startDate,
            "dateEnd" : 		date.endDate,
        };
        const itemsStore    = itemsPromo.checkedItems;
        const name          = this.state.promotionName;
        const idPromo       = match.params.id;
        actionsCreatePromo.updatePromo(dateToSend, name, itemsStore, idPromo)
    };

    render() {
        const {
            itemsPromo,
            history,
            dataTable,
            refCollection,
            openDateModalHandler,
            date,
            editInputHandler,
            translations,
            formatDate,
        } = this.props;
        if(!dataTable.headerData.length) {
            return <div> </div>
        }
        return (
            <SalesHistoriesWrapper>

                <AddLineHeader>
                    <Button
                        goBack
                        weight='bold'
                        actionHandler={history.goBack}
                        text={translations['common__text__previousPage']}
                    />
                </AddLineHeader>
                <AddLineHeader>
                    <CalendarLink
                        onClick={openDateModalHandler}
                    >
                        <CalendarImage src={Calendar} />
                    </CalendarLink>
                    <DateTextWrapper>

                    {
                        date.touch && date.validate
                            ? StringFormater.ResponseMessageParser(translations['common__text__datePeriod_noHours'], [formatDate(date.startDate),formatDate(date.endDate)])
                            : date.message
                    }
                    </DateTextWrapper>
                </AddLineHeader>
                <AddLineHeader>
                    <PromoName
                        placeholder={itemsPromo.items.promo.promoLabel}
                        value={this.state.promotionName}
                        onChange={(evt) => this.changePromoNameHandler(evt)}
                        maxLength={40}
                    />
                    {
                        !this.state.promotionName.length &&
                       translations['page__promoDetail__promoName_pleaseEnterName']
                    }
                </AddLineHeader>
                {
                    itemsPromo.error &&
                    <ErrorMessage>
                        {itemsPromo.error}
                    </ErrorMessage>
                }
                <HistoriesHeader>
                    <TitleHeader>
                        {translations['page__promoDetail__itemTable_title']}
                    </TitleHeader>
                </HistoriesHeader>
                <ContentWrapper>
                    <ContentActionHeader>
                        <TableTitleHeader color={ theme.colors.primary }>
                            { itemsPromo.items.store &&
                            itemsPromo.items.store.companyStoreLblToDisplay
                            }
                        </TableTitleHeader>
                        <TableActionWrapper>
                            <Button
                                actionHandler={this.updatePromotionHandler}
                                // text="Valider"
                                text={translations['common__text__save']}
                                bgColor={ theme.colors.validation }
                                disabled={ itemsPromo.isStarted
                                    ?
                                        ( itemsPromo.error ? true : !itemsPromo.isValid || !this.state.promotionName.length )
                                    :
                                        ( itemsPromo.error ? true : this.findIfAllItemsIsValidated() || !this.state.promotionName.length)
                                }
                            />
                            <Button
                                // text="Annuler"
                                text={translations['common__text__cancel']}
                                bgColor={ theme.colors.cancel }
                                actionHandler={history.goBack}
                            />
                        </TableActionWrapper>
                    </ContentActionHeader>
                    {/* TODO: component Table layout to config */}
                    {dataTable.headerData.length &&
                    <TableLayout
                        headerData={dataTable.headerData}
                        bodyData={dataTable.bodyData}
                        column={dataTable.headerData.length}
                        refCollection={refCollection}
                        editInputHandler={editInputHandler}
                        translations={translations}
                    />
                    }
                </ContentWrapper>

            </SalesHistoriesWrapper>
        );
    }
}

const mapStateToProps = state => {
    return {
        itemsPromo:     state.createPromoItemsStore,
        itemsPriceInit: state.itemsStorePrices,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionsCreatePromo: bindActionCreators(createPromoActionCreators, dispatch),
    };
};

export default withRouter(DatePickerFutureCreate(TableNested(ModalHOC(connect(mapStateToProps, mapDispatchToProps)(UpdatePromo)))))
