import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// redux import
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { salesOrdersActionCreators } from '../../../state/actions';

// import components
import Modal from '../../../components/Ui/Modals/Modal'
import CR from '../../../constants/RouteConstants'
import Button from '../../../components/Ui/Buttons'

import StringFormater from '../../../helpers/StringFormater';
import theme from "../../../theme/theme";

/////////////////////////////
//  START style
////////////////////////////
const GenerateCodeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 592px;
    padding: 35px 0 25px 0;
`;
const ActionTittle = styled.div`
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${ props => props.theme.colors.menu.wrapper };
    width: 350px;
`;
const ColorElement = styled.span`
    color: ${ props => props.colors };
`;
const CodeNumber = styled.div`
    display: flex;
    width: 403px;
    height: 62px;
    border: 1px solid ${ props => props.theme.colors.primary };
    font-weight: bold;
    font-size: 30px;
    justify-content: center;
    align-items:center;
    margin-top: 21px;
    margin-bottom: 25px;
`;
const SelectedDateWrapper = styled.div`
    margin-top: 38px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const SelectedDateButton = styled.input`
    margin-right: 12px;
`;
const SelectedDateLabel = styled.label`
    margin-right: ${ props => props.margin ? '22px' : '0' };
`;
const SelectedDateText = styled.p`
    margin: 0 33px 0 0;
    color: ${ props => props.theme.colors.primary };
    padding-left: 33px;
`;
const ButtonWrapper = styled.div`
    width: 100%;
    height: 40px;
    margin-top: 52px;
    display: flex;
    justify-content: center;
`;
/////////////////////////////
//  END style
////////////////////////////

export class GeneratedCodeRenew extends Component {
    static propTypes = {
        salesOrders: PropTypes.object.isRequired,       // salesOrders was send
        elem: PropTypes.object.isRequired,              // gas cylinder selected
        closeModalHandler: PropTypes.func,              // past by modalHOC
    };

    state = {
        day: '1',
    };

    codeRenewHandler = () => {
        const { actionsSaleOrder, elem } = this.props;
        const { day } = this.state;
        actionsSaleOrder.postSalesOrderCodesRenew(elem.id, day, elem)
    };

    dataFactory = () => {
        const { elem, translations } = this.props;
        const data = {
                color: 'primary',
                headerTitle: translations['modal__renewCode__title'],
                bodyTitle: translations['modal__renewCode__description'],
                status: elem.saleOrderType.label,
                brandLabel: elem.itemBrand.label,
                itemLabel: elem.productLabel,
                codeGenerated: elem.generatedCode,
        };
        return data
    };

        /**
     * modify state to selected radio button
     */
    radioChangeHandler = (evt) => {
        const targetValue = evt.target.value; // target value: String
        this.setState({ day: targetValue })
    };// end function

    render() {
        const { match, closeModalHandler, translations } = this.props;

        const data = this.dataFactory();
        return (

            <Modal
                color={data.color}
                headerTittle={data.headerTitle}
                closeModal={closeModalHandler}
            >

                <GenerateCodeWrapper>
                    <ActionTittle>
                        {/* { data.bodyTitle } */}
                        {StringFormater.ResponseMessageParser(translations['modal__renewCode__description'], [data.status])}
                        {/* <ColorElement colors={theme.colors.validation}'>
                            {` ${data.status} `} 
                        </ColorElement>
                        suivant pour [ */}
                        <ColorElement colors={theme.colors.primary}>
                            {data.brandLabel}
                        </ColorElement>
                        
                        {` - ${data.itemLabel} ]`}
                    </ActionTittle>
                    <CodeNumber>
                        { data.codeGenerated }
                    </CodeNumber>
                    <SelectedDateWrapper>
                        <SelectedDateText>
                            {translations['modal__renewCode__expirationTime']+" :"}
                        </SelectedDateText>
                        <SelectedDateButton
                            id='1'
                            value='1'
                            type='radio'
                            onChange={this.radioChangeHandler}
                            checked={ this.state.day === '1' }
                        />
                            
                        <SelectedDateLabel margin>
                            {translations['common__text__1day']}
                        </SelectedDateLabel>
                        <SelectedDateButton
                            id='2'
                            value='2'
                            type='radio'
                            onChange={this.radioChangeHandler}
                            checked={ this.state.day === '2' }
                        />
                            
                        <SelectedDateLabel margin>
                            {translations['common__text__2days']}
                        </SelectedDateLabel>
                        <SelectedDateButton
                            id='3'
                            value='3'
                            type='radio'
                            onChange={this.radioChangeHandler}
                            checked={ this.state.day === '3' }
                        />
                            
                        <SelectedDateLabel margin>
                            {translations['common__text__3days']}
                        </SelectedDateLabel>
                    </SelectedDateWrapper>
                    <ButtonWrapper>
                        <Button
                            bgColor={theme.colors.error}
                            margin='true'
                            actionHandler={closeModalHandler}
                            // text='Annuler'
                            text={translations['common__text__cancel']}
                        />
                        <Button 
                            bgColor={theme.colors.validation}
                            actionHandler={this.codeRenewHandler}
                            link={`${match.url}${CR.modal.generatedCode}`}
                            // text='Valider'
                            text={translations['common__text__save']}
                        />
                    </ButtonWrapper>
                </GenerateCodeWrapper>
                
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        salesOrders: state.salesOrders,
        itemKiosk: state.itemsKiosk.selectedItem,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionsSaleOrder        : bindActionCreators(salesOrdersActionCreators, dispatch),

    };
};


export default connect( mapStateToProps, mapDispatchToProps )( GeneratedCodeRenew )
