import login from './login';
import brands from './brands';
import families from './families';
import itemsKiosk from './itemsKiosk';
import salesOrders from './salesOrders'
import salesHistories from './salesHistories'
import codesHistories from './codesHistories'
import itemsStorePrices from './itemsStorePrices'
import datePicker from './datePicker'
import promoItemStore from './promoItemStore'
import user from './user'
import kiosks from './kiosks'
import myAccount from './myAccount'
import createPromo from './createPromo'
import globalLoading from './globalLoading'
import paymentCollection from './paymentCollection'
import stockManager from './stockManager'
import modals from './modals'
import logs from './logs'
import languages from './languages'
import errorManager from './errorManager'
import dashBoard from './dashBoard'
import adminUser from './adminUser'
import userDetail from './userDetail'
import exportData from './exportData'
/* import wareHouseStockManagement from './wareHouseStockManagement' */


export const loginActionCreators = {
  ...login
};
export const brandsActionCreators = {
  ...brands
};
export const familiesActionCreators = {
  ...families
};
export const itemsKioskActionCreators = {
  ...itemsKiosk
};
export const salesOrdersActionCreators = {
  ...salesOrders
};
export const salesHistoriesActionCreators = {
  ...salesHistories
};
export const codesHistoriesActionCreators = {
  ...codesHistories
};
export const itemsStorePricesActionCreators = {
  ...itemsStorePrices
};
export const datePickerActionCreators = {
  ...datePicker
};
export const userActionCreators = {
  ...user
};
export const promoItemStoreActionCreators = {
  ...promoItemStore
};
export const kiosksActionCreators = {
  ...kiosks
};
export const myAccountActionCreators = {
  ...myAccount
};
export const createPromoActionCreators = {
  ...createPromo
};
export const globalLoadingActionCreators = {
  ...globalLoading
};
export const paymentCollectionActionCreators = {
  ...paymentCollection
};
export const stockManagerActionCreators = {
  ...stockManager
};
export const modalsActionCreators = {
  ...modals
};
export const languagesActionCreators = {
  ...languages
};
export const logsActionCreators = {
  ...logs
};
export const errorManagerActionCreators = {
  ...errorManager
};
export const dashBoardActionCreators = {
  ...dashBoard
};
export const adminUserActionCreators = {
  ...adminUser
};
export const userDetailActionCreators = {
  ...userDetail
};
export const exportDataActionCreators = {
  ...exportData
};
/* export const wareHouseStockManagementActionCreators = {
  ...wareHouseStockManagement
}; */
