import ActionsTypes from '../../constants/ActionsTypes';

const brands = {
    getBrandsByFamily: (idFamily) => ({
        type: ActionsTypes.GET_BRANDS_BY_FAMILY,
        payload: {
            ...idFamily
        },
    }),
    getBrandsByFamilySuccess: (brands) => ({
        type: ActionsTypes.GET_BRANDS_BY_FAMILY_SUCCESS,
        payload: {
            brands,
        },
    }),
    getBrandsByFamilyFailure: (error) => ({
        type: ActionsTypes.GET_BRANDS_BY_FAMILY_FAILURE,
        payload: {
            error,
        },
    }),
    updateBrandsSelected: (id, isChecked) => ({
        type: ActionsTypes.UPDATE_BRANDS_SELECTED,
        payload: {
            id,
            isChecked,
        },
    }),

};

export default brands;