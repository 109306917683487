import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { isEmpty } from "lodash";

// import components
import Header from "./Header";
import MenuLinksContainer from "./MenuLinksContainer";
import Footer from "./Footer";

/// /////////////////////////
// Styled
/// ////////////////////////

const MenuWrapper = styled.div`
  display: grid;
  grid-template-rows: 90px 430px 205px;
  grid-row-gap: 20px;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  width: 200px;
  height: 768px;
  background-color: ${(props) => props.theme.colors.menu.wrapper};
  z-index: 20;
  justify-content: stretch;
  box-shadow: 3px 0 2px rgba(0, 0, 0, 0.16);
  color: ${(props) => props.theme.colors.white};
  font-weight: bold;
  transition: left 300ms;
  overflow: hidden;
  left: ${({ menuTransition }) => {
    switch (menuTransition) {
      case "entering":
        return "-200px";
      case "entered":
        return "0px";
      case "exiting":
        return "-200px";
      case "exited":
        return "-200px";
      default:
        return "-200px";
    }
  }};
`;
const HeaderWrapper = styled.div`
  grid-row-start: 1;
  grid-row-end: 2;
`;
const LinkWrapper = styled.div`
  grid-row-start: 2;
  grid-row-end: 3;
  overflow: auto;
`;
const FooterWrapper = styled.div`
  grid-row-start: 3;
  grid-row-end: 4;
`;

const MenuHandler = styled.div`
  height: 768px;
  width: 203px;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
`;

/**
 *
 * @param {array} refCollection
 * @param {function} closeMenuHandler
 * @param {function} logout
 * @param {object} translations
 * @param {object} menuTransition
 * @param {function} changeLanguageHandler
 * @param {array} languagesList
 * @returns {jsx}
 */
const Menu = ({
  menuTransition,
  refCollection,
  closeMenuHandler,
  logout,
  translations,
  changeLanguageHandler,
  languagesList,
  selectedLanguage,
}) => {
  return (
    <MenuHandler>
      {!isEmpty(translations) && (
        <MenuWrapper
          ref={(el) => (refCollection["menu"] = el)}
          translations={translations}
          menuTransition={menuTransition}
        >
          <HeaderWrapper>
            <Header closeMenuHandler={closeMenuHandler} />
          </HeaderWrapper>
          <LinkWrapper>
            <MenuLinksContainer
              closeMenuHandler={closeMenuHandler}
              translations={translations}
            />
          </LinkWrapper>
          <FooterWrapper>
            <Footer
              logout={logout}
              closeMenuHandler={closeMenuHandler}
              translations={translations}
              changeLanguageHandler={changeLanguageHandler}
              languagesList={languagesList}
              selectedLanguage={selectedLanguage}
            />
          </FooterWrapper>
        </MenuWrapper>
      )}
    </MenuHandler>
  );
};

Menu.propTypes = {
  refCollection: PropTypes.array,
  closeMenuHandler: PropTypes.func,
  logout: PropTypes.func,
  translations: PropTypes.object,
  changeLanguageHandler: PropTypes.func,
  languagesList: PropTypes.array,
};

export default Menu;
