import keyMirror from 'key-mirror';

const ActionsTypes = keyMirror({

  // translation
  GET_TRANSLATIONS_BY_LANGUAGES: null,
  GET_TRANSLATIONS_BY_LANGUAGES_SUCCESS: null,
  GET_TRANSLATIONS_BY_LANGUAGES_FAILURE: null,
  GET_LANGUAGES_ENABLED: null,
  GET_LANGUAGES_ENABLED_SUCCESS: null,
  GET_LANGUAGES_ENABLED_FAILURE: null,
  GET_TRANSLATIONS_PUBLIC_PAGE: null,
  GET_TRANSLATIONS_PUBLIC_PAGE_SUCCESS: null,
  GET_TRANSLATIONS_PUBLIC_PAGE_FAILURE: null,


  // login
  GET_LOGIN: null,
  GET_LOGIN_SUCCESS: null,
  GET_LOGIN_FAILURE: null,
  GET_CURRENT_USER: null,
  CHANGE_PATH_USER: null,
  POST_FORGOT_PASSWORD: null,
  POST_FORGOT_PASSWORD_SUCCESS: null,
  POST_FORGOT_PASSWORD_FAILURE: null,

  // log out
  GET_LOGOUT: null,
  GET_LOGOUT_SUCCESS: null,
  GET_LOGOUT_FAILURE: null,

  // GLOBAL LOADER
  START_LOADING: null,
  END_LOADING: null,

  // user
  UPDATE_USER_KIOSK_SELECTED: null,
  GET_USER_SETTINGS: null,
  GET_USER_SETTINGS_SUCCESS: null,
  GET_USER_SETTINGS_FAILURE: null,
  PUT_USER_SETTINGS: null,
  PUT_USER_SETTINGS_SUCCESS: null,
  PUT_USER_SETTINGS_FAILURE: null,
  PUT_USER_NOTIF_SETTINGS: null,
  PUT_USER_NOTIF_SETTINGS_SUCCESS: null,
  PUT_USER_NOTIF_SETTINGS_FAILURE: null,
  GET_ADMIN_USER: null,
  GET_ADMIN_USER_SUCCESS: null,
  GET_ADMIN_USER_FAILURE: null,
  DELETE_ADMIN_USER: null,
  DELETE_ADMIN_USER_SUCCESS: null,
  DELETE_ADMIN_USER_FAILURE: null,

  // brands
  GET_BRANDS_BY_FAMILY: null,
  GET_BRANDS_BY_FAMILY_SUCCESS: null,
  GET_BRANDS_BY_FAMILY_FAILURE: null,
  UPDATE_BRANDS_SELECTED: null,

  // families
  GET_FAMILIES_AND_BRANDS_BY_KIOSK: null,
  GET_FAMILIES_AND_BRANDS_BY_KIOSK_SUCCESS: null,
  GET_FAMILIES_AND_BRANDS_BY_KIOSK_FAILURE: null,
  UPDATE_FAMILY_SELECTED: null,

  // itemsKiosk
  GET_ITEM_KIOSK: null,
  GET_ITEM_KIOSK_SUCCESS: null,
  GET_ITEM_KIOSK_FAILURE: null,
  POST_ITEM_KIOSK_CHECK_ORDER_VALIDATION: null,
  POST_ITEM_KIOSK_CHECK_ORDER_VALIDATION_SUCCESS: null,
  POST_ITEM_KIOSK_CHECK_ORDER_VALIDATION_FAILURE: null,
  GET_ITEMS_KIOSK_BY_FAMILY: null,
  GET_ITEMS_KIOSK_BY_FAMILY_SUCCESS: null,
  GET_ITEMS_KIOSK_BY_FAMILY_FAILURE: null,
  UPDATE_ITEMS_FILTER_BY_INPUT: null,
  START_UPDATE_ITEMS_FILTER_BY_INPUT: null,
  UPDATE_ITEMS_SORT_BY: null,

  // salesOrders
  POST_SALES_ORDERS: null,
  POST_SALES_ORDERS_SUCCESS: null,
  POST_SALES_ORDERS_FAILURE: null,
  POST_SALES_ORDERS_CODES_RENEW: null,
  POST_SALES_ORDERS_CODES_RENEW_SUCCESS: null,
  POST_SALES_ORDERS_CODES_RENEW_FAILURE: null,
  GET_SALES_ORDERS_CODES: null,
  GET_SALES_ORDERS_CODES_SUCCESS: null,
  GET_SALES_ORDERS_CODES_FAILURE: null,
  UPDATE_SALES_ORDERS: null,
  UPDATE_USER_CONSIGNMENT_INFO: null,
  UPDATE_USER_CONSIGNMENT_INFO_SUCCESS: null,
  UPDATE_USER_CONSIGNMENT_INFO_FAILURE: null,
  POST_SEND_SMS: null,
  POST_SEND_SMS_SUCCESS: null,
  POST_SEND_SMS_FAILURE: null,
  POST_SEND_EMAIL: null,
  POST_SEND_EMAIL_SUCCESS: null,
  POST_SEND_EMAIL_FAILURE: null,
  PUT_SALES_ORDERS_DEPOSIT_CONTRACT: null,
  PUT_SALES_ORDERS_DEPOSIT_CONTRACT_SUCCESS: null,
  PUT_SALES_ORDERS_DEPOSIT_CONTRACT_FAILURE: null,
  GET_PRINT_CODE_TICKET: null,
  GET_PRINT_CODE_TICKET_SUCCESS: null,
  GET_PRINT_CODE_TICKET_FAILURE: null,


  // salesHistories
  GET_SALES_HISTORIES: null,
  GET_SALES_HISTORIES_SUCCESS: null,
  GET_SALES_HISTORIES_FAILURE: null,
  GET_SALES_HISTORIES_BY_SALE: null,
  GET_SALES_HISTORIES_BY_SALE_SUCCESS: null,
  GET_SALES_HISTORIES_BY_SALE_FAILURE: null,
  GET_SALES_HISTORIES_RECEIPT: null,
  GET_SALES_HISTORIES_RECEIPT_SUCCESS: null,
  GET_SALES_HISTORIES_RECEIPT_FAILURE: null,


  // codeHistories
  GET_CODE_HISTORIES: null,
  GET_CODE_HISTORIES_SUCCESS: null,
  GET_CODE_HISTORIES_FAILURE: null,
  DELETE_CODE_HISTORIES_ITEM: null,
  DELETE_CODE_HISTORIES_ITEM_SUCCESS: null,
  DELETE_CODE_HISTORIES_ITEM_FAILURE: null,

  // pricesManager
  GET_ITEMS_STORE_PRICES: null,
  GET_ITEMS_STORE_PRICES_SUCCESS: null,
  GET_ITEMS_STORE_PRICES_FAILURE: null,
  UPDATE_ITEMS_STORE_PRICES_SELECTED: null,
  UPDATE_ITEMS_STORE_PRICES_SELECTED_SUCCESS: null,
  UPDATE_ITEMS_STORE_PRICES_SELECTED_FAILURE: null,
  SELECTED_ITEMS_STORE_PRICES_UPDATE: null,
  
  // PROMO LIST
  GET_PROMO_ITEM_STORE : null,
  GET_PROMO_ITEM_STORE_SUCCESS : null,
  GET_PROMO_ITEM_STORE_FAILURE : null,
  GET_PROMO_ITEM_DETAIL : null,
  GET_PROMO_ITEM_DETAIL_SUCCESS : null,
  GET_PROMO_ITEM_DETAIL_FAILURE : null,
  GET_PROMO_ITEM_DETAIL_ALL : null,
  GET_PROMO_ITEM_DETAIL_ALL_SUCCESS : null,
  GET_PROMO_ITEM_DETAIL_ALL_FAILURE : null,
  UPDATE_PROMO_ITEM_DISPLAY: null,
  UPDATE_PROMO_STATUS_DISPLAY: null,
  DELETE_PROMO_ITEM_STORE: null,
  DELETE_PROMO_ITEM_STORE_SUCCESS: null,
  DELETE_PROMO_ITEM_STORE_FAILURE: null,

  // CREATE PROMO
  INIT_CREATE_PROMO: null,
  CHECK_CREATE_PROMO_VALIDATION: null,
  CHECK_CREATE_PROMO_VALIDATION_SUCCESS: null,
  CHECK_CREATE_PROMO_VALIDATION_FAILURE: null,
  CREATE_PROMO: null,
  CREATE_PROMO_SUCCESS: null,
  CREATE_PROMO_FAILURE: null,
  UPDATE_ITEM_PRICES_PROMO: null,
  DELETE_ITEM_PRICES_PROMO: null,
  INIT_UPDATE_PROMO: null,
  INIT_UPDATE_PROMO_SUCCESS: null,
  INIT_UPDATE_PROMO_FAILURE: null,
  UPDATE_PROMO: null,
  UPDATE_PROMO_SUCCESS: null,
  UPDATE_PROMO_FAILURE: null,

  // KioskManager
  GET_KIOSKS : null,
  GET_KIOSKS_SUCCESS: null,
  GET_KIOSKS_FAILURE: null,
  UPDATE_KIOSKS_SELECTED: null,
  UPDATE_KIOSKS_SELECTED_SUCCESS: null,
  UPDATE_KIOSKS_SELECTED_FAILURE: null,

  // datePicker
  UPDATE_DATE: null,

  // myAccount
  GET_MY_ACCOUNT: null,
  GET_MY_ACCOUNT_SUCCESS: null,
  GET_MY_ACCOUNT_FAILURE: null,
  UPDATE_MY_ACCOUNT: null,
  UPDATE_MY_ACCOUNT_SUCCESS: null,
  UPDATE_MY_ACCOUNT_FAILURE: null,

  // user detail
  GET_USER_DETAIL: null,
  GET_USER_DETAIL_SUCCESS: null,
  GET_USER_DETAIL_FAILURE: null,
  UPDATE_USER_DETAIL: null,
  UPDATE_USER_DETAIL_SUCCESS: null,
  UPDATE_USER_DETAIL_FAILURE: null,
  USER_DETAIL_RESET_PASSWORD: null,
  USER_DETAIL_RESET_PASSWORD_SUCCESS: null,
  USER_DETAIL_RESET_PASSWORD_FAILURE: null,
  POST_USER_CREATE: null,
  POST_USER_CREATE_SUCCESS: null,
  POST_USER_CREATE_FAILURE: null,

  // PAYMENT COLLECTION
  GET_SALES_ORDER_EXCLUDED: null,
  GET_SALES_ORDER_EXCLUDED_SUCCESS: null,
  GET_SALES_ORDER_EXCLUDED_FAILURE: null,
  GET_PAYMENT_COLLECTION: null,
  GET_PAYMENT_COLLECTION_SUCCESS: null,
  GET_PAYMENT_COLLECTION_FAILURE: null,
  GET_PAYMENT_COLLECTION_DETAIL: null,
  GET_PAYMENT_COLLECTION_DETAIL_SUCCESS: null,
  GET_PAYMENT_COLLECTION_DETAIL_FAILURE: null,
  UPDATE_PAYMENT_COLLECTION_SELECTED: null,
  UPDATE_SALES_ORDER_EXCLUDED_AND_INCLUDED: null,
  UPDATE_PAYMENT_COLLECTION_SALES_ORDER: null,
  UPDATE_PAYMENT_COLLECTION_SALES_ORDER_SUCCESS: null,
  UPDATE_PAYMENT_COLLECTION_SALES_ORDER_FAILURE: null,
  GET_SALES_ORDER_PAYMENT_COLLECTION_DETAIL: null,

  // Stock_manager
  GET_ITEMS_KIOSKS_STOCK          : null,
  GET_ITEMS_KIOSKS_STOCK_SUCCESS  : null,
  GET_ITEMS_KIOSKS_STOCK_FAILURE  : null,
  UPDATE_ITEMS_KIOSKS_STOCK          : null,
  UPDATE_ITEMS_KIOSKS_STOCK_SUCCESS  : null,
  UPDATE_ITEMS_KIOSKS_STOCK_FAILURE  : null,
  UPDATE_STOCK_FAMILY_SELECTED: null,
  UPDATE_STOCK_BRANDS_SELECTED: null,

  // Modal
  HYDRATE_MODAL : null,
  CLOSE_MODAL : null,
  CLOSE_MODAL_REDIRECT : null,

  // Error
  CREATE_ERROR: null,
  CREATE_CRITICAL_ERROR: null,


  // logs
  GET_LOGS_ALERT: null,
  GET_LOGS_ALERT_SUCCESS: null,
  GET_LOGS_ALERT_FAILURE: null,
  CLOSE_LOGS: null,
  PUT_REMOTED_LOG_ALERT: null,
  PUT_REMOTED_LOG_ALERT_SUCCESS: null,
  PUT_REMOTED_LOG_ALERT_FAILURE: null,
  PUT_RESOLVED_LOG_ALERT: null,
  PUT_RESOLVED_LOG_ALERT_FAILURE: null,
  PUT_RESOLVED_LOG_ALERT_SUCCESS: null,
  PUT_RESOLVED_ALL_LOG_ALERT:null,
  PUT_RESOLVED_ALL_LOG_ALERT_FAILURE:null,
  PUT_RESOLVED_ALL_LOG_ALERT_SUCCESS:null,
  GET_LOGS_HISTORY: null,
  GET_LOGS_HISTORY_FAILURE: null,
  GET_LOGS_HISTORY_SUCCESS: null,
  UPDATE_LOGS_HISTORY_FILTER: null,
  UPDATE_LOGS_HISTORY_FILTER_LEVEL: null,
  
  // dashboard
  GET_DASHBOARD_SETTINGS: null,
  GET_DASHBOARD_SETTINGS_SUCCESS: null,
  GET_DASHBOARD_SETTINGS_FAILURE: null,
  UPDATE_DASHBOARD_SETTINGS_SELECTED: null,
  PUT_DASHBOARD_SETTINGS: null,
  PUT_DASHBOARD_SETTINGS_SUCCESS: null,
  PUT_DASHBOARD_SETTINGS_FAILURE: null,
  GET_DASHBOARD: null,
  GET_DASHBOARD_SUCCESS: null,
  GET_DASHBOARD_FAILURE: null,
  UPDATE_DASHBOARD_SETTINGS_ORDER: null,


  // export data
  GET_EXPORT_DATA: null,
  GET_EXPORT_DATA_SUCCESS: null,
  GET_EXPORT_DATA_FAILURE: null,

  // ware house stock
  GET_WARE_HOUSE_STOCK_MANAGEMENT: null,
  GET_WARE_HOUSE_STOCK_MANAGEMENT_SUCCESS: null,
  GET_WARE_HOUSE_STOCK_MANAGEMENT_FAILURE: null,


});

export default ActionsTypes