import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// assets import
import CloseMenu from '../../../../assets/icones/global/close.png'
import LogoWedeal from '../../../../assets/icones/header/logo-Wedeal.png'


//////////////////
//  START STYLE
/////////////////
const Close = styled.div`
    display: flex;
    position: absolute;
    top: 6px;
    left: 170px;
    width: 24px;
    height: 24px;
    cursor: pointer;
`;
const LogoweDealWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 56px;
`;
/**
 *
 * @param {func} closeMenuHandler
 * @returns {*}
 * @constructor
 */
const Header = ({ closeMenuHandler }) => {
    return (
        <Fragment>
            <Close
                name='showMenu'
                onClick={closeMenuHandler}
            >
                <img src={CloseMenu} />
            </Close>
            <LogoweDealWrapper>
                <img src={LogoWedeal} />
            </LogoweDealWrapper>
        </Fragment>
    )
};

Header.propTypes = {
    closeMenuHandler: PropTypes.func,
};

export default Header
