import {
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';
import {
    kiosksActionCreators,
    globalLoadingActionCreators,
    errorManagerActionCreators, modalsActionCreators
} from '../actions';
import API from '../../constants/ApiUrl'
import * as R from '../../constants/ApiConstants'
import { selectIdsUser } from '../selectors/user'
import { map } from 'lodash'


const getKiosks = function* () {
    
    const user      = yield select(selectIdsUser);
    const apiCall   = R.GET_KIOSKS(user);

    yield put(globalLoadingActionCreators.startLoading());

    try{
        const data = yield API(user.jwt).get(apiCall);

        yield put(kiosksActionCreators.getKiosksSuccess(data.data));
        
    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(kiosksActionCreators.getKiosksFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());

};

const updateKiosks = function* ({payload}) {
    
    const kiosksList = map(payload.kiosksList, (elem) => {
            const item = {
                idKiosk: elem.idKiosk,
                idExternStore: elem.idExternStore,
            };
        return item
    });

    yield put(globalLoadingActionCreators.startLoading());

    const user      = yield select(selectIdsUser);
    const apiCall   = R.UPDATE_KIOSKS_STORE(user);
    
    try{
        const data = yield API(user.jwt).put(apiCall, kiosksList);

        yield put(kiosksActionCreators.updateKiosksSuccess(data.data));

        if(data.data.error.length){
            yield put(errorManagerActionCreators.createError(data));
        }else{

            if( data.status === 200 ) {
                const modal = {
                    level: 'validation',
                    title: 'page__kioskManagement__modalUpdateSuccess_title',
                    key: 'page__kioskManagement__modalUpdateSuccess_body',
                    show: true,
                    path: ''
                };
                yield put(modalsActionCreators.hydrateModal(modal))
            }
        }
        
    } catch (e) {

        yield put(errorManagerActionCreators.createError(e.response));
        yield put(kiosksActionCreators.updateKiosksFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());
    
};

const sagas = function* () {
    yield takeLatest(ActionsTypes.GET_KIOSKS, getKiosks);
    yield takeLatest(ActionsTypes.UPDATE_KIOSKS_SELECTED, updateKiosks);
};

export default sagas