import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ModalBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px;
`;
/**
 *
 * @param {object} children
 * @returns {*}
 * @constructor
 */
const ModalBody = ({ children }) => {
    return (
        <ModalBodyWrapper>
            {children}
        </ModalBodyWrapper>
    )
};

ModalBody.propTypes = {
    children: PropTypes.array,
};

export default ModalBody
