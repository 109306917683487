import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled  from 'styled-components'

// import routes
import ModalRoute from '../../route/ModalRoute'

/////////////////////////////
//  START style
////////////////////////////
const ModalWrapper = styled.div`
    position: absolute;
    top: ${ props => props.postop + 'px'};
    left: 0;
    display: flex;
    justify-items: center;
    align-items: center;
    width: 1024px;
    height: 100vh;
    z-index: 19;
    overflow: hidden;
`;
const ModalBackground = styled.div`
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.5;
    z-index: 19;
    width: 1024px;
    height: 100vh;
`;
/////////////////////////////
//  END style
////////////////////////////


export default function (ComposedComponent) {
    class ModalHOC extends Component {
        static propTypes = {
            selectedElement: PropTypes.object,       // element selected on clic action button
            type: PropTypes.bool,                    // with deposit or not
        };
        state = {
            showModal: false,
            selectedElement: null,
            deleteItem: null,
            windowPageYOffset: 0,
            windowPageXOffset: 0,
        };
        componentWillUnmount = () => {
            this.closeModalHandler()
        };

        openModalHandler = ( element ) => {
            this.setState({
                showModal: !this.state.showModal,
                selectedElement: element,
                windowPageYOffset: window.pageYOffset,
                windowPageXOffset: window.pageXOffset,
            });
            document.body.style.overflow = 'hidden'
            // window.addEventListener('scroll', this.onScrollHandler);
        };
        closeModalHandler = ( goBackOf ) => {
            const { history, match } = this.props;

            this.setState({
                showModal: !this.state.showModal,
                selectedElement: null,
            });

            document.body.style.overflow = 'scroll'

            if((history.location.pathname).includes(match.path)){this.props.history.replace(match.url)}
        };
        onScrollHandler = () => {
            const { showModal, windowPageYOffset, windowPageXOffset } = this.state;
            if( showModal ) {
                window.scrollTo(windowPageXOffset, windowPageYOffset);
            }
        };
        render() {
            const { selectedElement, type } = this.state;
            let posTop = window.scrollY - 120;
            return (
                <Fragment>
                    {
                        this.state.showModal &&
                                <ModalWrapper
                                    postop={posTop}
                                >
                                    <ModalRoute
                                        match={this.props.match}
                                        elem={selectedElement}
                                        closeModalHandler={this.closeModalHandler}
                                        deposit={!selectedElement.withDeposit}
                                        type={type}
                                        postop={window.scrollY}
                                    />
                                    <ModalBackground
                                        postop={window.scrollY}
                                        onClick={this.closeModalHandler}
                                    />
                                </ModalWrapper>
                    }
                    <ComposedComponent 
                        modalHandler={this.openModalHandler}
                        { ...this.props } 
                    />
                </Fragment>
            )
        }
    }

    return ModalHOC
}

