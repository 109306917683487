////////LIBRARY/////////
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import moment from 'moment'
import { find } from 'lodash'
///////COMPONENTS///////
import 'moment/locale/fr'
import Button from '../../Buttons'
import TagImgAndText from "../../TagImgAndText";
import theme from "../../../../theme/theme";
/////////ASSETS/////////
import Close from '../../../../assets/icones/global/close-black.png'

/////////STYLED/////////
const AnimationLogAlert = keyframes`
    from {
      width: 0px;
    }
    to {
      width: 260px;
    }
`
const PositionComponent = styled.div`
    position: absolute;
    top: 124px;
    overflow: hidden;
    z-index: 1;
    right: 0px;
	box-shadow: -2px 0 6px rgba(0, 0, 0, 0.16);
	transition: width 300ms;
	width: ${ ( { menuTransition } ) => {
    switch ( menuTransition ) {
        case "entering":
            return "0px"
        case "entered":
            return "297px"
        case "exiting":
            return "0px"
        case "exited":
            return "0px"
        default:
            return "0px"
    }
} };
`
const ComponentWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 297px;
    background-color: ${ props => props.theme.colors.white };
`
const HeaderWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 23px;
    padding: 15px;
`
const BodyWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 7px;
    max-height: 633px;
    overflow-y: auto;
    padding-bottom: 15px;
`
const HeaderTitle = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 900;
    font-size: ${ props => props.theme.font.fxl };
    color: ${ props => props.theme.colors.primary };
`
const HeaderSubTitle = styled.div`
    color: ${ props => props.colored };
    margin: ${ props => props.margin ? '5px 0' : '0' };
   
`
const BodyCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: ${ props => props.theme.colors.logAlertBodyCard };
    padding: 10px 15px;
`
const TitleCard = styled.div`
    font-weight: 900;
`
const ButtonContainer = styled.div`
    height: 36px;
`
const TitleImg = styled.img`
    height: 23px;
    width: 20px;
    margin-right: 10px;
`
const CloseContainer = styled.div`
    position: absolute;
    top: 12px;
    right: 8px;
    cursor: pointer;
`
const CloseImg = styled.img`
    height: 24px;
    width: 24px;
`

const CloseAll = styled.div`
    height: 23px;
    width: max-content;
    display:flex;
    align-items:center;
    color:${props => props.theme.colors.primary};
    text-decoration:underline;
    margin-left:7%;
    cursor:pointer;
`
/////////STYLED/////////

/**
 *
 * @param {string} side
 * @param {string} titleImg
 * @param {string} title
 * @param {array} content
 * @param {array} refCollection
 * @param {func} closeMenuHandler
 * @param {func} openDetailHandler
 * @param {object} translations
 * @param {object} menuTransition
 * @returns {jsx}
 */
const LogsAlert =
    ( {
          side,
          titleImg,
          title,
          content,
          refCollection,
          closeMenuHandler,
          openDetailHandler,
          translations,
          menuTransition,
          opencloseAllNotif
      } ) => {

        ///////////////////////////////// RENDER ///////////////////////////////////////

        return (
            <Fragment>
                <PositionComponent
                    side={ side }
                    ref={ el => ( refCollection['logsAlert'] = el ) }
                    menuTransition={ menuTransition }
                >
                    <ComponentWrapper>
                       
                        <CloseContainer
                            onClick={ closeMenuHandler }
                        >
                            <CloseImg
                                src={ Close }
                            />
                        </CloseContainer>
                        <HeaderWrapper>
                            <HeaderTitle>
                                <TitleImg
                                    src={ titleImg }
                                />
                                { title }
                            
                            </HeaderTitle>
                            {  
                                find(content, n => (n.logLevel?.id === 6)) &&
                                <CloseAll onClick={() => opencloseAllNotif()}>{translations['header__admin__notif__link_closeAll']}</CloseAll>
                            }
                        </HeaderWrapper>
                        <BodyWrapper>
                            {
                                content.map( ( item, id ) => (
                                    <BodyCard
                                        key={ id }
                                    >
                                        <TitleCard>
                                            <TagImgAndText
                                                width='20px'
                                                idLevel={item.logLevel.id}
                                                text={moment( item.dateCreated ).locale( 'fr' ).format( 'ddd D/M/YY - HH:mm:ss' )}
                                            />
                                        </TitleCard>
                                        <HeaderSubTitle
                                            colored={theme.colors.primary}
                                            margin
                                        >
                                            { translations[item.logEvent.translationKey] }
                                        </HeaderSubTitle>
                                        <ButtonContainer>
                                            <Button
                                                actionHandler={() => openDetailHandler(item)}
                                                text={translations['header__notif_button_viewDetail']}
                                            />
                                        </ButtonContainer>
                                    </BodyCard>
                                ) )
                            }
                        </BodyWrapper>
                    </ComponentWrapper>
                </PositionComponent>
            </Fragment>

        )
    };

LogsAlert.propTypes = {
    side: PropTypes.string,
    titleImg: PropTypes.string,
    title: PropTypes.string,
    titleContent: PropTypes.string,
    textColor: PropTypes.string,
    content: PropTypes.array,
    refCollection: PropTypes.array,
    closeMenuHandler: PropTypes.func,
    translations: PropTypes.object
};

export default LogsAlert
