import React, { Fragment } from 'react'
import { Switch, Route } from 'react-router'

// list routes constant
import CR from '../constants/RouteConstants'

// list component to render
import ReservedRoute from '../components/Routing/ReservedRoute'
import Login from '../containers/Login'
import Home from '../containers/Home'
import ProductCatalogue from '../containers/ProductCatalogue'
import SalesHistories from '../containers/SalesHistories'
import CodesHistories from '../containers/CodesHistories'
import PriceManager from '../containers/PriceManager'
import KiosksManager from '../containers/KiosksManager'
import ProductDetail from '../containers/ProductDetail'
import MyAccount from '../containers/MyAccount'
import PromoManager from '../containers/PromoManager'
import PaymentCollection from '../containers/PaymentCollection'
import PaymentCollectionDetail from '../containers/PaymentCollectionDetail'
import StockManager from '../containers/StockManager'
import UpdatePromo from '../containers/PromoManager/UpdatePromo'
import CriticalError from "../containers/Error/CriticalError";
import UserSetting from "../containers/UserSetting";
import AdminUser  from "../containers/AdminUsers";
import LogsHistory  from "../containers/LogsHistory";
import UserDetail from "../containers/UserDetail";
import CreateUser from "../containers/CreateUser";
/* import WareHouseStockManagement from "../containers/WareHouseStockManagement"; */
import Receipt from "../containers/Receipt/Receipt";

const AppRoute = (
  <Fragment>
    <Switch>

      {/* START not authenticated user route */}
      <Route
        exact
        path={CR.login}
        component={Login}
      />
      <Route
        exact
        path={CR.criticalError}
        component={CriticalError}
      />

      {/* END not authenticated user route */}

      {/* START authenticated user route */}
      <ReservedRoute
        path={CR.home}
        component={Home}
      />
      <ReservedRoute
        path={CR.kioskProduct}
        // breadcrumb='> produits'
        breadcrumb={'header__breadcrumb_itemsCatalogue'}
        component={ProductCatalogue}
      />
      <ReservedRoute
        path={CR.salesHistories}
        // breadcrumb='> historique des ventes'
        breadcrumb={'header__breadcrumb_salesHistory'}
        component={SalesHistories}
      />
      <ReservedRoute
        path={CR.salesHistoriesByItem}
        // breadcrumb='> produits > historique des ventes'
        breadcrumb={'header__breadcrumb_salesHistoryByItem'}
        component={SalesHistories}
      />
      <ReservedRoute
          path={CR.salesHistoriesBySales.route}
          // breadcrumb='> produits > historique des ventes'
          breadcrumb={'header__breadcrumb_salesHistoryByItem'}
          component={SalesHistories}
      />
      <ReservedRoute
        path={CR.codeHistories}
        // breadcrumb='> historique des codes'
        breadcrumb={'header__breadcrumb_codesHistory'}
        component={CodesHistories}
      />
      <ReservedRoute
        path={CR.productDetail.route}
        // breadcrumb='> produits > fiche produit'
        breadcrumb={'header__breadcrumb_productDetail'}
        component={ProductDetail}
      />
     {/*  <ReservedRoute
        path={CR.priceManagment}
        // breadcrumb='> gestion des prix'
        breadcrumb={'header__breadcrumb_pricesManagement'}
        component={PriceManager}
      /> */}
      <ReservedRoute
        path={CR.kioskManagment}
        // breadcrumb='> gestion des machines'
        breadcrumb={'header__breadcrumb_kiosksManagement'}
        component={KiosksManager}
      />
      <ReservedRoute
        path={CR.myAccount}
        breadcrumb={'header__breadcrumb_myAccount'}
        component={MyAccount}
      />
      <ReservedRoute
        path={`${CR.userDetail}/:id`}
        breadcrumb={'header__breadcrumb_updateUser'}
        component={UserDetail}
      />
      <ReservedRoute
        path={CR.promoManager}
        // breadcrumb='> gestion des prix > générer promo'
        breadcrumb={'header__breadcrumb_promoCreation'}
        component={PromoManager}
      />
     {/*  <ReservedRoute
        exact
        path={CR.telecollection}
        // breadcrumb='> gestion des télécollectes'
        breadcrumb={'header__breadcrumb_paymentCollectionHistory'}
        component={PaymentCollection}
      />
      <ReservedRoute
        exact
        path={`${CR.telecollection}/:id`}
        // breadcrumb='> gestion des télécollectes > détail télécollecte'
        breadcrumb={'header__breadcrumb_paymentCollectionDetail'}
        component={PaymentCollectionDetail}
      /> */}
      <ReservedRoute
        exact
        path={`${CR.mangeStockKiosk}`}
        // breadcrumb='> gestion des stocks'
        breadcrumb={'header__breadcrumb_stockManagement'}
        component={StockManager}
      />
      <ReservedRoute
        exact
        path={`${CR.mangeStockKiosk}`}
        // breadcrumb='> gestion des stocks'
        breadcrumb={'header__breadcrumb_stockManagement'}
        component={StockManager}
      />
      <ReservedRoute
          exact
          path={`${CR.mangeStockKiosk}/:id`}
          // breadcrumb='> gestion des stocks'
          breadcrumb={'header__breadcrumb_stockManagement'}
          component={StockManager}
      />
      <ReservedRoute
        exact
        path={`${CR.updatePromoManager.text}/:id`}
        // breadcrumb='> gestion des prix > update promo'
        breadcrumb={'header__breadcrumb_promoUpdate'}
        component={UpdatePromo}
      />

      <ReservedRoute
          exact
          path={`${CR.userSetting}`}
          // breadcrumb='> gestion des prix > update promo'
          breadcrumb={'header__breadcrumb_mySettings'}
          component={UserSetting}
      />

      <ReservedRoute
          path={CR.adminUser}
          breadcrumb={'header__breadcrumb_userManagement'}
          component={AdminUser}
      />
      <ReservedRoute
          path={CR.logHistory}
          // breadcrumb='> logs'
          breadcrumb={'header__breadcrumb_logsHistory'}
          component={LogsHistory}
      />
      <ReservedRoute
          path={`${CR.logHistoryUser.route}`}
          breadcrumb={'header__breadcrumb_userLogHistory'}
          component={LogsHistory}
      />
      <ReservedRoute
          path={`${CR.logHistoryUsers}`}
          breadcrumb={'header__breadcrumb_usersLogHistory'}
          component={LogsHistory}
      />
      <ReservedRoute
          path={`${CR.createUser}`}
          breadcrumb={'header__breadcrumb_createUser'}
          component={CreateUser}
      />
{/*       <ReservedRoute
          path={`${CR.warehouseStockManagement}`}
          breadcrumb={'header__breadcrumb_warehouseStockManagement'}
          component={WareHouseStockManagement}
      /> */}
      <ReservedRoute
          path={`${CR.receipt.merchant.route}`}
          component={Receipt}
          noHeader
      />
      <ReservedRoute
          path={`${CR.receipt.customer.route}`}
          component={Receipt}
          noHeader
      />
      <ReservedRoute
          path={`${CR.printTicket.route}`}
          component={Receipt}
          noHeader
      />

      {/* error route authenticated user */}
      <ReservedRoute
        component={Home}
      />
      {/* END authenticated user route */}

    </Switch>
  </Fragment>
);


export default AppRoute
