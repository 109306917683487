import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import '../../../DatePickerStyle/react-datepicker.css'

import styled from 'styled-components'

// import components
import Modal from '../../../components/Ui/Modals/Modal'
import { fr } from 'date-fns/locale'
import moment from 'moment'
import theme from "../../../theme/theme";
import DateFormat from "../../../helpers/dateFormat";
import { ErrorFormMsg } from "../../../theme/GlobalCss";
import Button from "../../../components/Ui/Buttons";


/// //////////////////////////
//  START style
/// /////////////////////////
const ModalDateTimeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 611px;
    padding: 26px;
`;
const CalandarsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: ${props => props.marginTop ? '23px' : '0'};
    height: ${props => props.height ? props.height + 'px' : 'inherit' };
`;
const InputsWrapper = styled.div`
    width: 611px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;
    font-size: 16px;
`;
const PaireInputWrapper = styled.div`
    display: flex;
    width: 280px;
    justify-content: space-between;
    z-index: 2;
`;
const InputStyle = styled.div`
    width: 86px;
    height: 14px;
    padding: 13px 20px;
    border: 1px solid #8D9097;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const TextAndInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const TextBelowInput = styled.p`
    color: #0885D5;
    margin: 0;
    padding-bottom: 5px;
`;
const ButtonBottm = styled.div`
    padding: 11px 15px;
    text-align: center;
    background-color: ${props => props.bcg};
    border-radius: 3px;
    margin-right: ${props => props.margin ? '22px' : '0'};
    color: white;
    cursor: pointer;
`;
const BottomButtonWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    height: 39px;
    position: relative;
`;
const ErrorMsg = styled.span`
  display: flex;
  position: absolute;
  top: -17px;
  right: 0px;
  direction: rtl;
`
/// //////////////////////////
//  END style
/// /////////////////////////

const DateTimePicker = ({
  startDate,
  endDate,
  startDateHandleChange,
  endDateHandleChange,
  startTimeHandleChange,
  endTimeHandleChange,
  formatDate,
  cancelUpdateHandler,
  toDayHandler,
  weekSelectHandler,
  monthSelectHandler,
  updateDate,
  translations
}) => {
  const isSameDay = moment(startDate).isSame(moment(endDate), 'day');

  return (
    <Modal>
      <ModalDateTimeWrapper>
        <InputsWrapper>
          <PaireInputWrapper>
            <TextAndInputWrapper>
              <TextBelowInput>
                {translations['modal__dateSelection__startDate']}
                {/* Date de début */}
              </TextBelowInput>
              <InputStyle>
                {formatDate(startDate)}
              </InputStyle>
            </TextAndInputWrapper>
            <TextAndInputWrapper>
              <TextBelowInput>
                {translations['modal__dateSelection__startHour']}
                {/* Heure de début */}
              </TextBelowInput>
              <DatePicker
                selected={startDate}
                onChange={startTimeHandleChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption='Time'
                timeFormat='HH:mm'
                dateFormat='HH:mm'
                minTime={ moment(startDate).startOf('day').toDate() }
                maxTime={isSameDay ? moment(endDate).subtract(15, 'minutes').toDate() : moment(startDate).endOf('day').toDate()}
              />
            </TextAndInputWrapper>
          </PaireInputWrapper>
          <PaireInputWrapper>
            <TextAndInputWrapper>
              <TextBelowInput>
                {translations['modal__dateSelection__endDate']}
                {/* Date de fin */}
              </TextBelowInput>
              <InputStyle>
                {formatDate(endDate)}
              </InputStyle>
            </TextAndInputWrapper>
            <TextAndInputWrapper>
              <TextBelowInput>
                {translations['modal__dateSelection__endHour']}
                {/* Heure de fin */}
              </TextBelowInput>
              <InputStyle>
                <DatePicker
                  selected={endDate}
                  onChange={endTimeHandleChange}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption='Time'
                  timeFormat='HH:mm'
                  dateFormat='HH:mm'
                  minTime={isSameDay ? moment(startDate).add(15, 'minutes').toDate() : moment(endDate).startOf('day').toDate()}
                  maxTime={moment(endDate).endOf('day').toDate()}
                />
              </InputStyle>
            </TextAndInputWrapper>
          </PaireInputWrapper>

        </InputsWrapper>
        <CalandarsWrapper
            height='320'
        >
          <DatePicker
            selected={startDate}
            onChange={startDateHandleChange}
            locale={fr}
            maxDate={moment(endDate).subtract(15, 'minutes').toDate()}
            inline
          />
          <DatePicker
            selected={endDate}
            onChange={endDateHandleChange}
            locale={fr}
            inline
            minDate={moment(startDate).add(15, 'minutes').toDate()}
          />
        </CalandarsWrapper>

        <CalandarsWrapper marginTop>
          <BottomButtonWrapper>
            <ButtonBottm
              bcg={theme.colors.primary}
              margin
              onClick={toDayHandler}
            >
              {translations['common__text__today']}
              {/* Aujourd'hui */}
            </ButtonBottm>
            <ButtonBottm
              onClick={weekSelectHandler}
              bcg={theme.colors.primary}
              margin
            >
              {translations['common__text__week']}
              {/* Semaine */}
            </ButtonBottm>
            <ButtonBottm
              bcg={theme.colors.primary}
              onClick={monthSelectHandler}
            >
              {translations['common__text__month']}
              {/* Mois */}
            </ButtonBottm>
          </BottomButtonWrapper>
          <BottomButtonWrapper>
            <ButtonBottm
              onClick={cancelUpdateHandler}
              bcg={theme.colors.error}
              margin
            >
              {translations['common__text__cancel']}
              {/* Annuler */}
            </ButtonBottm>
            <Button
                text={translations['common__text__save']}
                actionHandler={updateDate}
                bgColor={theme.colors.validation}
                disabled={DateFormat.dateEndIsBefore(startDate, endDate)}
            />
            {
              DateFormat.dateEndIsBefore(startDate, endDate) &&
                <ErrorMsg>
                  {translations['common__message__dateSelection_update_invalidRangeDate']}
                </ErrorMsg>
            }

          </BottomButtonWrapper>
        </CalandarsWrapper>

      </ModalDateTimeWrapper>
    </Modal>
  )
};

DateTimePicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  startDateHandleChange: PropTypes.func,
  endDateHandleChange: PropTypes.func,
  startTimeHandleChange: PropTypes.func,
  endTimeHandleChange: PropTypes.func,
  formatDate: PropTypes.func,
  cancelUpdateHandler: PropTypes.func,
  updateDate: PropTypes.func,
  translations: PropTypes.object
};

export default DateTimePicker
