/*import pictShortcutHome from "../assets/icones/shortcut/management/home.svg"
import pictShortcutUser from "../assets/icones/shortcut/management/user.svg"
import pictShortcutTag from "../assets/icones/shortcut/management/tag.svg"
import pictShortcutRole from "../assets/icones/shortcut/management/role.svg"
import pictShortcutCompany from "../assets/icones/shortcut/management/company.svg"
import pictShortcutRgpd from "../assets/icones/shortcut/management/rgpd.svg"
import pictShortcutLog from "../assets/icones/shortcut/management/log.svg"
import pictShortcutReferential from "../assets/icones/shortcut/items/referential.svg"
// TODO : Import pictShortcutCatalog when back has added the shortcut
import pictShortcutSummary from "../assets/icones/shortcut/stocks/summary.svg"
import pictShortcutSaleKiosk from "../assets/icones/shortcut/sales/kiosk.svg"
import pictShortcutSaleItem from "../assets/icones/shortcut/sales/item.svg"
import pictShortcutConsignment from "../assets/icones/shortcut/sales/consignment.svg"
import pictShortcutSaleCode from "../assets/icones/shortcut/sales/code.svg"
import pictShortcutPaymentCollection from "../assets/icones/shortcut/sales/payment-collection.svg"*/

import pictShortcutStockManagement from "../assets/icones/shortcut/stocks/stock-management.svg"
import pictShortcutSalesHistory from "../assets/icones/shortcut/sales/sales-history.svg"
import pictShortcutCodesHistory from "../assets/icones/shortcut/sales/codes-history.svg"
import pictShortcutHome from "../assets/icones/shortcut/management/home.svg"
import pictShortcutLogHistory from "../assets/icones/shortcut/management/logs-history.svg"
import pictShortcutUsersManagement from "../assets/icones/shortcut/management/users-management.svg"
import pictShortcutKiosksManagement from "../assets/icones/shortcut/management/kiosks-management.svg"
import pictShortcutProducts from "../assets/icones/shortcut/items/products.svg"

import pictUsagesBbq from "../assets/icones/item/usages/bbq.png"
import pictUsagesCamping from "../assets/icones/item/usages/camping.png"
import pictUsagesDesherbeur from "../assets/icones/item/usages/desherbeur.png"
import pictUsagesWeldingTorch from "../assets/icones/item/usages/welding-torch.png"
import pictUsagesShower from "../assets/icones/item/usages/shower.png"
import pictUsagesCookingPot from "../assets/icones/item/usages/cooking-pot.png"
import pictUsagesPatioHeater from "../assets/icones/item/usages/patio-heater.png"
import pictUsagesOcho from "../assets/icones/item/usages/ocho.png"
import pictUsagesHeating from "../assets/icones/item/usages/heating.png"

export const shortcutPicsConfig = [
   /* {"id": 33, "picture": pictShortcutHome},
    {"id": 24, "picture": pictShortcutUser},
    {"id": 25, "picture": pictShortcutTag},
    {"id": 26, "picture": pictShortcutRole},
    {"id": 27, "picture": pictShortcutCompany},
    {"id": 28, "picture": pictShortcutRgpd},
    {"id": 29, "picture": pictShortcutLog},
    {"id": 30, "picture": pictShortcutLog},
    {"id": 31, "picture": pictShortcutLog},
    {"id": 32, "picture": pictShortcutLog},
    
    {"id": 12, "picture": pictShortcutReferential},
    // TODO : catalog.svg is missing => no back shortcut is returned
    
    {"id": 17, "picture": pictShortcutSummary},
    {"id": 18, "picture": pictShortcutSaleKiosk},
    {"id": 19, "picture": pictShortcutSaleItem},
    {"id": 20, "picture": pictShortcutConsignment},
    {"id": 21, "picture": pictShortcutSaleCode},
    {"id": 22, "picture": pictShortcutPaymentCollection},*/

    {"id": 1, "picture": pictShortcutHome},
    {"id": 2, "picture": pictShortcutProducts},
    {"id": 3, "picture": pictShortcutStockManagement},
    {"id": 5, "picture": pictShortcutUsersManagement},
    {"id": 6, "picture": pictShortcutKiosksManagement},
    {"id": 9, "picture": pictShortcutSalesHistory},
    {"id": 10, "picture": pictShortcutCodesHistory},
    {"id": 11, "picture": pictShortcutLogHistory}
]

export const usagePicsConfig = [
    {"id": 1,"picture": pictUsagesBbq},
    {"id": 2,"picture": pictUsagesCamping},
    {"id": 3,"picture": pictUsagesDesherbeur},
    {"id": 4,"picture": pictUsagesWeldingTorch},
    {"id": 5,"picture": pictUsagesShower},
    {"id": 6,"picture": pictUsagesCookingPot},
    {"id": 7,"picture": pictUsagesPatioHeater},
    {"id": 8,"picture": pictUsagesOcho},
    {"id": 9,"picture": pictUsagesHeating}
]