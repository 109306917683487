import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

// import routes
import ModalPapper from '../../../components/Ui/Modals/Modal'
/////////////////////////////
//  START style
////////////////////////////

const ModalWrapper = styled.div`
    position: absolute;
    top: ${ props => props.postop + 'px'};
    left: 0;
    display: flex;
    justify-items: center;
    align-items: center;
    width: 1024px;
    height: 100vh;
    overflow:hidden;
`;
const ModalBackground = styled.div`
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.5;
    z-index: 40;
    width: 1024px;
    height: 100vh;
`;
/////////////////////////////
//  END style
////////////////////////////

export class ModalSummerize extends Component {
    static propTypes = {
        showModal:              PropTypes.bool.isRequired,
        closeModalHandler:      PropTypes.func.isRequired,
        children:               PropTypes.object.isRequired,
        title:                  PropTypes.string,
        level:                  PropTypes.string,
        translations:           PropTypes.object,
        name:                   PropTypes.string,
    };

    state = {
        windowPageYOffset: window.pageYOffset,
        windowPageXOffset: window.pageXOffset,
    };

    componentDidUpdate( prevProps) {
        if(prevProps.showModal !== this.props.showModal) {
            this.setState({
                windowPageYOffset: window.pageYOffset,
                windowPageXOffset: window.pageXOffset,
            })
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.onScrollHandler);
    };
    onScrollHandler = () => {
        window.scrollTo(this.state.windowPageXOffset, this.state.windowPageYOffset);
    };

    render() {
        const { 
            showModal,
            closeModalHandler,
            children,
            title,
            level,
            name
        } = this.props;
        if( showModal ) {
            window.addEventListener('scroll', this.onScrollHandler );
        }else{
            window.removeEventListener('scroll', this.onScrollHandler);
        }

        let posTop = this.state.windowPageYOffset ;
        posTop = (name !== 'header') ? posTop - 120 : 0;
        return (
            <Fragment>
                {
                    showModal &&
                        <ModalWrapper
                            postop={posTop}
                        >
                            <ModalPapper
                                critical={1}
                                headerTittle={title}
                                closeModal={closeModalHandler}
                                color={level}
                            >
                                {children}
                            </ModalPapper>
                            <ModalBackground
                                postop={window.scrollY}
                                onClick={closeModalHandler}
                            />
                        </ModalWrapper>
                }
            </Fragment>
        )
    }
}


export default ModalSummerize

