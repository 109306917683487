import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

// components
import MenuLinks from "../MenuLinks";
import { Transition } from "react-transition-group";



////////////////////////
//  START styled
////////////////////////
const LinkWrapper = styled( Link )`
    text-decoration: none;
    display: grid;
    grid-template-columns: 30px 1fr;
    grid-row-gap: 2px;
    padding-left: 11px;
    justify-content: start;
    align-items: center;
    background-color: ${ props => props.theme.colors.menu.link };
    cursor: pointer;
    
    //nested menu
    grid-column-start: ${ props => props.nested === 'true' ? 1 : 0 };
    grid-column-end: ${ props => props.nested === 'true' ? 3 : 0 };
    background-color: ${ props => props.nested === 'true' ? props.theme.colors.grey : props.theme.colors.menu.link };
    grid-template-rows: ${ props => props.nested === 'true' ? '40px' : '50px' };
    overflow: ${ props => props.nested === 'true' ? 'hidden' : 'inherit' };
    
	// nested animation
	transition: max-height .3s;
	max-height: ${ ( { transitionstate } ) => {
		switch ( transitionstate ) {
			case "entering":
				return "0px"
			case "entered":
				return "50px"
			case "exiting":
				return "0px"
			case "exited":
				return "0px"
			default:
				return null
		}
	} };
`;
const LinkItem = styled.p`
    color: ${ props => props.theme.colors.white };
    text-transform: capitalize;
    font-weight: bold;
    margin-left: 10px;
    font-size: ${ props => props.extend === 'true' ? props.theme.font.fxs : props.theme.font.fm };
`;
const LinkImgWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: ${ props => props.extend === 'true' ? '16px' : 'auto' };
    width: auto;
`;
const ImgContent = styled.img`
    max-width: 16px;
    max-height: 16px;
`;
/**
 *
 * @param {func} toggleMenu
 * @param {func} handleNested
 * @param {object} translations
 * @param {Object} data
 * @param {Object} openMenuState
 * @param {string} transitionState
 * @returns {jsx}
 */
const MenuLink = ( { handleOpenNested, toggleMenu, translations, data, openMenuState, transitionState } ) => {
	return (

		<LinkWrapper
			to={ data.linkTo ? data.linkTo : '#' }
			as={ !data.linkTo && 'div' }
			onClick={ data.linkTo ? toggleMenu : handleOpenNested }
			id={ data.id }
			nested={ data.expend.toString() }
			transitionstate={ transitionState }
		>
			<LinkImgWrapper
				id={ data.id }
			>
				<ImgContent
					src={ data.image }
					extend={ data.expend.toString() }
				/>
			</LinkImgWrapper>
			<LinkItem
				id={ data.id }
				extend={ data.expend.toString() }
			>
				{ translations[data.text] }
			</LinkItem>

			<Transition
				in={ data.nested && openMenuState[data.id] }
				timeout={ {
					appear: 0,
					enter: 0,
					exit: 300,
				} }
				mountOnEnter
				unmountOnExit
			>
				{ state => (
					<MenuLinks
						menuList={ data.menuNested }
						closeMenuHandler={ toggleMenu }
						translations={ translations }
						openMenuState={ openMenuState }
						handleOpenNested={ handleOpenNested }
						transitionState={ state }
					/>
				) }
			</Transition>


		</LinkWrapper>

	)
};

MenuLink.propTypes = {
	toggleMenu: PropTypes.func,
	handleNested: PropTypes.func,
	translations: PropTypes.object,
	data: PropTypes.object,
	openMenuState: PropTypes.object,
	transitionState: PropTypes.string,
};

export default MenuLink
