import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { map, isEmpty } from 'lodash'

// component import
import DropdownDashboardSetting
    from "../../components/Ui/DropdownDashboardSetting/DropdownDashboardSetting";
import ModalDatePicker from '../../Hoc/ModalDatePicker'
import ChartFactory from "../../components/Ui/Charts/ChartFactory";

// asset
import Calendar from '../../assets/icones/global/calendarX2.png'

// helper
import StringFormater from '../../helpers/StringFormater';

// redux import
import { bindActionCreators } from "redux";
import {
    dashBoardActionCreators,
} from "../../state/actions";
import { connect } from "react-redux";

// selector
import { selectKioskSelected } from "../../state/selectors/user";
import { selectDashboardTables } from "../../state/selectors/dashBoard";

// style
import {
    PageWrapper,
    LineTableHeader,
    TitleHeader,
    ActionWrapper,
    CalendarButton,
    CalendarImage,
    CalendarText,

} from "../../theme/GlobalCss";


/////////////////////////////
//  START style
////////////////////////////
const AdjustButton = styled.div`
    border-radius: 4px;
    background-color: ${ props => props.theme.colors.primary };
    margin: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LineChartWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
    grid-row-gap: 25px;
    grid-column-gap: 16px;
`;

/////////////////////////////
//  END style
////////////////////////////

class Home extends Component {
    static propTypes = {
        actionsDatePicker:              PropTypes.object,
        closeDateModalHandler:          PropTypes.func,
        datePicker:                     PropTypes.object,
        formatDate:                     PropTypes.func,
        getDateFormat:                  PropTypes.func,
        openDateModalHandler:           PropTypes.func,
        dashBoardSetting:               PropTypes.object,
        actionDashBoard:                PropTypes.object,
        userKiosk:                      PropTypes.object,
    };

    componentDidMount() {
        this.getDashboardTables();
        this.getDashBoardSetting()
    }

    getDashBoardSetting = () => {
        const { actionDashBoard } = this.props;
        actionDashBoard.getDashboardSetting()
    };

    getDashboardTables = () => {
        const { actionDashBoard, getDateFormat } = this.props;
        const dateFormat = getDateFormat();
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        actionDashBoard.getDashboard(dateFormat,timeZone)
    };

    componentDidUpdate( prevProps) {
        const { history, userKiosk, datePicker } = this.props;

        if(history.location.pathname !== '/home'){
            history.replace('/home')
        }
        if ( datePicker !== prevProps.datePicker ) {
            this.getDashboardTables()
        }
        // when user switch kiosk selected get the new data
        if ( userKiosk !== prevProps.userKiosk ) {
            this.getDashboardTables()
        }
    }

    selectDashboardSettingHandler = ( id, isChecked ) => {
        const { actionDashBoard } = this.props;
        actionDashBoard.updateDashBoardSelected(id, isChecked)
    };

    onBlurPutDashboardSettingHandler = ( ) => {
        const { actionDashBoard, getDateFormat } = this.props;
        const dateFormat = getDateFormat();
        actionDashBoard.putDashboardSetting(dateFormat)
    };

    onDragEndHandler = ( result ) => {
        const { actionDashBoard } = this.props;
        const idItem    = parseInt(result.draggableId);
        const endRank   = result.destination.index;
        const startRank = result.source.index;
        actionDashBoard.updateDashboardSettingOrder( idItem, startRank, endRank )
    };

    render() {
        const { 
            formatDate,
            datePicker,
            openDateModalHandler,
            translations,
            dashBoardSetting,
            dashBoardTables,
        } = this.props;
        return (
            <PageWrapper>
                <LineTableHeader>
                    <TitleHeader>
                        <CalendarButton
                            onClick={openDateModalHandler}
                        >
                            <CalendarImage src={Calendar} />
                        </CalendarButton>
                        <CalendarText>
                            {StringFormater.ResponseMessageParser(translations['common__text__datePeriod_noHours'], [formatDate(datePicker.startDate),formatDate(datePicker.endDate)])}
                        </CalendarText>
                    </TitleHeader>
                    <ActionWrapper>
                        <AdjustButton>
                            <DropdownDashboardSetting
                                PaperTopPosition={'45'}
                                data={dashBoardSetting.items}
                                selectActionsHandler={this.selectDashboardSettingHandler}
                                translations={translations}
                                onBlurPutDashboardSettingHandler={this.onBlurPutDashboardSettingHandler}
                                onDragEndHandler={this.onDragEndHandler}
                                rtl
                            />
                        </AdjustButton>
                    </ActionWrapper>
                </LineTableHeader>
                <LineChartWrapper>
                    {
                        map(dashBoardTables, ( elem, id ) => (
                            !isEmpty(elem) &&
                                <ChartFactory
                                    key={id}
                                    data={elem}
                                    translations={translations}
                                />
                        ))
                    }
                </LineChartWrapper>

            </PageWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        dashBoardSetting: state.dashBoard,
        userKiosk: selectKioskSelected(state),
        dashBoardTables: selectDashboardTables(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionDashBoard: bindActionCreators(dashBoardActionCreators, dispatch),
    };
};


export default ModalDatePicker(connect(mapStateToProps, mapDispatchToProps)(Home))
