import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const ButtonAction = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${ props => props.padding ? `0 ${props.padding}px` : '0 15px' };
    background-color: ${ props => props.bgcolor ? props.bgcolor : props.theme.colors.primary };
    border-radius: 4px;
    font-weight: ${ props => props.weight === 'bold' ? 'bold' : 900};
    font-size: ${ props => props.goback ? props.theme.font.fs : props.theme.font.fm} ;
    color: ${ props => props.color ? props.color : props.theme.colors.white };
    cursor: pointer;
    height: 100%;
    margin-right: ${ props => props.margin ? '16px' : '0'};
    text-align: center;
    text-transform: ${ props => props.caps ? 'uppercase' : 'inherit' };
`;
const ButtonImage = styled.img`
    margin-right: 6px;
    max-height: 24px;
    max-width: 24px;
`;
const ButtonActionLink = styled(Link)`
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${ props => props.padding ? `0 ${props.padding}px` : '0 15px' };
    height: 100%;
    background-color: ${ props => props.bgcolor ? props.bgcolor : props.theme.colors.primary };
    border-radius: 4px;
    font-weight: ${ props => props.weight === 'bold' ? 'bold' : 900};
    font-size: ${ props => props.goback ? props.theme.font.fs : props.theme.font.fm };
    color: ${ props => props.color ? props.color : props.theme.colors.white };
    cursor: pointer;
    margin-right: ${ props => props.margin ? '16px' : '0'};
    text-align: center;
    text-transform: ${ props => props.caps ? 'uppercase' : 'inherit' };
`;
const GoBackIcone = styled.div`
    width: 0; 
    height: 0; 
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent; 
    border-right:8px solid ${ props => props.theme.colors.white };
    margin-right: 6px;
`;
const ButtonActionDisabled = styled(ButtonAction)`
    background-color: ${ props => props.theme.colors.grey };
    opacity: 0.5;
    cursor: not-allowed;
`;
/**
 *
 * @param {string} link url to redirect
 * @param padding
 * @param {src} img src to img
 * @param {string} text text to display
 * @param {function} actionHandler onClick action
 * @param {string} color
 * @param {string} bgColor
 * @param {string} margin
 * @param {string} weight
 * @param {boolean} goBack
 * @param {boolean} disabled
 * @param {boolean} caps
 * @param {string} target if blank link
 */
const Button = ({ 
    link,
    padding,
    img,
    text,
    actionHandler,
    color,
    bgColor,
    margin,
    weight,
    goBack,
    disabled,
    caps,
    target,
    }) => {
        return (
                link ?
                    disabled ?
                        <ButtonActionDisabled
                            color={color}
                            bgcolor={bgColor}
                            margin={margin}
                            weight={weight}
                            goback={goBack}
                            padding={padding}
                            caps={caps && caps.toString()}
                        >
                            { img && <ButtonImage src={img} /> }
                            {goBack && <GoBackIcone />}
                            {text}
                        </ButtonActionDisabled>
                    :

                        <ButtonActionLink
                            to={link}
                            onClick={actionHandler}
                            color={color}
                            bgcolor={bgColor}
                            margin={margin}
                            weight={weight}
                            goback={goBack}
                            padding={padding}
                            target={target}
                            caps={caps && caps.toString()}
                        >
                            { img && <ButtonImage src={img} /> }
                            {goBack && <GoBackIcone />}
                            {text}
                        </ButtonActionLink>
                :
                    disabled ? 
                        <ButtonActionDisabled
                            color={color}
                            bgcolor={bgColor}
                            margin={margin}
                            weight={weight}
                            goback={goBack}
                            padding={padding}
                            caps={caps && caps.toString()}
                        >
                            { img && <ButtonImage src={img} /> }
                            {goBack && <GoBackIcone />}
                            {text}
                        </ButtonActionDisabled>
                    :
                        <ButtonAction
                            color={color}
                            onClick={actionHandler}
                            bgcolor={bgColor}
                            margin={margin}
                            weight={weight}
                            goback={goBack}
                            padding={padding}
                            caps={caps && caps.toString()}
                        >
                            { img && <ButtonImage src={img} /> }
                            {goBack && <GoBackIcone />}
                            {text}
                        </ButtonAction>
        )
};

Button.propTypes = {
    link: PropTypes.string,
    img: PropTypes.string,
    text: PropTypes.string,
    actionHandler: PropTypes.func,
    color: PropTypes.string,
    bgColor: PropTypes.string,
    margin: PropTypes.string,
    weight: PropTypes.string,
    goBack: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default Button
