import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { isEmpty, map, isUndefined } from 'lodash'

import { bindActionCreators } from "redux";
import {
    salesHistoriesActionCreators, salesOrdersActionCreators,
} from "../../state/actions";
import { connect } from "react-redux";
import CR from "../../constants/RouteConstants";

// assets
import noImage from '../../assets/images/logoLogin.svg'
import imageImport from "../../helpers/imagesImport";


/////////////////////////////
//  START style
////////////////////////////
const PageWrapper = styled.div`
    display: flex;
    align-items: center;
    grid-column: 1 / 13;
    flex-direction: column;
    margin-top: -120px;
`;
const TableWrapper = styled.div`
    display: grid;
    grid-template-columns: max-content max-content;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    border: 1px solid black;
    padding: 20px;
`;
const TableTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    font-size: ${props => props.theme.font.fxxxl};
    font-weight: bold;
`;
const TableContentTitle = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px;
`;
const TableContentValue = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
`;
const TableImgWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 1/3;
    margin-bottom: 20px;
`;
const TableImg = styled.img`
    max-height: 45px;
`;
const TableTicketContentValue = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
`;
const BorderLineRows = styled.div`
    grid-column: 1/3;
    border-top: 1px solid black;
`;
const FooterWrapper = styled.div`
    width: ${props => {
        switch (props.datascope) {
            case "customer":
                return 'calc(68 * 7px)';
            case "merchant":
                return 'calc(68 * 6px)';
            default:
                return 'calc(68 * 6px)';
        }
    }};
    & > p{
        text-align: justify;
        font-size: 8px;
    }
    & > span{
        font-size: 4px;
    }
`;
/////////////////////////////
//  END style
////////////////////////////

class Receipt extends Component {
    static propTypes = {
        receipt: PropTypes.object,
        actionsSalesHistories: PropTypes.object,
    };

    componentDidMount() {
        this.getReceiptsHandler()
    }

    getReceiptsHandler = () => {
        const { actionsSalesHistories, match, actionsSaleOrder } = this.props;
        const idItem = parseInt(match.params.id);
        let scope;
        switch (match.path) {
            case CR.receipt.merchant.route:
                scope = 'merchant'
                actionsSalesHistories.getSalesHistoriesReceipt(idItem, scope)
                break;
            case CR.receipt.customer.route:
                scope = 'customer'
                actionsSalesHistories.getSalesHistoriesReceipt(idItem, scope)
                break;
            case CR.printTicket.route:
                actionsSaleOrder.getPrintCodeTicket(idItem)
                break;
            default:
                break;
        }
    };

    render() {
        const {
            receipt,
            translations,
            match
        } = this.props;
        let scope;
        switch (match.path) {
            case CR.receipt.merchant.route:
                scope = 'merchant'
                break;
            case CR.receipt.customer.route:
                scope = 'customer'
                break;
            default:
                scope = 'merchant'
                break;
        }

        if (isEmpty(receipt)) {
            return <div></div>
        }
        return (
            <PageWrapper>
                <TableTitle>
                    {translations[receipt.header]}
                </TableTitle>

                <TableWrapper>
                    <TableImgWrapper>
                        <TableImg src={isEmpty(receipt.urlImg) ? noImage : `data:image/png;base64,${receipt.urlImg}`} alt="" />
                    </TableImgWrapper>
                    {
                        map(receipt.rows, (elem, id) => (
                            <Fragment
                                key={id}
                            >
                                <BorderLineRows />
                                <TableContentTitle>{translations[elem.title]} : </TableContentTitle>
                                <TableContentValue>{translations[elem.value] || elem.value}</TableContentValue>
                            </Fragment>
                        ))
                    }
                    <BorderLineRows />
                    {
                        !isUndefined(receipt.ticket) &&
                        <Fragment>
                            <TableContentTitle>{translations[receipt.ticket.title]} : </TableContentTitle>
                            <TableTicketContentValue>
                                {
                                    map(receipt.ticket.value, (elem, id) => (
                                        <div
                                            key={id}
                                        >
                                            {elem}
                                        </div>
                                    ))
                                }
                            </TableTicketContentValue>
                        </Fragment>
                    }
                </TableWrapper>
                <FooterWrapper datascope={scope}>
                    <span>{receipt.printNumber}</span>
                    <p>{translations[receipt.footer]}</p>
                </FooterWrapper>
            </PageWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        receipt: state.salesHistories.receipt
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionsSalesHistories: bindActionCreators(salesHistoriesActionCreators, dispatch),
        actionsSaleOrder: bindActionCreators(salesOrdersActionCreators, dispatch),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Receipt)
