import styled, { keyframes } from "styled-components";

export const PageWrapper = styled.div`
    grid-column: 1 / 13;
`;

///////////////////////////////////////////////////////////////
// Line title and actions        START
///////////////////////////////////////////////////////////////
export const LineTableHeader = styled.div`
    display: grid;
    grid-template-columns: max-content repeat(auto-fit, max-content);
    grid-auto-flow: column;
    grid-column-gap: 16px;
    grid-template-rows: max-content;
    grid-auto-rows: 36px;
    margin-top: 27px;
    margin-bottom: 14px;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
`;
export const TitleHeader = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    color: ${ props => props.theme.colors.cancel };
    height: 52px;
    margin-left: 15px;
`;
export const ActionWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: stretch;
    justify-content: stretch;
    flex-direction: row-reverse;
    flex-basis: max-content;

    & > * {
        margin: 8px;
        height: 36px !important;
    }
`;
export const TitleHeaderTable = styled.div`
    display: flex;
    color: ${ props => props.theme.colors.cancel };
    height: 52px;
    margin-left: 15px;
    align-items: center;
    color: ${ props => props.color ? props.color : 'inherit' };
`;
export const ContentTableWrapper = styled.div`
    background-color: ${ props => props.theme.colors.white };
    border: 1px solid ${ props => props.borderColor ? props.borderColor : props.theme.colors.borderTable };
    padding: 0 20px 20px 20px;
    margin: ${ props => props.margin ? props.margin : '0' };
`;
///////////////////////////////////////////////////////////////
// Line title and actions        END
///////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////
// Calendar button and his image        START
///////////////////////////////////////////////////////////////
export const CalendarButton = styled.div`
    padding: 9px;
    cursor: pointer;
    background-color: ${ props => props.theme.colors.primary };
    border-radius: 3px;
    margin-right: 15px;
`;
export const CalendarImage = styled.img`
    max-width: 18px;
    max-height: 18px;
`;
export const CalendarText = styled.div`
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
///////////////////////////////////////////////////////////////
// Calendar button and his image        END
///////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////
// detail down arrow        START
///////////////////////////////////////////////////////////////
export const DetailDownArrow = styled.div`
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    margin-left: 10px;
    transition: all .5s ease;
`;
export const DetailActionWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 100%;
`;
///////////////////////////////////////////////////////////////
// detail down arrow        END
///////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////
// global        START
///////////////////////////////////////////////////////////////
export const ImgSized = styled.img`
    max-width: ${ props => props.width + 'px' || 'inherit' };
    max-height: ${ props => props.height +'px' || 'inherit' };
    cursor: ${ props => props.cursor ? 'pointer' : 'inherit' };
`;
///////////////////////////////////////////////////////////////
// global        END
///////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////
// table editable        START
///////////////////////////////////////////////////////////////
export const BoxValidation = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 27px;
    width: 27px;
    background-color: ${ props => props.theme.colors.primary };
    color: ${ props => props.theme.colors.white };
    font-weight: bold;
    border-radius: 3px;
    cursor: pointer;
`;
export const DisabledValidation = styled(BoxValidation)`
    display: none;
    background-color: ${ props => props.theme.colors.grey };
    opacity: 0.5;
    cursor: not-allowed;
`;
export const BoxValidationWrapper = styled.div`
    display: none;
    justify-content: space-around;
    align-items: center;
    width: 100%;
`;
export const BoxCanceled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 27px;
    width: 27px;
    background-color: ${ props => props.theme.colors.primary };
    color: ${ props => props.theme.colors.white };
    font-weight: bold;
    border-radius: 3px;
    cursor: pointer;
`;
///////////////////////////////////////////////////////////////
// table editable        END
///////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////
// FORMS        START
///////////////////////////////////////////////////////////////
export const UserWrapperForm = styled.form`
    display: grid;
    grid-template-columns: max-content max-content;
    grid-auto-rows: max-content;
    grid-column-gap: 144px;
`;
export const UserInfoLeftWrapper = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: max-content 208px;
    grid-template-rows: repeat(6, 30px) ${ props => props.password ? '56px' : 'none'};
    grid-column-gap: 20px;
    grid-row-gap: 14px;
    align-items: center;
    color: ${ props => props.theme.colors.menu.link};
    box-sizing: border-box;
`;
export const UserInfoRightWrapper = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-template-rows: repeat(2, 30px) max-content;
    grid-column-gap: 20px;
    grid-row-gap: 14px;
    align-items: center;
    justify-items: flex-start;
    color: ${ props => props.theme.colors.menu.link};
    box-sizing: border-box;
`;
export const InputWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const KioskCheckBoxWrapper = styled.div`
    display: grid;
    grid-template-columns: max-content;
    grid-row-gap: 10px;
    input{
        width: 17px;
        height: 17px;
        padding: 0;
        margin: 0;
    }
`;
export const LabelCheckbox = styled.label`
    display: grid;
    grid-template-columns: min-content max-content;
    grid-column-gap: 5px;
    align-content: center;
`;
export const InputLogin = styled.input`
    height: 30px;
    width: 208px;
    border-radius: 3px;
    border: ${ props => props.touched ? props.validated ? `1px solid ${props.theme.colors.validation}` : `1px solid ${props.theme.colors.error}` : `1px solid ${props.theme.colors.borderTable}` };
    box-sizing: border-box;
    font-size: 14px;
    padding-left: 13px;
    color: ${ props => props.theme.colors.menu.link };
    background-color: ${ props => props.theme.colors.white };
    :focus{
        outline: none !important;
        border-color: ${ props => props.theme.colors.primary };
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        box-shadow: 0 0 0px 1000px ${ props => props.theme.colors.white } inset;
    }
`;
export const RadioElementWrapper = styled.div`
    display: grid;
    grid-template-columns: 85px 85px;
    justify-items: flex-start;
    grid-column-gap: 17px;
    align-items: center;
`;

export const RadioItem = styled.div`
    display: flex;
    width: 100%;
    position: relative
`;

export const RadioInput = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${ props => props.theme.colors.white };
    border-radius: 50%;
    border: 1px solid ${ props => props.theme.colors.borderList};
    ::before{
        position: absolute;
        content: "";
        height: 9px;
        width: 9px;
        left: 3px;
        bottom: 3px;
        display: none;
        background-color: ${ props => props.theme.colors.menu.link };
        border-radius: 50%;
    }    
`;
export const RadioButtonLabel = styled.label`
    display: flex;
    position: relative;
    width: 17px;
    height: 17px;
`;
// DIFF
export const TextLabel = styled.p`
    margin: 0 0 0 17px;
    padding: 0;
    text-decoration: ${ props => props.disabled ? 'line-through' : 'inherit' };
`;
export const RadioCustom = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
    :checked + ${RadioInput} {
      background-color: ${ props => props.theme.colors.white };
    }
    :focus + ${RadioInput} {
      box-shadow: 0 0 1px ${ props => props.theme.colors.primary };
    }
    :checked + ${RadioInput}:before {
      display: flex;
    }
`;
export const SelectInput = styled.select`
    height: 30px;
    width: 208px;
    border-radius: 3px;
    //TODO : for select dropDown test
    // border: ${ props => props.touched ? props.validated ? `3px solid ${props.theme.colors.validation}` : `3px solid ${props.theme.colors.error}` : `3px solid ${props.theme.colors.borderTable}` };
    box-sizing: border-box;
    font-size: 14px;
    padding-left: 13px;
    color: ${ props => props.theme.colors.menu.link };
    background-color:  ${ props => props.theme.colors.white };
    &:focus{
        outline: none !important;
        border-color:  ${ props => props.theme.colors.menu.primary };
    }
`;
export const ErrorInputMsg = styled.div`
    position: absolute;
    left: 220px;
    width: 80px;
    color: ${props => props.theme.colors.error};
`;
export const ErrorFormMsg = styled.div`
    position: absolute;
    top: -40px;
    width: 240px;
    text-align: center;
    justify-self: center;
    color: ${props => props.theme.colors.error};
`;
export const LabelText = styled.p`
    margin: 0;
    font-weight: bold;
    font-size: 13px;
    color:  ${ props => props.theme.colors.menu.link };
`;
export const LabelTextKiosks = styled.p`
    margin: 0;
    font-weight: bold;
    font-size: 13px;
    color:  ${ props => props.theme.colors.menu.link };
    align-self: flex-start;
`;
///////////////////////////////////////////////////////////////
// FORMS        END
///////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////
// ANIMATION START
///////////////////////////////////////////////////////////////

export const OpenNestedAnimation = keyframes`
    from {
      max-height: 0px;
    }
    to {
      max-height: 1000px;
    }
`;

///////////////////////////////////////////////////////////////
// ANIMATION START
///////////////////////////////////////////////////////////////