import ActionsTypes from '../../constants/ActionsTypes';

const createPromo = {
    initCreatePromo: () => ({
        type: ActionsTypes.INIT_CREATE_PROMO,
        payload: {},
    }),
    checkCreatePromoValidation: (itemsPromo, id) => ({
        type: ActionsTypes.CHECK_CREATE_PROMO_VALIDATION,
        payload: {
            itemsPromo,
            id,
        },
    }),
    checkCreatePromoValidationFailure: (error) => ({
        type: ActionsTypes.CHECK_CREATE_PROMO_VALIDATION_FAILURE,
        payload: {
            error,
        },
    }),
    checkCreatePromoValidationSuccess: (itemsPromo) => ({
        type: ActionsTypes.CHECK_CREATE_PROMO_VALIDATION_SUCCESS,
        payload: {
            itemsPromo,
        }
    }),
    createPromo: (date, label, itemsStore, idPromo) => ({
        type: ActionsTypes.CREATE_PROMO,
        payload: {
            date,
            label,
            itemsStore,
            idPromo,
        }
    }),
    createPromoFailure: (error) => ({
        type: ActionsTypes.CREATE_PROMO_FAILURE,
        payload: {
            error,
        }
    }),
    createPromoSuccess : (itemsPromo) => ({
        type: ActionsTypes.CREATE_PROMO_SUCCESS,
        payload: {
            itemsPromo
        }
    }),
    updateItemPromoPrices : (price, id, field) => ({
        type: ActionsTypes.UPDATE_ITEM_PRICES_PROMO,
        payload: {
            price,
            id,
            field
        }
    }),
    deleteItemPromoPrices : ( id ) => ({
        type: ActionsTypes.DELETE_ITEM_PRICES_PROMO,
        payload: {
            id
        }
    }),
    initUpdatePromo: ( id ) => ({
        type: ActionsTypes.INIT_UPDATE_PROMO,
        payload: { id },
    }),
    initUpdatePromoSuccess: ( idPromo ) => ({
        type: ActionsTypes.INIT_UPDATE_PROMO_SUCCESS,
        payload: { idPromo },
    }),
    initUpdatePromoFailure: ( error ) => ({
        type: ActionsTypes.INIT_UPDATE_PROMO_FAILURE,
        payload: { error },
    }),

    updatePromo: ( date, label, itemsStore, idPromo ) => ({
        type: ActionsTypes.UPDATE_PROMO,
        payload: {
            date,
            label,
            itemsStore,
            idPromo,
        },
    }),
    updatePromoSuccess: (itemsPromo) => ({
        type: ActionsTypes.UPDATE_PROMO_SUCCESS,
        payload: {
            itemsPromo,
        },
    }),
    updatePromoFailure: (error) => ({
        type: ActionsTypes.UPDATE_PROMO_FAILURE,
        payload: {
            error,
        },
    }),

};

export default createPromo;