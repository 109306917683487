import ActionsTypes from '../../constants/ActionsTypes';

const salesHistories = {
    getSalesHistories: ( date, id ) => ( {
        type: ActionsTypes.GET_SALES_HISTORIES,
        payload: {
            date,
            id,
        },
    } ),
    getSalesHistoriesSuccess: ( salesHistories ) => ( {
        type: ActionsTypes.GET_SALES_HISTORIES_SUCCESS,
        payload: {
            salesHistories,
        },
    } ),
    getSalesHistoriesFailure: ( error ) => ( {
        type: ActionsTypes.GET_SALES_HISTORIES_FAILURE,
        payload: {
            error,
        },
    } ),
    getSalesHistoriesBySale: ( id ) => ( {
        type: ActionsTypes.GET_SALES_HISTORIES_BY_SALE,
        payload: {
            id,
        },
    } ),
    getSalesHistoriesBySaleSuccess: ( salesHistories ) => ( {
        type: ActionsTypes.GET_SALES_HISTORIES_BY_SALE_SUCCESS,
        payload: {
            salesHistories,
        },
    } ),
    getSalesHistoriesBySaleFailure: ( error ) => ( {
        type: ActionsTypes.GET_SALES_HISTORIES_BY_SALE_FAILURE,
        payload: {
            error,
        },
    } ),
    getSalesHistoriesReceipt: ( id, scope ) => ( {
        type: ActionsTypes.GET_SALES_HISTORIES_RECEIPT,
        payload: {
            id,
            scope,
        },
    } ),
    getSalesHistoriesReceiptSuccess: ( receipt ) => ( {
        type: ActionsTypes.GET_SALES_HISTORIES_RECEIPT_SUCCESS,
        payload: {receipt},
    } ),
    getSalesHistoriesReceiptFailure: ( error ) => ( {
        type: ActionsTypes.GET_SALES_HISTORIES_RECEIPT_FAILURE,
        payload: {
            error,
        },
    } ),
};

export default salesHistories;