import { createSelector } from 'reselect'
import { orderBy } from 'lodash'

const getDashboardSetting   = state => state.dashBoard.items;
const getDashboardTables    = state => state.dashBoard.tables;

export const selectDashboardSetting = createSelector(
    [ getDashboardSetting ],
    ( dashBoard ) => {

        return dashBoard
    }
);

export const selectDashboardTables = createSelector(
    [ getDashboardTables ],
    ( tables ) => {
        return orderBy(tables, ['rank'], ['asc'] )
    }
);