import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';

extendUpdate(update);

const salesHistories = {
    [ActionsTypes.GET_SALES_HISTORIES]: (state) => update(state, {
        salesHistories: {
            isLoading: {
                $set: true,
            },
            error: {
                $set: false,
            },
            items: {
                $set: false
            }
        },
    }),
    [ActionsTypes.GET_SALES_HISTORIES_SUCCESS]: (state, payload) => update(state, {
        salesHistories: {
            isLoading: {
                $set: false,
            },
            error: {
                $set: false,
            },
            items: {
                $set: payload.salesHistories
            }
        },
    }),
    [ActionsTypes.GET_SALES_HISTORIES_FAILURE]: (state, payload) => update(state, {
        salesHistories: {
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
            items: {
                $set: false,
            }
        },
    }),
    [ActionsTypes.GET_SALES_HISTORIES_BY_SALE]: (state) => update(state, {
        salesHistories: {
            isLoading: {
                $set: true,
            },
            error: {
                $set: false,
            },
            items: {
                $set: false
            }
        },
    }),
    [ActionsTypes.GET_SALES_HISTORIES_BY_SALE_SUCCESS]: (state, payload) => update(state, {
        salesHistories: {
            isLoading: {
                $set: false,
            },
            error: {
                $set: false,
            },
            items: {
                $set: [payload.salesHistories]
            }
        },
    }),
    [ActionsTypes.GET_SALES_HISTORIES_BY_SALE_FAILURE]: (state, payload) => update(state, {
        salesHistories: {
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
            items: {
                $set: false,
            }
        },
    }),
    [ActionsTypes.PUT_SALES_ORDERS_DEPOSIT_CONTRACT_FAILURE]: (state, payload) => {
        return update(state, {
            salesHistories: {
                error: {
                    $set: payload.error
                }
            }
        })
    },
    [ActionsTypes.GET_SALES_HISTORIES_RECEIPT]: (state) => {
        return update(state, {
            salesHistories: {
                isLoading: {
                    $set: true,
                },
                error: {
                    $set: false,
                },
            },
        })
    },

    [ActionsTypes.GET_SALES_HISTORIES_RECEIPT_SUCCESS]: (state, payload) => {
        return update( state, {
            salesHistories: {
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: false,
                },
                receipt: {
                    $set: payload.receipt
                }
            }
        })
    },
    [ActionsTypes.GET_SALES_HISTORIES_RECEIPT_FAILURE]: (state, payload) => update(state, {
        salesHistories: {
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
        },
    }),
};

export default salesHistories;
