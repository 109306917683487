import React, { Component } from 'react'
import { withRouter } from "react-router";
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { map } from 'lodash'

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import {
    adminUserActionCreators,
} from '../../state/actions';

//assets import
import LogsHistory from "../../assets/icones/global/log-history.svg";
import AddButton from "../../assets/icones/global/add-button.png";
import ExportData from '../../assets/icones/global/export-data.png'


// component import
import TableLayout from '../TableLayout'
import ActionButton from '../../components/Ui/ActionButton'
import CR from '../../constants/RouteConstants'
import ModalHOC from '../../Hoc/ModalHOC/'
import TableNested from '../../Hoc/TableNested'
import Button from '../../components/Ui/Buttons'
import UserAuthorizationLayout from "../../components/UserAuthorizationLayout";
import userPrivileges from "../../constants/RoleList";

// style
import {
    PageWrapper,
    LineTableHeader,
    TitleHeader,
    ActionWrapper,
    TitleHeaderTable,
    ContentTableWrapper,
    ImgSized,
} from "../../theme/GlobalCss";
import theme from "../../theme/theme";

/////////////////////////////
//  START style
////////////////////////////
const EnabledColorStatus = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    background-color: ${ props => props.color === 'true' ? props.theme.colors.validation : props.theme.colors.error };
`;
// TODO make a component
const AddButtonWrapper = styled(Link)`
    max-width: 32px ;
    max-height: 32px ;
`;
/////////////////////////////
//  END style
////////////////////////////

export class AdminUser extends Component {
    static propTypes = {
        actionsAdminUser:                   PropTypes.object,
        actionsDatePicker:                  PropTypes.object,
        closeDateModalHandler:              PropTypes.func,
        adminUser:                          PropTypes.object,
        dataTable:                          PropTypes.object,
        datePicker:                         PropTypes.object,
        formatDate:                         PropTypes.func,
        getDateFormat:                      PropTypes.func,
        hydrateStateHandler:                PropTypes.func,
        modalHandler:                       PropTypes.func,
        nestedOnClickHandler:               PropTypes.func,
        openDateModalHandler:               PropTypes.func,
        refCollection:                      PropTypes.array,
        kiosksSelected:                     PropTypes.object,
    };

///////////////////////////////////////////////////
///////     START INIT COMPONENT    ///////////////
///////////////////////////////////////////////////
    componentDidMount = () => {        
        this.FetchAdminUser()
        
    };
///////////////////////////////////////////////////
///////     END INIT COMPONENT    /////////////////
///////////////////////////////////////////////////

    FetchAdminUser = () => {
        const {
            actionsAdminUser,
        } = this.props;
        actionsAdminUser.getAdminUser()
    };

    /**
     * data object to row layout
     */
    bodyData = () => {
        const { 
            modalHandler,
            match,
            adminUser,
            translations,
        } = this.props;

        const header = [
            {name: translations['page__userManagement__userTable_tableHeader_name'], useName: 'nom', sortTable: true, },
            {name: translations['page__userManagement__userTable_tableHeader_login'], useName: 'identifiant', sortTable: true, },
            {name: translations['page__userManagement__userTable_tableHeader_email'], useName: 'email', sortTable: true, },
            {name: translations['page__userManagement__userTable_tableHeader_role'], useName: 'role', sortTable: true, },
            {name: translations['page__userManagement__userTable_tableHeader_active'], useName: 'actif', sortTable: true, },
            {name: translations['common__text__actions'], useName: 'actions'}
        ];
        return{
            header: header,
            body : map(adminUser.items.Users, (elem) => {
                
                return ({
                    id: elem.id,
                    elements: [
                        {name :'nom', value: `${elem.firstName} ${elem.lastName }`, sortValue: `${elem.firstName} ${elem.lastName }`, },
                        {name :'identifiant', value: elem.login, sortValue: elem.login, },
                        {name :'email', value: elem.email , sortValue: elem.email, },
                        {name :'role', value: elem.role.label, sortValue: elem.role.label, },
                        {name :'actif',
                            value:
                                <EnabledColorStatus
                                    color={`${elem.enabled}`}
                                />
                        , sortValue: elem.enabled, },
                        {name: 'actions', display: 'flex',
                            value: 
                            <ActionButton
                                size='30' 
                                link={`${match.url}${CR.modal.adminUser}`}
                                actionHandler={ () => modalHandler( elem ) }
                            />
                        },
                    ]
                })
            })   
        };
    };

    componentDidUpdate(prevProps) {
        const { adminUser, hydrateStateHandler, translations } = this.props;

        // hydrate parent STATE if Table data change
        if ( adminUser.items.Users !== prevProps.adminUser.items.Users ) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            hydrateStateHandler(data)
        }
        if( translations !== prevProps.translations) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            hydrateStateHandler(data)
        }
    }
    render() {
        const {
            translations,
            adminUser,
            dataTable,
            refCollection,
            initSortTableHandle,
        } = this.props;

        return (
            <PageWrapper>
                <LineTableHeader>
                    <TitleHeader>
                        {translations['page__userManagement__userTable_title']}
                    </TitleHeader>
                    <ActionWrapper>

                    </ActionWrapper>
                </LineTableHeader>
                <ContentTableWrapper>
                    <LineTableHeader>
                        <TitleHeaderTable
                            color={theme.colors.primary}
                        >
                            {
                                `${translations['page__userManagement__userTable_subTitle']} : ${adminUser.items.CompanyTyped}`
                            }
                        </TitleHeaderTable>

                        <ActionWrapper>
                            <UserAuthorizationLayout
                                name={userPrivileges.manage[13]}
                            >
                                <AddButtonWrapper
                                    to={CR.createUser}
                                >
                                    <ImgSized width={32} height={32} src={AddButton} />
                                </AddButtonWrapper>
                            </UserAuthorizationLayout>
                            <UserAuthorizationLayout name={userPrivileges.view[24]} >
                                <Button
                                    text={translations['page__userManagement__userTable_button_allUsersHistory']}
                                    link={CR.logHistoryUsers}
                                    img={LogsHistory}
                                />
                            </UserAuthorizationLayout>
                        </ActionWrapper>
                    </LineTableHeader>
                    <TableLayout
                        headerData={dataTable.headerData}
                        bodyData={dataTable.bodyData}
                        column={dataTable.headerData.length}
                        refCollection={refCollection}
                        initSortTableHandle={initSortTableHandle}
                        translations={translations}
                    />
                </ContentTableWrapper>

            </PageWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        adminUser: state.adminUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionsAdminUser: bindActionCreators(adminUserActionCreators, dispatch),
    };
};

export default withRouter(TableNested(ModalHOC(connect(mapStateToProps, mapDispatchToProps)(AdminUser))))
