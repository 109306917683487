import ActionsTypes from '../../constants/ActionsTypes';

const promoItemStore = {
    getPromoItemStore: () => ({
        type: ActionsTypes.GET_PROMO_ITEM_STORE,
        payload: {},
    }),
    getPromoItemStoreSuccess: (promoItemStore) => ({
        type: ActionsTypes.GET_PROMO_ITEM_STORE_SUCCESS,
        payload: {
            promoItemStore,
        },
    }),
    getPromoItemStoreFailure: (error) => ({
        type: ActionsTypes.GET_PROMO_ITEM_STORE_FAILURE,
        payload: {
            error,
        },
    }),
    getPromoItemDetail: (id) => ({
        type: ActionsTypes.GET_PROMO_ITEM_DETAIL,
        payload: {id},
    }),
    getPromoItemDetailSuccess: (promoItemDetail) => ({
        type: ActionsTypes.GET_PROMO_ITEM_DETAIL_SUCCESS,
        payload: {
            promoItemDetail,
        },
    }),
    getPromoItemDetailFailure: (error) => ({
        type: ActionsTypes.GET_PROMO_ITEM_DETAIL_FAILURE,
        payload: {
            error,
        },
    }),
    getPromoItemDetailAll: (id) => ({
        type: ActionsTypes.GET_PROMO_ITEM_DETAIL_ALL,
        payload: {id},
    }),
    getPromoItemDetailAllSuccess: (promoItemDetail) => ({
        type: ActionsTypes.GET_PROMO_ITEM_DETAIL_ALL_SUCCESS,
        payload: {
            promoItemDetail,
        },
    }),
    getPromoItemDetailAllFailure: (error) => ({
        type: ActionsTypes.GET_PROMO_ITEM_DETAIL_ALL_FAILURE,
        payload: {
            error,
        },
    }),
    updatePromoItemDisplay: (id) => ({
        type: ActionsTypes.UPDATE_PROMO_ITEM_DISPLAY,
        payload: {
            id,
        }
    }),
    updatePromoStatusDisplay: (id, checked) => ({
        type: ActionsTypes.UPDATE_PROMO_STATUS_DISPLAY,
        payload: {
            id,
            checked,
        }
    }),
    deletePromoItemStore: (id) => ({
        type: ActionsTypes.DELETE_PROMO_ITEM_STORE,
        payload: {id},
    }),
    deletePromoItemStoreSuccess: (promoItemStore) => ({
        type: ActionsTypes.DELETE_PROMO_ITEM_STORE_SUCCESS,
        payload: {
            promoItemStore,
        },
    }),
    deletePromoItemStoreFailure: (error) => ({
        type: ActionsTypes.DELETE_PROMO_ITEM_STORE_FAILURE,
        payload: {
            error,
        },
    }),
};

export default promoItemStore;