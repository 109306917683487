import ActionsTypes from '../../constants/ActionsTypes';

const codesHistories = {
    getCodesHistories: (date) => ({
        type: ActionsTypes.GET_CODE_HISTORIES,
        payload: {
            date,
        },
    }),
    getCodesHistoriesSuccess: (codesHistories) => ({
        type: ActionsTypes.GET_CODE_HISTORIES_SUCCESS,
        payload: {
            codesHistories,
        },
    }),
    getCodesHistoriesFailure: (error) => ({
        type: ActionsTypes.GET_CODE_HISTORIES_FAILURE,
        payload: {
            error,
        },
    }),
    deleteCodesHistoriesItem: ( id ) => ({
        type: ActionsTypes.DELETE_CODE_HISTORIES_ITEM,
        payload: {
            id,
        },
    }),
    deleteCodesHistoriesItemSuccess: (codesHistoriesItem) => ({
        type: ActionsTypes.DELETE_CODE_HISTORIES_ITEM_SUCCESS,
        payload: {
            codesHistoriesItem,
        },
    }),
    deleteCodesHistoriesItemFailure: (error) => ({
        type: ActionsTypes.DELETE_CODE_HISTORIES_ITEM_FAILURE,
        payload: {
            error,
        },
    }),

};

export default codesHistories;