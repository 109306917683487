import axios from 'axios'

export function API_TOKEN( token ) {
    const urlCaller = axios.create( {
        baseURL: '/extranetv2_store',
        headers: {
            'Authorization': `Bearer ${ token }`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    } );
    return urlCaller
}
export function API_TOKEN_DL_FILE( token ) {
    const urlCaller = axios.create( {
        baseURL: '/extranetv2_store',
        'responseType': 'blob',
        headers: {
            'Authorization': `Bearer ${ token }`,
        }
    } );
    return urlCaller
}

export const API = axios.create({
    baseURL: '/extranetv2_store',
  });

export default API_TOKEN