////////LIBRARY/////////
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
///////COMPONENTS///////
import { datePickerActionCreators } from '../../state/actions';
import DatePicker from '../../containers/Modals/DateTimePicker'

/////////STYLED/////////
const ModalWrapper = styled.div`
    position: absolute;
    top: ${ props => props.postop + 'px'};
    left: 0;
    display: flex;
    justify-items: center;
    align-items: center;
    width: 1024px;
    height: 100vh;
    z-index: 19;
    overflow-x: hidden;
`;
const ModalBackground = styled.div`
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.5;
    z-index: 19;
    width: 1024px;
    height: 100vh;
`
/////////STYLED/////////

export default function(ComposedComponent) {
    class ModalDatePicker extends Component {
        static propTypes = {
        };
        state = {
            date: {
                startDate: {},
                endDate: {},
                timeTouch: false,
            },
            showModal: false,
            windowPageYOffset: 0,
            windowPageXOffset: 0,
        };
        defaultState = {...this.state}

       	///////////////////////////////// HANDLE ///////////////////////////////////////

        componentDidMount = () => {
            const { 
                datePicker,
            } = this.props;

            this.setState({
                date:{
                    startDate: datePicker.startDate,
                    endDate: datePicker.endDate
                }
            }, () => {
                this.defaultState = {...this.state}
            });
        };

        startDateHandleChange = date => {
            const prevState     = cloneDeep(this.state.date);

            // check if time was set by user
            // if not put midnight by default
            if( prevState.timeTouch ){
                prevState['startDate']  = moment(date).toDate()
            }else{
                prevState['startDate']  = moment(date).startOf('day').toDate();
            }

            this.setState({
                date:{
                    ...prevState
                }
            });
        };
        endDateHandleChange = date => {
            const prevState     = cloneDeep(this.state.date);

            // check if time was set by user
            // if not put midnight by default
            if( prevState.timeTouch ){
                prevState['endDate']  = new Date(date);
            }else{
                prevState['endDate']  = moment(date).endOf('day').toDate();
            }

            this.setState({
                date: {
                    ...prevState
                }
            });
        };
        startTimeHandleChange = date => {
            const prevState         = cloneDeep(this.state.date);
            prevState['startDate']  = moment(date).toDate();
            prevState.timeTouch     = true;

            this.setState({
                date:{
                    ...prevState
                }
            });
        };
        endTimeHandleChange = date => {
            const prevState         = cloneDeep(this.state.date);
            prevState.endDate       = date;
            prevState.timeTouch     = true;

            this.setState({
                date: {
                    ...prevState
                }
            });
        };
        openDateModalHandler = () => {
            const prevState         = this.state;
            prevState['showModal']  = true;
            prevState.windowPageYOffset  = window.pageYOffset;
            prevState.windowPageXOffset  = window.pageXOffset;

            this.setState({
                ...prevState
            });
            window.addEventListener('scroll', this.onScrollHandler);
        };
        closeDateModalHandler = () => {
            const prevState         = this.state;
            prevState['showModal']  = false;

            this.setState({
                ...prevState
            });
            window.removeEventListener('scroll', this.onScrollHandler);
        };

        onScrollHandler = () => {
            const { showModal, windowPageYOffset, windowPageXOffset } = this.state;
            if( showModal ) {
                window.scrollTo(windowPageXOffset, windowPageYOffset);
            }
        };

        getDateFormat = () => {
            const { datePicker } = this.props;

            return {
                "dateStart": moment(datePicker.startDate).toDate(),
                "dateEnd": moment(datePicker.endDate).toDate(),
            }
        };
        getDateFormatToLayout = (date) => {
            return moment(date).format('DD-MM-YYYY')
        };

        updateDate = () => {
            const { date } = this.state;
            const { actionsDatePicker } = this.props;

            this.defaultState.date = date

            const updateStore = {
                    startDate: date.startDate,
                    endDate: date.endDate,
            };
            
            actionsDatePicker.updateDate(updateStore);
            this.closeDateModalHandler()
        };

        onClickToDayHandler = () => {
            const prevDate      = cloneDeep(this.state.date);
            prevDate.startDate  = moment().startOf('day').toDate();
            prevDate.endDate    = moment().endOf('day').minute(45).toDate();

            this.setState({date: {...prevDate}});
        };
        onClickOneWeekHandler = () => {
            const prevDate      = cloneDeep(this.state.date);
            prevDate.startDate  = moment().subtract(7, 'days').startOf('day').toDate();
            prevDate.endDate    = moment().endOf('day').minute(45).toDate();

            this.setState({date: {...prevDate}});
        };
        onClickOneMonthHandler = () => {
            const prevDate = cloneDeep(this.state.date);
            prevDate.startDate = moment().subtract(1, 'months').startOf('day').toDate();
            prevDate.endDate   = moment().endOf('day').minute(45).toDate();

            this.setState({date: {...prevDate}});
        };


        cancelUpdateHandler = () => {
            this.setState({...this.defaultState})
        };

    	///////////////////////////////// RENDER ///////////////////////////////////////

        render() {
            let posTop = window.scrollY - 120;
            const { translations } = this.props;

            return (
                <Fragment>
                    {
                        this.state.showModal &&
                            <ModalWrapper
                                postop={posTop}
                            >
                                <DatePicker
                                    toDayHandler={this.onClickToDayHandler}
                                    weekSelectHandler={this.onClickOneWeekHandler}
                                    monthSelectHandler={this.onClickOneMonthHandler}
                                    closeModalHandler={this.closeDateModalHandler}
                                    startDate={this.state.date.startDate}
                                    endDate={this.state.date.endDate}
                                    startDateHandleChange={this.startDateHandleChange}
                                    endDateHandleChange={this.endDateHandleChange}
                                    startTimeHandleChange={this.startTimeHandleChange}
                                    endTimeHandleChange={this.endTimeHandleChange}
                                    formatDate={this.getDateFormatToLayout}
                                    updateDate={this.updateDate}
                                    cancelUpdateHandler={this.cancelUpdateHandler}
                                    translations={translations}
                                />
                                <ModalBackground
                                    onClick={this.cancelUpdateHandler}
                                />
                            </ModalWrapper>
                    }
                    <ComposedComponent
                        closeDateModalHandler={this.closeDateModalHandler}
                        getDateFormat={this.getDateFormat}
                        formatDate={this.getDateFormatToLayout}
                        openDateModalHandler={this.openDateModalHandler}
                        { ...this.props } 
                    />
                </Fragment>
            )
        }
    }

    const mapStateToProps = state => {
        return {
            datePicker: state.user.datePicker,
        };
    };
    
    const mapDispatchToProps = (dispatch) => {
        return {
            actionsDatePicker: bindActionCreators(datePickerActionCreators, dispatch),
        };
    };

    return connect( mapStateToProps, mapDispatchToProps )(ModalDatePicker)
}

