import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';

extendUpdate(update);

const datePicker = {
    [ActionsTypes.UPDATE_DATE]: (state, payload) => {

        return update(state, {
            user: {
                datePicker:{
                    $set: payload.date
                },
            },

        })        
    },
};

export default datePicker