import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Form, Field } from "react-final-form";
import { map } from "lodash";

// redux import
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { myAccountActionCreators } from "../../state/actions";

// component import
import Button from "../../components/Ui/Buttons";

// style
import {
  PageWrapper,
  LineTableHeader,
  TitleHeader,
  ActionWrapper,
  TitleHeaderTable,
  ContentTableWrapper,
} from "../../theme/GlobalCss";
import theme from "../../theme/theme";

import { myAccountValidator } from "../../helpers/FormValidators";

/////////////////////////////
//  START style
/////////////////////////////
const UserWrapperForm = styled.form`
  position: relative;
  display: grid;
  grid-template-columns: max-content 208px;
  grid-auto-rows: 30px;
  grid-column-gap: 20px;
  grid-row-gap: 14px;
  align-items: center;
  color: ${(props) => props.theme.colors.menu.link};
  box-sizing: border-box;
`;
const InputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const InputLogin = styled.input`
  height: 30px;
  width: 208px;
  border-radius: 3px;
  border: ${(props) =>
    props.touched
      ? props.validated
        ? `1px solid ${props.theme.colors.validation}`
        : `1px solid ${props.theme.colors.error}`
      : `1px solid ${props.theme.colors.borderTable}`};
  box-sizing: border-box;
  font-size: 14px;
  padding-left: 13px;
  color: ${(props) => props.theme.colors.menu.link};
  background-color: ${(props) => props.theme.colors.white};
  :focus {
    outline: none !important;
    border-color: ${(props) => props.theme.colors.primary};
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: 0 0 0px 1000px ${(props) => props.theme.colors.white} inset;
  }
`;
const SelectInput = styled.select`
  height: 30px;
  width: 208px;
  border-radius: 3px;
  border: ${(props) =>
    props.touched
      ? props.validated
        ? `3px solid ${props.theme.colors.validation}`
        : `3px solid ${props.theme.colors.error}`
      : `1px solid ${props.theme.colors.borderTable}`};
  box-sizing: border-box;
  font-size: 14px;
  padding-left: 13px;
  color: ${(props) => props.theme.colors.menu.link};
  background-color: ${(props) => props.theme.colors.white};
  :focus {
    outline: none !important;
    border-color: ${(props) => props.theme.colors.primary};
  }
`;
const ErrorInputMsg = styled.div`
  position: absolute;
  left: 220px;
  width: 250px;
  color: ${(props) => props.theme.colors.error};
`;
const ErrorFormMsg = styled.div`
  position: absolute;
  top: -40px;
  width: 240px;
  text-align: center;
  justify-self: center;
  color: ${(props) => props.theme.colors.error};
`;
const LabelText = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 13px;
  color: ${(props) => props.theme.colors.menu.link};
`;
/////////////////////////////
//  END style
/////////////////////////////

export class MyAccount extends Component {
  static propTypes = {
    history: PropTypes.object,
    myAccount: PropTypes.object,
    translations: PropTypes.object,
    languagesList: PropTypes.array,
    actionsMyAccount: PropTypes.object,
  };

  componentDidMount = () => {
    this.getMyAccount();
  };

  getMyAccount = () => {
    const { actionsMyAccount } = this.props;
    actionsMyAccount.getMyAccount();
  };

  onSubmitHandler = (values) => {
    const { actionsMyAccount } = this.props;
    actionsMyAccount.updateMyAccount(values);
  };

  render() {
    const { history, myAccount, translations, languagesList } = this.props;
    let submit;
    let resetForm;
    if (myAccount.isLoading || !myAccount.items) {
      return <div></div>;
    }
    return (
      <PageWrapper>
        <LineTableHeader>
          <ActionWrapper>
            <Button
              goBack
              weight="bold"
              actionHandler={history.goBack}
              text={translations["common__text__previousPage"]}
            />
          </ActionWrapper>
        </LineTableHeader>

        <LineTableHeader>
          <TitleHeader>
            {/* Fiche utilisateur */}
            {translations["page__userDetail__userSheet_title"]}
          </TitleHeader>
          <ActionWrapper>
            <Button
              bgColor={theme.colors.validation}
              type="submit"
              actionHandler={(event) => {
                submit(event);
              }}
              text={translations["common__text__save"]}
            />
            <Button
              actionHandler={() => resetForm()}
              bgColor={theme.colors.cancel}
              text={translations["common__text__cancel"]}
            />
          </ActionWrapper>
        </LineTableHeader>
        <ContentTableWrapper>
          <LineTableHeader>
            <TitleHeaderTable color={theme.colors.primary}>
              {`${myAccount.items.firstName} ${myAccount.items.lastName} [ ${
                myAccount.items.role.label || "default"
              } ]`}
            </TitleHeaderTable>
          </LineTableHeader>
          <Form
            onSubmit={this.onSubmitHandler}
            initialValues={{
              lastName: myAccount.items.lastName,
              firstName: myAccount.items.firstName,
              phoneNumber: myAccount.items.phoneNumber,
              email: myAccount.items.email,
            }}
            validate={myAccountValidator}
            render={({ handleSubmit, values, form }) => {
              submit = handleSubmit;
              resetForm = form.reset;
              return (
                // USER DETAIL
                <UserWrapperForm>
                  {myAccount.error.data && (
                    <ErrorFormMsg>{myAccount.error.data.error}</ErrorFormMsg>
                  )}
                  <LabelText>
                    {/* Langue par défaut : */}
                    {translations[
                      "page__userDetail__userSheet_form_defaultLanguage"
                    ] + " :"}
                  </LabelText>
                  <Field
                    name="idPreferedLanguage"
                    initialValue={myAccount.items.preferedLanguage.id || 1}
                  >
                    {({ input, meta }) => (
                      <InputWrapper>
                        <SelectInput {...input}>
                          {map(languagesList, (elem, id) => (
                            <option key={id} value={elem.id}>
                              {elem.labelEnglish}
                            </option>
                          ))}
                        </SelectInput>
                        {meta.error && meta.touched && (
                          <ErrorInputMsg>{meta.error}</ErrorInputMsg>
                        )}
                      </InputWrapper>
                    )}
                  </Field>
                  <LabelText>
                    {/* Nom : */}
                    {translations["page__userDetail__userSheet_form_lastname"] +
                      "* :"}
                  </LabelText>
                  <Field name="lastName">
                    {({ input, meta }) => (
                      <InputWrapper>
                        <InputLogin
                          {...input}
                          type="text"
                          // placeholder="Nom"
                          placeholder={
                            translations[
                              "page__userDetail__userSheet_form_lastname_prefilled"
                            ]
                          }
                          maxLength="150"
                          touched={meta.touched}
                          validated={!meta.error}
                        />
                        {meta.error && meta.touched && (
                          <ErrorInputMsg>{meta.error}</ErrorInputMsg>
                        )}
                      </InputWrapper>
                    )}
                  </Field>
                  <LabelText>
                    {/* Prénom : */}
                    {translations[
                      "page__userDetail__userSheet_form_firstname"
                    ] + "* :"}
                  </LabelText>
                  <Field name="firstName">
                    {({ input, meta }) => (
                      <InputWrapper>
                        <InputLogin
                          {...input}
                          type="text"
                          // placeholder="Prénom"
                          placeholder={
                            translations[
                              "page__userDetail__userSheet_form_firstname_prefilled"
                            ]
                          }
                          maxLength="150"
                          touched={meta.touched}
                          validated={!meta.error}
                        />
                        {(meta.error || meta.submitError) && meta.touched && (
                          <ErrorInputMsg>
                            {meta.error || meta.submitError}
                          </ErrorInputMsg>
                        )}
                      </InputWrapper>
                    )}
                  </Field>
                  <LabelText>
                    {/* Téléphone : */}
                    {translations["page__userDetail__userSheet_form_phone"] +
                      "* :"}
                  </LabelText>
                  <Field name="phoneNumber">
                    {({ input, meta }) => (
                      <InputWrapper>
                        <InputLogin
                          {...input}
                          type="tel"
                          // placeholder="Téléphone"
                          placeholder={
                            translations[
                              "page__userDetail__userSheet_form_phone_prefilled"
                            ]
                          }
                          maxLength="150"
                          touched={meta.touched}
                          validated={!meta.error}
                        />
                        {meta.error && meta.touched && (
                          <ErrorInputMsg>{meta.error}</ErrorInputMsg>
                        )}
                      </InputWrapper>
                    )}
                  </Field>
                  <LabelText>
                    {/* Identifiant : */}
                    {translations["page__userDetail__userSheet_form_login"] +
                      "* :"}
                  </LabelText>
                  <Field name="login" initialValue={myAccount.items.login}>
                    {({ input }) => (
                      <InputWrapper>
                        <InputLogin
                          {...input}
                          type="text"
                          placeholder={
                            translations[
                              "page__userDetail__userSheet_form_login_prefilled"
                            ]
                          }
                          maxLength="150"
                          disabled
                        />
                      </InputWrapper>
                    )}
                  </Field>
                  <LabelText>
                    {/* Email : */}
                    {translations["page__userDetail__userSheet_form_email"] +
                      "* :"}
                  </LabelText>
                  <Field name="email">
                    {({ input, meta }) => (
                      <InputWrapper>
                        <InputLogin
                          {...input}
                          type="text"
                          placeholder={
                            translations[
                              "page__userDetail__userSheet_form_email_prefilled"
                            ]
                          }
                          touched={meta.touched}
                          validated={!meta.error}
                          maxLength={"150"}
                        />
                        {meta.error && meta.touched && (
                          <ErrorInputMsg>{meta.error}</ErrorInputMsg>
                        )}
                      </InputWrapper>
                    )}
                  </Field>
                  <LabelText>
                    {/* Nouveau mot de passe : */}
                    {translations[
                      "page__userDetail__userSheet_form_newPassword"
                    ] + " :"}
                  </LabelText>
                  <Field name="newPassword">
                    {({ input, meta }) => (
                      <InputWrapper>
                        <InputLogin
                          {...input}
                          name="noPassword"
                          type="password"
                          placeholder={
                            translations[
                              "page__userDetail__userSheet_form_newPassword_prefilled"
                            ]
                          }
                          autoComplete="new-password"
                          touched={meta.touched}
                          validated={!meta.error}
                          maxLength={"50"}
                        />
                        {meta.error && meta.touched && (
                          <ErrorInputMsg>{meta.error}</ErrorInputMsg>
                        )}
                      </InputWrapper>
                    )}
                  </Field>

                  {values.newPassword && (
                    <Fragment>
                      <LabelText>
                        {/* Confirmer mot de passe :  */}
                        {translations[
                          "page__userDetail__userSheet_form_newPassword2"
                        ] + " :"}
                      </LabelText>
                      <Field name="confirmPassword">
                        {({ input, meta }) => (
                          <InputWrapper>
                            <InputLogin
                              {...input}
                              name="noPassword"
                              type="password"
                              placeholder={
                                translations[
                                  "page__userDetail__userSheet_form_newPassword2_prefilled"
                                ]
                              }
                              autoComplete="new-password"
                              touched={meta.touched}
                              validated={!meta.error}
                              maxLength={"50"}
                            />
                            {meta.error && meta.touched && (
                              <ErrorInputMsg>{meta.error}</ErrorInputMsg>
                            )}
                          </InputWrapper>
                        )}
                      </Field>
                      <LabelText>
                        {/* Mot de passe actuel : */}
                        {translations[
                          "page__userDetail__userSheet_form_oldPassword"
                        ] + " :"}
                      </LabelText>
                      <Field name="oldPassword">
                        {({ input, meta }) => (
                          <InputWrapper>
                            <InputLogin
                              {...input}
                              name="noPassword"
                              type="password"
                              text={
                                translations[
                                  "page__userDetail__userSheet_form_oldPassword_prefilled"
                                ]
                              }
                              autoComplete="new-password"
                              touched={meta.touched}
                              validated={!meta.error}
                              maxLength={"50"}
                            />
                            {(meta.error || meta.submitError) &&
                              meta.touched && (
                                <ErrorInputMsg>
                                  {meta.error || meta.submitError}
                                </ErrorInputMsg>
                              )}
                          </InputWrapper>
                        )}
                      </Field>
                    </Fragment>
                  )}
                </UserWrapperForm>
              );
            }}
          />
        </ContentTableWrapper>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    myAccount: state.myAccount,
    languagesList: state.languages.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actionsMyAccount: bindActionCreators(myAccountActionCreators, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
