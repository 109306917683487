////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { map } from 'lodash'
///////COMPONENTS///////
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
/////////ASSETS/////////
import Adjust from "../../../assets/icones/global/adjust.png"
import DragDot from '../../../assets/icones/global/dragDot.png'

/////////STYLED/////////
const DropdownButton = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.width + "px" };
    border-radius: 3px;
    //text-transform: ${props => props.upper ? 'uppercase' : 'capitalize'};
    background-color: ${props => props.theme.colors.primary};
    cursor: pointer;
    margin: ${ props => props.margin ? '0 16px' : 'none'}
`
const ContentCheckBoxWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
`
const Paper = styled.div`
    position: absolute;
    display: grid;
    border-radius: 5px;
    grid-template-columns: max-content;
    grid-auto-rows: 40px;
    grid-row-gap: 5px;
    justify-content: stretch;
    align-items: stretch;
    background-color: ${ props => props.theme.colors.white };
    border: 1px solid ${ props => props.theme.colors.borderTable };
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    padding: 10px 20px;
    left: 50%;
    top: ${props => props.topPosition + "px" };
    right: ${ props => props.rtl ? '0' : 'inherit' };
    left: ${ props => props.rtl ? 'inherit' : '0' };
    z-index: 1;
`
const PaperList = styled.span`
    cursor: pointer;
    color: ${ props => props.theme.colors.menu.wrapper };
`
const PaperCheckbox = styled.input`
    cursor: pointer;
`
const ListWraper = styled.label`
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-column-gap: 10px;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    border: 1px solid ${ props => props.theme.colors.borderList };;
    padding: 0 5px;
    background-color: ${ props => props.isDrag ? props.theme.colors.whiteGrey : props.theme.colors.white };
    font-weight: ${ props => props.isDrag ? 'bold' : 'inherit' };
    font-style: ${ props => props.isDrag ? 'italic' : 'inherit' };
`
const AdjustImg = styled.img`
    max-height: 36px;
    max-width: 36px;
`
const DragDotWrapper = styled.img`
    max-height: 20px ;
    max-width: 13px;
`
/////////STYLED/////////

export class DropdownDashboardSetting extends Component {
    static propTypes = {
        PaperTopPosition: PropTypes.string,
        width: PropTypes.string,
        data: PropTypes.array,
        selectActionsHandler: PropTypes.func,
    }
    state = {
        showPaper: false,
    }
    refCollectionCheckbox = {}

	///////////////////////////////// HANDLE ///////////////////////////////////////

    showPaperHandler = () => {
        this.setState({
            showPaper: true
        })
        document.addEventListener('mousedown', this.closePaperHandlerCheckbox)
    }

    closePaperHandlerCheckbox = (evt) => {
        const { onBlurPutDashboardSettingHandler } = this.props
        if(!this.dropdownCheckbox){
            document.removeEventListener('mousedown', this.closePaperHandlerCheckbox)
        }else{
            if(!this.dropdownCheckbox.contains(evt.target)) {
                this.setState({
                    showPaper: false
                })
                onBlurPutDashboardSettingHandler();
                document.removeEventListener('mousedown', this.closePaperHandlerCheckbox)
            }
        }
    }

    checkBoxHandler = (evt) => {
        const { selectActionsHandler } = this.props;
        if(this.dropdownCheckbox.contains(evt.target)) {
            map(this.refCollectionCheckbox, (elem) => {
                if(elem){
                    if(elem.contains(evt.target)){
                        selectActionsHandler(elem.id, elem.checked)
                    }
                }
            })
        }
    }

   	///////////////////////////////// RENDER ///////////////////////////////////////

    render() {
        const {
            margin,
            data,
            width,
            PaperTopPosition,
            upper,
            translations,
            onDragEndHandler,
            rtl
        } = this.props
        const paperList = map(data, (elem, id) => {
            return (
                <Draggable
                    draggableId={`${elem.id}`}
                    index={id}
                    key={elem.id}
                >
                    {(provided, snapshot) => (
                        <ListWraper
                            ref={provided.innerRef}
                            onMouseDown={this.checkBoxHandler}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            isDrag={snapshot.isDragging}
                        >
                            <PaperCheckbox
                                id={elem.id}
                                value={elem.label}
                                onChange={this.checkBoxHandler}
                                checked={elem.isSelected}
                                type='checkbox'
                                ref={(element) => {
                                this.refCollectionCheckbox[elem.label] = element
                            }}
                            />
                            <PaperList>
                                { translations[elem.translationKey] }
                            </PaperList>
                            <DragDotWrapper src={DragDot} />
                        </ListWraper>
                    )}
                </Draggable>
            )
        })
        return (
            <DropdownButton
                width={width}
                upper={upper}
                margin={margin}
            >
                <ContentCheckBoxWrapper onMouseDown={this.showPaperHandler} >
                    <AdjustImg src={Adjust} />
                </ContentCheckBoxWrapper>
                { this.state.showPaper &&
                <DragDropContext onDragEnd={onDragEndHandler} onDragStart={this.startDrag} >
                    <div>
                        <Droppable droppableId={'droppable'} >
                            {(provided) => (
                                <div ref={(element) => { this.dropdownCheckbox = element }}>
                                    <Paper
                                        topPosition={PaperTopPosition}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        rtl={rtl}>
                                        {paperList}
                                        {provided.placeholder}
                                    </Paper>
                                </div>
                            )}
                        </Droppable>
                    </div>
                </DragDropContext>
                }
            </DropdownButton>
        )
    }
}



export default DropdownDashboardSetting
