import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { find } from 'lodash'

/// //////////////////////////
//  START style
/// /////////////////////////

/// //////////////////////////
//  END style
/// /////////////////////////
/**
 *
 * @param name                          key name off privilage to display
 * @param userAuthorization             list off privileges off user
 * @param children                      element to display
 * @returns frameElement
 * @constructor
 */
const UserAuthorizationLayout = ({ name, userAuthorization, children }) => {
  const authorized = find(userAuthorization, { label: name }) || { isAuthorize: false };
  return (
    <Fragment>
      { authorized.isAuthorize && children }
    </Fragment>
  )
};

UserAuthorizationLayout.propTypes = {
  name: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  userAuthorization: PropTypes.array
};

const mapStateToProps = state => {
  return {
    userAuthorization: state.user.identity.rolePrivileges.privileges
  }
};

export default connect(mapStateToProps, null)(UserAuthorizationLayout)
