import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import _ from 'lodash';

extendUpdate(update);

const brands = {

    [ActionsTypes.GET_BRANDS_BY_FAMILY]: (state) => update(state, {
        brands: {
            brandsByFamily:{
                isLoading: {
                    $set: true,
                },

            },
        }
    }),
    [ActionsTypes.GET_BRANDS_BY_FAMILY_SUCCESS]: (state, payload) => {
        _.forEach(payload.brands, (elem) => {
            _.assign(elem, {active: true})
        });

        return update(state, {
            brands: {
                brandsByFamily:{
                    isLoading: {
                        $set: false,
                    },
                    brands:{
                        $set: payload.brands
                    }
    
                },
            },

        })        
    },
    [ActionsTypes.GET_BRANDS_BY_FAMILY_FAILURE]: (state, payload) => update(state, {
        brands: {
            brandsByFamilY:{
                isLoading: {
                    $set: false,
                },
                error:{
                    $set: payload.error
                }

            },
        },
    }),
    [ActionsTypes.UPDATE_BRANDS_SELECTED]: (state, payload) => {
        const oldState = {...state.brands.brandsByFamily.brands};
        
        _.forEach(oldState, (elem) => {
            if (elem.id === payload.id) {
                elem.active = payload.isChecked
            } 
        });
        
        return update(state, {
            brands: {
                brandsByFamily:{
                    brands:{
                        $set: oldState
                    }
    
                },
            },

        })        
    },
};

export default brands