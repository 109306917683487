import ActionsTypes from '../../constants/ActionsTypes';

const login = {
    getUserDetail: ( idUser ) => ( {
        type: ActionsTypes.GET_USER_DETAIL,
        payload: { idUser },
    } ),
    getUserDetailSuccess: ( userDetail ) => ( {
        type: ActionsTypes.GET_USER_DETAIL_SUCCESS,
        payload: {
            userDetail,
        },
    } ),
    getUserDetailFailure: ( error ) => ( {
        type: ActionsTypes.GET_USER_DETAIL_FAILURE,
        payload: {
            error,
        },
    } ),
    updateUserDetail: ( user, idUser ) => ( {
        type: ActionsTypes.UPDATE_USER_DETAIL,
        payload: {
            user,
            idUser
        },
    } ),
    updateUserDetailSuccess: ( user ) => ( {
        type: ActionsTypes.UPDATE_USER_DETAIL_SUCCESS,
        payload: { user },
    } ),
    updateUserDetailFailure: ( error ) => ( {
        type: ActionsTypes.UPDATE_USER_DETAIL_FAILURE,
        payload: { error },
    } ),
    UserDetailResetPassword: ( idUser ) => ( {
        type: ActionsTypes.USER_DETAIL_RESET_PASSWORD,
        payload: {
            idUser
        },
    } ),
    UserDetailResetPasswordSuccess: ( response ) => ( {
        type: ActionsTypes.USER_DETAIL_RESET_PASSWORD_SUCCESS,
        payload: { response },
    } ),
    UserDetailResetPasswordFailure: ( error ) => ( {
        type: ActionsTypes.USER_DETAIL_RESET_PASSWORD_FAILURE,
        payload: { error },
    } ),
    postUserCreate: ( user ) => ( {
        type: ActionsTypes.POST_USER_CREATE,
        payload: {
            user,
        },
    } ),
    postUserCreateSuccess: ( user ) => ( {
        type: ActionsTypes.POST_USER_CREATE_SUCCESS,
        payload: { user },
    } ),
    postUserCreateFailure: ( error ) => ( {
        type: ActionsTypes.POST_USER_CREATE_FAILURE,
        payload: { error },
    } ),
};

export default login;