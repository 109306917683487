import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import { map, forEach } from 'lodash'

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { userDetailActionCreators } from '../../state/actions';


// component import
import Button from '../../components/Ui/Buttons'


// style
import {
    PageWrapper,
    LineTableHeader,
    TitleHeader,
    ActionWrapper,
    ContentTableWrapper,
    UserWrapperForm,
    UserInfoLeftWrapper,
    UserInfoRightWrapper,
    InputWrapper,
    KioskCheckBoxWrapper,
    LabelCheckbox,
    InputLogin,
    RadioElementWrapper,
    RadioItem,
    RadioInput,
    RadioButtonLabel,
    TextLabel,
    RadioCustom,
    SelectInput,
    ErrorInputMsg,
    ErrorFormMsg,
    LabelText,
    LabelTextKiosks,
} from "../../theme/GlobalCss";
import theme from "../../theme/theme";
import { createUserValidator } from "../../helpers/FormValidators";

/////////////////////////////
//  START style
/////////////////////////////
/////////////////////////////
//  END style
/////////////////////////////

export class CreateUser extends Component {
    static propTypes = {
        history:                PropTypes.object,
        userDetail:             PropTypes.object,
        translations:           PropTypes.object,
        languagesList:          PropTypes.array,
        actionsUserDetail:      PropTypes.object,
        idCurrentUser:          PropTypes.number,
    };

    componentDidMount = () => {
        this.getMyAccount()
    };

    getMyAccount = () => {
        const { actionsUserDetail, idCurrentUser } = this.props;

        actionsUserDetail.getUserDetail(idCurrentUser);
    };

    onSubmitHandler = (values) => {
        const { actionsUserDetail } = this.props;

        actionsUserDetail.postUserCreate(values)
    };

    render() {
        const { history, userDetail, translations, languagesList } = this.props;
        let submit;
        let resetForm;
        if( userDetail.isLoading || !userDetail.items) {
            return <div></div>
        }

        let userKioskSelected = [];
        forEach(userDetail.items.kiosks, (elem) => elem.isSelected ? userKioskSelected.push(elem.id) : null);
        const isUpdate = userDetail.update;
        const initialData = {
            firstName : isUpdate ? userDetail.items.firstName : '',
            lastName : isUpdate ? userDetail.items.lastName : '',
            phoneNumber : isUpdate ? userDetail.items.phoneNumber : '',
            userName : isUpdate ? userDetail.items.userName : '',
            email : isUpdate ? userDetail.items.email : '',
            kiosks: userKioskSelected,
            idPreferedLanguage: userDetail.items.preferedLanguage.id || 1,
            enabled: `true`,
            idRole: userDetail.items.role.id,
        };
        return (
            <PageWrapper>

                <LineTableHeader>
                    <ActionWrapper>
                        <Button
                            goBack
                            weight='bold'
                            actionHandler={history.goBack}
                            text={translations['common__text__previousPage']}
                        />
                    </ActionWrapper>
                </LineTableHeader>

                <LineTableHeader>
                    <TitleHeader>
                        {/* Fiche utilisateur */}
                        {/*TODO: add translations*/}
                        {translations['page__userCreate__title']}
                    </TitleHeader>
                    <ActionWrapper>
                        <Button
                            bgColor={theme.colors.validation}
                            type="submit"
                            actionHandler={(event) => {
                                submit(event)
                            }}
                            text={translations['common__text__save']}
                        />
                        <Button
                            actionHandler={() => resetForm()}
                            bgColor={theme.colors.cancel}
                            text={translations['common__text__cancel']}

                        />
                    </ActionWrapper>
                </LineTableHeader>
                <ContentTableWrapper>
                    <LineTableHeader>
                        {/*TODO: what to do ?*/}
                        {/*<LineTableHeader color={theme.colors.primary}>*/}
                        {/*    {translations['page__userCreate__title']}*/}
                        {/*</LineTableHeader>*/}
                    </LineTableHeader>
                    <Form
                        onSubmit={this.onSubmitHandler}
                        initialValues={{...initialData}}
                        keepDirtyOnReinitialize
                        validate={createUserValidator}
                        render={({
                            handleSubmit,
                            values,
                            form,
                        }) => {
                            submit = handleSubmit;
                            resetForm = form.reset;
                            return (
                                // USER DETAIL 
                                <UserWrapperForm>
                                    {userDetail.error.data && <ErrorFormMsg>{userDetail.error.data.traductionKey}</ErrorFormMsg>}

                                    {/*LEFT SIDE START*/}
                                    <UserInfoLeftWrapper>
                                        <LabelText>
                                            {/* Langue par défaut : */}
                                            {translations['page__userDetail__userSheet_form_defaultLanguage']+" :"}
                                        </LabelText>
                                        <Field name="idPreferedLanguage">
                                            {({ input, meta }) => (
                                            <InputWrapper>
                                                <SelectInput {...input} >
                                                    {
                                                        map(languagesList, (elem, id ) => (
                                                            <option
                                                                key={id}
                                                                value={elem.id}
                                                            >
                                                                {elem.labelEnglish}
                                                            </option>
                                                        ))
                                                    }

                                                </SelectInput>
                                                {meta.error && meta.touched && <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                            </InputWrapper>
                                            )}
                                        </Field>
                                        <LabelText>
                                            {/* Nom : */}
                                            {translations['page__userDetail__userSheet_form_lastname']+"* :"}
                                        </LabelText>
                                        <Field name="lastName"
                                        >
                                            {({ input, meta }) => (
                                            <InputWrapper>
                                                <InputLogin
                                                    {...input}
                                                    type="text"
                                                    placeholder={translations['page__userDetail__userSheet_form_lastname_prefilled']}
                                                    maxLength='150'
                                                    touched={meta.touched}
                                                    validated={!meta.error}
                                                />
                                                {meta.error && meta.touched && <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                            </InputWrapper>
                                            )}
                                        </Field>
                                        <LabelText>
                                            {/* Prénom : */}
                                            {translations['page__userDetail__userSheet_form_firstname']+"* :"}
                                        </LabelText>
                                        <Field name="firstName"
                                        >
                                            {({ input, meta }) => (
                                            <InputWrapper>
                                                <InputLogin
                                                    {...input}
                                                    type="text"
                                                    placeholder={translations['page__userDetail__userSheet_form_firstname_prefilled']}
                                                    maxLength='150'
                                                    touched={meta.touched}
                                                    validated={!meta.error}
                                                />
                                                {(meta.error || meta.submitError) && meta.touched && (
                                                    <ErrorInputMsg>{meta.error || meta.submitError}</ErrorInputMsg>
                                                )}
                                            </InputWrapper>
                                            )}
                                        </Field>
                                        <LabelText>
                                            {/* Téléphone : */}
                                            {translations['page__userDetail__userSheet_form_phone']+"* :"}
                                        </LabelText>
                                        <Field name="phoneNumber"
                                        >
                                            {({ input, meta }) => (
                                            <InputWrapper>

                                                <InputLogin
                                                    {...input}
                                                    type="tel"
                                                    placeholder={translations['page__userDetail__userSheet_form_phone_prefilled']}
                                                    maxLength='150'
                                                    touched={meta.touched}
                                                    validated={!meta.error}
                                                />
                                                {meta.error && meta.touched && <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                            </InputWrapper>
                                            )}
                                        </Field>
                                        <LabelText>
                                            {/* Identifiant : */}
                                            {translations['page__userDetail__userSheet_form_login']+"* :"}
                                        </LabelText>
                                        <Field name="userName"
                                        >
                                            {({ input, meta }) => (
                                            <InputWrapper>
                                                <InputLogin
                                                    {...input}
                                                    type="text"
                                                     placeholder={translations['page__userDetail__userSheet_form_login_prefilled']}
                                                    touched={meta.touched}
                                                    validated={!meta.error}
                                                    maxLength='150'
                                                />
                                                {meta.error && meta.touched && <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                            </InputWrapper>
                                            )}
                                        </Field>
                                        <LabelText>
                                            {/* Email : */}
                                            {translations['page__userDetail__userSheet_form_email']+"* :"}
                                        </LabelText>
                                        <Field name="email"
                                        >
                                            {({ input, meta }) => (
                                            <InputWrapper>
                                                <InputLogin
                                                    {...input}
                                                    type="text"
                                                    placeholder={translations['page__userDetail__userSheet_form_email_prefilled']}
                                                    touched={meta.touched}
                                                    validated={!meta.error}
                                                    maxLength={'150'}
                                                />
                                                {meta.error && meta.touched && <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                            </InputWrapper>
                                            )}
                                        </Field>

                                    </UserInfoLeftWrapper>
                                    {/*LEFT SIDE END*/}


                                    {/*RIGHT SIDE START*/}
                                    <UserInfoRightWrapper>

                                        {/* User activation : */}
                                        <LabelText>
                                            {translations['page__userDetail__userSheet_form_activeDesactive']+" :"}
                                        </LabelText>
                                        <div>
                                            <RadioElementWrapper>
                                                <RadioItem>
                                                    <RadioButtonLabel>
                                                        <Field
                                                            type="radio"
                                                            name="enabled"
                                                            value='true'
                                                        >
                                                            {({ input}) => (
                                                                <RadioCustom {...input}/>
                                                            )}
                                                        </Field>
                                                        <RadioInput />
                                                        <TextLabel>
                                                            {/*TODO : traduction*/}
                                                            Activé
                                                        </TextLabel>
                                                    </RadioButtonLabel>
                                                </RadioItem>
                                                <RadioItem>
                                                    <RadioButtonLabel>
                                                        <Field
                                                            type="radio"
                                                            name="enabled"
                                                            value='false'
                                                        >
                                                            {({ input }) => (
                                                                <RadioCustom {...input} disabled/>
                                                            )}
                                                        </Field>
                                                        <RadioInput />
                                                        <TextLabel
                                                            disabled
                                                        >
                                                            {/*TODO : traduction*/}
                                                            Désactivé
                                                        </TextLabel>
                                                    </RadioButtonLabel>
                                                </RadioItem>
                                            </RadioElementWrapper>
                                        </div>


                                        {/* User Role : */}
                                        <LabelText>
                                            {translations['page__userDetail__userSheet_form_role']+" :"}
                                        </LabelText>
                                        <Field
                                            name="idRole"
                                        >
                                            {({ input, meta }) => (
                                                <InputWrapper>
                                                    <SelectInput {...input} >
                                                        {
                                                            map(userDetail.items.roles, (elem, id ) => (
                                                                <option
                                                                    key={id}
                                                                    value={elem.id}
                                                                >
                                                                  {  elem.label}
                                                                </option>
                                                            ))
                                                        }
                                                    </SelectInput>
                                                    {meta.error && meta.touched && <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                                </InputWrapper>
                                            )}
                                        </Field>


                                        {/* User Kiosks : */}
                                        <LabelTextKiosks>
                                            {translations['page__userDetail__userSheet_form_kioskSelected']+" :"}
                                        </LabelTextKiosks>
                                        <Field
                                            name="kiosks"
                                        >
                                            {({ meta }) => (
                                                <KioskCheckBoxWrapper>
                                                    {
                                                        map(userDetail.items.kiosks, (elem, id ) => (
                                                            <LabelCheckbox
                                                                key={ id }
                                                            >
                                                                <Field
                                                                    name='kiosks'
                                                                    component="input"
                                                                    type="checkbox"
                                                                    value={ elem.id }
                                                                    // disabled last input checked
                                                                    disabled={
                                                                        values.kiosks.length === 1 &&
                                                                        values.kiosks[0] === elem.id
                                                                    }
                                                                />{ ' ' }
                                                                { `${ elem.description } - ${ elem.model }` }
                                                            </LabelCheckbox>
                                                        ))
                                                    }
                                                    {meta.error && meta.touched && <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                                </KioskCheckBoxWrapper>

                                            )}
                                        </Field>
                                    </UserInfoRightWrapper>
                                    {/*RIGHT SIDE END*/}

                                </UserWrapperForm>
                            )
                        }}
                    />
                </ContentTableWrapper>
            </PageWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        userDetail: state.userDetail,
        languagesList: state.languages.items,
        idCurrentUser: state.user.identity.id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionsUserDetail: bindActionCreators(userDetailActionCreators, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser)
