import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Form, Field } from 'react-final-form'
import { withRouter } from "react-router";
import { cloneDeep, isNull } from 'lodash'


// import components
import Modal from '../../../components/Ui/Modals/Modal'
import CR from '../../../constants/RouteConstants'

// redux import
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { salesOrdersActionCreators } from '../../../state/actions';
import UserAuthorizationLayout
    from "../../../components/UserAuthorizationLayout/UserAuthorizationLayout";

// helpers
import userPrivileges from "../../../constants/RoleList";
import Button from "../../../components/Ui/Buttons";
import theme from "../../../theme/theme";

/////////////////////////////
//  START style
////////////////////////////
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 596px;
    padding: 35px 30px;
`;
const UserWrapperForm = styled.form`
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 160px);
    grid-column-gap: 23px;
    grid-row-gap: 28px;
    align-items: center;
    color: ${props => props.theme.colors.menu.link};
    box-sizing: border-box;
`;
const InputLabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;
const InputLogin = styled.input`
    height: 45px;
    margin-top: 5px;
    width: 160px;
    border-radius: 3px;
    border: ${props => props.touched ? props.validated ? `1px solid ${props.theme.colors.validation}` : `1px solid ${props.theme.colors.error}` : `1px solid ${props.theme.colors.cancel}`};
    box-sizing: border-box;
    font-size: 14px;
    padding-left: 13px;
    color: ${props => props.theme.colors.cancel};
    background-color: ${props => props.theme.colors.white};
    :focus{
        outline: none !important;
        border-color: ${props => props.theme.colors.primary};
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        box-shadow: 0 0 0px 1000px ${props => props.theme.colors.white} inset;
    }
`;
const LabelText = styled.p`
    margin: 0;
    font-size: 13px;
    color: ${props => props.theme.colors.cancel};
`;
const ErrorInputMsg = styled.div`
    position: absolute;
    top: 55px;
    left: 5px;
    width: 200px;
    color: ${props => props.theme.colors.error};
`;
const InputWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ButtonWrapper = styled.div`
    width: 100%;
    height: 40px;
    margin-top: 52px;
    display: flex;
    justify-content: flex-end;
`;
const TitleModal = styled.div`
    display: flex;
    color: ${props => props.theme.colors.menu.link};
    font-weight: 900;
    font-size: 16px;
`;
const UserConsignmentModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    grid-row-gap: 24px;
`

const Split = styled.div`
    border: 1px solid ${theme.colors.black};
    margin-bottom:15px;
    opacity:0.33;
`
/////////////////////////////
//  END style
////////////////////////////


export class UserConsignementInfo extends Component {
    static propTypes = {
        elem: PropTypes.object,
        type: PropTypes.bool,
        closeModalHandler: PropTypes.func,
        actions: PropTypes.object,
        salesOrder: PropTypes.object,
        idUser: PropTypes.number,
    };

    ////////////////////////////////////////////////////////////////////////
    /**
     * send a sales orders
     */
    createSalesOrders = (value) => {
        const { actions, salesOrder, match, elem, closeModalHandler } = this.props;
        if (
            match.path === CR.salesHistories ||
            match.path === CR.salesHistoriesBySales.route ||
            match.path === CR.salesHistoriesByItem
        ) {
            const userConsignmentInfo = {
                clientFirstname: value.firstName,
                clientLastname: value.lastName,
                clientEmail: value.email,
                customerId: value.customerId,
                companyName: value.companyName,
                erpOrderId: value.erpOrderId,
                erpBlId: value.erpBlId,
                customerMobileNumber: value.customerMobileNumber,
            };

            actions.updateUserConsignmentInfo(userConsignmentInfo, elem.id);
            closeModalHandler()
        }
        if (match.path === CR.kioskProduct || match.path === CR.productDetail.route) {
            const newSalesOrder = cloneDeep(salesOrder.items);
            newSalesOrder.clientFirstname = value.firstName;
            newSalesOrder.clientLastname = value.lastName;
            newSalesOrder.clientEmail = value.email;
            newSalesOrder.customerId = value.customerId;
            newSalesOrder.companyName = value.companyName;
            newSalesOrder.erpOrderId = value.erpOrderId;
            newSalesOrder.erpBlId = value.erpBlId;
            newSalesOrder.customerMobileNumber = value.customerMobileNumber;

            actions.updateSalesOrders(newSalesOrder);
            actions.postSalesOrders(newSalesOrder)
        }

    };//end function

    componentDidUpdate(prevProps) {
        const { modalError, salesOrder, history, match } = this.props;

        if (
            (modalError !== prevProps.modalError) &&
            (prevProps.modalError.show) &&
            (!modalError.modalError)
        ) {
            // If an error occured
            if (prevProps.modalError.show && modalError.show === false) {
                // When the modal is closed by click, redirects
                window.location.href = `${CR.kioskProduct}`
            }
        } else {
            if (salesOrder.generatedCode !== prevProps.salesOrder.generatedCode) {
                history.push(`${match.url}${CR.modal.generatedCode}`)
            }
        }
    }

    ////////////////////////////////////////////////////////////////////////
    validate = (values) => {
        const { translations } = this.props;
        // eslint-disable-next-line
        const regMail = RegExp(/^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        // eslint-disable-next-line
        const regName = RegExp(/[ˆ(\d|+|\-)]/);
        const errors = {};
        if (!values.lastName) {
            errors.lastName = translations['common__text__requiredField']
        } else {
            if (values.lastName.length > 30) {
                errors.lastName = `30 ${translations['common__text__charMax']}`
            }
            if (regName.test(values.lastName)) {
                errors.lastName = translations['common__text__invalidName']
            }
        }
        if (!values.firstName) {
            errors.firstName = translations['common__text__requiredField']
        } else {
            if (values.firstName.length > 30) {
                errors.firstName = `30 ${translations['common__text__charMax']}`
            }
            if (regName.test(values.firstName)) {
                errors.firstName = translations['common__text__invalidFirstName']
            }
        }
        if (!values.email) {
            errors.email = translations['common__text__requiredField']
        } else {
            if (!regMail.test(values.email)) {
                errors.email = translations['common__text__invalidEmail']
            }
        }
        return errors
    };

    render() {
        const { closeModalHandler, match, translations, elem } = this.props;
        let submit;
        let isSalesOrder = match.path === CR.kioskProduct;
        return (
            <Modal
                closeModal={closeModalHandler}
            >
                <ActionWrapper>
                    <Form
                        onSubmit={this.createSalesOrders}
                        validate={this.validate}
                        render={({
                            handleSubmit,
                        }) => {
                            submit = handleSubmit;
                            return (
                                <UserConsignmentModalWrapper>
                                    <TitleModal>
                                        {translations['modal__customerInfo__title'] + " :"}
                                    </TitleModal>
                                    <UserWrapperForm>
                                        <InputLabelWrapper>
                                            <LabelText>
                                                {translations['modal__customerInfo__form_name'] + " :"}
                                            </LabelText>
                                            <Field
                                                name="lastName"
                                                initialValue={!isSalesOrder ? elem.clientLastname : ''}
                                            >
                                                {({ input, meta }) => (
                                                    <InputWrapper>
                                                        <InputLogin
                                                            {...input}
                                                            type="text"
                                                            placeholder={translations['modal__customerInfo__form_name_prefilled']}
                                                            maxLength='150'
                                                            touched={meta.touched}
                                                            validated={!meta.error}
                                                        />
                                                        {meta.error && meta.touched &&
                                                            <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                                    </InputWrapper>

                                                )}
                                            </Field>
                                        </InputLabelWrapper>
                                        <InputLabelWrapper>
                                            <LabelText>
                                                {translations['modal__customerInfo__form_firstname'] + " :"}
                                            </LabelText>
                                            <Field
                                                name="firstName"
                                                initialValue={!isSalesOrder ? elem.clientFirstname : ''}
                                            >
                                                {({ input, meta }) => (
                                                    <InputWrapper>
                                                        <InputLogin
                                                            {...input}
                                                            type="text"
                                                            placeholder={translations['modal__customerInfo__form_firstname_prefilled']}
                                                            maxLength='150'
                                                            touched={meta.touched}
                                                            validated={!meta.error}
                                                        />
                                                        {(meta.error || meta.submitError) && meta.touched && (
                                                            <ErrorInputMsg>{meta.error || meta.submitError}</ErrorInputMsg>
                                                        )}
                                                    </InputWrapper>
                                                )}
                                            </Field>
                                        </InputLabelWrapper>

                                        <InputLabelWrapper>
                                            <LabelText>
                                                {translations['modal__customerInfo__form_email'] + " :"}
                                            </LabelText>
                                            <Field
                                                name="email"
                                                initialValue={!isSalesOrder ? elem.clientEmail : ''}
                                            >
                                                {({ input, meta }) => (
                                                    <InputWrapper>

                                                        <InputLogin
                                                            {...input}
                                                            type="text"
                                                            placeholder={translations['modal__customerInfo__form_email_prefilled']}
                                                            touched={meta.touched}
                                                            validated={!meta.error}
                                                            maxLength={'150'}
                                                        />
                                                        {meta.error && meta.touched &&
                                                            <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                                    </InputWrapper>
                                                )}
                                            </Field>
                                        </InputLabelWrapper>
                                        <div style={{ width: '598px' }}>
                                            <Split />
                                        </div>
                                    </UserWrapperForm>
                                    <TitleModal>
                                        {`${translations['modal__customerInfo__additionnal_info_title']} :`}
                                    </TitleModal>
                                    <UserWrapperForm>
                                        <InputLabelWrapper>
                                            <LabelText>
                                                {`${translations['modal__customerInfo__additionnal_info_customerNumber']} :`}
                                            </LabelText>
                                            <Field
                                                name="customerId"
                                                initialValue={!isSalesOrder && !isNull(elem.salesByCodeInformation) ? elem.salesByCodeInformation?.customerId : ''}
                                            >
                                                {({ input, meta }) => (
                                                    <InputWrapper>
                                                        <InputLogin
                                                            {...input}
                                                            type="text"
                                                            placeholder={`${translations['modal__customerInfo__additionnal_info_customerNumber']}`}
                                                            touched={meta.touched}
                                                            validated={!meta.error}
                                                        />
                                                        {meta.error && meta.touched &&
                                                            <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                                    </InputWrapper>

                                                )}
                                            </Field>
                                        </InputLabelWrapper>
                                        <InputLabelWrapper>
                                            <LabelText>
                                                {`${translations['modal__customerInfo__additionnal_info_companyName']} :`}
                                            </LabelText>
                                            <Field
                                                name="companyName"
                                                initialValue={!isSalesOrder && !isNull(elem.salesByCodeInformation) ? elem.salesByCodeInformation?.companyName : ''}
                                            >
                                                {({ input, meta }) => (
                                                    <InputWrapper>
                                                        <InputLogin
                                                            {...input}
                                                            type="text"
                                                            placeholder={`${translations['modal__customerInfo__additionnal_info_companyName']}`}
                                                            touched={meta.touched}
                                                            validated={!meta.error}
                                                        />
                                                        {(meta.error || meta.submitError) && meta.touched && (
                                                            <ErrorInputMsg>{meta.error || meta.submitError}</ErrorInputMsg>
                                                        )}
                                                    </InputWrapper>
                                                )}
                                            </Field>
                                        </InputLabelWrapper>

                                        <InputLabelWrapper>
                                            <LabelText>
                                                {`${translations['modal__customerInfo__additionnal_info_ERP_command_ref']} :`}
                                            </LabelText>
                                            <Field
                                                name="erpOrderId"
                                                initialValue={!isSalesOrder && !isNull(elem.salesByCodeInformation) ? elem.salesByCodeInformation?.erpOrderId : ''}
                                            >
                                                {({ input, meta }) => (
                                                    <InputWrapper>

                                                        <InputLogin
                                                            {...input}
                                                            type="text"
                                                            placeholder={`${translations['modal__customerInfo__additionnal_info_ERP_command_ref']}`}
                                                            touched={meta.touched}
                                                            validated={!meta.error}
                                                        />
                                                        {meta.error && meta.touched &&
                                                            <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                                    </InputWrapper>
                                                )}
                                            </Field>
                                        </InputLabelWrapper>
                                        <InputLabelWrapper>
                                            <LabelText>
                                                {`${translations['modal__customerInfo__additionnal_info_ERP_BL']} :`}
                                            </LabelText>
                                            <Field
                                                name="erpBlId"
                                                initialValue={!isSalesOrder && !isNull(elem.salesByCodeInformation) ? elem.salesByCodeInformation?.erpBlId : ''}
                                            >
                                                {({ input, meta }) => (
                                                    <InputWrapper>
                                                        <InputLogin
                                                            {...input}
                                                            type="text"
                                                            placeholder={`${translations['modal__customerInfo__additionnal_info_ERP_BL']}`}
                                                            touched={meta.touched}
                                                            validated={!meta.error}
                                                        />
                                                        {meta.error && meta.touched &&
                                                            <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                                    </InputWrapper>

                                                )}
                                            </Field>
                                        </InputLabelWrapper>
                                        <InputLabelWrapper>
                                            <LabelText>
                                                {`${translations['modal__customerInfo__additionnal_info_mobilePhone']} :`}
                                            </LabelText>
                                            <Field
                                                name="customerMobileNumber"
                                                initialValue={!isSalesOrder && !isNull(elem.salesByCodeInformation) ? elem.salesByCodeInformation?.customerMobileNumber : ''}
                                            >
                                                {({ input, meta }) => (
                                                    <InputWrapper>
                                                        <InputLogin
                                                            {...input}
                                                            type="text"
                                                            placeholder={`${translations['modal__customerInfo__additionnal_info_mobilePhone']}`}
                                                            touched={meta.touched}
                                                            validated={!meta.error}
                                                        />
                                                        {meta.error && meta.touched &&
                                                            <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                                    </InputWrapper>

                                                )}
                                            </Field>
                                        </InputLabelWrapper>
                                    </UserWrapperForm>
                                </UserConsignmentModalWrapper>
                            )
                        }}
                    />
                    <ButtonWrapper>
                        <Button
                            bgColor={theme.colors.error}
                            margin='21px'
                            actionHandler={closeModalHandler}
                            text={translations['common__text__cancel']}

                        />

                        <UserAuthorizationLayout
                            name={userPrivileges.manage[0]}
                        >
                            {
                                isSalesOrder
                                    ?
                                    <Button
                                        bgColor={theme.colors.validation}
                                        actionHandler={(event) => {
                                            submit(event)
                                        }}
                                        link={`${match.url}${CR.modal.generatedCode}`}
                                        text={translations['common__text__save']}
                                    />
                                    :
                                    <Button
                                        bgColor={theme.colors.validation}
                                        actionHandler={(event) => {
                                            submit(event)
                                        }}
                                        text={translations['common__text__save']}
                                    />
                            }
                        </UserAuthorizationLayout>


                    </ButtonWrapper>
                </ActionWrapper>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        idUser: state.user.identity.id,
        salesOrder: state.salesOrders,
        modalError: state.modal
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(salesOrdersActionCreators, dispatch),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserConsignementInfo))
