import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';

extendUpdate(update);

const globalLoading = {

    [ActionsTypes.START_LOADING]: (state) => update(state, {
        globalLoading: {
            loading: {
                $set: true
            },
            count: {
                $set: state.globalLoading.count + 1
            }
        }
    }),
    [ActionsTypes.END_LOADING]: (state) =>  {
        const oldState = { ...state.globalLoading };
        oldState.count = oldState.count > 0 && oldState.count - 1;
        let data;

        data = !!oldState.count;

        // forEach(state, (elem) => {
        //     if( elem.isLoading) {
        //         data = true
        //     }
        // })

        return update(state, {
            globalLoading: {
                loading: {
                    $set: data
                },
                count: {
                    $set: oldState.count
                }
            }
        })
    },
};

export default globalLoading