import login from './login';
import brands from './brands';
import families from './families';
import itemsKiosk from './itemsKiosk';
import salesOrders from './salesOrders';
import salesHistories from './salesHistories';
import codesHistories from './codesHistories';
import itemsStorePrices from './itemsStorePrices';
import datePicker from './datePicker';
import user from './user'
import promoItemStore from './promoItemStore'
import kiosks from './kiosks'
import myAccount from './myAccount'
import createPromo from './createPromo'
import globalLoading from './globalLoading'
import paymentCollection from './paymentCollection'
import stockManager from './stockManager'
import modals from './modals'
import languages from './languages'
import logs from './logs'
import errorManager from './errorManager'
import dashBoard from './dashBoard'
import adminUser from './adminUser'
import userDetail from './userDetail'
import exportData from './exportData'
/* import wareHouseStockManagement from './wareHouseStockManagement' */
import initialState from '../initialState';

const reducersMap = {
  ...login,
  ...brands,
  ...families,
  ...itemsKiosk,
  ...salesOrders,
  ...salesHistories,
  ...codesHistories,
  ...itemsStorePrices,
  ...datePicker,
  ...promoItemStore,
  ...user,
  ...kiosks,
  ...myAccount,
  ...createPromo,
  ...globalLoading,
  ...paymentCollection,
  ...modals,
  ...languages,
  ...stockManager,
  ...logs,
  ...errorManager,
  ...dashBoard,
  ...adminUser,
  ...userDetail,
  ...exportData,
/*   ...wareHouseStockManagement, */
  leaveStateUnchanged: state => state,
};

const reducers = function reducers(state = initialState, action) {
  const reducer = reducersMap[action.type] || reducersMap.leaveStateUnchanged;
  const newState = reducer(state, action.payload, action.meta);
  return newState;
};

export default reducers;
