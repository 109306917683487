import NoImage from "../assets/images/no-picture.svg";
import { isNull } from "lodash";

const imageImport = (img) => {
  let URI;
  switch (process.env.REACT_APP_TARGET) {
    case "recette":
      URI = "https://recette-vm-store.we-deal.fr/";
      break;
    case "pre-recette":
      URI = "https://pre-recette-vm-store.we-deal.fr/";
      break;
    case "prod":
      URI = "https://vm-store.we-deal.fr/";
      break;

    default:
      URI = "https://dev-vm-store.we-deal.fr/";
      break;
  }
  return !isNull(img) ? `${URI}${img}` : NoImage;
};
export default imageImport;
