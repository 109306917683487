import { createSelector } from 'reselect'
import { find, forEach, filter, isEmpty } from 'lodash'

const getFamilies                   = state => state.itemsKiosksStock.filter;
const getItemsKiosks                = state => state.itemsKiosksStock.items;


export const selectFamilySelected = createSelector(
    [ getFamilies ],
    (families) => (
        find(families, {active : true})
    )
);
export const selectBrandsFamilies = createSelector(
    [ selectFamilySelected ],
    (families) => {
        if(!isEmpty(families)){
            return families.brands
        }
        return []
    }
);

export const selectItemsByFamilySelected = createSelector(
    [ getItemsKiosks, selectFamilySelected ],
    (itemsKiosk, family) => {

        const items = filter(itemsKiosk, ( item, )=> {
            return family.id === item.itemFamily.id
        });
        return items
    }
);
export const selectItemsByFamilyByBrands = createSelector(
    [ selectItemsByFamilySelected, selectBrandsFamilies ],
    (itemsKiosk, brandsFamily) => {

        if(!isEmpty(itemsKiosk) && !isEmpty(brandsFamily)) {
            const items = filter( itemsKiosk, ( items, ) => {
                let flag = false;
                forEach( brandsFamily, ( elem ) => {
                    if (
                        elem.active &&
                        elem.id === items.itemBrand.id
                    ) {
                        flag = true
                    }
                } );
                if ( flag ) {
                    return items
                }
            } );
            return items
        }
        return []
    }
);
