import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { map, isArray  } from 'lodash'

// assets
import SortableTable from '../../../assets/icones/global/sort-table.png'

/////////////////////////////
//  START style
////////////////////////////
const TableHeader = styled.div`
    display: flex;
    background-color: ${ props => props.nested ? props.theme.colors.tableHeader.white1 : props.theme.colors.tableHeader.white2 };
    font-weight: bold;
    color: ${ props => props.theme.colors.tableHeader.grey1 };
    padding: 0 5px;
    justify-content: center;
    align-items: center;
    height: ${ /* props => props.nested ? '45px' : '45px' */ '45px' };
    border-bottom: 1px solid ${ props => props.theme.colors.borderTable};
    cursor: pointer;
`;
const PaperCheckbox = styled.input`
    cursor: pointer;
    height: 17px;
    width: 17px;
    border: 1px solid ${ props => props.theme.colors.borderList };
    border-radius: 2px;
    background-color: ${ props => props.theme.colors.white };
`;
const TitleNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const TitleName = styled.p`
    margin: 0;
    padding: 0;
`;
const SortableImg = styled.img`
    margin-left: 5px;
    max-height: 11px;
    max-width: 7px;
`;
/////////////////////////////
//  END style
////////////////////////////

const TableLayoutHeaders = ({ header, checkboxChangeHandler, nested, initSortTableHandle }) => {

    return (
        map( header, ( item, id ) => (
            item.useName === 'checkBox' ?

                <TableHeader
                    key={ id }
                    id={ item.useName }
                    nested={ nested }
                >
                    <PaperCheckbox
                        id={ 'ALL' }
                        type='checkbox'
                        checked={ item.isChecked }
                        onChange={ checkboxChangeHandler }
                    />
                </TableHeader>
                :
                <TableHeader
                    key={ id }
                    id={ item.name }
                    nested={ nested }
                    onClick={item.sortTable ? () => initSortTableHandle(item.useName) : null}
                >
                    {
                        isArray( item.name )
                            ?
                            <TitleNameWrapper>
                                {
                                    map( item.name, ( elem, id ) => (
                                        <TitleName
                                            key={id}
                                        >
                                            { elem }
                                        </TitleName>
                                    ) )
                                }
                            </TitleNameWrapper>

                            :
                            <TitleName>
                                { item.name }
                            </TitleName>
                    }
                    {
                        item.sortTable &&
                        <SortableImg src={SortableTable}/>
                    }
                </TableHeader>
        ) )
    );
};

TableLayoutHeaders.propTypes = {
    header: PropTypes.array,
    checkboxChangeHandler: PropTypes.func,
    nested: PropTypes.bool,
};


export default TableLayoutHeaders
