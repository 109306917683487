import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import Store from '../../helpers/Store'
// TODO: refact when url who i am is valide
import _ from 'lodash'

extendUpdate(update);

const login = {

    [ActionsTypes.GET_LOGIN]: (state) => update(state, {
        user: {
            isLoading: {
                $set: true,
            },
        }
    }),
    [ActionsTypes.GET_LOGIN_SUCCESS]: (state, payload) => {
        const user      = payload.user;
        const lastKiosk = (user.userKiosks[0]);
        if(!user.kioskSelected){
            _.assign(user, {'kioskSelected': lastKiosk.idKiosk});
            Store.set('user', { jwttoken : user.jwttoken})
        }

        return update(state, {
            user:{
                isLoading: {
                    $set: false,
                },
                identity: {
                    $set: {...user},
                },
                isConnected: {
                    $set : true,
                },
            },

        })        
    },
    [ActionsTypes.GET_LOGIN_FAILURE]: (state, payload) => update(state, {
        user:{
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
            isConnected: {
                $set : false,
            }
        },
    }),
    [ActionsTypes.GET_CURRENT_USER]: (state) => update(state, {
        user: {
            isLoading: {
                $set: true,
            },
        }
    }),

    [ActionsTypes.CHANGE_PATH_USER]: (state, payload) => update(state, {
        user:{
            path: {
                $set: payload.path
            }
        },
    }),
    [ActionsTypes.GET_LOGOUT]: (state) => {

        Store.remove('user');

        return update(state, {
            user:{
                isLoading: {
                    $set: false,
                },
                identity:{
                    $set : {'kioskSelected' : {}}

                },
                isConnected: {
                    $set : false,
                },
                error: {
                    $set: false
                },
                path: {
                    $set: false,
                },
            },

        })
    },

    [ActionsTypes.POST_FORGOT_PASSWORD]: (state) => update(state, {
        user: {
            isLoading: {
                $set: true,
            },
            forgotPassword: {
                $set: 0
            }
        }
    }),
    [ActionsTypes.POST_FORGOT_PASSWORD_SUCCESS]: (state, payload) => {
        return update(state, {
            user: {
                isLoading: {
                    $set: false,
                },
                forgotPassword: {
                    $set: payload.response
                }
            }
        })
    },
    [ActionsTypes.POST_FORGOT_PASSWORD_FAILURE]: (state, payload) => update(state, {
        user:{
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
            isConnected: {
                $set : false,
            }
        },
    }),
};

export default login