import ActionsTypes from '../../constants/ActionsTypes';

const itemsKiosk = {
    getItemKiosk: (id) => ({
        type: ActionsTypes.GET_ITEM_KIOSK,
        payload: {
            id,
        },
    }),
    getItemKioskSuccess: (itemKiosk) => ({
        type: ActionsTypes.GET_ITEM_KIOSK_SUCCESS,
        payload: {
            itemKiosk,
        },
    }),
    getItemKioskFailure: (error) => ({
        type: ActionsTypes.GET_ITEM_KIOSK_FAILURE,
        payload: {
            error,
        },
    }),

// ===========================================================================
    /**
     * Get item kiosk by id and check if is valid for sale
     * @param {string} id
     * @param {number | null} idPromo
     * @return {{payload: {id: *, idPromo: *}, type: null}}
     */
    postItemKioskCheckOrderValidation: (id, idPromo) => ({
        type: ActionsTypes.POST_ITEM_KIOSK_CHECK_ORDER_VALIDATION,
        payload: {
            id,
            idPromo
        },
    }),
    postItemKioskCheckOrderValidationSuccess: (itemKiosk) => ({
        type: ActionsTypes.POST_ITEM_KIOSK_CHECK_ORDER_VALIDATION_SUCCESS,
        payload: {
            itemKiosk,
        },
    }),
    postItemKioskCheckOrderValidationFailure: (error) => ({
        type: ActionsTypes.POST_ITEM_KIOSK_CHECK_ORDER_VALIDATION_FAILURE,
        payload: {
            error,
        },
    }),
// ===========================================================================


    getItemsKioskByFamily: () => ({
        type: ActionsTypes.GET_ITEMS_KIOSK_BY_FAMILY,
        payload: {},
    }),
    getItemsKioskByFamilySuccess: (itemsKiosk) => ({
        type: ActionsTypes.GET_ITEMS_KIOSK_BY_FAMILY_SUCCESS,
        payload: {
            itemsKiosk,
        },
    }),
    getItemsKioskByFamilyFailure: (error) => ({
        type: ActionsTypes.GET_ITEMS_KIOSK_BY_FAMILY_FAILURE,
        payload: {
            error,
        },
    }),
    startUpdateItemsFilterByInput: (text) => ({
        type: ActionsTypes.START_UPDATE_ITEMS_FILTER_BY_INPUT,
        payload: {
            text,
        },
    }),
    updateItemsFilterByInput: (text) => ({
        type: ActionsTypes.UPDATE_ITEMS_FILTER_BY_INPUT,
        payload: {
            text,
        },
    }),
    updateItemsSortBy: (id) => ({
        type: ActionsTypes.UPDATE_ITEMS_SORT_BY,
        payload: {
            id,
        },
    }),
};

export default itemsKiosk;