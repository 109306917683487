import moment from "moment";

let endDay = moment().endOf("day").toDate();

let startDay = moment().subtract(7, "days").startOf("day").toDate();

const initialState = {
  globalLoading: {
    loading: false,
    count: 0,
  },
  languages: {
    items: [],
    translations: {},
    isLoading: false,
    error: false,
    scope: "",
    selectedLanguage: null,
  },
  userSettings: {
    isLoading: false,
    items: [],
    error: false,
    countIsTrue: 0,
  },
  user: {
    isLoading: true,
    error: {},
    identity: {
      kioskSelected: {},
    },
    isConnected: false,
    familySelected: {},
    datePicker: {
      startDate: startDay,
      endDate: endDay,
    },
    path: false,
    forgotPassword: 0,
  },
  families: {
    items: [],
    isLoading: false,
    error: {},
  },
  itemsKiosk: {
    isLoading: false,
    error: {},
    items: [],
    selectedItem: {},
    filter: {
      textFilter: "",
      sortBy: [
        {
          id: 1,
          label: "page__itemCatalogue__sortBy_AtoZ",
          active: false,
        },
        {
          id: 2,
          label: "page__itemCatalogue__sortBy_ZtoA",
          active: false,
        },
        {
          id: 3,
          label: "page__itemCatalogue__sortBy_priceAsc",
          active: false,
        },
        {
          id: 4,
          label: "page__itemCatalogue__sortBy_priceDesc",
          active: false,
        },
        {
          id: 5,
          label: "page__itemCatalogue__sortBy_stockAsc",
          active: false,
        },
        {
          id: 6,
          label: "page__itemCatalogue__sortBy_stockDesc",
          active: false,
        },
        {
          id: 7,
          label: "page__itemCatalogue__sortBy_promo",
          active: false,
        },
      ],
    },
  },
  salesOrders: {
    isLoading: false,
    error: false,
    generatedCode: false,
  },
  salesHistories: {
    isLoading: false,
    error: false,
    items: false,
    contractURL: {
      items: "",
      error: false,
    },
    receipt: {},
  },
  codesHistories: {
    isLoading: false,
    error: false,
    items: false,
  },
  itemsStorePrices: {
    isLoading: false,
    error: false,
    items: false,
  },
  promoItemStore: {
    isLoading: false,
    error: false,
    items: false,
    statusFilter: [
      {
        id: 3,
        label: "page__priceManagement__tablePromo_statusFilter_inProgress",
        active: true,
      },
      {
        id: 2,
        label: "page__priceManagement__tablePromo_statusFilter_planified",
        active: true,
      },
      {
        id: 4,
        label: "page__priceManagement__tablePromo_statusFilter_ended",
        active: false,
      },
    ],
  },
  kiosksManager: {
    isLoading: false,
    error: false,
    items: false,
  },
  myAccount: {
    isLoading: false,
    error: false,
    items: false,
  },
  userDetail: {
    isLoading: false,
    error: false,
    items: false,
    update: false,
  },
  createPromoItemsStore: {
    isLoading: false,
    error: false,
    items: false,
    checkedItems: false,
    isStarted: null,
    isValid: true,
  },
  paymentCollection: {
    isLoading: false,
    error: false,
    items: false,
    selected: null,
    detail: null,
    excluded: null,
  },
  itemsKiosksStock: {
    isLoading: false,
    error: false,
    items: false,
  },
  modal: {
    key: false,
    title: "",
    level: "",
    show: false,
    errorMsgParams: [],
    path: "",
  },
  error: {
    status: "",
    msg: "",
  },
  logs: {},
  dashBoard: {
    isLoading: false,
    error: false,
    items: [],
    tables: {},
  },
  adminUser: {
    isLoading: false,
    error: false,
    items: [],
  },
  logsHistory: {
    isLoading: false,
    error: false,
    items: [],
    filter: [],
    filterLevel: [],
  },
  exportData: {
    isLoading: false,
    error: false,
  },
  /*     wareHouseStockManagement:{
        isLoading: false,
        error: false,
        items:[],
    }, */
};

export default initialState;
