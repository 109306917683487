import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import { cloneDeep, find, forEach } from 'lodash'

extendUpdate(update);

const myAccount = {

    [ActionsTypes.GET_USER_DETAIL]: (state) => update(state, {
        userDetail: {
            isLoading: {
                $set: true,
            },
            error: {
                $set: false,
            }
        }
    }),
    [ActionsTypes.GET_USER_DETAIL_SUCCESS]: (state, payload) => {
        return update(state, {
            userDetail:{
                isLoading: {
                    $set: false,
                },
                items: {
                    $set: payload.userDetail
                },
                error: {
                    $set: false
                },
            },

        })        
    },
    [ActionsTypes.GET_USER_DETAIL_FAILURE]: (state, payload) => update(state, {
        userDetail:{
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
        },
    }),
    [ActionsTypes.UPDATE_USER_DETAIL]: (state) => update(state, {
        userDetail: {
            isLoading: {
                $set: true,
            },
        }
    }),
    [ActionsTypes.UPDATE_USER_DETAIL_SUCCESS]: (state, payload) => {
        const user          = payload.user;
        const currentUser   = cloneDeep(state.user.identity);

        // update current user if user selected is himself
        if(user.id === currentUser.id){
            currentUser['firstName']                = user.firstName;
            currentUser['lastName']                 = user.lastName;
            currentUser['email']                    = user.email;
            currentUser['phoneNumber']              = user.phoneNumber;
            currentUser['preferedLanguage']         = user.preferedLanguage;
            currentUser['userKiosks']               = user.userKiosks;
            currentUser['kioskSelected']            = user.userKiosks[0].idKiosk
        }
        return update(state, {
            user: {
                identity: {
                    $set: {...currentUser}
                }
            },
            userDetail:{
                isLoading: {
                    $set: false,
                },
                items: {
                    $set: user
                },
                error: {
                    $set: false
                },
            },

        })
    },
    [ActionsTypes.UPDATE_USER_DETAIL_FAILURE]: (state, payload) => update(state, {

        userDetail:{
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error
            },
        },
    }),


    [ActionsTypes.USER_DETAIL_RESET_PASSWORD]: (state) => update(state, {
        userDetail: {
            isLoading: {
                $set: true,
            },
            error: {
                $set: false
            },
        }
    }),
    [ActionsTypes.USER_DETAIL_RESET_PASSWORD_SUCCESS]: (state,) => {

        return update(state, {
            userDetail:{
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: false
                },
            },

        })
    },
    [ActionsTypes.USER_DETAIL_RESET_PASSWORD_FAILURE]: (state, payload) => update(state, {

        userDetail:{
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error
            },
        },
    }),

    [ActionsTypes.POST_USER_CREATE]: (state) => update(state, {
        userDetail: {
            isLoading: {
                $set: true,
            },
            update: {
                $set : false,
            }
        }
    }),
    [ActionsTypes.POST_USER_CREATE_SUCCESS]: (state, payload) => {
        const oldUser = cloneDeep(state.userDetail.items);
        const newUser = payload.user;

        oldUser.userRole = find(oldUser.roles, {id : parseInt(newUser.idRole) });
        forEach(oldUser.kiosks, (elem) => {
            forEach(newUser.kiosks, (item) => {

                elem.isSelected = item === elem.id;
            } )

        });
        oldUser.firstName = newUser.firstName;
        oldUser.lastName = newUser.lastName;
        oldUser.phoneNumber = newUser.phoneNumber;
        oldUser.userName = newUser.userName;
        oldUser.email = newUser.email;

        return update(state, {
            userDetail:{
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: false
                },
                items: {
                    $set : oldUser
                },
                update:{
                    $set : true
                }
            },
        })
    },
    [ActionsTypes.POST_USER_CREATE_FAILURE]: (state, payload) => {

        return update(state, {
            userDetail:{
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: payload.error
                }
            },
        })
    },
};

export default myAccount