import RouteConstants from './RouteConstants'

const userPrivileges = {
  manage: [
    'extranet__store__userInfo_manage', // 0
    'extranet__store__paimentCollectionDetail_manage', // 1
    'extranet__store__salesByCodeHistory_manage', // 2
    'extranet__store__generateCode_manage', // 3
    'extranet__store__itemPrices_manage', // 4
    'extranet__store__promotionDetail_manage', // 5
    'extranet__store__stockManagement_manage', // 6
    'extranet__store__deliveriesDetail_manage', // 7
    'extranet__store__manualRestock_manage', // 8
    'extranet__store__lockerStockCorrection_manage', // 9
    'extranet__store__warehouseStockCorrection_manage', // 10
    'extranet__store__kiosksManagement_manage', // 11
    'extranet__store__kioskDetail_manage', // 12
    'extranet__store__userDetail_manage', // 13
    'extranet__store__logsHistory_manage', // 14
    'extranet__store__myAccount_manage', // 15
    'extranet__store__userSettings_manage', // 16
    'extranet__store__userDetail_action_forcePasswordRenew' // 17
  ],
  viewAutoPath: {
    [RouteConstants.salesHistories]: 'extranet__store__salesHistory_view',
    [RouteConstants.salesHistoriesByItem]: 'extranet__store__salesHistory_view',
    [RouteConstants.salesHistoriesBySales.route]: 'extranet__store__salesHistory_view',
    [RouteConstants.receipt.merchant.route]: 'extranet__store__salesTicket_view',
    [RouteConstants.receipt.customer.route]: 'extranet__store__clientTicket_view',
    [RouteConstants.userConsignementInfo]: 'extranet__store__userInfo_view',
    [RouteConstants.telecollection]: 'extranet__store__paimentCollectionHistory_view',
    [RouteConstants.telecollectionDetail]: 'extranet__store__paimentCollectionDetail_view',
    [RouteConstants.codeHistories]: 'extranet__store__salesByCodeHistory_view',
    [RouteConstants.kioskProduct]: 'extranet__store__itemCatalogue_view',
    [RouteConstants.printTicket.route]: 'extranet__store__itemCatalogue_view',
    [RouteConstants.productDetail.route]: 'extranet__store__itemDetail_view',
    [RouteConstants.home]: 'extranet__store__dashboard_view',
    [RouteConstants.priceManagment]: 'extranet__store__itemPrices_view',
    [RouteConstants.mangeStockKiosk]: 'extranet__store__stockManagement_view',
    [RouteConstants.mangeStockKioskDetail]: 'extranet__store__stockManagement_view',
    [RouteConstants.kioskManagment]: 'extranet__store__kiosksManagement_view',
    [RouteConstants.adminUser]: 'extranet__store__usersManagement_view',
    [`${RouteConstants.userDetail}/:id`]: 'extranet__store__userDetail_view',
    [RouteConstants.logHistory]: 'extranet__store__logsHistory_view',
    [RouteConstants.logHistoryUser.route]: 'extranet__store__logsHistory_view',
    [RouteConstants.logHistoryUsers]: 'extranet__store__logsHistory_view',
    [RouteConstants.createUser]: 'extranet__store__userDetail_manage',
    [RouteConstants.myAccount]: 'extranet__store__myAccount_view',
    [RouteConstants.userSetting]: 'extranet__store__userSettings_view',
    [RouteConstants.promoManager]: 'extranet__store__promotionDetail_manage',
    [RouteConstants.updatePromoManager.route]: 'extranet__store__promotionDetail_manage',
    /* [RouteConstants.warehouseStockManagement]: 'extranet__store__warehouseStockManagement_view', */
  },
  view: [
    'extranet__store__salesHistory_view', // 0
    'extranet__store__salesTicket_view', // 1
    'extranet__store__salesInvoice_view', // 2
    'extranet__store__userInfo_view', // 3
    'extranet__store__paimentCollectionHistory_view', // 4
    'extranet__store__paimentCollectionDetail_view', // 5
    'extranet__store__correctSaleWithProblem_view', // 6
    'extranet__store__depositContract_view', // 7
    'extranet__store__salesByCodeHistory_view', // 8
    'extranet__store__itemCatalogue_view', // 9
    'extranet__store__itemDetail_view', // 10
    'extranet__store__dashboard_view', // 11
    'extranet__store__itemPrices_view', // 12
    'extranet__store__stockManagement_view', // 13
    'extranet__store__warehouseStockManagement_view', // 14
    'extranet__store__deliveriesHistory_view', // 15
    'extranet__store__deliveriesDetail_view', // 16
    'extranet__store__manualRestock_view', // 17
    'extranet__store__lockerStockCorrection_view', // 18
    'extranet__store__warehouseStockCorrection_view', // 19
    'extranet__store__kiosksManagement_view', // 20
    'extranet__store__kioskDetail_view', // 21
    'extranet__store__usersManagement_view', // 22
    'extranet__store__userDetail_view', // 23
    'extranet__store__logsHistory_view', // 24
    'extranet__store__myAccount_view', // 25
    'extranet__store__userSettings_view', // 26
    'extranet__store__menu_sales_view', // 27
    'extranet__store__menu_products_view', // 28
    'extranet__store__menu_stock_view', // 29
    'extranet__store__menu_administration_view', // 30
    'extranet__store__clientTicket_view', // 31
  ],
  export: [
    'extranet__store__salesHistory_export', // 0
    'extranet__store__salesByCodeHistory_export', // 1
    'extranet__store__itemPrices_export', // 2
    'extranet__store__promotions_export', // 3
    'extranet__store__stockManagement_export', // 4
    'extranet__store__warehouseStockManagement_export', // 5
    'extranet__store__logsHistory_export', // 6
    'extranet__store__paimentCollectionHistory_export' // 7
  ],
  analyze: [
    'extranet__store__salesHistory_analyze',
    'extranet__store__salesByCodeHistory_analyze',
    'extranet__store__promotions_analyze'
  ],
};

export default userPrivileges
