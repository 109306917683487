import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import { forEach, assign } from 'lodash'

extendUpdate(update);

const families = {

    [ActionsTypes.GET_FAMILIES_AND_BRANDS_BY_KIOSK]: (state) => {
        return update(state, {
            families: {
                isLoading: {
                    $set: true,
                },
            },
        })
    },
    [ActionsTypes.GET_FAMILIES_AND_BRANDS_BY_KIOSK_SUCCESS]: (state, payload) => {

        forEach(payload.families, (elem) => {
            forEach(elem.brands, (item) => {
                assign(item, {active: true})
            });
            elem.id === 1 ?
                assign(elem, {active: true})
            :
                assign(elem, {active: false})
        });


        return update(state, {
            families: {
                isLoading: {
                    $set: false,
                },
                items:{
                    $set: payload.families,
                },
                error: {
                    $set: false,
                }
            }
        })
    },
    [ActionsTypes.GET_FAMILIES_AND_BRANDS_BY_KIOSK_FAILURE]: (state, payload) => update(state, {
        families: {
            isLoading: {
                $set: false,
            },
            error:{
                $set: payload.error
            }
        }
    }),
    [ActionsTypes.UPDATE_FAMILY_SELECTED]: (state, payload) => {
        const oldState = {...state.families};
    
        forEach(oldState.items, (elem) => {
        
            elem.id === payload.id ?
                elem.active = true
            :
                elem.active = false
        });
        return update(state, {
            families: {
                items: {
                    $set: [...oldState.items]
                }

            }

        })
    },
    [ActionsTypes.UPDATE_BRANDS_SELECTED]: (state, payload) => {
        const oldState = {...state.families};
        
        forEach(oldState.items, (elem) => {
            if (elem.active) {
                forEach(elem.brands, (item) => {
                    if(item.id === parseInt(payload.id)) {
                        item.active = payload.isChecked
                    }
                })
            } 
        });
        return update(state, {
            families: {
                items: {
                    $set: [...oldState.items]
                }
            }

        })
    },
};

export default families