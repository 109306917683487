import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

// import assets
import Close from '../../../../assets/icones/global/close.png'

/////////////////////////////
//  START style
////////////////////////////
const AnimationModal = keyframes`
    from {
      transform: translate(-250%, -50%);
    }
    to {
      transform: translate(-50%, -50%);
    }
`;
const ModalWrapper = styled.div`
    position: absolute;
    max-height: 90%;
    overflow-y: auto;
    left: 50%;
    top: 50%;
    z-index: ${ props => props.critical ? '41' : '20' };
    transform: translate(-50%, -50%);
    animation: ${AnimationModal} ease .5s;
`;
const ModalHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 29px;
    background-color: ${ props => 
        props.color
            ?
                props.theme.colors[props.color]
            :
                props.theme.colors.primary
    };
    padding: 17px 20px;
`;
const HeaderTittle = styled.div`
    font-size: ${ props => props.theme.font.fxxxl};
    color: ${ props => props.theme.colors.white };
    font-weight: 900;
    cursor: default;
    padding-right: 20px;
`;
const CloseImage = styled.img`
    cursor: pointer;
`;
const ModalBodyWrapper = styled.div`
    background-color: ${ props => props.theme.colors.white };
`;
/////////////////////////////
//  END style
////////////////////////////
/**
 *
 * @param {string} headerTittle
 * @param {object} children
 * @param {string} color
 * @param {func} closeModal
 * @param {number} critical
 * @returns {jsx}
 * @constructor
 */
const Modal = ({ headerTittle, children, color, closeModal, critical }) => {
    return (
        <ModalWrapper
            critical={critical}
        >
            {
                headerTittle &&
                    <ModalHeaderWrapper
                        color={color}
                    >
                        <HeaderTittle>
                            {headerTittle}
                        </HeaderTittle>
                        <CloseImage 
                            src={Close} 
                            onClick={closeModal}
                        />
                    </ModalHeaderWrapper>
            }
            <ModalBodyWrapper>
                {children}
            </ModalBodyWrapper>
        </ModalWrapper>
    )
};

Modal.propTypes = {
    headerTittle: PropTypes.string,
    color: PropTypes.string,
    closeModal: PropTypes.func,
    children: PropTypes.object,
    critical: PropTypes.number,
};

export default Modal
