import React, { Component,  } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { map, find } from 'lodash'

import { Transition } from "react-transition-group";

//////////////////////////////
// START Component style
/////////////////////////////

const DropdownButton = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: ${props => props.width + "px" };
    border-radius: 3px;
    text-transform: ${props => props.upper ? 'uppercase' : 'capitalize'};
    background-color: ${props => props.theme.colors.primary};
    cursor: pointer;
    margin: ${ props => props.margin ? '0 16px' : 'none'};
    padding: ${ props => props.padding ? `0 ${props.padding}px` : '0 5px' };
`;
const ContentCheckBoxWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr min-content;
    justify-items: center;
    align-items: center;
    grid-column-gap: 5px;
    width: 100%;
    height: 100%;
`;
const DownArrow = styled.div`
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid ${ props => props.theme.colors.white };
    transition: all .5s ease;
    transform: rotate( ${ props => props.open ? 180 : 0 }deg );
`;
const TextButton = styled.p`
    margin: 0;
    padding: ${ props => props.paddingText ? `0 ${props.paddingText}px` : '0 5px' };
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.font.fm};
`;
const Paper = styled.div`
    position: absolute;
    display: grid;
    grid-template-columns: max-content;
    grid-auto-rows: 40px;
    justify-content: stretch;
    align-items: stretch;
    background-color: ${ props => props.theme.colors.white };
    border: 1px solid ${ props => props.theme.colors.borderTable };
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    padding: 0 20px;
    top: ${props => props.topPosition + "px" };
    right: ${ props => props.rtl ? '0' : 'inherit' };
    left: ${ props => props.rtl ? 'inherit' : '0' };
    z-index: 1;
    overflow: hidden;
    transition: all 300ms;
    transform-origin: top;
    transform: scaleY(${ ( { menuTransition } ) => {
        switch ( menuTransition ) {
            case "entering":
                return "0"
            case "entered":
                return "1"
            case "exiting":
                return "0"
            case "exited":
                return "0"
            default:
                return "0"
        }
    } });
`;
const PaperList = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
`;

/////////////////////////////
// END component Style
////////////////////////////

/**
 * @param {array}       data
 * @param {string}      width
 * @param {boolean}     upper
 * @param {function}    selectActionsHandler
 * @param {string}      paperTopPosition
 */
export class Dropdown extends Component {
    static propTypes = {
        PaperTopPosition:           PropTypes.string,
        width:                      PropTypes.string,
        data:                       PropTypes.array,
        upper:                      PropTypes.bool,
        selectActionsHandler:       PropTypes.func,
    };

    state = {
        showPaper: false,
    };
    refCollectionDropdown = {};

    showPaperHandler = () => {
        this.setState({
            showPaper: true
        });

        document.addEventListener('click', this.closePaperHandlerDropdown);
    };

    componentWillUnmount = () => {
        document.removeEventListener('click', this.closePaperHandlerDropdown);
    };

    closePaperHandlerDropdown = (evt) => {
        
        if(this.dropdown.contains(evt.target)) {
            map(this.refCollectionDropdown, (elem) => {
                if(elem.contains(evt.target)){
                    this.props.selectActionsHandler(elem.id)
                }
            })
            
        }
        document.removeEventListener('click', this.closePaperHandlerDropdown);
        this.setState({
            showPaper: false
        })
    };

    render() {
        const {
            data,
            width,
            PaperTopPosition,
            upper,
            title,
            translations,
            toTranslate,
            rtl,
            paddingText,
        } = this.props;

        const dataActive    =  find(data, { 'active': true});
        const buttonText    = title;
        const paperList     = map(data, (elem, id) => {
            return (
                <PaperList
                    key={id}
                    id={elem.id}
                    name={elem.label}
                    ref={(element) => {
                        this.refCollectionDropdown[elem.label] = element
                    }}
                >
                    {
                        toTranslate ?
                            translations[elem.label]
                        :
                            elem.label
                    }
                </PaperList>
            )
        });
        return (
            <DropdownButton
                width={width}
                upper={upper}
            >
                <ContentCheckBoxWrapper
                    onClick={this.showPaperHandler}
                >
                    <TextButton
                        paddingText={paddingText}
                    >
                        {
                            buttonText ?
                                buttonText
                                :
                                dataActive && dataActive.label
                        }
                    </TextButton>
                    <DownArrow
                        open={this.state.showPaper}
                    />
                </ContentCheckBoxWrapper>
                <Transition
                    in={ this.state.showPaper }
                    timeout={ {
                        appear: 0,
                        enter: 0,
                        exit: 300,
                    } }
                    mountOnEnter
                    unmountOnExit
                >
                    { state => (
                        <Paper
                            topPosition={ PaperTopPosition }
                            ref={ ( element ) => {
                                this.dropdown = element
                            } }
                            menuTransition={ state }
                            rtl={rtl}
                        >
                            { paperList }
                        </Paper>
                    ) }
                </Transition>
            </DropdownButton>
        )
    }
}

export default Dropdown
