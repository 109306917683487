import React, { Component } from 'react';
import { withRouter } from "react-router";
import { find, forEach } from 'lodash'

import { ReactComponent as LogoBackground } from "../../../assets/images/logoBackground.svg";
import { ReactComponent as Logo } from "../../../assets/images/logoLogin.svg";
import FlagEng from '../../../assets/icones/global/frenchFlag.svg'
import FlagFr from '../../../assets/icones/global/englishFlag.svg'
import styled from "styled-components";
import Button from "../../../components/Ui/Buttons";



//////////////////////////////
// START Component style
/////////////////////////////

// layout page login
const LoginLayout = styled.div`
    @import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i,900&display=swap');
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    position: relative;
    width: 1024px;
    margin: auto;
`;
// logo component
const ImgContent = styled.div`
    display: inherit;
    align-content: center;
    margin-bottom: 100px;
    margin-top: 186px
`;
// svg bottom left component
const ImgBackground = styled.div`
    position: absolute;
    width: 314px;
    height: 424px;
    top: 343px;
    right: 702px;
    overflow: hidden;
`;
const LoginWrapper = styled.div`
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const TextWrapper = styled.div`
    margin-bottom: 30px;
`;
const TextAndButton = styled.div`
    display: inherit;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 300px;
    h3 {
        text-align: center;
    }
`;
const ButtonTryAgainWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    height: 45px;
`;
const FlagWrapper = styled.div`
    display: flex;
    align-content: center;
    justify-content: space-evenly;
    width: 195px;
    margin-top: 90px;
`;
const FlagImg = styled.img`
    max-width: 26px;
    max-height: 17px;
`;
const FlagImgWrapper = styled.div`
    width: 26px;
    height: 17px;
`;
/////////////////////////////
// END component Style
////////////////////////////

class CriticalError extends Component {

    state = {
        french: {
            id: 1,
            isSelected: true,
            title: 'Erreur interne Serveur !',
            body: `Veuillez nous excuser pour ce désagrément.
                    Nos équipes support ont été informées et tentent de régler le problème au plus vite.
                    Veuillez réessayer de nouveau ou contacter votre support si le problème persiste.`,
            btn : 'Réessayer'

        },
        english: {
            id: 2,
            isSelected: false,
            title: 'Internal Server Error!',
            body: `Please accept our apologize for this issue.
                    Our Support team has been informed and is trying to solve the issue asap.
                    Please try again or contact your support if the problem persists.`,
            btn : 'Try again'
        },

    };

    switchLanguage = (id) => {
        const idLanguage = id;
        const oldState  = this.state;

        forEach(oldState, ( elem ) => {
            elem.isSelected = elem.id === idLanguage;
        });

        this.setState({...oldState})
    };


    render() {
        const translationText = find(this.state, { isSelected : true});

        return (
            <LoginLayout>

                <ImgBackground>
                    <LogoBackground/>
                </ImgBackground>

                <LoginWrapper>

                    <ImgContent>
                        <Logo/>
                    </ImgContent>

                    <TextAndButton>
                        <TextWrapper>
                            <h3>
                                {translationText .title}
                            </h3>
                            <p>
                                {translationText .body}
                            </p>
                        </TextWrapper>

                        <ButtonTryAgainWrapper>
                            <Button
                                actionHandler={() => this.props.history.push('/home')}
                                text={translationText.btn}
                            />
                        </ButtonTryAgainWrapper>
                    </TextAndButton>

                    <FlagWrapper>
                        <FlagImgWrapper
                            onClick={() => this.switchLanguage(2)}
                        >
                            <FlagImg src={FlagEng}/>
                        </FlagImgWrapper>
                        <FlagImgWrapper
                            onClick={() => this.switchLanguage(1)}
                        >
                            <FlagImg src={FlagFr}/>
                        </FlagImgWrapper>
                    </FlagWrapper>

                </LoginWrapper>



            </LoginLayout>
        );
    }
}

export default withRouter(CriticalError);