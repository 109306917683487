import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { filter, find, map, merge } from 'lodash'

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { stockManagerActionCreators } from '../../state/actions';
import { selectKioskSelected } from '../../state/selectors/user'
import {
    selectBrandsFamilies,
    selectFamilySelected,
    selectItemsByFamilyByBrands,
} from "../../state/selectors/stockManager";

// component import
import TableLayout from '../TableLayout'
import TableCheckboxEditable from '../../Hoc/TableCheckboxEditable'
import ActionButton from '../../components/Ui/Buttons'
import UserAuthorizationLayout from '../../components/UserAuthorizationLayout'
import userPrivileges from '../../constants/RoleList'
import ExportData from '../../components/ExportData/ExportData';
import CR from '../../constants/RouteConstants';
import Button from '../../components/Ui/Buttons';

//assets import
import Edit from '../../assets/icones/global/edit.png'
import WhiteEdit from '../../assets/icones/global/white-edit.png'
import Validation from '../../assets/icones/global/validation.png'
import Dropdown from "../../components/Ui/Dropdown/Dropdown";
import DropdownCheckbox from "../../components/Ui/DropdownCheckbox";

// style
import {
    PageWrapper,
    LineTableHeader,
    TitleHeader,
    ActionWrapper,
    TitleHeaderTable,
    ContentTableWrapper,
    ImgSized,
    BoxValidation,
    DisabledValidation,
    BoxValidationWrapper,
    BoxCanceled,
} from "../../theme/GlobalCss";
import theme from "../../theme/theme";


/////////////////////////////
//  START style
////////////////////////////

/////////////////////////////
//  END style
////////////////////////////

export class StockManager extends Component {
    static propTypes = {
        actionsStockManager:        PropTypes.object,
        cancelActionHandler:        PropTypes.func,
        checkboxChangeHandler:      PropTypes.func,
        dataTable:                  PropTypes.object,
        editAllCancelHandler:       PropTypes.func,
        editAllHandler:             PropTypes.func,
        editAllValidationHandler:   PropTypes.func,
        editInputHandler:           PropTypes.func,
        editedOnCLickHandler:       PropTypes.func,
        hydrateStateHandler:        PropTypes.func,
        validationActionHandler:    PropTypes.func,
        itemsKiosksStock:           PropTypes.object,
        refCollection:              PropTypes.array,
        kioskSelected:              PropTypes.object,
        getDateFormat:              PropTypes.func,
    };
    ///////////////////////////////////////////////////
    ///////     START INIT COMPONENT    ///////////////
    ///////////////////////////////////////////////////

    componentDidMount = () => {
        this.getProductStock()
    };
    getProductStock = () => {
        const { actionsStockManager, match } = this.props;
        const idItem = match.params.id || false;

        actionsStockManager.getItemsKiosksStock(idItem);
    };

    componentDidUpdate(prevProps) {
        const { itemsFiltered, hydrateStateHandler, match, kioskSelected, translations } = this.props;

        if(match.path !== prevProps.match.path) {
            this.getProductStock()
        }
        if( itemsFiltered !== prevProps.itemsFiltered ) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            hydrateStateHandler(data)
        }
        if ( kioskSelected !== prevProps.kioskSelected ) {
            this.getProductStock()
        }
        if( translations !== prevProps.translations) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            hydrateStateHandler(data)
        }
    }
    ///////////////////////////////////////////////////
    ///////     END INIT COMPONENT    /////////////////
    ///////////////////////////////////////////////////



    /**
     * data object to row layout
     */
    bodyData = () => {
        const {
            itemsFiltered,
            editedOnCLickHandler,
            translations,
        } = this.props;
        const header = [
            {name: 'CHECKBOX',useName: 'checkBox', isChecked: false},
            {name: translations['page__stockManagement__itemTable_tableHeader_item'], useName: 'label', sortTable: true, },
            {name: translations['page__stockManagement__itemTable_tableHeader_capacity'], useName: 'kioskCapacity', sortTable: true, },
            {name: translations['page__stockManagement__itemTable_tableHeader_alert'], useName: 'alertStock', sortTable: true, },
            {name: translations['page__stockManagement__itemTable_tableHeader_lockerQty'].split("\\n"), useName: 'lockerStock', sortTable: true, },
            {name: translations['page__stockManagement__itemTable_tableHeader_warehouseQty'], useName: 'bufferStock', sortTable: true, },
            {name: translations['page__stockManagement__itemTable_tableHeader_emptyErrorQty'].split("\\n"), useName: 'emptyStock', sortTable: true, },
            {name: translations['page__stockManagement__itemTable_tableHeader_reservedQty'], useName: 'reservedStock', sortTable: true, },
            {name: translations['page__stockManagement__itemTable_tableHeader_avalaibleQty'].split("\\n"), useName: 'advailableStock', sortTable: true, },
            {name: translations['common__text__actions'], useName: 'actions'},
        ];

        return{
            header: header,
            body:   map(itemsFiltered, (elem,) => {
                const alertStock = parseInt(elem.alertStock);
                const colorLocker =
                        (elem.lockerStock <= alertStock && elem.lockerStock > 0)
                            ? theme.colors.warning
                            : elem.lockerStock === 0
                                ? theme.colors.error
                                : 'none';
                return ({
                    id: elem.id,
                    elements: [
                        {name :'checkBox', value: '', isChecked: false},
                        {name :'label', value: elem.label, sortValue: elem.label, },
                        {name :'kioskCapacity', value: elem.kioskCapacity, sortValue: elem.kioskCapacity, },
                        {name :'alertStock', value: alertStock, editable:true, active:false, sortValue: alertStock, },
                        {name :'lockerStock', value: elem.lockerStock, bcg: colorLocker, color: colorLocker !== 'none' ? 'white' : 'black', sortValue: elem.lockerStock,  },
                        {name :'bufferStock', value: elem.bufferStock, sortValue: elem.bufferStock, },
                        {name :'emptyStock', value: `${elem.emptyStock} + ${elem.anomalyStock}`, sortValue: `${elem.emptyStock} + ${elem.anomalyStock}`, },
                        {name :'reservedStock', value: elem.reservedStock, sortValue: elem.reservedStock, },
                        {name :'advailableStock', value: elem.advailableStock, sortValue: elem.advailableStock, },
                        {
                            name: 'actions',
                            display: 'flex',
                            value:
                                <UserAuthorizationLayout
                                    name={userPrivileges.manage[6]}
                                >
                                    <ImgSized
                                        name='edit'
                                        src={Edit}
                                        id={elem.id}
                                        onClick={editedOnCLickHandler}
                                        width={20}
                                        height={20}
                                        cursor
                                    />
                                    <BoxValidationWrapper
                                        name='validation'
                                        id={elem.id}
                                    >
                                        <BoxValidation
                                            id={elem.id}
                                            onClick={this.validationOneLineOnClickHandler}
                                            name='validate'
                                        >
                                            <ImgSized id={elem.id} src={Validation} width={13} />
                                        </BoxValidation>
                                        <DisabledValidation
                                            id={elem.id}
                                            name='disabled'
                                        >
                                            <ImgSized src={Validation} width={13} />
                                        </DisabledValidation>
                                        <BoxCanceled
                                            id={elem.id}
                                            onClick={(evt) => this.onLineCancelActionHandler(evt, elem)}
                                        >
                                            X
                                        </BoxCanceled>
                                    </BoxValidationWrapper>
                                </UserAuthorizationLayout>
                        },
                    ]
                })
            })
        };
    };

    validationOneLineOnClickHandler = (evt) => {
        const {
            validationActionHandler,
            refCollection,
            actionsStockManager,
        }= this.props;

        const oldState          = {...this.props.dataTable};
        const id                = parseInt(evt.target.id);
        oldState.editOne        = !oldState.editOne;
        const kiosksStockList   = [];

        oldState.bodyData
            .forEach( (elem) => {
                if(elem.id === id) {
                    const editable = filter(elem.elements, { active : true });
                    let kioskStock = {
                        id: elem.id,
                    };

                    let alert;
                    editable
                        .forEach( item => {
                            alert = parseInt(refCollection[elem.id][item.name].children[0].value);
                            merge(kioskStock, {[item.name]: alert});
                            kiosksStockList.push(kioskStock)
                        })
                }

            });
        validationActionHandler(oldState, id);
        actionsStockManager.updateItemsKiosksStock(kiosksStockList)
    };

    ValidationEditAllOneClickHandler = () => {
        const {
            editAllValidationHandler,
            refCollection,
            actionsStockManager,
        }= this.props;

        const oldState      = {...this.props.dataTable};
        const kiosksStockList   = [];
        oldState.editing    = false;
        let alert;

        oldState.bodyData
            .forEach( (elem) => {
                const isChecked = find(elem.elements, { isChecked : true });
                if(isChecked) {
                    const editable = filter(elem.elements, { active : true });
                    let kioskStock = {
                        id: elem.id,
                    };
                    editable
                        .forEach( item => {
                            alert = parseInt(refCollection[elem.id][item.name].children[0].value);
                            merge(kioskStock, {[item.name]: alert});
                            kiosksStockList.push(kioskStock)
                        })
                }

            });

        actionsStockManager.updateItemsKiosksStock(kiosksStockList);
        editAllValidationHandler(oldState)
    };

    cancelEditAllHandler = (data) => {
        const oldState      = {...this.props.dataTable};
        oldState.editing    = false;

        oldState.bodyData
            .forEach( (elem) => {
                const isChecked = find(elem.elements, { isChecked : true });
                let newItem = false;
                if(isChecked) {
                    newItem = find(data, { id : elem.id })
                }
                elem.elements.forEach( (item) => {
                    if (item.editable && newItem){
                        item.value = `< ${newItem[item.name]}`
                    }
                })
            });
        this.props.editAllCancelHandler(oldState)
    };
    onLineCancelActionHandler = (evt, data) => {
        const id            = parseInt(evt.target.id);
        const oldState      = {...this.props.dataTable};
        oldState.editOne    = !oldState.editOne;
        oldState.bodyData
            .forEach( (elem) => {
                if( elem.id === id ) {
                    elem.elements.forEach( (item) => {
                        if (item.editable){
                            item.value = data[item.name]
                        }
                    })
                }
            });
        this.props.cancelActionHandler(oldState, id)
    };

    layoutEditAllAndValidateAll = () => {
        const {
            editAllHandler,
            itemsKiosksStock,
            dataTable,
            translations
        } = this.props;

        let flagToNotEmptyValue = false;
        let isChecked           = false;

        dataTable.bodyData
            .forEach( (elem) => {
                const editable  = filter(elem.elements, { editable : true });
                const checked   = filter(elem.elements, { isChecked : true });

                if(checked.length){
                    isChecked = true
                }
                editable.forEach( (item) => {
                    if(item.value === ""){
                        flagToNotEmptyValue = true
                    }

                })
            });
        return dataTable.editAll ?
            <Fragment>
                <ActionButton
                    actionHandler={ this.ValidationEditAllOneClickHandler }
                    // text="Valider"
                    text={ translations['common__text__save'] }
                    bgColor={theme.colors.validation}
                    margin='true'
                    disabled={ flagToNotEmptyValue }
                />
                <ActionButton
                    actionHandler={ () => this.cancelEditAllHandler( itemsKiosksStock.items ) }
                    // text="Annuler"
                    text={ translations['common__text__cancel'] }
                    bgColor={ theme.colors.cancel }
                />
            </Fragment>
            :
            <UserAuthorizationLayout
                name={ userPrivileges.manage[6] }
            >
                <ActionButton
                    actionHandler={ dataTable.editOne ? null : editAllHandler }
                    img={ WhiteEdit }
                    // text="Éditer en masse"
                    text={ translations['common__text__massChange'] }
                    disabled={ dataTable.editOne || !isChecked }
                />
            </UserAuthorizationLayout>
    };

    selectFamilyHandler = (id) => {
        const { actionsStockManager } = this.props;

        const idItem = parseInt(id);

        actionsStockManager.updateStockFamilySelected(idItem)
    };
    selectBrandsCheckedHandler = (id, isChecked) => {
        const { actionsStockManager } = this.props;

        const idItem = parseInt(id);

        actionsStockManager.updateStockBrandsSelected(idItem, isChecked)
    };

    render() {
        const {
            refCollection,
            itemsKiosksStock,
            dataTable,
            editInputHandler,
            checkboxChangeHandler,
            kioskSelected,
            translations,
            initSortTableHandle,
            brandsList,
            getDateFormat,
        } = this.props;

        return (
            <PageWrapper>
                <LineTableHeader>
                    <TitleHeader>
                        {/* Gestion des stocks */}
                        {translations['page__stockManagement__itemTable_title']}
                    </TitleHeader>
                    <ActionWrapper>
                        <UserAuthorizationLayout
                            name={ userPrivileges.export[4] }
                        >
                            <ExportData
                                text={ translations['common__text__exportData'] }
                                scope={ 'stock' }
                                getDateFormat={ getDateFormat }
                            />
                        </UserAuthorizationLayout>
                        <UserAuthorizationLayout
                            name={ userPrivileges.view[14] }
                        >
                             {/* <Button
                                text={translations['page__stockManagement__itemTable_button_displayWarehouseStock']}
                                link={CR.warehouseStockManagement} 
                            /> */}
                        </UserAuthorizationLayout>
                    </ActionWrapper>
                </LineTableHeader>
                <ContentTableWrapper>
                    <LineTableHeader>
                        <TitleHeaderTable color={ theme.colors.primary }>
                            {`${kioskSelected.idExternOwner} - ${kioskSelected.idExternStore}`}
                        </TitleHeaderTable>
                        <ActionWrapper>
                            <DropdownCheckbox
                                PaperTopPosition='50'
                                data={brandsList}
                                selectActionsHandler={this.selectBrandsCheckedHandler}
                                title={translations['common__text__brand']}
                                paddingText={'25'}
                                rtl
                            />
                            <Dropdown
                                data={itemsKiosksStock.filter}
                                selectActionsHandler={this.selectFamilyHandler}
                                rtl
                                PaperTopPosition='50'
                                paddingText={'25'}
                            />
                        </ActionWrapper>
                        <ActionWrapper>
                            { this.layoutEditAllAndValidateAll() }
                        </ActionWrapper>
                    </LineTableHeader>
                    <TableLayout
                        headerData={dataTable.headerData}
                        bodyData={dataTable.bodyData}
                        column={dataTable.headerData.length}
                        refCollection={refCollection}
                        editInputHandler={editInputHandler}
                        checkboxChangeHandler={checkboxChangeHandler}
                        editAllActive={dataTable.editAll}
                        initSortTableHandle={initSortTableHandle}
                        translations={translations}
                    />
                </ContentTableWrapper>
            </PageWrapper>

        )
    }
}

const mapStateToProps = state => {
    return {
        itemsKiosksStock: state.itemsKiosksStock,
        itemsFiltered : selectItemsByFamilyByBrands(state),
        kioskSelected : selectKioskSelected(state),
        familySelected: selectFamilySelected(state),
        brandsList: selectBrandsFamilies(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionsStockManager: bindActionCreators(stockManagerActionCreators, dispatch),
    };
};

export default TableCheckboxEditable(connect(mapStateToProps, mapDispatchToProps)(StockManager))
