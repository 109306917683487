import ActionsTypes from '../../constants/ActionsTypes';

const dashBoard = {
    getDashboardSetting: ( ) => ({
        type: ActionsTypes.GET_DASHBOARD_SETTINGS,
        payload: {},
    }),
    getDashboardSettingSuccess: (dashBoardSetting) => ({
        type: ActionsTypes.GET_DASHBOARD_SETTINGS_SUCCESS,
        payload: {
            dashBoardSetting,
        },
    }),
    getDashboardSettingFailure: (error) => ({
        type: ActionsTypes.GET_DASHBOARD_SETTINGS_FAILURE,
        payload: {
            error,
        },
    }),
    updateDashBoardSelected: (id, isChecked) => ({
        type: ActionsTypes.UPDATE_DASHBOARD_SETTINGS_SELECTED,
        payload: {
            id,
            isChecked,
        },
    }),

    putDashboardSetting: ( dateFormat ) => ({
        type: ActionsTypes.PUT_DASHBOARD_SETTINGS,
        payload: {dateFormat},
    }),
    putDashboardSettingSuccess: (dashBoardSetting) => ({
        type: ActionsTypes.PUT_DASHBOARD_SETTINGS_SUCCESS,
        payload: {
            dashBoardSetting,
        },
    }),
    putDashboardSettingFailure: (error) => ({
        type: ActionsTypes.PUT_DASHBOARD_SETTINGS_FAILURE,
        payload: {
            error,
        },
    }),
    getDashboard: ( datesRange, timeZoneID ) => ({
        type: ActionsTypes.GET_DASHBOARD,
        payload: {datesRange, timeZoneID},
    }),
    getDashboardSuccess: (dashBoard) => ({
        type: ActionsTypes.GET_DASHBOARD_SUCCESS,
        payload: {
            dashBoard,
        },
    }),
    getDashboardFailure: (error) => ({
        type: ActionsTypes.GET_DASHBOARD_FAILURE,
        payload: {
            error,
        },
    }),
    updateDashboardSettingOrder: ( idItem, startIdx, endIdx ) => ({
        type: ActionsTypes.UPDATE_DASHBOARD_SETTINGS_ORDER,
        payload: {
            idItem,
            startIdx,
            endIdx,
        },
    }),
};

export default dashBoard;