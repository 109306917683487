import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TableLayoutHeaders from './TableLayoutHeaders';
import TableLayoutRows from './TableLayoutRows';
import { connect } from 'react-redux'
import EmptyRowBody from './EmptyRowBody';
import { reject, forEach, isEmpty } from 'lodash'

/////////////////////////////
//  START style
////////////////////////////
const TableWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(${ props => props.gridColumns }, minmax(max-content, 1fr));
    grid-auto-rows: auto;
    justify-content: start;
    align-items: center;
    font-size: ${ props => props.theme.font.fs };
    width: 100%;
    transition: all .5s ease;
    overflow-x: auto;
`;

const TableHandler = styled.div`
    position: relative;
    width: 100%;
`

const TableResultsLine = styled.div`
    margin: 15px;
    font-weight:bold;
`
/////////////////////////////
//  END style
////////////////////////////

const TableLayout = 
    ({ 
        headerData,
        bodyData,
        refCollection,
        editInputHandler,
        checkboxChangeHandler,
        column,
        nested,
        initSortTableHandle,
        translations
    }) => {
        if( isEmpty(headerData) && isEmpty(bodyData) ) {
            return (
                    <TableWrapper
                        gridColumns={1}
                        nested={nested}
                    >
                        <EmptyRowBody />
                    </TableWrapper>
                )
        }

        // create new data without null
        const headerFiltered    = reject(headerData, {name: null});
        const bodyFiltered      = forEach(bodyData, (item) => {
            item.elements       = reject(item.elements, {value: null})
        });
        // if column is defined table have this number of column otherwise header length is taken or finaly is set to 1
        const tableColumn       = column ? column : headerFiltered.length ? headerFiltered.length : 1;

        return(
            <TableHandler>
                {
                    !nested && bodyFiltered.length > 0 && <TableResultsLine>{translations["common__text__tableRowsCount"]+" : "}{bodyFiltered.length}</TableResultsLine>
                }             
                <TableWrapper
                    gridColumns={tableColumn}
                    nested={nested}
                >
                    {
                        // if no header return just blank div
                        headerFiltered.length &&
                                <TableLayoutHeaders 
                                    header={headerFiltered}
                                    checkboxChangeHandler={checkboxChangeHandler}
                                    nested={nested}
                                    initSortTableHandle={initSortTableHandle}
                                />
                    }
                    {
                        // if body is empty layout a component which take all table size
                        bodyFiltered.length
                            ?
                                <TableLayoutRows 
                                    data={bodyFiltered}
                                    nested={nested}
                                    refCollection={refCollection}
                                    editInputHandler={editInputHandler}
                                    checkboxChangeHandler={checkboxChangeHandler}
                                />
                            :
                                <EmptyRowBody />
                    }
                </TableWrapper>
            </TableHandler>
        )
};

TableLayout.propTypes = {
    headerData: PropTypes.array,
    bodyData: PropTypes.array,
    refCollection: PropTypes.array,
    editInputHandler: PropTypes.func,
    checkboxChangeHandler: PropTypes.func,
    nested: PropTypes.bool,
};

export default TableLayout
