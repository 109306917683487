import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

//////////////////////////////
///////// START STYLE ////////
//////////////////////////////

const SrcImagesWrapper = styled.img`
  max-width: ${(props) => props.width + "px"};
  //max-height: ${(props) => props.height + "px"};
  box-sizing: border-box;
  cursor: ${(props) => (props.pointer ? "pointer" : "default")};
`;

//////////////////////////////
///////// END STYLE //////////
//////////////////////////////

/**
 *
 * 2 layout possible
 * if noAction create img with onClick disabled
 * else is use for flag to change language
 *
 * @param src           img on base64
 * @param width         with off img
 * @param height        heigth off img
 * @param idElem        id off element clic
 * @param click         action on clic
 * @param scope         target for language
 * @param noAction      bool to switch languages or just decode
 * @returns {*}
 * @constructor
 */
function SrcImages({
  src,
  width,
  height,
  idElem,
  click,
  scope,
  noAction,
  additionnalStyle = {},
}) {
  return !noAction ? (
    <SrcImagesWrapper
      onClick={() => click(idElem, scope)}
      src={src}
      width={width}
      height={height}
      style={additionnalStyle}
    />
  ) : (
    <SrcImagesWrapper
      src={src}
      width={width}
      style={additionnalStyle}
      pointer
      // height={height}
    />
  );
}

SrcImages.propTypes = {
  src: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  idElem: PropTypes.number,
  click: PropTypes.func,
  scope: PropTypes.string,
  noAction: PropTypes.bool,
};

export default SrcImages;
