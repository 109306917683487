import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router";
import { isEmpty, isUndefined } from 'lodash'


// redux import
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { languagesActionCreators } from '../../state/actions';

import {
    selectLanguagesDefault,
    selectTranslations,
} from "../../state/selectors/languages";

/////////////////////////////
//  START style
////////////////////////////

/////////////////////////////
//  END style
////////////////////////////


export default function (ComposedComponent) {
    class TranslationHandler extends Component {
        static propTypes = {
            translations:      PropTypes.object,
        };
        // TODO: refactor with userIdentity test
        componentDidMount() {
            const { languages, match, defaultLanguages, userIdentity } = this.props;
            if( !languages.items.length > 0) {
                this.getLanguagesEnabled()
            }
            if(languages.items.length){
                if( languages.scope === 'public' &&
                    match.path !== '/login'
                ){
                    this.changeLanguageHandler(userIdentity.preferedLanguage.id, 'private')
                }else{
                    this.changeLanguageHandler(defaultLanguages.id, 'public')
                }
            }
        }
        // TODO: refactor test to check if userIdentity
        componentDidUpdate( prevProps ) {
            const { languages, defaultLanguages, match, userIdentity } = this.props;
            if( (prevProps.languages.items !== languages.items) ) {
                if(match.path !== '/login'){
                    this.changeLanguageHandler(userIdentity.preferedLanguage.id, 'private')
                }else{
                    this.changeLanguageHandler(defaultLanguages.id, 'public')
                }
            }
            if( !isUndefined(userIdentity.preferedLanguage) ){
                if( userIdentity.preferedLanguage.id !== prevProps.userIdentity.preferedLanguage.id ) {
                    if(match.path !== '/login'){
                        this.changeLanguageHandler(userIdentity.preferedLanguage.id, 'private')
                    }
                }
            }
        }

        getLanguagesEnabled = () => {
            const { languagesActions } = this.props;
            languagesActions.getLanguagesEnabled()
        };

        /**
         *
         * @param   {string}        translationKey
         * @returns {string}        value in translation object on 'key'
         */
        getTranslation = (translationKey) => {
            const { translations } = this.props;
            return translations[translationKey] || 'Pas de traduction'
        };// end function

        /**
         * call to change user languages
         * @param   {string}        idLanguage
         * @param   {string}        scope for language ( e.g 'public' || 'private' )
         */
        changeLanguageHandler = ( idLanguage, scope ) => {
            const { languagesActions } = this.props;
            if( scope === 'private'){
                languagesActions.getTranslationByLanguages(idLanguage)
            } else {            

                languagesActions.getTranslationPublicPage(idLanguage)
            }
        };// end function


        render() {
            const { languagesList, languages } = this.props;
            if( languages.isLoading ){
                return <div></div>
            }
            if( isEmpty(languages.translations) && !languages.isLoading ){
                return <div> Translation in this languages are null </div>
            }
            return (
                <ComposedComponent
                    getTranslation={this.getTranslation}
                    changeLanguageHandler={this.changeLanguageHandler}
                    getLanguagesEnabled={this.getLanguagesEnabled}
                    languagesList={languagesList}
                    { ...this.props }
                />
            )
        }
    }

    const mapStateToProps = state => {
        return {
            translations:           selectTranslations(state),
            languagesList:          state.languages.items,
            languages:              state.languages,
            defaultLanguages:       selectLanguagesDefault(state),
            userIdentity:   state.user.identity
        };
    };

    const mapDispatchToProps = dispatch => ({
        languagesActions    : bindActionCreators(languagesActionCreators, dispatch),
    });

    return withRouter(connect(mapStateToProps, mapDispatchToProps)(TranslationHandler))
}

