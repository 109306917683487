import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';

extendUpdate(update);

const adminUser = {

    [ActionsTypes.GET_EXPORT_DATA]: (state) => update(state, {
        exportData: {
            isLoading: {
                $set: true,
            },
            error: {
                $set: false
            },
        }
    }),
    [ActionsTypes.GET_EXPORT_DATA_SUCCESS]: (state) => {
        return update(state, {
            exportData:{
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: false
                },
            },

        })        
    },
    [ActionsTypes.GET_EXPORT_DATA_FAILURE]: (state, payload) => {

        return update(state, {
            exportData:{
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: payload.error
                },
            },

        })
    },


};

export default adminUser