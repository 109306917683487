import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { find, replace } from 'lodash'

// import components
import ModalActionWrapper from "../ModalActionsWrapper";
import UserAuthorizationLayout
    from "../../../../components/UserAuthorizationLayout";
import userPrivileges from "../../../../constants/RoleList";
import CR from "../../../../constants/RouteConstants";
import Theme from '../../../../theme/theme'
import StringFormater from '../../../../helpers/StringFormater';

// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { adminUserActionCreators } from "../../../../state/actions";
import ModalSummerize from "../../ModalSummerize";
import ModalBody from "../../../../components/Ui/Modals/ModalBody";
import Button from "../../../../components/Ui/Buttons";

/// /////////////////////////
//  START style
/// /////////////////////////
const ButtonWrapper = styled.div`
    display: flex;
    height: 45px;
`;
const TextModal = styled.div`
    margin: 0;
    padding: 15px 0;
    text-align: center;
    width:${props => props.width ? `${props.width}px` : null}
`;
/// /////////////////////////
//  END style
/// /////////////////////////

export class ModalAdminUserAction extends Component {

    static propTypes = {
        elem: PropTypes.object,
        deposit: PropTypes.bool,
        match: PropTypes.object,
        closeModalHandler: PropTypes.func,
        actionsAdminUser: PropTypes.object,
        adminUser: PropTypes.object,
        userList: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object
        ]),
    };

    componentDidUpdate(prevProps) {
        const { adminUser } = this.props;

        if (adminUser.items.Users !== prevProps.adminUser.items.Users) {
            this.setState({
                showSuccessDeletionModal: true,
            })
        }
    }

    state = {
        showDeleteModal: false,
        userCanDisabled: false,
        scope: false,
        showSuccessDeletionModal: false,
        isDisabled: true
    };

    deleteUserHandler = (scope, id) => {
        const { actionsAdminUser } = this.props;
        this.setState({
            scope: scope,
        }, () => {
            actionsAdminUser.deleteAdminUser(id, scope);
            this.closeDeleteModalHandler()
        })

    };

    closeModalSuccess = () => {
        const { closeModalHandler, elem } = this.props;
        this.setState({
            showSuccessDeletionModal: false,


        });
        if (this.state.scope == 'delete') {
            closeModalHandler()
        }
    };

    openDeleteModalHandler = () => {
        const { elem } = this.props;
        this.setState({
            showDeleteModal: true,
            userCanDisabled: elem.enabled,
        })
    };

    closeDeleteModalHandler = () => {
        const { elem } = this.props;
        this.setState({
            showDeleteModal: false,
            userCanDisabled: elem.enabled,
            isDisabled: true
        })
        if (this.state.scope == 'disabled') {
            this.setState({
                isDisabled: false
            })
        }
    };

    render() {
        const {
            elem,
            closeModalHandler,
            translations,
            currentUser,
        } = this.props;
        const translationModalSuccessBody = this.state.scope == 'delete' ?
            translations['page__userManagement__userTable_modalAction_deleteUser_modalChooseDeletionType_deleteUser_modalUpdateSuccess_body']
            : this.state.scope == 'disabled' ?
                translations['page__userManagement__userTable_modalAction_deleteUser_modalChooseDeletionType_disableUser_modalUpdateSuccess_body']
                : 'No translation found';
        const translationModalSuccessTitle = this.state.scope == 'delete' ?
            translations['page__userManagement__userTable_modalAction_deleteUser_modalChooseDeletionType_deleteUser_modalUpdateSuccess_title']
            : this.state.scope == 'disabled' ?
                translations['page__userManagement__userTable_modalAction_deleteUser_modalChooseDeletionType_disableUser_modalUpdateSuccess_title']
                : 'No translation found';
        const lastName = `<span style="color: ${Theme.colors.primary}" >${elem.lastName}</span>`
        const firstName = `<span style="color: ${Theme.colors.primary}" > ${elem.firstName}</span>`
        const textModal = StringFormater.ResponseMessageParser(
            replace(translations['page__userManagement__userTable_modalAction_deleteUser_modalChooseDeletionTypeUserDetail_body'], '\\n', '<br>'),
            [lastName, firstName])

        return (
            <Fragment>
                {/* MODAL SUCCES ON DELETE USER START */}
                {
                    this.state.showSuccessDeletionModal &&

                    <ModalSummerize
                        showModal={this.state.showSuccessDeletionModal}
                        closeModalHandler={this.closeModalSuccess}
                        title={translationModalSuccessTitle}
                        name={'header'}
                    >
                        <ModalBody>
                            <TextModal>
                                <p>
                                    {`${elem.firstName} ${elem.lastName} ${translationModalSuccessBody}`}
                                </p>
                            </TextModal>
                            <ButtonWrapper>
                                <Button
                                    actionHandler={this.closeModalSuccess}
                                    text={translations['common__text__ok']}
                                    bgColor={Theme.colors.validation}
                                />
                            </ButtonWrapper>
                        </ModalBody>
                    </ModalSummerize>
                }
                {/* MODAL END */}
                {/* MODAL DELETE ON DELETE ACTION START*/}
                {
                    this.state.showDeleteModal &&

                    <ModalSummerize
                        showModal={this.state.showDeleteModal}
                        closeModalHandler={this.closeDeleteModalHandler}
                        title={translations['page__userManagement__userTable_modalAction_deleteUser_modalChooseDeletionType_title']}
                    >
                        <ModalBody>
                            <TextModal width={362}>
                                {
                                    this.state.userCanDisabled && this.state.isDisabled ?
                                        <p>
                                            {translations['page__userManagement__userTable_modalAction_deleteUser_modalChooseDeletionType_body']}
                                        </p>
                                        :
                                        <p style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: textModal }}></p>

                                }
                            </TextModal>
                            <ButtonWrapper>

                                <Button
                                    actionHandler={this.closeDeleteModalHandler}
                                    text={translations['common__text__cancel']}
                                    bgColor={Theme.colors.error}
                                    margin='true'
                                />

                                {
                                    this.state.userCanDisabled && this.state.isDisabled &&
                                    <Button
                                        actionHandler={() => this.deleteUserHandler('disabled', elem.id)}
                                        text={translations['page__userManagement__userTable_modalAction_deleteUser_modalChooseDeletionType_button_disableUser']}
                                        bgColor={Theme.colors.warning}
                                        margin='true'
                                    />
                                }

                                <Button
                                    actionHandler={() => this.deleteUserHandler('delete', elem.id)}
                                    text={translations['page__userManagement__userTable_modalAction_deleteUser_modalChooseDeletionType_button_deleteUser']}
                                    bgColor={Theme.colors.error}
                                />


                            </ButtonWrapper>
                        </ModalBody>
                    </ModalSummerize>
                }
                {/* MODAL END */}
                <ModalActionWrapper
                    title={`${elem.firstName} ${elem.lastName}`}
                    closeModal={closeModalHandler}
                >
                    {
                        currentUser.id !== elem.id &&
                        <UserAuthorizationLayout
                            name={userPrivileges.manage[13]}
                        >
                            <Button
                                actionHandler={this.openDeleteModalHandler}
                                text={translations['page__userManagement__userTable_modalAction_button_deleteUser']}
                                bold
                                caps
                            />
                        </UserAuthorizationLayout>
                    }


                    <UserAuthorizationLayout
                        name={userPrivileges.view[23]}
                    >
                        <Button
                            link={`${CR.userDetail}/${elem.id}`}
                            text={translations['page__userManagement__userTable_modalAction_button_editUser']}
                            caps
                            bold
                        />
                    </UserAuthorizationLayout>

                    <UserAuthorizationLayout
                        name={userPrivileges.view[24]}
                    >
                        <Button
                            link={`${CR.logHistoryUser.text}/${elem.id}`}
                            text={translations['page__userManagement__userTable_modalAction_button_viewUserHistory']}
                            bold
                            caps
                        />
                    </UserAuthorizationLayout>
                </ModalActionWrapper>
            </Fragment>

        )
    }
}

const mapStateToProps = state => {
    return {
        adminUser: state.adminUser,
        currentUser: state.user.identity,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionsAdminUser: bindActionCreators(adminUserActionCreators, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAdminUserAction)
