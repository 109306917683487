import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isEmpty, isEqual, isNull, set } from 'lodash'
import { Link } from "react-router-dom";

// redux import
import { connect } from 'react-redux';

// import components
import Modal from '../../../components/Ui/Modals/Modal'

// import assets
import PrintTicket from '../../../assets/icones/global/print.png'

import StringFormater from '../../../helpers/StringFormater';
import { bindActionCreators } from "redux";
import {
    salesOrdersActionCreators
} from "../../../state/actions";
import ModalBody from "../../../components/Ui/Modals/ModalBody";
import Button from "../../../components/Ui/Buttons";
import theme from "../../../theme/theme";
import CR from "../../../constants/RouteConstants";

/////////////////////////////
//  START style
////////////////////////////
const GenerateCodeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 700px;
    padding: 35px 25px 25px 25px;
`;
const ActionTittle = styled.div`
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${props => props.theme.colors.menu.wrapper};
    width: 350px;
`;
const ColorElement = styled.span`
    color: ${props => props.colors};
`;
const CodeNumber = styled.div`
    display: flex;
    width: auto;
    height: 62px;
    border: 1px solid ${props => props.theme.colors.primary};
    font-weight: bold;
    font-size: 30px;
    justify-content: center;
    align-items:center;
    margin-top: 21px;
    margin-bottom: 25px;
`;
const PrintTicketWrapper = styled(Link)`
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    width: 89px;
    height: 95px;
    background-color: ${props => props.theme.colors.primary};
    cursor: pointer;
    border-radius: 3px;
    text-decoration: none;
`;
const PrintTicketText = styled.p`
    font-weight: bold;
    margin: 0px;
    color: ${props => props.theme.colors.white};
    width: 60px;
`;
const PrintTicketImage = styled.img`
    max-width: 38px;
    max-height: 38px;
`;
const OrElement = styled.div`
    height: 27px;
    font-size: 22px;
    color: ${props => props.theme.colors.menu.wrapper};
    font-weight: 900;
    text-transform: uppercase;
`;
const FooterWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 95px;
    justify-content: space-between;
`;
const SmsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 240px;
    height: 95px;
`;
const SmsInput = styled.input`
    border-radius: 3px;
    width: 240px;
    border: 1px solid ${props => props.theme.colors.cancel};
    padding: 12px 0;
    text-align: center;
    box-sizing: border-box;
`;
const SmsButton = styled.div`
    font-weight: bold;
    border-radius: 3px;
    width: 240px;
    padding: 12px 0;
    color: ${props => props.theme.colors.white};
    text-align: center;
    cursor: pointer;
    background-color: ${props => props.disabled ? props.theme.colors.grey : props.theme.colors.validation};
    opacity: ${props => props.disabled ? '0.5' : '1'};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;
const MessageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
const DisplayOnSend = styled.div`
    color: ${props => props.isSuccess ? props.theme.colors.menu.link : props.theme.colors.error}
`;
const ButtonWrapper = styled.div`
    display: flex;
    height: 30px;
`;
const TextModal = styled.div`
    margin: 0;
    padding: 15px 0;
`;
const EmailWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 240px;
    height: 95px;
`;
const EmailInput = styled.input`
    border-radius: 3px;
    width: 240px;
    border: 1px solid ${props => props.theme.colors.cancel};
    padding: 12px 0;
    text-align: center;
    box-sizing: border-box;
`;
const EmailButton = styled.div`
    font-weight: bold;
    border-radius: 3px;
    width: 240px;
    padding: 12px 0;
    color: ${props => props.theme.colors.white};
    text-align: center;
    cursor: pointer;
    background-color: ${props => props.disabled ? props.theme.colors.grey : props.theme.colors.validation};
    opacity: ${props => props.disabled ? '0.5' : '1'};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;
/////////////////////////////
//  END style
////////////////////////////

export class GeneratedCode extends Component {
    static propTypes = {
        salesOrders: PropTypes.object.isRequired,        // salesOrders was send
        elem: PropTypes.object.isRequired,        // gas cylinder selected
        closeModalHandler: PropTypes.func,                     // past by modalHOC
    };

    state = {
        phoneNumber: null,
        isSendSMS: false,
        errorModal: false,
        email: null,
        isValidEmail: null,
        isSendEmail: false,
        data: null
    };

    phoneNumberHandler = (evt) => {
        this.setState({
            phoneNumber: evt.target.value
        })
    };

    emailHandler = (evt) => {
        const regMail = RegExp(/^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        this.setState({
            // Also checking mail validity at same time
            isValidEmail: regMail.test(evt.target.value)
        })
        this.setState({
            email: evt.target.value
        })
    }

    sendSmsHandler = () => {
        const { actionsSaleOrder, salesOrders } = this.props;

        this.setState({
            isSendSMS: true,
        }, () => {
            actionsSaleOrder.postSendSMS(this.state.phoneNumber, salesOrders.generatedCode.id)
        })
    };

    sendEmailHandler = () => {
        const { actionsSaleOrder, salesOrders } = this.props;

        this.setState({
            isSendEmail: true,
        }, () => {
            actionsSaleOrder.postSendEmail(this.state.email, salesOrders.generatedCode.id)
        })
    }

    dataFactory = () => {
        const { salesOrders, itemKiosk, translations } = this.props;
        let phoneNumberText = !salesOrders.generatedCode.optionSMS ? `${translations['modal__generatedCode__button_sendSMS']} ${salesOrders.generatedCode.numberSMSAvailable} ${translations['modal__generatedCode__button_sendSMS_numberLeft']}` : translations['modal__generatedCode__button_sendSMS'].slice(0, -1);

        let data;
        if (salesOrders.generatedCode.review) {
            data = {
                color: 'primary',
                headerTitle: translations['modal__reprintCode__title'],
                bodyTitle: translations['modal__reprintCode__description'],
                status: itemKiosk.saleOrderType.label,
                brandLabel: itemKiosk.itemBrand.label,
                itemLabel: itemKiosk.productLabel,
                codeGenerated: salesOrders.generatedCode.generatedCode,
                phoneNumberText: phoneNumberText,
                phoneNumber: salesOrders.generatedCode.phoneNumber,
                email: salesOrders.generatedCode.customerEmail,
                isSendStatus: salesOrders.generatedCode.sendStatus || false,
                sendMessage: salesOrders.generatedCode.message || '',
            }
        } else if (!salesOrders.error) {
            data = {
                color: 'validation',
                headerTitle: translations['modal__generatedCode__title'],
                status: salesOrders.items.type ? translations['common__text__exchange'] : translations['common__text__sales'],
                brandLabel: itemKiosk.itemBrand.label || '',
                itemLabel: itemKiosk.label || '',
                codeGenerated: salesOrders.generatedCode.generatedCode,
                phoneNumberText: phoneNumberText,
                phoneNumber: salesOrders.generatedCode.phoneNumber,
                email: salesOrders.generatedCode.customerEmail,
                isSendStatus: salesOrders.generatedCode.sendStatus || false,
                sendMessage: salesOrders.generatedCode.message || '',
            }

        } else {

            data = {
                level: 'error',
                title: translations['modal__errorDefault__title'],
                description: StringFormater.ResponseMessageParser(translations[salesOrders.error.traductionKey], [salesOrders.error.msgParameters])
            }
        }
        return data
    };

    componentDidMount() {
        const { itemKiosk } = this.props
        const { data } = this.state
        if (!isEmpty(itemKiosk)) {
            const leData = this.dataFactory()
            if (!isEqual(leData, data)) {
                this.setState({ data: leData })
            }
        }
    }

    componentDidUpdate() {
        const { itemKiosk } = this.props
        const { data, email, phoneNumber } = this.state
        if (!isEmpty(itemKiosk)) {
            const leData = this.dataFactory()
            if (!isEqual(leData, data)) {
                this.setState({ data: leData })
            }
        }
        // Instanciating email and phone input with user infos
        /*if(!isNull(data) && isNull(email) && isNull(phoneNumber)) {
            this.setState({email: data.email, phoneNumber: data.phoneNumber})
        }*/
    }

    render() {
        const { itemKiosk, closeModalHandler, translations, salesOrders } = this.props;
        const { phoneNumber, email, data, isValidEmail } = this.state;

        return (
            (!isNull(data) && !isEmpty(itemKiosk)) ?
                !salesOrders.error ?
                    <Modal
                        color={data.color}
                        headerTittle={data.headerTitle}
                        closeModal={closeModalHandler}
                    >
                        <GenerateCodeWrapper>
                            <ActionTittle>
                                {StringFormater.ResponseMessageParser(translations['modal__generatedCode__description'], [data.status])}
                                <ColorElement colors={theme.colors.primary}>
                                    {data.brandLabel}
                                </ColorElement>

                                {` - ${data.itemLabel} ]`}
                            </ActionTittle>
                            <CodeNumber>
                                {data.codeGenerated}
                            </CodeNumber>
                            <FooterWrapper>
                                <PrintTicketWrapper
                                    to={`${CR.printTicket.text}/${salesOrders.generatedCode.id}`}
                                    target={'_blank'}
                                >
                                    <PrintTicketImage src={PrintTicket} />
                                    <PrintTicketText>
                                        {translations['modal__generatedCode__button_printTicket']}
                                    </PrintTicketText>
                                </PrintTicketWrapper>
                                <OrElement>
                                    {translations['common__text__or']}
                                </OrElement>
                                <SmsWrapper>
                                    <SmsInput
                                        placeholder={translations['modal__generatedCode__telNumber_prefilled']}
                                        //value={phoneNumber === null ? salesOrders.generatedCode.phoneNumber : phoneNumber}
                                        onChange={this.phoneNumberHandler}
                                    />
                                    <SmsButton
                                        disabled={isNull(phoneNumber) || !phoneNumber.length || (salesOrders.generatedCode.numberSMSAvailable === 0)}
                                        onClick={(!isNull(phoneNumber) && phoneNumber.length) ? this.sendSmsHandler : null}
                                    >
                                        {data.phoneNumberText}
                                    </SmsButton>
                                </SmsWrapper>
                                <OrElement>
                                    {translations['common__text__or']}
                                </OrElement>
                                <EmailWrapper>
                                    <EmailInput
                                        placeholder={translations['modal__customerInfo__additionnal_info_customer_email']}
                                        //value={email === null ? salesOrders.generatedCode.customerEmail : email}
                                        onChange={this.emailHandler}
                                    />
                                    <EmailButton
                                        disabled={email === null || !email.length || !isValidEmail}
                                        onClick={email !== null && isValidEmail ? this.sendEmailHandler : null}
                                    >
                                        {translations['modal__customerInfo__additionnal_info_sendEmail']}
                                    </EmailButton>
                                </EmailWrapper>
                            </FooterWrapper>
                            <MessageWrapper>
                                <DisplayOnSend
                                    isSuccess={data.isSendStatus}
                                >
                                    {
                                        (this.state.isSendSMS || this.state.isSendEmail) &&
                                        <p>
                                            {data.sendMessage}
                                        </p>
                                    }
                                </DisplayOnSend>
                            </MessageWrapper>
                        </GenerateCodeWrapper>

                    </Modal>
                    :
                    <Modal
                        color={data.level}
                        headerTittle={data.title}
                        closeModal={closeModalHandler}
                    >
                        <ModalBody>
                            <TextModal>
                                {data.description}
                            </TextModal>
                            <ButtonWrapper>
                                <Button
                                    actionHandler={closeModalHandler}
                                    text={translations['common__text__ok']}
                                    margin='true'
                                    bgColor={theme.colors.primary}
                                />
                            </ButtonWrapper>
                        </ModalBody>
                    </Modal>
                : <div></div>
        )
    }
}

const mapStateToProps = state => {
    return {
        salesOrders: state.salesOrders,
        itemKiosk: state.itemsKiosk.selectedItem,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionsSaleOrder: bindActionCreators(salesOrdersActionCreators, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneratedCode)
