import { createSelector } from 'reselect'
import { find, isEmpty } from 'lodash'

const getUserSettingsNotif      = state => state.userSettings.items.userNotifications;

export const selectSettingDeliveryArrival = createSelector(
    [ getUserSettingsNotif ],
    (userSettings) => {

        if(!isEmpty(userSettings)) {
            const arrivalMail = find( userSettings, { id: 1 } );
            const arrivalApp = find( userSettings, { id: 2 } );
            const arrivalSMS = find( userSettings, { id: 3 } );

            const data = [
                {
                    tittle: 'common__text__notifMail',
                    id: arrivalMail.id,
                    checked: arrivalMail.isSelected,
                },
                {
                    tittle: 'common__text__notifApplication',
                    id: arrivalApp.id,
                    checked: arrivalApp.isSelected,
                },
                {
                    tittle: 'common__text__notifSMS',
                    id: arrivalSMS.id,
                    checked: arrivalSMS.isSelected,
                }
            ];
            return data
        }

    }
);
export const selectSettingDeliveryDeparture = createSelector(
    [ getUserSettingsNotif ],
    (userSettings) => {
        if(!isEmpty(userSettings)) {

            const departureMail = find( userSettings, { id: 4 } );
            const departureApp = find( userSettings, { id: 5 } );
            const departureSMS = find( userSettings, { id: 6 } );

            const data = [
                {
                    tittle: 'common__text__notifMail',
                    id: departureMail.id,
                    checked: departureMail.isSelected,
                },
                {
                    tittle: 'common__text__notifApplication',
                    id: departureApp.id,
                    checked: departureApp.isSelected,
                },
                {
                    tittle: 'common__text__notifSMS',
                    id: departureSMS.id,
                    checked: departureSMS.isSelected,
                }
            ];
            return data
        }
    }
);
export const selectSettingDelivery = createSelector(
    [ getUserSettingsNotif ],
    (userSettings) => {
        if(!isEmpty(userSettings)) {

            const deliveryMail = find( userSettings, { id: 7 } );


            const data = [
                {
                    tittle: 'common__text__notifMail',
                    id: deliveryMail.id,
                    checked: deliveryMail.isSelected,
                },
            ];
            return data
        }
    }
);
export const selectSettingMaintainerArrival = createSelector(
    [ getUserSettingsNotif ],
    (userSettings) => {
        if(!isEmpty(userSettings)) {

            const MaintainerArrivalMail = find( userSettings, { id: 8 } );
            const MaintainerArrivalApp = find( userSettings, { id: 9 } );
            const MaintainerArrivalSMS = find( userSettings, { id: 10 } );

            const data = [
                {
                    tittle: 'common__text__notifMail',
                    id: MaintainerArrivalMail.id,
                    checked: MaintainerArrivalMail.isSelected,
                },
                {
                    tittle: 'common__text__notifApplication',
                    id: MaintainerArrivalApp.id,
                    checked: MaintainerArrivalApp.isSelected,
                },
                {
                    tittle: 'common__text__notifSMS',
                    id: MaintainerArrivalSMS.id,
                    checked: MaintainerArrivalSMS.isSelected,
                }
            ];
            return data
        }
    }
);
export const selectSettingMaintainerDeparture = createSelector(
    [ getUserSettingsNotif ],
    (userSettings) => {
        if(!isEmpty(userSettings)) {

            const MaintainerDepartureMail = find( userSettings, { id: 11 } );
            const MaintainerDepartureApp = find( userSettings, { id: 12 } );
            const MaintainerDepartureSMS = find( userSettings, { id: 13 } );

            const data = [
                {
                    tittle: 'common__text__notifMail',
                    id: MaintainerDepartureMail.id,
                    checked: MaintainerDepartureMail.isSelected,
                },
                {
                    tittle: 'common__text__notifApplication',
                    id: MaintainerDepartureApp.id,
                    checked: MaintainerDepartureApp.isSelected,
                },
                {
                    tittle: 'common__text__notifSMS',
                    id: MaintainerDepartureSMS.id,
                    checked: MaintainerDepartureSMS.isSelected,
                }
            ];
            return data
        }
    }
);
export const selectSettingStockState = createSelector(
    [ getUserSettingsNotif ],
    (userSettings) => {
        if(!isEmpty(userSettings)) {

            const StockStateDaily = find( userSettings, { id: 14 } );
            const StockStateWeekly = find( userSettings, { id: 15 } );
            const StockStateMonthly = find( userSettings, { id: 16 } );

            const data = [
                {
                    tittle: 'common__text__daily',
                    id: StockStateDaily.id,
                    checked: StockStateDaily.isSelected,
                },
                {
                    tittle: 'common__text__weekly',
                    id: StockStateWeekly.id,
                    checked: StockStateWeekly.isSelected,
                },
                {
                    tittle: 'common__text__monthly',
                    id: StockStateMonthly.id,
                    checked: StockStateMonthly.isSelected,
                }
            ];
            return data
        }
    }
);
export const selectSettingSalesState = createSelector(
    [ getUserSettingsNotif ],
    (userSettings) => {
        if(!isEmpty(userSettings)) {

            const StateSalesDaily = find( userSettings, { id: 17 } );
            const StateSalesWeekly = find( userSettings, { id: 18 } );
            const StateSalesMonthly = find( userSettings, { id: 19 } );

            const data = [
                {
                    tittle: 'common__text__daily',
                    id: StateSalesDaily.id,
                    checked: StateSalesDaily.isSelected,
                },
                {
                    tittle: 'common__text__weekly',
                    id: StateSalesWeekly.id,
                    checked: StateSalesWeekly.isSelected,
                },
                {
                    tittle: 'common__text__monthly',
                    id: StateSalesMonthly.id,
                    checked: StateSalesMonthly.isSelected,
                }
            ];
            return data
        }
    }
);
export const selectSettingNotifUserConnection = createSelector(
    [ getUserSettingsNotif ],
    (userSettings) => {
        if(!isEmpty(userSettings)) {

            const userConnectiondMail = find( userSettings, { id: 20 } );

            const data = [
                {
                    tittle: 'common__text__notifMail',
                    id: userConnectiondMail.id,
                    checked: userConnectiondMail.isSelected,
                },
            ];
            return data
        }
    }
);
export const selectSettingIssueRaised = createSelector(
    [ getUserSettingsNotif ],
    (userSettings) => {
        if(!isEmpty(userSettings)) {

            const issueRaisedMail = find( userSettings, { id: 21 } );

            const data = [
                {
                    tittle: 'common__text__notifMail',
                    id: issueRaisedMail.id,
                    checked: issueRaisedMail.isSelected,
                },
            ];
            return data
        }
    }
);
