import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// import components
import Modal from '../../../../components/Ui/Modals/Modal'
// redux
import { connect } from 'react-redux'
/// /////////////////////////
//  START style
/// /////////////////////////
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 450px;
    padding: 35px 0;
`;
const ActionTittle = styled.div`
    font-size: ${props => props.theme.font.fxxl};
    font-weight: bold;
    color: ${ props => props.theme.colors.menu.wrapper};
    margin-bottom: 19px;
`;
const ButtonActionWrapper = styled.div`
    display: grid;
    grid-template-columns: 240px;
    grid-auto-rows: 44px;
    grid-row-gap: 11px;
    justify-content: center;
    align-items: center; 
`;
/// /////////////////////////
//  END style
/// /////////////////////////

/**
 *
 * @param {string} title
 * @param {node}  children
 * @param {function} closeModal
 * @param {object} translations
 * @returns {*}
 * @constructor
 */
const ModalActionsWrapper = ({ title, children, closeModal, translations }) => {
  return (
    <Modal
      headerTittle={translations['common__text__actions']}
      closeModal={closeModal}
    >
      <ActionWrapper>
        <ActionTittle>
          {'[ ' + title + ' ]'}
        </ActionTittle>
        <ButtonActionWrapper>
          { children }
        </ButtonActionWrapper>
      </ActionWrapper>
    </Modal>
  )
};

ModalActionsWrapper.propTypes = {
  title: PropTypes.string,
  closeModal: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
};

const mapStateToProps = state => {
  return {
    translations: state.languages.translations,
  };
};
export default connect(mapStateToProps, null)(ModalActionsWrapper)
