import ActionsTypes from '../../constants/ActionsTypes';

const salesOrders = {
    postSalesOrders: ( salesOrders ) => ( {
        type: ActionsTypes.POST_SALES_ORDERS,
        payload: {
            salesOrders,
        },
    } ),
    postSalesOrdersSuccess: ( codeGenerated ) => ( {
        type: ActionsTypes.POST_SALES_ORDERS_SUCCESS,
        payload: {
            codeGenerated,
        },
    } ),
    postSalesOrdersFailure: ( error ) => ( {
        type: ActionsTypes.POST_SALES_ORDERS_FAILURE,
        payload: {
            error,
        },
    } ),
    updateSalesOrders: ( salesOrders ) => ( {
        type: ActionsTypes.UPDATE_SALES_ORDERS,
        payload: {
            salesOrders,
        },
    } ),
    getSalesOrderCodes: ( id, elem ) => ( {
        type: ActionsTypes.GET_SALES_ORDERS_CODES,
        payload: { id, elem },
    } ),
    getSalesOrderCodesSuccess: ( salesOrders, elem ) => ( {
        type: ActionsTypes.GET_SALES_ORDERS_CODES_SUCCESS,
        payload: { salesOrders, elem },
    } ),
    getSalesOrderCodesFailure: ( error ) => ( {
        type: ActionsTypes.GET_SALES_ORDERS_CODES_FAILURE,
        payload: { error },
    } ),
    postSalesOrderCodesRenew: ( id, days, elem ) => ( {
        type: ActionsTypes.POST_SALES_ORDERS_CODES_RENEW,
        payload: { id, days, elem },
    } ),
    postSalesOrderCodesRenewSuccess: ( salesOrders, elem ) => ( {
        type: ActionsTypes.POST_SALES_ORDERS_CODES_RENEW_SUCCESS,
        payload: { salesOrders, elem },
    } ),
    postSalesOrderCodesRenewFailure: ( error ) => ( {
        type: ActionsTypes.POST_SALES_ORDERS_CODES_RENEW_FAILURE,
        payload: { error },
    } ),
    updateUserConsignmentInfo: ( userInfo, id ) => ( {
        type: ActionsTypes.UPDATE_USER_CONSIGNMENT_INFO,
        payload: {
            userInfo,
            id
        },
    } ),
    updateUserConsignmentInfoSuccess: ( salesOrder ) => ( {
        type: ActionsTypes.UPDATE_USER_CONSIGNMENT_INFO_SUCCESS,
        payload: { salesOrder },
    } ),
    updateUserConsignmentInfoFailure: ( error ) => ( {
        type: ActionsTypes.UPDATE_USER_CONSIGNMENT_INFO_FAILURE,
        payload: { error },
    } ),

    postSendSMS: ( phoneNumber, idElem ) => ( {
        type: ActionsTypes.POST_SEND_SMS,
        payload: {
            phoneNumber,
            idElem,
        },
    } ),
    postSendSMSSuccess: ( salesOrder ) => ( {
        type: ActionsTypes.POST_SEND_SMS_SUCCESS,
        payload: { salesOrder },
    } ),
    postSendSMSFailure: ( error ) => ( {
        type: ActionsTypes.POST_SEND_SMS_FAILURE,
        payload: { error },
    } ),

    postSendEmail: ( email, idElem ) => ( {
        type: ActionsTypes.POST_SEND_EMAIL,
        payload: {
            email,
            idElem,
        },
    } ),
    postSendEmailSuccess: ( salesOrder ) => ( {
        type: ActionsTypes.POST_SEND_EMAIL_SUCCESS,
        payload: { salesOrder },
    } ),
    postSendEmailFailure: ( error ) => ( {
        type: ActionsTypes.POST_SEND_EMAIL_FAILURE,
        payload: { error },
    } ),

    putSalesOrderDepositContract: ( id ) => ( {
        type: ActionsTypes.PUT_SALES_ORDERS_DEPOSIT_CONTRACT,
        payload: {
            id
        },
    } ),
    putSalesOrderDepositContractSuccess: ( salesOrder ) => ( {
        type: ActionsTypes.PUT_SALES_ORDERS_DEPOSIT_CONTRACT_SUCCESS,
        payload: { salesOrder },
    } ),
    putSalesOrderDepositContractFailure: ( error ) => ( {
        type: ActionsTypes.PUT_SALES_ORDERS_DEPOSIT_CONTRACT_FAILURE,
        payload: { error },
    } ),

    getPrintCodeTicket: ( id ) => ( {
        type: ActionsTypes.GET_PRINT_CODE_TICKET,
        payload: {
            id,
        },
    } ),
    getPrintCodeTicketSuccess: ( ticket ) => ( {
        type: ActionsTypes.GET_PRINT_CODE_TICKET_SUCCESS,
        payload: {ticket},
    } ),
    getPrintCodeTicketFailure: ( error ) => ( {
        type: ActionsTypes.GET_PRINT_CODE_TICKET_FAILURE,
        payload: {
            error,
        },
    } ),

};

export default salesOrders;