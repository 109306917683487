////////LIBRARY/////////
import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'


// TODO: get user to redux
const ModalRoute = ({ component: Component, ...rest}) => {
    return (
        <Route
            render={props => 
                <Component
                    translations={rest.translations}
                    {...props}
                    {...rest} />
            }
        />
    )
}

ModalRoute.propTypes = {}

const mapStateToProps = state => {
    return {
        translations: state.languages.translations
    }
}

export default connect(mapStateToProps)(ModalRoute)