import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import { cloneDeep } from 'lodash'

extendUpdate(update);

const myAccount = {

    [ActionsTypes.GET_MY_ACCOUNT]: (state) => update(state, {
        myAccount: {
            isLoading: {
                $set: true,
            },
        }
    }),
    [ActionsTypes.GET_MY_ACCOUNT_SUCCESS]: (state, payload) => {
        return update(state, {
            myAccount:{
                isLoading: {
                    $set: false,
                },
                items: {
                    $set: payload.myAccount
                },
                error: {
                    $set: false
                },
            },

        })        
    },
    [ActionsTypes.GET_MY_ACCOUNT_FAILURE]: (state, payload) => update(state, {
        myAccount:{
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
        },
    }),
    [ActionsTypes.UPDATE_MY_ACCOUNT]: (state) => update(state, {
        myAccount: {
            isLoading: {
                $set: true,
            },
        }
    }),
    [ActionsTypes.UPDATE_MY_ACCOUNT_SUCCESS]: (state, payload) => {
        const oldUser   = cloneDeep(state.user.identity);
        const user      = payload.user;

        for ( const elem in user ){
            oldUser[elem] = user[elem]
        }
        return update(state, {
            user:{
                identity: {
                    $set: oldUser
                }
            },
            myAccount:{
                isLoading: {
                    $set: false,
                },
                items: {
                    $set: {...payload.user}
                },
                error: {
                    $set: false
                },
            },

        })        
    },
    [ActionsTypes.UPDATE_MY_ACCOUNT_FAILURE]: (state, payload) => update(state, {
        myAccount:{
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error
            },
        },
    }),
};

export default myAccount