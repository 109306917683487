import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

import NotifToggleLine from './NotifToggleLine'
//////////////////////////////
///////// START STYLE ////////
//////////////////////////////

const UserNotifLineWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    flex-direction: column;

`;
const TittleLine = styled.div`
    font-style: italic;
`;

//////////////////////////////
///////// END STYLE //////////
//////////////////////////////

/**
 *
 * @param {array} data
 * @param { string} tittle
 * @param {object} translations
 * @param {func} onChangeHandler
 * @returns {jsx}
 */
function NotifLineSettings ( { data, tittle,translations, onChangeHandler }) {
    return (
        <UserNotifLineWrapper>
            <TittleLine>
                {translations[tittle]}
            </TittleLine>
            <NotifToggleLine
                data={data}
                translations={translations}
                onChangeHandler={onChangeHandler}
            />


        </UserNotifLineWrapper>
    );

}

NotifLineSettings.propTypes = {
    data: PropTypes.array,
    tittle: PropTypes.string,
    translations: PropTypes.object,
    onChangeHandler: PropTypes.func,
};

export default NotifLineSettings;