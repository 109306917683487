import ActionsTypes from '../../constants/ActionsTypes';

const paymentCollection = {
    getPaymentCollection: ( date ) => ({
        type: ActionsTypes.GET_PAYMENT_COLLECTION,
        payload: { date },
    }),
    getPaymentCollectionSuccess: (paymentCollection) => ({
        type: ActionsTypes.GET_PAYMENT_COLLECTION_SUCCESS,
        payload: {
            paymentCollection,
        },
    }),
    getPaymentCollectionFailure: (error) => ({
        type: ActionsTypes.GET_PAYMENT_COLLECTION_FAILURE,
        payload: {
            error,
        },
    }),
    getPaymentCollectionDetail: ( id ) => ({
        type: ActionsTypes.GET_PAYMENT_COLLECTION_DETAIL,
        payload: { id },
    }),
    getPaymentCollectionDetailSuccess: (paymentCollectionDetail) => ({
        type: ActionsTypes.GET_PAYMENT_COLLECTION_DETAIL_SUCCESS,
        payload: {
            paymentCollectionDetail,
        },
    }),
    getPaymentCollectionDetailFailure: (error) => ({
        type: ActionsTypes.GET_PAYMENT_COLLECTION_DETAIL_FAILURE,
        payload: {
            error,
        },
    }),
    getSalesOrderExcluded: ( id ) => ({
        type: ActionsTypes.GET_SALES_ORDER_EXCLUDED,
        payload: { id },
    }),
    getSalesOrderExcludedSuccess: ( salesOrderExcluded ) => ({
        type: ActionsTypes.GET_SALES_ORDER_EXCLUDED_SUCCESS,
        payload: {
            salesOrderExcluded,
        },
    }),
    getSalesOrderExcludedFailure: (error) => ({
        type: ActionsTypes.GET_SALES_ORDER_EXCLUDED_FAILURE,
        payload: {
            error,
        },
    }),
    updatePaymentCollectionSelected: ( id ) => ({
        type: ActionsTypes.UPDATE_PAYMENT_COLLECTION_SELECTED,
        payload: {
            id,
        },
    }),
    updateSalesOrderExcludedAndIncluded: ( id, type ) => ({
        type: ActionsTypes.UPDATE_SALES_ORDER_EXCLUDED_AND_INCLUDED,
        payload: {
            id,
            type,
        },
    }),
    updatePaymentCollectionSalesOrder: ( id ) => ({
        type: ActionsTypes.UPDATE_PAYMENT_COLLECTION_SALES_ORDER,
        payload: { id },
    }),
    updatePaymentCollectionSalesOrderSuccess: ( data ) => ({
        type: ActionsTypes.UPDATE_PAYMENT_COLLECTION_SALES_ORDER_SUCCESS,
        payload: { data },
    }),
    updatePaymentCollectionSalesOrderFailure: ( error ) => ({
        type: ActionsTypes.UPDATE_PAYMENT_COLLECTION_SALES_ORDER_FAILURE,
        payload: { error },
    }),

    getSalesOrderPaymentCollection: ( id ) => ({
        type: ActionsTypes.GET_SALES_ORDER_PAYMENT_COLLECTION_DETAIL,
        payload: { id },
    }),
};

export default paymentCollection;