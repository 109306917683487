import ActionsTypes from '../../constants/ActionsTypes';

const login = {
    getMyAccount: () => ({
        type: ActionsTypes.GET_MY_ACCOUNT,
        payload: {},
    }),
    getMyAccountSuccess: (myAccount) => ({
        type: ActionsTypes.GET_MY_ACCOUNT_SUCCESS,
        payload: {
            myAccount,
        },
    }),
    getMyAccountFailure: (error) => ({
        type: ActionsTypes.GET_MY_ACCOUNT_FAILURE,
        payload: {
            error,
        },
    }),
    updateMyAccount: (user) => ({
        type: ActionsTypes.UPDATE_MY_ACCOUNT,
        payload: {user},
    }),
    updateMyAccountSuccess: (user) => ({
        type: ActionsTypes.UPDATE_MY_ACCOUNT_SUCCESS,
        payload: {user},
    }),
    updateMyAccountFailure: (error) => ({
        type: ActionsTypes.UPDATE_MY_ACCOUNT_FAILURE,
        payload: {error},
    }),
};

export default login;