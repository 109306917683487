import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import { forEach, reject, cloneDeep } from 'lodash'

extendUpdate(update);

const promoItemStore = {
    [ActionsTypes.GET_PROMO_ITEM_STORE]: (state) => update(state, {
        promoItemStore: {
            isLoading: {
                $set: true,
            },
        },
    }),
    [ActionsTypes.GET_PROMO_ITEM_STORE_SUCCESS]: (state, payload) => {
        // const oldState      = state.promoItemStore
        const newState      = payload.promoItemStore;

        // if (oldState.items) {
        //     forEach(newState, (elem) => {
        //         const itemWithDisplay = find(oldState.items, {id: elem.id})
        //
        //         if line is open keep it open
        //         but not update it
        //         if( !isUndefined(itemWithDisplay) ) {
        //             elem['display'] = itemWithDisplay.display
        //         }
        //         if( !isUndefined(itemWithDisplay) && !isUndefined(itemWithDisplay.itemStorePromo) ) {
        //             elem['itemStorePromo'] = itemWithDisplay.itemStorePromo
        //         }
        //
        //     })
        // }

        return update(state, {
            promoItemStore: {
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: false,
                },
                items: {
                    $set: newState
                }
            },
        })
    },
    [ActionsTypes.GET_PROMO_ITEM_STORE_FAILURE]: (state, payload) => update(state, {
        promoItemStore: {
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
            items: {
                $set: false,
            }
        },
    }),
    [ActionsTypes.GET_PROMO_ITEM_DETAIL]: (state) => update(state, {
        promoItemStore: {
            isLoading: {
                $set: true,
            },
        },
    }),
    [ActionsTypes.GET_PROMO_ITEM_DETAIL_SUCCESS]: (state, payload) => {
        const data      = {...payload.promoItemDetail};
        const oldState  = { ...state.promoItemStore.items};

        forEach(oldState, (item) => {
            if(item.id === data.promoHeader.id) {
                item['itemStorePromo'] = {...data.items}
            }
        });

        return update(state, {
            promoItemStore: {
                isLoading: {
                    $set: false,
                },
                items: {
                    $set: {...oldState}
                }
            },
        })
    },
    [ActionsTypes.GET_PROMO_ITEM_DETAIL_FAILURE]: (state, payload) => update(state, {
        promoItemStore: {
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
            items: {
                $set: false,
            }
        },
    }),
    [ActionsTypes.GET_PROMO_ITEM_DETAIL_ALL]: (state) => update(state, {
        promoItemStore: {
            isLoading: {
                $set: true,
            },
        },
    }),
    [ActionsTypes.GET_PROMO_ITEM_DETAIL_ALL_SUCCESS]: (state, payload) => {
        const data      = {...payload.promoItemDetail};
        const oldState  = { ...state.promoItemStore.items};
        
        forEach(oldState, (item) => {
            forEach(data, ( elem ) => {
                if(item.id === elem.promoHeader.id) {
                    item['itemStorePromo'] = {...elem.items}
                }
            })
        });

        return update(state, {
            promoItemStore: {
                isLoading: {
                    $set: false,
                },
                items: {
                    $set: {...oldState}
                }
            },
        })
    },
    [ActionsTypes.GET_PROMO_ITEM_DETAIL_ALL_FAILURE]: (state, payload) => update(state, {
        promoItemStore: {
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
            items: {
                $set: false,
            }
        },
    }),
    [ActionsTypes.UPDATE_PROMO_ITEM_DISPLAY]: (state, payload) => {
        const data      = payload.id;
        const oldState  = cloneDeep(state.promoItemStore.items);

        switch (data) {
            case 'ALL':
                forEach(oldState, (item) => {
                    item['display'] = 'flex'
                });
                break;
            case 'ALL_NONE':
                forEach(oldState, (item) => {
                    item['display'] = 'flex'
                });
                break;
            default:
        }
        if (data === 'ALL'){
            forEach(oldState, (item) => {
                item['display'] = 'flex'
            })
        }else if(data === 'ALL_NONE') {
            forEach(oldState, (item) => {
                item['display'] = 'none'
            })
        }else {
            forEach(oldState, (item) => {
                if(item.id === data) {
                    if(item.display === 'flex'){
                        item['display'] = 'none'
                    }else{
                        item['display'] = 'flex'
                    }
                }
            })
        }
        
        return update(state, {
            promoItemStore: {
                isLoading: {
                    $set: false,
                },
                items: {
                    $set: {...oldState}
                }
            },
        })
    },
    [ActionsTypes.UPDATE_PROMO_STATUS_DISPLAY]: (state, payload) => {
        const elemId        = parseInt(payload.id);
        const elemChecked   = payload.checked;
        const oldState      = { ...state.promoItemStore};
        forEach(oldState.statusFilter, (item) => {

            if(item.id === elemId) {
                item.active = elemChecked
            }
        });
        
        return update(state, {
            promoItemStore: {
                statusFilter: {
                    $set: [...oldState.statusFilter]
                }
            },
        })
    },
    [ActionsTypes.DELETE_PROMO_ITEM_STORE]: (state) => update(state, {
        promoItemStore: {
            isLoading: {
                $set: true,
            },
        },
    }),
    [ActionsTypes.DELETE_PROMO_ITEM_STORE_SUCCESS]: (state, payload) => {
        const data      = {...payload.promoItemStore};
        const oldState  = { ...state.promoItemStore.items};

        const newState  = reject(oldState, {id: data.id});

        return update(state, {
            promoItemStore: {
                isLoading: {
                    $set: false,
                },
                items: {
                    $set: {...newState}
                }
            },
        })
    },
    [ActionsTypes.DELETE_PROMO_ITEM_STORE_FAILURE]: (state, payload) => update(state, {
        promoItemStore: {
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
            items: {
            }
        },
    }),
};

export default promoItemStore;
