import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { map, filter, merge, find } from 'lodash'

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { kiosksActionCreators } from '../../state/actions';

// component import
import TableLayout from '../TableLayout'
import TableCheckboxEditable from '../../Hoc/TableCheckboxEditable'
import ActionButton from '../../components/Ui/Buttons'
import UserAuthorizationLayout from '../../components/UserAuthorizationLayout'
import userPrivileges from '../../constants/RoleList'

//assets import
import Edit from '../../assets/icones/global/edit.png'
import WhiteEdit from '../../assets/icones/global/white-edit.png'
import Validation from '../../assets/icones/global/validation.png'
import TagImgAndText from "../../components/Ui/TagImgAndText";

// style
import {
    PageWrapper,
    LineTableHeader,
    TitleHeader,
    ActionWrapper,
    TitleHeaderTable,
    ContentTableWrapper,
    ImgSized,
    BoxValidation,
    DisabledValidation,
    BoxValidationWrapper,
    BoxCanceled,
} from "../../theme/GlobalCss";
import theme from "../../theme/theme";

/////////////////////////////
//  START style
////////////////////////////

//////////////////////
//  END style
////////////////////////////

export class KiosksManager extends Component {
    static propTypes = {
        actionsKiosks:              PropTypes.object,
        cancelActionHandler:        PropTypes.func,
        checkboxChangeHandler:      PropTypes.func,
        dataTable:                  PropTypes.object,
        editAllCancelHandler:       PropTypes.func,
        editAllHandler:             PropTypes.func,
        editAllValidationHandler:   PropTypes.func,
        editInputHandler:           PropTypes.func,
        editedOnCLickHandler:       PropTypes.func,
        hydrateStateHandler:        PropTypes.func,
        validationActionHandler:    PropTypes.func,
        kiosksManager:              PropTypes.object,
        refCollection:              PropTypes.array,
    };
    ///////////////////////////////////////////////////
    ///////     START INIT COMPONENT    ///////////////
    ///////////////////////////////////////////////////

    componentDidMount = () => {
        const { actionsKiosks } = this.props;
        actionsKiosks.getKiosks();
    };
    componentDidUpdate(prevProps) {
        const { kiosksManager, hydrateStateHandler, translations } = this.props;

        if( kiosksManager.items !== prevProps.kiosksManager.items ) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            hydrateStateHandler(data)
        }
        if( translations !== prevProps.translations) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            hydrateStateHandler(data)
        }
    }
    ///////////////////////////////////////////////////
    ///////     END INIT COMPONENT    /////////////////
    ///////////////////////////////////////////////////



    /**
     * data object to row layout
     */
    bodyData = () => {
        const {
            kiosksManager,
            editedOnCLickHandler,
            translations } = this.props;

        const header = [
            {name: 'CHECKBOX',useName: 'checkBox', isChecked: false},
            {name: translations['page__kioskManagement__kioskTable_tableHeader_kiosk'], useName: 'idExternOwner', sortTable: true, },
            {name: translations['page__kioskManagement__kioskTable_tableHeader_name'], useName: 'idExternStore', sortTable: true,},
            {name: translations['page__kioskManagement__kioskTable_tableHeader_model'], useName: 'model', sortTable: true,},
            {name: translations['page__kioskManagement__kioskTable_tableHeader_printer'], useName: 'print' , sortTable: true,},
            {name: translations['page__kioskManagement__kioskTable_tableHeader_bankFlow'], useName: 'bank' , sortTable: true,},
            {name: translations['page__kioskManagement__kioskTable_tableHeader_SIMCard'], useName: 'phone' , sortTable: true,},
            {name: translations['page__kioskManagement__kioskTable_tableHeader_others'], useName: 'other' , sortTable: true,},
            {name: translations['common__text__actions'], useName: 'actions'}, 
        ];
        return{
            header: header,
            body:   map(kiosksManager.items.Kiosks, (elem) => {
                return ({
                    id: elem.idKiosk,
                    elements: [
                        {name :'checkBox', value: '', isChecked: false, },
                        {name :'idExternOwner', value: elem.idExternOwner, sortValue: elem.idExternOwner, },
                        {name :'idExternStore', value: elem.idExternStore, editable:true, active:false, type:'text', maxLength: 29, sortValue: elem.idExternStore, },
                        {name :'model', value: elem.model, sortValue: elem.model, },
                        {name :'print', value:
                            <TagImgAndText
                                idLevel={elem.printerIntegrity.level}
                                width={'30px'}
                            />
                        , sortValue: elem.printerIntegrity.level, },
                        {name :'bank', value:
                                <TagImgAndText
                                    idLevel={elem.bankFlowIntegrity.level}
                                    width={'30px'}
                                />
                        , sortValue: elem.bankFlowIntegrity.level, },
                        {name :'phone', value:
                                <TagImgAndText
                                    idLevel={elem.simCardIntegrity.level}
                                    width={'30px'}
                                />
                        , sortValue: elem.simCardIntegrity.level, },
                        {name :'other', value:
                                <TagImgAndText
                                    idLevel={elem.otherIntegrity.level}
                                    width={'30px'}
                                />
                        , sortValue: elem.otherIntegrity.level, },
                        {
                            name: 'actions',
                            display: 'flex',
                            value:
                                <Fragment>
                                    <UserAuthorizationLayout
                                        name={userPrivileges.manage[11]}
                                    >
                                        <ImgSized
                                            name='edit'
                                            src={Edit}
                                            id={elem.idKiosk}
                                            onClick={editedOnCLickHandler}
                                            width={20}
                                            height={20}
                                            cursor={'true'}
                                        />
                                    </UserAuthorizationLayout>
                                    <BoxValidationWrapper
                                        name='validation'
                                        id={elem.idKiosk}
                                    >
                                        <BoxValidation
                                            id={elem.idKiosk}
                                            onClick={this.validationOneLineOnClickHandler}
                                            name='valided'
                                        >
                                            <ImgSized width={13} id={elem.idKiosk} src={Validation} />
                                        </BoxValidation>
                                        <DisabledValidation
                                            id={elem.idKiosk}
                                            name='disabled'
                                        >
                                            <ImgSized width={13} src={Validation} />
                                        </DisabledValidation>
                                        <BoxCanceled
                                            id={elem.idKiosk}
                                            onClick={(evt) => this.onLineCancelActionHandler(evt, {idExternStore:elem.idExternStore})}
                                        >
                                            X
                                        </BoxCanceled>
                                    </BoxValidationWrapper>
                                </Fragment>,
                        },
                    ]
                })
            })
        };
    };

    validationOneLineOnClickHandler = (evt) => {
        const {
            validationActionHandler,
            refCollection,
        }= this.props;

        const oldState      = {...this.props.dataTable};

        const id            = parseInt(evt.target.id);
        oldState.editOne    = !oldState.editOne;
        const kiosksList   = [];

        oldState.bodyData
            .forEach( (elem) => {
                if(elem.id === id) {
                    const editable = filter(elem.elements, { active : true });
                    let kiosksFull = {
                        idKiosk: elem.id,
                    };

                    let idExternStore;
                    editable
                        .forEach( item => {
                            idExternStore = refCollection[elem.id][item.name].children[0].value;
                            merge(kiosksFull, {[item.name]: idExternStore});
                            kiosksList.push(kiosksFull)
                        })
                }

            });
        validationActionHandler(oldState, id);
        this.props.actionsKiosks.updateKiosks(kiosksList)
    };

    ValidationEditAllOneClickHandler = () => {
        const {
            editAllValidationHandler,
            refCollection,
        }= this.props;

        const oldState      = {...this.props.dataTable};
        const kiosksList   = [];
        oldState.editing    = false;
        let idExternStore;

        oldState.bodyData
            .forEach( (elem) => {
                const isChecked = find(elem.elements, { isChecked : true });
                if(isChecked) {
                    const editable = filter(elem.elements, { active : true });
                    let kiosksFull = {
                        idKiosk: elem.id,
                    };
                    editable
                        .forEach( item => {
                            idExternStore = refCollection[elem.id][item.name].children[0].value;

                            merge(kiosksFull, {[item.name]: idExternStore});
                            kiosksList.push(kiosksFull)
                        })
                }

            });

        this.props.actionsKiosks.updateKiosks(kiosksList);
        editAllValidationHandler(oldState)
    };

    cancelEditAllHandler = (data) => {
        const oldState      = {...this.props.dataTable};
        oldState.editing    = false;

        oldState.bodyData
            .forEach( (elem) => {
                const isChecked = find(elem.elements, { isChecked : true });
                let newItem = false;
                if(isChecked) {
                    newItem = find(data, { idKiosk : elem.id })
                }
                elem.elements.forEach( (item) => {
                    if (item.editable && newItem){
                        item.value = newItem[item.name]
                    }
                })
            });
        this.props.editAllCancelHandler(oldState)
    };
    onLineCancelActionHandler = (evt, data) => {
        const id            = parseInt(evt.target.id);
        const oldState      = {...this.props.dataTable};
        oldState.editOne    = !oldState.editOne;
        oldState.bodyData
            .forEach( (elem) => {
                if( elem.id === id ) {
                    elem.elements.forEach( (item) => {
                        if (item.editable){
                            item.value = data[item.name]
                        }
                    })
                }
            });
        this.props.cancelActionHandler(oldState, id)
    };

    layoutEditAllAndValidateAll = () => {
        const {
            editAllHandler,
            kiosksManager,
            dataTable,
            translations
        } = this.props;

        let flagToNotEmptyValue = false;
        let isChecked           = false;

        dataTable.bodyData
            .forEach( (elem) => {
                const editable = filter(elem.elements, { editable : true });
                const checked = filter(elem.elements, { isChecked : true });

                if(checked.length){
                    isChecked = true
                }
                editable.forEach( (item) => {
                    if(!item.value.length){
                        flagToNotEmptyValue = true
                    }

                })
            });

        const layoutEditAll =
            dataTable.editAll ?
                <Fragment>
                    <ActionButton
                        actionHandler={this.ValidationEditAllOneClickHandler}
                        text={translations['common__text__save']}
                        bgColor={ theme.colors.validation }
                        margin='true'
                        disabled={flagToNotEmptyValue}
                    />
                    <ActionButton
                        actionHandler={() => this.cancelEditAllHandler(kiosksManager.items.Kiosks)}
                        text={translations['common__text__cancel']}
                        bgColor={ theme.colors.cancel }
                    />
                </Fragment>
                :
                <UserAuthorizationLayout
                    name={userPrivileges.manage[11]}
                >
                    <ActionButton
                        actionHandler={dataTable.editOne ? null : editAllHandler}
                        img={WhiteEdit}
                        text={translations['common__text__massChange']}
                        disabled={dataTable.editOne || !isChecked}
                    />
                </UserAuthorizationLayout>;
        return layoutEditAll
    };

    render() {
        const {
            refCollection,
            kiosksManager,
            dataTable,
            editInputHandler,
            checkboxChangeHandler,
            translations,
            initSortTableHandle,
        } = this.props;

        return (
            <PageWrapper>
                <LineTableHeader>
                    <TitleHeader>
                        {/* Gestion des machines */}
                        {translations['page__kioskManagement__kioskTable_title']}
                    </TitleHeader>
                </LineTableHeader>
                <ContentTableWrapper>
                    <LineTableHeader>
                        <TitleHeaderTable color={theme.colors.primary}>
                            {
                                kiosksManager.items.storeCompany
                            }
                        </TitleHeaderTable>
                        <ActionWrapper>
                            { this.layoutEditAllAndValidateAll() }
                        </ActionWrapper>
                    </LineTableHeader>
                    <TableLayout
                        headerData={dataTable.headerData}
                        bodyData={dataTable.bodyData}
                        column={dataTable.headerData.length}
                        refCollection={refCollection}
                        editInputHandler={editInputHandler}
                        checkboxChangeHandler={checkboxChangeHandler}
                        editAllActive={dataTable.editAll}
                        initSortTableHandle={initSortTableHandle}
                        translations={translations}
                    />
                </ContentTableWrapper>
            </PageWrapper>

        )
    }
}

const mapStateToProps = state => {
    return {
        kiosksManager: state.kiosksManager,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionsKiosks: bindActionCreators(kiosksActionCreators, dispatch),
    };
};

export default TableCheckboxEditable(connect(mapStateToProps, mapDispatchToProps)(KiosksManager))
