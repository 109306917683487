import React from 'react'
import styled from 'styled-components'
import { useSelector } from "react-redux";

/////////////////////////////
//  START style
////////////////////////////
const TableBody = styled.div`
    display: flex;
    background-color: ${ props => props.theme.colors.white };
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    height: ${/* props => props.nested ? '30px' : '30px' */ '30px'};
    border-bottom: 1px solid ${ props => props.theme.colors.borderTable};
    grid-column: 1 / -1
`;
/////////////////////////////
//  END style
////////////////////////////

const EmptyRowBody = () => {

    // TODO: test of redux new API
    const translate = useSelector( state => state.languages.translations['common__text__noDataToDisplay'])

    return (
            <TableBody>
                {translate}
            </TableBody>
        )

};

EmptyRowBody.propTypes = {

};

export default EmptyRowBody
