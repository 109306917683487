import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { find, last, forEach, cloneDeep, map, orderBy } from 'lodash'
import './TableNested.css'

/////////////////////////////
//  START style
////////////////////////////

/////////////////////////////
//  END style
////////////////////////////


export default function(ComposedComponent) {
    class TableNested extends Component {
        static propTypes = {
            selectedElement: PropTypes.object,       // element selected on clic action button
            type: PropTypes.bool,                    // with deposit or not
        };
        state = {
            headerData: [
    
            ],
            bodyData: [
    
            ],
            sort: {
                column: "",
                sortType: "",
            },
        };

        refNestedCollection = [];

        initSortTableHandle = (column) => {
            const { sort, bodyData } = this.state;
            const oldBodyData   = cloneDeep(bodyData);
            const oldSort       = cloneDeep(sort);

            this.setState({ flag : false});

            if( oldSort.column !== column) {
                oldSort.column      = column;
                oldSort.sortType    = ''
            }

            if ( oldSort.sortType === 'asc' ) {
                oldSort.sortType = 'desc'
            } else {
                oldSort.sortType = 'asc'
            }

            let allElem = map(oldBodyData, (elem,) => {
                let item = find(elem.elements, {name: oldSort.column});
                return {
                    id : elem.id,
                    elem: item.isNumber ? parseFloat(item.sortValue) : item.sortValue,
                }
            });
            allElem = orderBy(allElem, ['elem'], [oldSort.sortType]);
            allElem = map(allElem, elem => {
                return find(oldBodyData, { id : elem.id})
            });

            this.setState({
                bodyData: allElem,
                sort: oldSort,
            })
        };

        hydrateStateHandler = (data) => {
            this.setState({
                headerData: data.header,
                bodyData: data.body,
            })
        };

        editInputHandler = (evt) => {
            const id            = parseInt(evt.target.id);
            const name          = evt.target.name;
            const inputValue    = evt.target.value;
            const oldState      = {...this.state};

            oldState.bodyData
                .forEach( (elem) => {
                    if( elem.id === id ) {
                        elem.elements.forEach( (item) => {
                            if (item.name === name){
                                if( item.active ){
                                    item.value = inputValue
                                }
                            }
                        })
                    }
                });

            this.setState({...oldState})
        };

        nestedOnClickHandler = (id) => {
            const oldState = {...this.state};
            // animation of arrow
            this.refNestedCollection[id].detail.children[0].children[0].classList.toggle('open-nested');
            forEach(oldState.bodyData, (item) => {
                if(item.id === id) {
                    last(item.elements).display === 'flex' ?
                        last(item.elements).display='none'
                    :
                        last(item.elements).display='flex'
                }
            });
            this.setState({...oldState})
        };

        render() {            
            return (
                <Fragment>
                    <ComposedComponent
                        nestedOnClickHandler={this.nestedOnClickHandler}
                        hydrateStateHandler={this.hydrateStateHandler}
                        refCollection={this.refNestedCollection}
                        editInputHandler={this.editInputHandler}
                        dataTable={this.state}
                        initSortTableHandle={this.initSortTableHandle}
                        { ...this.props } 
                    />
                </Fragment>
            )
        }
    }

    return TableNested
}

