import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import { isUndefined, cloneDeep } from 'lodash'

// component import
import DateTimePickerFuture from '../../containers/Modals/DateTimePickerFuture'
import DateFormat from "../../helpers/dateFormat";

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { createPromoActionCreators } from '../../state/actions';


/////////////////////////////
//  START style
////////////////////////////
const ModalWrapper = styled.div`
    position: absolute;
    top: ${ props => props.postop + 'px' };
    left: 0;
    display: flex;
    justify-items: center;
    align-items: center;
    width: 1024px;
    height: 100vh;
    z-index: 19;
    overflow-x: hidden;
`;
const ModalBackground = styled.div`
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.5;
    z-index: 19;
    width: 1024px;
    height: 100vh;
`;
/////////////////////////////
//  END style
////////////////////////////

export default function ( ComposedComponent ) {
    class DatePickerFutureCreate extends Component {
        static propTypes = {
            selectedElement: PropTypes.object,       // element selected on clic action button
            type: PropTypes.bool,                    // with deposit or not
            translations: PropTypes.object,
        };

        constructor(props) {
            super(props);

            this.state = {
                date: {
                    startDate: moment(this.props.itemsPromo.dateStart).toDate(),
                    endDate: moment(this.props.itemsPromo.dateEnd).toDate(),
                    touch: false,
                    timeTouch: false,
                    validate: false,
                    isStarted: false,
                    message: this.props.translations['page__promoDetail__promoDates_pleaseSelectDates']
                },
                showModal: false,
                windowPageYOffset: 0,
                windowPageXOffset: 0,
            };
            this.defaultState = this.state
        }


        componentDidUpdate( prevProps, prevState ) {
            const { itemsPromo, match, translations } = this.props;
            const { date } = this.state
            const isUpdate = !isUndefined( match.params.id );

            if (
                ( itemsPromo.items.promo !== prevProps.itemsPromo.items.promo ) &&
                isUpdate
            ) {
                const dateOldState = cloneDeep( this.state.date );
                dateOldState.startDate = new Date( itemsPromo.items.promo.dateStart );
                dateOldState.endDate = new Date( itemsPromo.items.promo.dateEnd );
                dateOldState['touch'] = true;
                dateOldState['validate'] = true;

                if ( itemsPromo.isStarted ) {
                    dateOldState.message = translations['page__promoDetail__promoDates_promoStartedStartDateFixed'] 
                    dateOldState['isStarted'] = itemsPromo.isStarted;
                }
                this.defaultState.date = dateOldState
                this.setState( { date: { ...dateOldState } } )
            }

            if (
                ( prevState.date.startDate !== date.startDate ) ||
                ( prevState.date.endDate !== date.endDate )
            ) {
                this.validateDateHandler()
            }

        }

        validateDateHandler = () => {
            const { startDate, endDate } = this.state.date
            const oldState = {...this.state.date}

            // check if startDate and endDate are valid : boolean
            const createDateIsValid  = !(
                DateFormat.dateEndIsBefore(startDate, endDate) ||
                DateFormat.dateIsBeforeNow(startDate) ||
                DateFormat.dateIsBeforeNow(endDate)
            )

            // check for started promo if startDate and endDate are valid : boolean
            const updateDateIsValid = !(
                DateFormat.dateEndIsBefore(startDate, endDate) ||
                DateFormat.dateIsBeforeNow(endDate)
            )

            oldState.validate = oldState.isStarted ? updateDateIsValid : createDateIsValid

            this.setState( {
                date: { ...oldState }
            } )
        }

        startDateHandleChange = date => {
            const prevState = cloneDeep( this.state.date );
            prevState['startDate'] = new Date( date )
            prevState.touch = true;

            this.setState( {
                date: {
                    ...prevState
                }
            } );
        };
        endDateHandleChange = date => {
            const prevState = cloneDeep( this.state.date );
            prevState['endDate'] = new Date( date );
            prevState.touch = true;

            this.setState( {
                date: {
                    ...prevState
                }
            } );
        };
        startTimeHandleChange = date => {
            const { translations } = this.props;
            const prevState = cloneDeep( this.state.date );
            prevState['startDate'] = moment( date ).toDate()

            if ( date < new Date() ) {
                prevState.validate = false;
                prevState.message = translations['page__promoDetail__promoDates_startDateInPast']
            } else {
                prevState.validate = true;
                prevState.message = ""
            }

            prevState.touch = true;
            prevState.timeTouch = true;

            this.setState( {
                date: {
                    ...prevState
                }
            } );
        };
        endTimeHandleChange = date => {
            const prevState = cloneDeep( this.state.date );
            prevState.endDate = date;
            prevState.touch = true;
            prevState.timeTouch = true;

            this.setState( {
                date: {
                    ...prevState
                }
            } );
        };
        openDateModalHandler = () => {
            const prevState = cloneDeep( this.state );
            prevState['showModal'] = true;
            prevState.windowPageYOffset = window.pageYOffset;
            prevState.windowPageXOffset = window.pageXOffset;

            this.setState( {
                ...prevState
            } );
            window.addEventListener( 'scroll', this.onScrollHandler );
        };
        closeDateModalHandler = () => {
            const prevState = cloneDeep( this.state );
            prevState['showModal'] = false;

            this.setState( {
                ...prevState
            } );
            window.removeEventListener( 'scroll', this.onScrollHandler );
        };

        getDateFormatToLayout = ( date ) => {
            return moment( date ).format( 'DD-MM-YYYY' )
        };

        getDateFormatLongToLayout = ( date ) => {
            return moment( date ).format( 'DD-MM-YYYY HH:mm' )
        };


        onClickToDayHandler = () => {
            const dateStart = moment().toDate();
            const dateEnd = moment().toDate();

            this.startDateHandleChange( dateStart );
            this.endDateHandleChange( dateEnd );
        };
        onClickoneWeekHandler = () => {
            const dateStart = moment().subtract( 7, 'days' ).toDate();
            const dateEnd = moment().toDate();

            this.startDateHandleChange( dateStart );
            this.endDateHandleChange( dateEnd );
        };
        onClickOneMonthHandler = () => {
            const dateStart = moment().subtract( 1, 'months' ).toDate();
            const dateEnd = moment().toDate();

            this.startDateHandleChange( dateStart );
            this.endDateHandleChange( dateEnd );
        };

        updateDate = () => {
            const { date } = this.state;
            const { actionsCreatePromo, match } = this.props;

            this.defaultState.date = date

            const idPromo   = match.params.id;
            const dateStart = date.startDate;
            const dateEnd   = date.endDate;

            const data = {
                dateStart: dateStart,
                dateEnd: dateEnd,
                itemsStore: []
            };
            if ( !isUndefined( idPromo ) ) {
                actionsCreatePromo.checkCreatePromoValidation( data, idPromo )
            } else {
                actionsCreatePromo.checkCreatePromoValidation( data )
            }

            this.closeDateModalHandler()
        };

        cancelUpdateHandler = () => {
            this.setState({...this.defaultState})
        };

        onScrollHandler = () => {
            const { showModal, windowPageYOffset, windowPageXOffset } = this.state;
            if ( showModal ) {
                window.scrollTo( windowPageXOffset, windowPageYOffset );
            }
        };

        render() {
            let posTop = window.scrollY - 120;
            const { translations } = this.props;
            const { date } = this.state

            return (
                <Fragment>
                    {
                        this.state.showModal &&
                        <ModalWrapper
                            postop={ posTop }
                        >
                            <DateTimePickerFuture
                                isStarted={ date.isStarted }
                                isValidDate={ date.validate }
                                toDayHandler={ this.onClickToDayHandler }
                                weekSelectHandler={ this.onClickoneWeekHandler }
                                monthSelectHandler={ this.onClickOneMonthHandler }
                                closeModalHandler={ this.closeDateModalHandler }
                                startDate={ date.startDate }
                                endDate={ date.endDate }
                                startDateHandleChange={ this.startDateHandleChange }
                                endDateHandleChange={ this.endDateHandleChange }
                                startTimeHandleChange={ this.startTimeHandleChange }
                                endTimeHandleChange={ this.endTimeHandleChange }
                                formatDate={ this.getDateFormatToLayout }
                                updateDate={ this.updateDate }
                                cancelUpdateHandler={ this.cancelUpdateHandler }
                                translations={ translations }
                            />
                            <ModalBackground
                                onClick={ this.cancelUpdateHandler }
                            />
                        </ModalWrapper>
                    }
                    <ComposedComponent
                        closeDateModalHandler={ this.closeDateModalHandler }
                        getDateFormat={ this.getDateFormat }
                        formatDateLong={ this.getDateFormatLongToLayout }
                        formatDate={ this.getDateFormatToLayout }
                        openDateModalHandler={ this.openDateModalHandler }
                        validationDate={ this.updateDate }
                        cancelDate={ this.cancelUpdateHandler }
                        date={ this.state.date }

                        { ...this.props }
                    />
                </Fragment>
            )
        }
    }

    const mapStateToProps = state => {
        return {
            itemsPromo: state.createPromoItemsStore,
            translations: state.languages.translations
        };
    };

    const mapDispatchToProps = ( dispatch ) => {
        return {
            actionsCreatePromo: bindActionCreators( createPromoActionCreators, dispatch ),
        };
    };

    return connect( mapStateToProps, mapDispatchToProps )( DatePickerFutureCreate )
}