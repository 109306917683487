import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import { cloneDeep, find, map, forEach, isEmpty, assign, findIndex } from 'lodash';

extendUpdate(update);

const stockManager = {
    [ActionsTypes.GET_ITEMS_KIOSKS_STOCK]: (state,) => {

        return update(state, {
            itemsKiosksStock: {
                isLoading: {
                    $set: true,
                }
            },

        })
    },

    [ActionsTypes.GET_ITEMS_KIOSKS_STOCK_SUCCESS]: (state, payload) => {
        const filters = payload.itemsKiosksStock.filter;

        if(!isEmpty(filters)){
            forEach(filters, (elem, id) => {
                assign(elem, { active : id === 0 });
                forEach(elem.brands, ( item, ) => {
                    assign(item, { active : true })
                })
            })
        }

        return update(state, {
            itemsKiosksStock: {
                isLoading: {
                    $set: false,
                },
                items: {
                    $set: payload.itemsKiosksStock.items
                },
                filter:{
                    $set: payload.itemsKiosksStock.filter
                },
            },

        })
    },

    [ActionsTypes.GET_ITEMS_KIOSKS_STOCK_FAILURE]: (state, payload) => {

        return update(state, {
            itemsKiosksStock: {
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: payload.error
                }
            },

        })
    },
    [ActionsTypes.UPDATE_ITEMS_KIOSKS_STOCK]: (state,) => {
        return update(state, {
            itemsKiosksStock: {
                isLoading: {
                    $set: true,
                }
            },

        })
    },

    [ActionsTypes.UPDATE_ITEMS_KIOSKS_STOCK_SUCCESS]: (state, payload) => {
        const oldState  = cloneDeep( state.itemsKiosksStock.items);
        const updated   = payload.itemsKiosksStock.updated;

        const newState  = map(oldState, (elem) => {
            const data = find(updated, {id: elem.id});
            if( data ) {
                elem.alertStock = data.alertStock
            }
            return elem
        });
        return update(state, {
            itemsKiosksStock: {
                isLoading: {
                    $set: false,
                },
                items: {
                    $set: [...newState]
                }
            },

        })
    },

    [ActionsTypes.UPDATE_ITEMS_KIOSKS_STOCK_FAILURE]: (state, payload) => {

        return update(state, {
            itemsKiosksStock: {
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: payload.error
                }
            },

        })
    },

    [ActionsTypes.UPDATE_STOCK_FAMILY_SELECTED]: (state, payload) => {
        const oldState = cloneDeep(state.itemsKiosksStock.filter);
        forEach(oldState, (elem) => {
            elem.id === payload.id
                ? elem.active = true
                : elem.active = false
        });

        return update(state, {
            itemsKiosksStock: {
                filter: {
                    $set: [...oldState]
                }

            }

        })
    },

    [ActionsTypes.UPDATE_STOCK_BRANDS_SELECTED]: (state, payload) => {
        const oldState      = cloneDeep(state.itemsKiosksStock.filter);
        const idxFamily     = findIndex(oldState, {active : true});
        const idxBrand      = findIndex(oldState[idxFamily].brands, { id: payload.id});

        oldState[idxFamily].brands[idxBrand].active = payload.isChecked;

        return update(state, {
            itemsKiosksStock: {
                filter: {
                    $set: [...oldState]
                }
            },
        })
    },

};

export default stockManager