import React, { PureComponent, Fragment } from 'react'
import styled from 'styled-components'
import Loader from 'react-loader-spinner'

// redux import
import { connect } from 'react-redux'

/////////////////////////////
//  START style
////////////////////////////
const LoadingWrapper = styled.div`
    position: absolute;
    top: ${ props => props.postop + 'px'};
    left: 0;
    display: flex;
    justify-items: center;
    align-items: center;
    width: 1024px;
    height: 100vh;
`;
const LoadingBackground = styled.div`
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 100;
    width: 1024px;
    height: 100vh;
`;
const ModalWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 90%;
    overflow-y: auto;
    left: 50%;
    top: 50%;
    z-index: 200;
    transform: translate(-50%, -50%);
`;
const TitleLoading = styled.div`
    font-size: ${ props => props.theme.font.fxxxl };
    margin-bottom: 20px;
    font-weight: bold;
`;
/////////////////////////////
//  END style
////////////////////////////


export default function (ComposedComponent) {
    class LoadingManager extends PureComponent {
        static propTypes = {
        };
        state = {
            showModal: false,
            windowPageOffset: 0 ,
        };
        componentDidUpdate(prevProps) {
            const { globalLoading } = this.props;
            if(globalLoading.loading !== prevProps.globalLoading.loading) {
                if(globalLoading.loading) {
                    this.openModalHandler()
                }else{
                    this.closeModalHandler()
                }
            }
        }
        componentWillUnmount = () => {
            this.closeModalHandler()
        };
        openModalHandler = (  ) => {
            const { globalLoading } = this.props;

            this.setState({
                showModal: globalLoading.loading,
                windowPageYOffset: window.pageYOffset,
                windowPageXOffset: window.pageXOffset,
            });
            
            window.addEventListener('scroll', this.onScrollHandler);
        };
        closeModalHandler = () => {
            const { globalLoading } = this.props;

            this.setState({
                showModal: globalLoading.loading,
            });
            window.removeEventListener('scroll', this.onScrollHandler);
        };
        onScrollHandler = () => {
            const { showModal, windowPageYOffset, windowPageXOffset } = this.state;
            if( showModal ) {
                window.scrollTo(windowPageXOffset, windowPageYOffset);
            }
        };
        render() {
            let posTop = window.scrollY - 120;
            return (
                <Fragment>
                    {
                        this.state.showModal &&
                                <LoadingWrapper
                                    postop={posTop}
                                >
                                    <ModalWrapper
                                        loader
                                    >
                                        <TitleLoading>
                                            Chargement en cours
                                        </TitleLoading>
                                        <Loader 
                                            type="ThreeDots"
                                            color="#0885D5"
                                            height={150}
                                            width={150}
                                        />
                                    </ModalWrapper>
                                    <LoadingBackground
                                        postop={posTop}
                                    />
                                </LoadingWrapper>
                    }
                    <ComposedComponent
                        { ...this.props }
                    />
                </Fragment>
            )
        }
    }

    const mapStateToProps = state => {
        return {
            globalLoading: state.globalLoading,
        };
      };
      
    return connect(mapStateToProps, null)(LoadingManager)
}

