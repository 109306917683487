///////////////////////////
//      API ROUTES      //
/////////////////////////

///////////////////////////
//        START         //
//      CONSTANT        //
/////////////////////////
// Distributor
/**
 * distributor url config
 * @param   {string}        id distributor of user
 * @returns {string}        /distributors/'id'
 */
export function DISTRIBUTORS(id) {
    return `/distributors/${id}`
} // END FUNCTION

// STORE
/**
 *
 * @param   {string}     id store of user
 * @return  {string}     /stores/'id'
 */
export function STORE(id) {
    return `/stores/${id}`;
} // END FUNCTION

// KIOSK
/**
 * api start with store and kiosk
 * @param   {string}    id id kiosk select by user
 * @returns {string}    /kiosks/'id'
 */
export function KIOSK(id) {
    return `/kiosks/${id}`
} // END FUNCTION

///////////////////////////
//        END           //
//      CONSTANT        //
/////////////////////////

//************************************************************
//************************************************************
// AUTH
/**
 *
 * @returns {string}
 */
export function GET_BEARER_TOKEN() {
    return `/auth/jwt`;
}
/**
 *
 * @returns {string}
 */
export function GET_CURRENT_USER() {
    return `/current-user`;
}

/**
 *
 * @returns {string}            /forgot-password
 */
export function POST_FORGOT_PASSWORD() {
    return `/forgot-password`;
}
//************************************************************

//************************************************************
// BRANDS
//BRANDS_BY_FAMILIES
/**
 * api url to get brands by family which select user
 * @param   {Object}      ids   id store of user
 * @param   {string}      idFamily  idFamily selected by user
 * @return  {string}      /distributor/'id'/store/'id'/kiosks/'id'/itemKiosk/families/'id'/brands
 */
export function GET_BRANDS_BY_FAMILIES(ids, idFamily) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${STORE(ids.idStore)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/itemKiosk` +
        `/families/${idFamily}` +
        `/brands`
}// END FUNCTION
//************************************************************

//************************************************************
// FAMILIES
//kiosks families brands
/**
 * api url to get the families and brands by kiosks
 * @param   {Object}      ids of user
 * @returns {string}      /distributors/'id'/kiosks/'id'/catalog/filter
 */
export function GET_FAMILIES_AND_BRANDS_BY_KIOSK(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/catalog/filter`
}// END FUNCTION
//************************************************************

//************************************************************
// ITEMS_KIOSK
/**
 * @param   {Object}      ids
 * @returns {string}    api url /distributors/'id'/kiosks/'id'/catalog/items/'id'
 */
export function GET_ITEM_KIOSK(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/catalog` +
        `/items/${ids.item}`
}// END FUNCTION

/**
 * @param   {Object}      ids
 * @returns {string}    api url /distributors/'id'/kiosks/'id'/catalog/items/'id'/checkOrderValidation
 */
export function POST_ITEM_KIOSK_CHECK_ORDER_VALIDATION(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/catalog` +
        `/items/${ids.item}` +
        `/checkOrderValidation`
}// END FUNCTION

/**
 * @param   {Object}        ids
 * @param   {string}        idFamily
 * @returns {string}        /distributors/'id'/kiosks/'id'/catalog/families/'id'/items
 */
export function GET_ITEMS_KIOSK_BY_FAMILY(ids, idFamily) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/catalog` +
        `/families/${idFamily}` +
        `/items`
}// END FUNCTION
//************************************************************

//************************************************************
// SALES_ORDERS
/**
 *
 * @param   {Object}        ids
 * @returns {string}        /distributor/'id'/stores/'id'/kiosks/'id'/salesorders/bycode
 */
export function POST_SALES_ORDERS(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/salesorders/bycode`
}// END FUNCTION

/**
 *
 * @param   {Object}        ids
 * @param   {string}        id sales orders id
 * @returns {string}        /distributor/'id'/stores/2/kiosks/1/salesorders/codes/1/codeReview
 */
export function GET_SALES_ORDERS(ids, id) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/salesorders` +
        `/codes/${id}` +
        `/codeReview`
}// END FUNCTION

/**
 *
 * @param   {Object}        ids
 * @param   {string}        id sales orders id
 * @returns {string}        /distributor/'id'/stores/'id'/kiosks/'id'/salesorders/'id'/ReactivateCode
 */
export function POST_SALES_ORDERS_CODES_RENEW(ids, id) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/salesorders` +
        `/codes/${id}` +
        `/ReactivateCode`
}// END FUNCTION

/**
 *
 * @param   {object}        ids
 * @param   {string}        id sales orders id
 * @returns {string}        /distributors/'id'/kiosks/'id'/salesorders/'id'/customer-info/update
 */
export function UPDATE_USER_CONSIGNMENT_INFO(ids, id) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/salesorders/${id}` +
        `/customer-info` +
        `/update`
}// END FUNCTION

/**
 *
 * @param   {object}        ids
 * @returns {string}        /distributors/1/kiosks/1/salesorders/1/sendcodebysms
 */
export function GET_SEND_PHONE_NUMBER(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/salesorders/${ids.idElem}` +
        `/sendcodebysms`
}// END FUNCTION

/**
 *
 * @param   {object}        ids
 * @returns {string}        /distributors/1/kiosks/1/salesorders/1/sendcodebymail
 */
export function GET_SEND_EMAIL(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/salesorders/${ids.idElem}` +
        `/sendcodebymail`
}// END FUNCTION

/**
 *
 * @param   {Object}        ids
 * @returns {string}        /distributors/'id'/kiosks/'id'/salesorderline/'id'/deposit-contract

 */
export function PUT_SALES_ORDERS_DEPOSIT_CONTRACT(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/salesorderline/${ids.idItem}` +
        `/deposit-contract?timeZoneID=${ids.timezone}`
}// END FUNCTION

/**
 *
 * @param   {Object}        ids
 * @param   {String}        scope Merchant or customer
 * @returns {string}        /distributor/'id'/kiosk/'id'/salesorder/'id'/tpe/merchant

 */
export function GET_SALES_HISTORIES_MERCHANT_TICKET(ids, scope) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/salesorders/${ids.idItem}` +
        `/tpe` +
        `/${scope}`
}// END FUNCTION

/**
 *
 * @param   {Object}        ids
 * @returns {string}        /distributors/'id'/kiosks/'id'/salesorders/codes/'id'/display

 */
export function GET_PRINT_CODE_TICKET(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/salesorders/codes/${ids.idItem}` +
        `/display`


}// END FUNCTION

//************************************************************

//************************************************************
//SALES_HISTORIES
/**
 *
 * @param   {Object}        ids
 * @returns {string}        /distributor/'id'/stores/'id'/kiosks/'id'/salesorders
 */
export function GET_SALES_HISTORIES(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/salesorders`
}// END FUNCTION

/**
 *
 * @param   {Object}        ids
 * @param   {string}        itemKioskId
 * @returns {string}        /distributors/'id'/kiosks/'id'/catalog/items/'id'/salesorders
 * @constructor
 */
export function GET_SALES_HISTORIES_BY_ITEM_KIOSK(ids, itemKioskId) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/catalog` +
        `/items/${itemKioskId}` +
        `/salesorders`
}// END FUNCTION

/**
 *
 * @param   {Object}        ids
 * @returns {string}        /distributors/'id'/kiosks/'id'/salesorders/'id'

 */
export function GET_SALES_ORDERS_BY_SALES(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/salesorders/${ids.idItem}`;
}// END FUNCTION
//************************************************************

//************************************************************
//CODES_HISTORIES
/**
 *
 * @param   {Object}        ids
 * @returns {string}        /distributor/'id'/kiosks/'id'/salesorders/codes
 */
export function GET_CODES_HISTORIES(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/salesorders/codes`
}// END FUNCTION
/**
 *
 * @param   {Object}    ids
 * @returns {string}    /distributors/'id'/kiosks/'id'/salesorders/codes/'id'/delete
 */
export function DELETE_CODES_HISTORY(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/salesorders` +
        `/codes/${ids.itemId}` +
        `/delete`
}// END FUNCTION

//************************************************************

//************************************************************
// ITEMS_STORE_PRICES
/**
 *
 * @param   {Object}         ids
 * @returns {string}        /distributor/'id'/stores/'id'/items
 */
export function GET_ITEMS_STORE_PRICES(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}${STORE(ids.idStore)}/items`
}// END FUNCTION

// UPDATE_ITEM_STORE
/**
 *
 * @param   {Object}        ids
 * @returns {string}        /stores/'id'/items/update
 */
export function UPDATE_ITEM_STORE(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${STORE(ids.idStore)}` +
        `/items` +
        `/update`
}// END FUNCTION
//************************************************************

//************************************************************
// ITEM_STORE_PROMO
/**
 *
 * @param   {Object}        ids
 * @returns {string}        /stores/'id'/promotions
 */
export function GET_PROMO_ITEM_STORE(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${STORE(ids.idStore)}` +
        `/promotions`
}// END FUNCTION

// ITEM_DETAIL_PROMO
/**
 *
 * @param   {Object}        ids
 * @param   {string}        idItem
 * @returns {string}        /stores/'id'/promotion/'id'
 */
export function GET_PROMO_ITEM_DETAIL(ids, idItem) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${STORE(ids.idStore)}` +
        `/promotion/${idItem}`
}// END FUNCTION

// ITEM_DETAIL_PROMO_ALL
/**
 *
 * @param   {Object}        ids
 * @returns {string}        /stores/'id'/promotions/all
 */
export function GET_PROMO_ITEM_DETAIL_ALL(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${STORE(ids.idStore)}` +
        `/promotions` +
        `/all`
}// END FUNCTION

// ITEM_STORE_DELETE
/**
 *
 * @param   {Object}        ids
 * @param   {string}        idItem
 * @returns {string}        /stores/'id'/promotion/'id'/delete
 */
export function DELETE_PROMO_ITEM_STORE(ids, idItem) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${STORE(ids.idStore)}` +
        `/promotion/${idItem}` +
        `/delete`
}// END FUNCTION

/**
 * check if items store in promotion are not in other promotion on the same date range
 * @param   {Object}        ids
 * @returns {string}        /distributors/'id'/stores/'id'/promotion/checkitems
 */
export function CHECK_ITEMS_STORE_PROMOTION(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${STORE(ids.idStore)}` +
        `/promotion` +
        `/checkitems`
}// END FUNCTION
/**
 * check if items store in promotion are not in other promotion on the same date range
 * @param   {Object}        ids
 * @returns {string}        /distributors/'id'/stores/'id'/promotion/'id'/checkitems
 */
export function CHECK_ITEMS_STORE_PROMOTION_UPDATE(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${STORE(ids.idStore)}` +
        `/promotion/${ids.promotion}` +
        `/checkitems`
}// END FUNCTION


/**
 * check if items store in promotion are not in other promotion on the same date range
 * @param   {Object}        ids
 * @returns {string}        /distributors/'id'/stores/'id'/promotion/create
 */
export function POST_PROMTION(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${STORE(ids.idStore)}` +
        `/promotion/` +
        `/create`
}// END FUNCTION

/**
 * check if items store in promotion are not in other promotion on the same date range
 * @param   {Object}        ids
 * @returns {string}        /distributors/'id'/stores/'id'/promotion/'id'/update
 */
export function PUT_UPDATE_PROMO(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${STORE(ids.idStore)}` +
        `/promotion/${ids.promotion}` +
        `/update`
}// END FUNCTION


//************************************************************

//************************************************************
// Kiosks manager
/**
 *
 * @param   {Object}        ids
 * @returns {string}        /stores/'id'/kiosks
 */
export function GET_KIOSKS(ids) {
    return `${STORE(ids.idStore)}/kiosks`
}// END FUNCTION

/**
 *
 * @param   {Object}        ids
 * @returns {string}        /stores/'id'/kiosks/update
 */
export function UPDATE_KIOSKS_STORE(ids) {
    return `${STORE(ids.idStore)}/kiosks/update`
}// END FUNCTION
//************************************************************

//************************************************************
// myAccount
/**
 *
 * @returns {string}        /current-user/my-account
 */
export function GET_MY_ACCOUNT() {
    return `/current-user/my-account`
}// END FUNCTION



/**
 *
 * @returns {string}        /current-user/my-account/update
 */
export function UPDATE_MY_ACCOUNT() {
    return `/current-user/my-account/update`
}// END FUNCTION

//************************************************************


// ************************************************************
// user detail
/**
 *  @param  {Object}        ids
 * @returns {string}        /companytyped/'id'/users/'id'
 */
export function GET_USER_DETAIL(ids) {
    return `/companytyped/${ids.idStore}` +
        `/users/${ids.idUser}`
}// END FUNCTION



/**
 * @param   {Object}        ids
 * @returns {string}        /companytyped/'id'/users/'id'/update
 */
export function UPDATE_USER_DETAIL(ids) {
    return `/companytyped/${ids.idStore}` +
        `/users/${ids.idUser}` +
        `/update`
}// END FUNCTION

/**
 *
 * @param   {Object}        ids
 * @returns {string}        /companytyped/'id'/users/'id'/reset-password
 */
export function USER_DETAIL_RESET_PASSWORD(ids) {
    return `/companytyped/${ids.idStore}` +
        `/users/${ids.idUser}` +
        `/reset-password`

}// END FUNCTION

/**
 *
 * @param   {Object}        ids
 * @returns {string}        /companytyped/'id'/users/create
 */
export function POST_USER_CREATE(ids) {
    return `/companytyped/${ids.idStore}` +
        `/users` +
        `/create`

}// END FUNCTION

//************************************************************

//************************************************************
// adminUser

/**
 * @param {Object}          ids
 * @returns {string}        /companytyped/'id'/users
 */
export function GET_ADMIN_USER(ids) {
    return `/companytyped/${ids.idStore}` +
        `/users`
}// END FUNCTION

/**
 * @param {Object}          ids
 * @returns {string}        /companytyped/'id'/users/'id'/disabled
 */
export function DISABLED_ADMIN_USER(ids) {
    return `/companytyped/${ids.idStore}` +
        `/users/${ids.idUser}` +
        `/disabled`
}// END FUNCTION

/**
 * @param {Object}          ids
 * @returns {string}        /companytyped/'id'/users/'id'/delete
 */
export function DELETE_ADMIN_USER(ids) {
    return `/companytyped/${ids.idStore}` +
        `/users/${ids.idUser}` +
        `/delete`
}// END FUNCTION

//************************************************************

//************************************************************
// paymentCollection
/**
 *
 * @param   {Object}        ids
 * @returns {string}        /distributors/'id'/kiosks/'id'/paymentscollection
 */
export function GET_PAYMENT_COLLECTION(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/paymentscollection`
}// END FUNCTION

/**
 *
 * @param   {Object}        ids
 * @param   {string}        id
 * @returns {string}        /distributors/'id'/kiosks/'id'/paymentscollection/'id'
 */
export function GET_PAYMENT_COLLECTION_DETAIL(ids, id) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/paymentscollection/${id}`
}// END FUNCTION

/**
 *
 * @param   {Object}        ids
 * @param   {string}        id
 * @returns {string}        /distributors/'id'/kiosks/'id'/paymentscollection/'id'/salesorder/exclused
 */
export function GET_SALES_ORDER_EXCLUDED(ids, id) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/paymentscollection/${id}` +
        `/salesorder` +
        `/exclused`
}// END FUNCTION

/**
 *
 * @param   {Object}        ids
 * @param   {string}        id
 * @returns {string}        /distributors/'id'/kiosks/'id'/paymentscollection/'id'/grouping
 */
export function UPDATE_PAYMENT_COLLECTION_SALES_ORDER(ids, id) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/paymentscollection/${id}` +
        `/grouping`
}// END FUNCTION

/**
 *
 * @param   {Object}        ids
 * @returns {string}        /distributors/'id'/kiosks/'id'/paymentscollection/'id'/header
 */
export function GET_SALES_ORDER_PAYMENT_COLLECTION_HEADER(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/paymentscollection/${ids.idPaymentCollection}` +
        `/header`
}// END FUNCTION

//************************************************************

//************************************************************
// stock manager
/**
 *
 * @param   {Object}        ids
 * @returns {string}        /distributors/'id'/kiosks/'id'/items/stock
 */
export function GET_ITEMS_KIOSKS_STOCK(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/items` +
        `/stock`
}// END FUNCTION

/**
 *
 * @param   {Object}        ids
 * @returns {string}        /distributors/'id'/kiosks/'id'/catalog/items/'id'/stock
 */
export function GET_ONE_ITEMS_KIOSKS_STOCK(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/catalog` +
        `/items/${ids.item}` +
        `/stock`
}// END FUNCTION


/**
 *
 * @returns {string}        /items/stockalert/update
 */
export function UPDATE_ITEMS_KIOSKS_STOCK() {
    return `/items` +
        `/stockalert` +
        `/update`
}// END FUNCTION


//************************************************************

//************************************************************
// languages
/**
 *
 * @returns {string}        /languages
 */
export function GET_LANGUAGE_ENABLED() {
    return `/languages` +
        `/public`
}// END FUNCTION

/**
 *  @param      {string}        idLanguage
 *  @returns    {string}        /translations/languages/'id'
 */
export function GET_TRANSLATION_LANGUAGES_BY_ID(idLanguage) {
    return `/translations` +
        `/languages/${idLanguage}`
}// END FUNCTION

/**
 *  @param      {string}        idLanguage
 *  @returns    {string}        /translations/languages/'id'/public
 */
// /distributors/1/kiosks/1/salesorders/2/customer-info/update
export function GET_TRANSLATION_LANGUAGES_PUBLIC_BY_ID(idLanguage) {
    return `/translations` +
        `/languages/${idLanguage}` +
        `/public`
}// END FUNCTION


//************************************************************


//************************************************************
// logs
/**
 *
 * @returns {string}        /current-user/kiosk/'id'/logs
 */
export function GET_LOGS_ALERT() {
    return `/current-user` +
        `/logs`
}// END FUNCTION

/**
 *  @param  {string}        id log
 * @returns {string}        /current-user/kiosk/'id'/log/'id'/resolved
 */
export function RESOLVED_LOG_ALERT(id) {
    return `/current-user` +
        `/logs/${id}` +
        `/resolved`
}// END FUNCTION


/**
 * 
 * @returns {string}        /current-user/logs/close/all
 */
export function RESOLVED_ALL_LOG_ALERT() {
    return `/current-user` +
        `/logs` +
        `/close` +
        `/all`
}// END FUNCTION


/**
 *
 *  @param  {string}        id log
 * @returns {string}        /current-user/kiosk/'id'/log/'id'/remoted
 */
export function REMOTED_LOG_ALERT(id) {
    return `/current-user` +
        `/logs/${id}` +
        `/remoted`
}// END FUNCTION

/**
 *
 *  @param  {string}        ids
 * @returns {string}        /kiosk/'id'/logs
 */
export function GET_LOGS_HISTORY(ids) {
    return `kiosk/${ids.idKiosk}` +
        `/logs`
}// END FUNCTION


/**
 *
 *  @param  {string}        ids
 * @returns {string}        /companytyped/'id'/kiosk/'id'/users/'id'/logs
 */
export function GET_LOGS_HISTORY_USER(ids) {
    return `/companytyped/${ids.idStore}` +
        `/users/${ids.idUser}` +
        `/logs`
}// END FUNCTION

/**
 *
 *  @param  {string}        ids
 * @returns {string}        /companytyped/'id'/kiosk/'id'/users/logs
 */
export function GET_LOGS_HISTORY_USERS(ids) {
    return `/companytyped/${ids.idStore}` +
        `/users` +
        `/logs`
}// END FUNCTION


//************************************************************


//************************************************************
// dashBoard
/**
 *
 * @returns {string}        /current-user/setting/dashboard
 */
export function GET_DASHBOARD_SETTING() {
    return `/current-user` +
        `/setting` +
        `/dashboard`
}// END FUNCTION

/**
 *
 * @returns {string}        /current-user/setting/dashboard/update
 */
export function PUT_DASHBOARD_SETTING() {
    return `/current-user` +
        `/setting` +
        `/dashboard` +
        `/update`

}// END FUNCTION

/**
*
* @returns {string}        /distributors/'id'/kiosks/'id'/dashboard
*/
export function GET_DASHBOARD(ids) {
    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${KIOSK(ids.idKiosk)}` +
        `/dashboard`

}// END FUNCTION

//************************************************************


//************************************************************
// user
/**
 *
 * @returns {string}        /current-user/setting
 */
export function GET_USER_SETTING() {
    return `/current-user` +
        `/setting`
}// END FUNCTION

/**
 *
 * @returns {string}        /current-user/setting/shortcut/'id'/update
 */
export function PUT_USER_SETTING(id) {
    return `/current-user` +
        `/setting` +
        `/shortcut/${id}` +
        `/update`
}// END FUNCTION

/**
 *
 * @returns {string}        }/current-user/setting/notification/1/update
 */
export function PUT_USER_SETTING_NOTIF(id) {
    return `/current-user` +
        `/setting` +
        `/notification/${id}` +
        `/update`
}// END FUNCTION


//************************************************************

//************************************************************
// export data
/**
 *
 * @param ids                       ids
 * @param scope                     target
 * @param idInput                   idInput
 * @returns {string|boolean}        endPoint
 */
export function GET_EXPORT_DATA(ids, scope, idInput) {

    switch (scope) {

        case 'salesorders':
            return `${DISTRIBUTORS(ids.idDistributors)}` +
                `${KIOSK(ids.idKiosk)}` +
                `/salesorders` +
                `/export-xls`;

        case 'bycode':
            return `${DISTRIBUTORS(ids.idDistributors)}` +
                `${KIOSK(ids.idKiosk)}` +
                `/salesorders` +
                `/bycode` +
                `/export-xls`;

        case 'promotions':
            return `${DISTRIBUTORS(ids.idDistributors)}` +
                `${STORE(ids.idStore)}` +
                `/promotions` +
                `/export-xls`;

        case 'items':
            return `${DISTRIBUTORS(ids.idDistributors)}` +
                `${STORE(ids.idStore)}` +
                `/items` +
                `/export-xls`;

        case 'paymentscollection':
            return `${DISTRIBUTORS(ids.idDistributors)}` +
                `${KIOSK(ids.idKiosk)}` +
                `/paymentscollection` +
                `/export-xls`;

        case 'usersLogs':
            return `companytyped/${ids.idStore}` +
                `/users` +
                `/logs` +
                `/export-xls`;

        case 'userLogs':
            return `companytyped/${ids.idStore}` +
                `/users/${idInput}` +
                `/logs` +
                `/export-xls`;

        case 'kiosksLogs':
            return `/kiosk/${ids.idKiosk}` +
                `/logs` +
                `/export-xls`;

        case 'stock':
            return `${DISTRIBUTORS(ids.idDistributors)}` +
                `${KIOSK(ids.idKiosk)}` +
                `/stock` +
                `/export-xls`;

        default: return false
    }

}// END FUNCTION

//************************************************************

//************************************************************
// Warehouse stock
/**
 *
 * @param ids                       ids
 * @returns {string|boolean}        /distributors/'id'/stores/'id'/warehouse-stock/items
 */
export function GET_WARE_HOUSE_STOCK_MANAGEMENT(ids) {

    return `${DISTRIBUTORS(ids.idDistributors)}` +
        `${STORE(ids.idStore)}` +
        `/warehouse-stock` +
        `/items`
}// END FUNCTION

//************************************************************