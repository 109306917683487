import React, { Component } from 'react'
import { withRouter } from "react-router";
import PropTypes from 'prop-types'
import { map } from 'lodash'
import moment from 'moment'

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import {
    exportDataActionCreators,
    salesHistoriesActionCreators,
} from '../../state/actions';
import { selectIdsUser } from '../../state/selectors/user'

//assets import
import Calendar from '../../assets/icones/global/calendarX2.png'
import Graph from '../../assets/icones/global/graph.svg'
import Telecolection from '../../assets/icones/global/telecollecte.png'


// component import
import ModalDatePicker from '../../Hoc/ModalDatePicker'
import TableLayout from '../TableLayout'
import ActionButton from '../../components/Ui/ActionButton'
import CR from '../../constants/RouteConstants'
import ModalHOC from '../../Hoc/ModalHOC/'
import TableNested from '../../Hoc/TableNested'
import Button from '../../components/Ui/Buttons'
import UserAuthorizationLayout from "../../components/UserAuthorizationLayout";
import userPrivileges from "../../constants/RoleList";
import ExportData from "../../components/ExportData/ExportData";

// style
import {
    PageWrapper,
    LineTableHeader,
    TitleHeader,
    ActionWrapper,
    TitleHeaderTable,
    ContentTableWrapper,
    CalendarButton,
    CalendarImage,
    CalendarText,
    DetailDownArrow,
    DetailActionWrapper,
} from "../../theme/GlobalCss";
import theme from "../../theme/theme";


// helpers
import StringFormater from '../../helpers/StringFormater';


/////////////////////////////
//  START style
////////////////////////////

/////////////////////////////
//  END style
////////////////////////////

export class SalesHistories extends Component {
    static propTypes = {
        actionsDatePicker: PropTypes.object,
        actionsSalesHistories: PropTypes.object,
        closeDateModalHandler: PropTypes.func,
        dataTable: PropTypes.object,
        datePicker: PropTypes.object,
        formatDate: PropTypes.func,
        getDateFormat: PropTypes.func,
        hydrateStateHandler: PropTypes.func,
        modalHandler: PropTypes.func,
        nestedOnClickHandler: PropTypes.func,
        openDateModalHandler: PropTypes.func,
        refCollection: PropTypes.array,
        salesHistories: PropTypes.object,
        userKiosk: PropTypes.object,
    };

    ///////////////////////////////////////////////////
    ///////     START INIT COMPONENT    ///////////////
    ///////////////////////////////////////////////////
    componentDidMount = () => {
        this.getSalesOrderByTimeRange()

    };
    ///////////////////////////////////////////////////
    ///////     END INIT COMPONENT    /////////////////
    ///////////////////////////////////////////////////

    getSalesOrderByTimeRange = () => {
        const {
            actionsSalesHistories,
            match,
            getDateFormat,
        } = this.props;

        const dateFormat = getDateFormat();

        if (match.path === CR.salesHistoriesBySales.route) {
            actionsSalesHistories.getSalesHistoriesBySale(match.params.id);
        }
        if (match.path === CR.salesHistoriesByItem) {
            actionsSalesHistories.getSalesHistories(dateFormat, match.params.id);
        }
        if (match.path === CR.salesHistories) {
            actionsSalesHistories.getSalesHistories(dateFormat);
        }

    };
    /**
     * data object to row layout
     */
    bodyData = () => {
        const {
            modalHandler,
            match,
            salesHistories,
            nestedOnClickHandler,
            translations,
            allowDepositContracts,
        } = this.props;

        const underTableHeader = [
            {
                name: translations['page__salesHistory__salesTable_expand_tableHeader_lockerOut'],
                useName: 'lockerOut'
            },
            {
                name: translations['page__salesHistory__salesTable_expand_tableHeader_lockerIn'],
                useName: 'lockerIn'
            },
            {
                name: translations['page__salesHistory__salesTable_expand_tableHeader_familly'],
                useName: 'familly'
            },
            {
                name: translations['page__salesHistory__salesTable_expand_tableHeader_brand'],
                useName: 'brand'
            },
            {
                name: translations['page__salesHistory__salesTable_expand_tableHeader_item'],
                useName: 'label'
            },
            {
                name: translations['page__salesHistory__salesTable_expand_tableHeader_itemType'],
                useName: 'type'
            },
            {
                name: translations['page__salesHistory__salesTable_expand_tableHeader_price'],
                useName: 'price'
            },
            {
                name: translations['page__salesHistory__salesTable_expand_modalAction_title'],
                useName: 'actions'
            }
        ];
        const header = [
            { name: translations['common__text__detail'], useName: 'detail' },
            {
                name: translations['page__salesHistory__salesTable_tableHeader_date'],
                useName: 'date',
                sortTable: true,
            },
            {
                name: translations['page__salesHistory__salesTable_tableHeader_type'],
                useName: 'type',
                sortTable: true
            },
            {
                name: translations['page__salesHistory__salesTable_tableHeader_paymentStatus'],
                useName: 'payment',
                sortTable: true
            },
            {
                name: translations['page__salesHistory__salesTable_tableHeader_transacId'],
                useName: 'transaction',
                sortTable: true
            },
            {
                name: translations['page__salesHistory__salesTable_tableHeader_item'],
                useName: 'denomination',
                sortTable: true
            },
            {
                name: translations['page__salesHistory__salesTable_tableHeader_accessory'],
                useName: 'acc',
                sortTable: true
            },
            {
                name: translations['page__salesHistory__salesTable_tableHeader_paymentType'],
                useName: 'paymentType',
                sortTable: true
            },
            {
                name: translations['page__salesHistory__salesTable_tableHeader_price'],
                useName: 'price',
                sortTable: true
            },
            { name: translations['common__text__actions'], useName: 'actions' }
        ];
        return {
            header: header,
            body: map(salesHistories.items, (elem) => {

                let dateParser = [
                    moment(elem.dateCreated).format('DD/MM/YYYY'),
                    moment(elem.dateCreated).format('HH:mm:ss')
                ];
                return ({
                    id: elem.id,
                    elements: [
                        {
                            name: 'detail',
                            value:
                                <DetailActionWrapper
                                    id={elem.id}
                                    onClick={() => nestedOnClickHandler(elem.id)}
                                >
                                    <DetailDownArrow />
                                </DetailActionWrapper>
                        },
                        {
                            name: 'date',
                            value: dateParser,
                            sortValue: elem.dateCreated,
                        },
                        {
                            name: 'type',
                            value: translations[elem.saleOrderType.translationKey],
                            bcg: elem.salesOrderStatus.colorCode,
                            type: 'button',
                            color: theme.colors.white,
                            sortValue: translations[elem.saleOrderType.translationKey],
                        },
                        {
                            name: 'payment',
                            value: translations[elem.paymentStatus.translationKey],
                            color: elem.paymentStatus.colorCode,
                            sortValue: translations[elem.paymentStatus.translationKey],
                        },
                        {
                            name: 'transaction',
                            value: elem.id_extern,
                            sortValue: elem.id_extern,
                        },
                        {
                            name: 'denomination',
                            value: elem.productLabel,
                            sortValue: elem.productLabel,
                        },
                        {
                            name: 'acc',
                            value: elem.accessoryIsPresent ? translations['common__text__yes'] : translations['common__text__no'],
                            sortValue: elem.accessoryIsPresent,
                        },
                        {
                            name: 'paymentType',
                            value: translations[elem.paymentType.translationKey],
                            sortValue: translations[elem.paymentType.translationKey],
                        },
                        {
                            name: 'price',
                            value: elem.priceTTC + elem.currency.symbol,
                            color: theme.colors.validation,
                            isNumber: true,
                            sortValue: elem.priceTTC,
                        },
                        {
                            name: 'actions', display: 'flex',
                            value:
                                <ActionButton
                                    size='30'
                                    link={`${match.url}${CR.modal.salesHistoriesAction}`}
                                    actionHandler={() => modalHandler(elem)}
                                />
                        },
                        {
                            id: elem.id,
                            nested: true,
                            display: 'none',
                            header: underTableHeader,
                            body: map(elem.salesOrderLines, (item) => {
                                return ({
                                    id: item.id,
                                    elements: [
                                        {
                                            id: item.id,
                                            name: 'lockerOu',
                                            value: `[${item.lockerOut || ' - '}]`,
                                            color: theme.colors.primary
                                        },
                                        {
                                            id: item.id,
                                            name: 'lockerIn',
                                            value: `[${item.lockerIn || ' - '}]`,
                                            color: theme.colors.primary
                                        },
                                        {
                                            id: item.id,
                                            name: 'familly',
                                            value: translations[item.itemFamily.translationKey]
                                        },
                                        {
                                            id: item.id,
                                            name: 'brand',
                                            value: item.itemBrand.label
                                        },
                                        {
                                            id: item.id,
                                            name: 'label',
                                            value: item.productLabel
                                        },
                                        {
                                            id: item.id,
                                            name: 'type',
                                            value: translations[item.saleOrderLineType.translationKey]
                                        },
                                        {
                                            id: item.id,
                                            name: 'price',
                                            value: item.priceTTC + elem.currency.symbol,
                                            color: theme.colors.validation
                                        },
                                        {
                                            id: item.id,
                                            name: 'actions',
                                            display: 'flex',
                                            value:
                                                elem.canPrintConsignmentContract &&
                                                <UserAuthorizationLayout
                                                    name={userPrivileges.view[7]}
                                                >
                                                    <ActionButton
                                                        link={`${match.url}${CR.modal.salesHistoriesActionItem}`}
                                                        actionHandler={() => modalHandler(elem)}
                                                        size="25"
                                                    />
                                                </UserAuthorizationLayout>

                                        },
                                    ]
                                })
                            })

                        }
                    ]
                })
            })
        }
    };

    componentDidUpdate(prevProps) {
        const {
            match,
            salesHistories,
            hydrateStateHandler,
            datePicker,
            translations,
        } = this.props;

        // reload fetch data if 'sales-histories' url is different
        if (match.url !== prevProps.match.url) {
            this.getSalesOrderByTimeRange()
        }
        // fetch data if date change
        if (datePicker !== prevProps.datePicker) {
            this.getSalesOrderByTimeRange()
        }
        if (this.props.userKiosk !== prevProps.userKiosk) {
            if (match.params.id) {
                this.props.history.push('/home')
            } else {
                this.getSalesOrderByTimeRange()
            }
        }
        // hydrate parent STATE if Table data change
        if (salesHistories.items !== prevProps.salesHistories.items) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            hydrateStateHandler(data)
        }
        if (translations !== prevProps.translations) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            hydrateStateHandler(data)
        }
    }


    render() {
        const {
            translations,
            formatDate,
            datePicker,
            openDateModalHandler,
            dataTable,
            refCollection,
            getDateFormat,
            initSortTableHandle,
        } = this.props;

        return (
            <PageWrapper>
                <LineTableHeader>
                    <TitleHeader>
                        {translations['page__salesHistory__salesTable_title']}
                    </TitleHeader>

                    <ActionWrapper>
                        {/* <UserAuthorizationLayout
                            name={ userPrivileges.view[4] }>
                            <Button
                                link={ CR.telecollection }
                                text={ translations['page__salesHistory__salesTable_button_displayPaymentCollection'] }
                                img={Telecolection}
                            />
                        </UserAuthorizationLayout> */}
                        <UserAuthorizationLayout
                            name={userPrivileges.export[0]}
                        >
                            <ExportData
                                text={translations['common__text__exportData']}
                                scope={'salesorders'}
                                getDateFormat={getDateFormat}
                            />

                        </UserAuthorizationLayout>
                    </ActionWrapper>
                </LineTableHeader>
                <ContentTableWrapper>
                    <LineTableHeader>
                        <TitleHeaderTable>
                            <CalendarButton
                                onClick={openDateModalHandler}
                            >
                                <CalendarImage src={Calendar} />
                            </CalendarButton>
                            <CalendarText>
                                {StringFormater.ResponseMessageParser(
                                    translations['common__text__datePeriod_noHours'],
                                    [formatDate(datePicker.startDate), formatDate(datePicker.endDate)]
                                )}
                            </CalendarText>
                        </TitleHeaderTable>
                        <ActionWrapper>
                            <UserAuthorizationLayout
                                name={userPrivileges.analyze[0]}>
                                <Button
                                    text={translations['page__salesHistory__salesTable_button_analyzeSales']}
                                    img={Graph}
                                />
                            </UserAuthorizationLayout>
                        </ActionWrapper>
                    </LineTableHeader>
                    <TableLayout
                        headerData={dataTable.headerData}
                        bodyData={dataTable.bodyData}
                        column={dataTable.headerData.length}
                        refCollection={refCollection}
                        initSortTableHandle={initSortTableHandle}
                        translations={translations}
                    />
                </ContentTableWrapper>

            </PageWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        salesHistories: state.salesHistories,
        userKiosk: selectIdsUser(state),
        allowDepositContracts: state.user.identity.allowDepositContracts
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionsSalesHistories: bindActionCreators(salesHistoriesActionCreators, dispatch),
    };
};

export default withRouter(ModalDatePicker(TableNested(ModalHOC(connect(mapStateToProps, mapDispatchToProps)(SalesHistories)))))
