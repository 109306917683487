////////LIBRARY/////////
import React, { Component } from 'react'
import  Chart from 'chart.js'
import styled from 'styled-components'
import { forEach, map } from 'lodash'
///////COMPONENTS///////
import ColorsCharts from '../../../constants/ChartsColorsList'

/////////STYLED/////////
const ChartWrapper = styled.div`
    width: 1fr;
    grid-column: ${ props => props.isFull ? '1 / 3': 'auto'};
    height: min-content;
    background-color: ${ props => props.theme.colors.white };
    padding: 5px;
    border: 1px solid ${ props => props.theme.colors.borderTable };
`
/////////STYLED/////////

class DoughnutChart extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if( prevProps.data !== this.props.data){
            this.myChart.destroy()
            this.createChart()
        }

        if(prevProps.translations !== this.props.translations) {
            this.myChart.data.datasets = this.makeDataSets();
            this.myChart.options.title.text = this.props.translations[this.props.data.tradKeyTitle];
            this.myChart.update();
        }
    }

    ///////////////////////////////// HANDLE ///////////////////////////////////////

    makeDataSets = () => {
        const { data } = this.props;
        return map(data.contents, (elem, id) => (
            {
                fill: false,
                strokeColor: ColorsCharts[id],
                pointStyle: 'circle',
                borderWidth: 0,
                radius: 4,
                backgroundColor: map(elem.contents, ( elem, id )  => ColorsCharts[id] ),
                data: map(elem.contents, ( elem => elem.value)),
            }
        ))
    };

    createChart = () => {
        const { data, translations } = this.props;
        const formatLabel = []
        forEach(data.contents, (elem => (
            forEach(elem.contents, (item => formatLabel.push(item.label)))
        )))
        const dataToTable = this.makeDataSets()
        this.myChart = new Chart(this.canvasRef.current, {
            type: 'doughnut',
            options: {
                title: {
                    display: true,
                    text: translations[data.tradKeyTitle],
                    position: 'top',
                    align: 'start',
                },
                legend:{
                    position: 'right',
                    labels: {
                        usePointStyle: true,
                    }

                },
            },
            data: {
                labels:formatLabel,
                datasets: dataToTable,
            }
        })

    }

    componentDidMount() {
        this.createChart()
    }

	///////////////////////////////// RENDER ///////////////////////////////////////

    render() {
        const { data } = this.props;
        return (
            <ChartWrapper
                isFull={data.fullWidth}
            >
                <canvas ref={this.canvasRef}/>
            </ChartWrapper>
        )
    }
}

export default DoughnutChart;
