import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { map, filter, merge, find, findIndex, isNull } from 'lodash'
import { HashLink as Link } from 'react-router-hash-link'

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { itemsStorePricesActionCreators } from '../../state/actions';

// component import
import TableLayout from '../TableLayout'
import TableCheckboxEditable from '../../Hoc/TableCheckboxEditable'
import ActionButton from '../../components/Ui/Buttons'
import SummarizePromo from './SummarizePromo'
import CR from '../../constants/RouteConstants'
import UserAuthorizationLayout from "../../components/UserAuthorizationLayout";
import userPrivileges from "../../constants/RoleList";
import ExportData from "../../components/ExportData/ExportData";

//assets import
import Edit from '../../assets/icones/global/edit.png'
import WhiteEdit from '../../assets/icones/global/white-edit.png'
import Sound from '../../assets/icones/global/sound.png'
import Validation from '../../assets/icones/global/validation.png'

// style
import {
    PageWrapper,
    LineTableHeader,
    TitleHeader,
    ActionWrapper,
    TitleHeaderTable,
    ContentTableWrapper,
    ImgSized,
    BoxValidation,
    DisabledValidation,
    BoxValidationWrapper,
    BoxCanceled,
} from "../../theme/GlobalCss";
import theme from "../../theme/theme";

/////////////////////////////
//  START style
////////////////////////////
const LinkNoStyle = styled(Link)`
    color: ${ props => props.theme.colors.primary };
    font-weight: 900;
    margin-right: 16px;
    text-decoration: underline;
    cursor: pointer;
`;
/////////////////////////////
//  END style
////////////////////////////

export class PriceManager extends Component {
    static propTypes = {
        actionsItemsStorePrices:        PropTypes.object,
        cancelActionHandler:            PropTypes.func,
        checkboxChangeHandler:          PropTypes.func,
        dataTable:                      PropTypes.object,
        editAllCancelHandler:           PropTypes.func,
        editAllHandler:                 PropTypes.func,
        editAllValidationHandler:       PropTypes.func,
        editInputHandler:               PropTypes.func,
        editedOnCLickHandler:           PropTypes.func,
        hydrateStateHandler:            PropTypes.func,
        validationActionHandler:        PropTypes.func,
        itemsStorePrices:               PropTypes.object,
        refCollection:                  PropTypes.array,
    };

    ///////////////////////////////////////////////////
    ///////     START INIT COMPONENT    ///////////////
    ///////////////////////////////////////////////////

    componentDidMount = () => {
        const { actionsItemsStorePrices } = this.props;
        actionsItemsStorePrices.getItemsStorePrices();
    };
    componentDidUpdate(prevProps) {
        const { itemsStorePrices, hydrateStateHandler, translations } = this.props;

        if( itemsStorePrices.items !== prevProps.itemsStorePrices.items ) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            hydrateStateHandler(data)
        }
        if( translations !== prevProps.translations) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            hydrateStateHandler(data)
        }
    }
    ///////////////////////////////////////////////////
    ///////     END INIT COMPONENT    /////////////////
    ///////////////////////////////////////////////////



    /**
     * data object to row layout
     */
    bodyData = () => {
        const {
            itemsStorePrices,
            editedOnCLickHandler,
            translations,
            } = this.props;

        const header = [
            {name: 'CHECKBOX',useName: 'checkBox', isChecked: false},
            {name: translations['page__priceManagement__tablePrice_tableHeader_familly'], useName: 'famille', sortTable: true, },
            {name: translations['page__priceManagement__tablePrice_tableHeader_brand'], useName: 'marque', sortTable: true, },
            {name: translations['page__priceManagement__tablePrice_tableHeader_item'], useName: 'produit', sortTable: true, },
            {name: translations['page__priceManagement__tablePrice_tableHeader_promo'], useName: 'promo', sortTable: true, },
            {name: translations['page__priceManagement__tablePrice_tableHeader_salePrice'], useName: 'standardPrice', sortTable: true, },
            {name: translations['page__priceManagement__tablePrice_tableHeader_depositPrice'], useName: 'depositPrice', sortTable: true, },
            {name: translations['page__priceManagement__tablePrice_tableHeader_itemType'], useName: 'typeProduit', sortTable: true, },
            {name: translations['common__text__actions'], useName: 'actions'}
        ];

        return{
            header: header,
            body:   map(itemsStorePrices.items.itemsStore, (elem, id) => {
                const prices = {
                    standardPrice: elem.standardPrice.toFixed(2),
                    depositPrice: !isNull(elem.depositPrice)  ? elem.depositPrice.toFixed(2) : '-'
                };

                return ({
                    id: elem.id,
                    elements: [
                        {name :'checkBox', value: '', isChecked: false},
                        {name :'famille', value: translations[elem.itemFamily.translationKey], sortValue: translations[elem.itemFamily.translationKey], },
                        {name :'marque', value: elem.itemBrand.label, sortValue: elem.itemBrand.label, },
                        {name :'produit', value: elem.label, sortValue: elem.label, },
                        {name :'promo', value: elem.promoStatut ? translations[elem.promoStatut.translationKey] : '-', sortValue: elem.promoStatut ? translations[elem.promoStatut.translationKey] : '-', },
                        {name :'standardPrice', value: prices.standardPrice, editable:true, active:false, price: true, currency: elem.currency.symbol, isNumber : true, sortValue: prices.standardPrice, },
                        {name :'depositPrice', value: prices.depositPrice, editable: elem.withDeposit, active:false, price: true, currency: elem.currency.symbol, sortValue: prices.depositPrice, },
                        {name :'typeProduit', value: translations[elem.itemTypeTranslationKey], sortValue: translations[elem.itemTypeTranslationKey], },
                        {
                            name: 'actions',
                            display: 'flex',
                            value:
                                    <UserAuthorizationLayout
                                        key={id}
                                        name={userPrivileges.manage[4]}
                                    >
                                        <ImgSized
                                            name='edit'
                                            src={Edit}
                                            id={elem.id}
                                            onClick={editedOnCLickHandler}
                                            width={20}
                                            height={20}
                                            cursor={'true'}
                                        />
                                        <BoxValidationWrapper
                                            name='validation'
                                            id={elem.id}
                                        >
                                            <BoxValidation
                                                id={elem.id}
                                                onClick={this.validationOneLineOnClickHandler}
                                                name='valided'
                                            >
                                                <ImgSized width={13} id={elem.id} src={Validation} />
                                            </BoxValidation>
                                            <DisabledValidation
                                                id={elem.id}
                                                name='disabled'
                                            >
                                                <ImgSized width={13} src={Validation} />
                                            </DisabledValidation>
                                            <BoxCanceled
                                                id={elem.id}
                                                onClick={(evt) => this.onLineCancelActionHandler(evt, prices)}
                                            >
                                                X
                                            </BoxCanceled>
                                        </BoxValidationWrapper>
                                    </UserAuthorizationLayout>
                        },
                    ]
                })
            })
        };
    };

    validationOneLineOnClickHandler = (evt) => {
        const {
            validationActionHandler,
            refCollection,
        }= this.props;

        const oldState      = {...this.props.dataTable};

        const id            = parseInt(evt.target.id);
        oldState.editOne    = !oldState.editOne;
        const priciesList   = [];

        oldState.bodyData
            .forEach( (elem) => {
                if(elem.id === id) {
                    const editable = filter(elem.elements, { active : true });
                    let prices = {
                        id: elem.id,
                    };
                    let limitNumber;
                    editable
                        .forEach( item => {
                            limitNumber = parseFloat(refCollection[elem.id][item.name].children[0].value) > '99999' ? '99999' : refCollection[elem.id][item.name].children[0].value;
                            if ( !limitNumber.length ) {
                                limitNumber = '1'
                            }

                            const price = parseFloat(limitNumber.replace(',', '.').replace('-',''));
                            merge(prices, {[item.name]: price});
                            merge(priciesList, [prices])
                        })
                }

            });
        validationActionHandler(oldState, id);
        this.props.actionsItemsStorePrices.updateItemsStorePrices(priciesList)
    };

    ValidationeditAllOneClickHandler = () => {
        const {
            editAllValidationHandler,
            refCollection,
        }= this.props;

        const oldState      = {...this.props.dataTable};
        const priciesList   = [];
        oldState.editing    = false;
        let limitNumber;

        oldState.bodyData
            .forEach( (elem) => {
                const isChecked = find(elem.elements, { isChecked : true });
                if(isChecked) {
                    const editable = filter(elem.elements, { active : true });
                    let prices = {
                        id: elem.id,
                    };
                    editable
                        .forEach( item => {
                            limitNumber = parseFloat(refCollection[elem.id][item.name].children[0].value) > '99999' ? '99999' : refCollection[elem.id][item.name].children[0].value;
                            if ( !limitNumber.length ) {
                                limitNumber = '1'
                            }
                            const price = parseFloat(limitNumber.replace(',', '.').replace('-',''));
                            merge(prices, {[item.name]: price});
                            const idxPrices = findIndex(priciesList, { id : prices.id});
                            if (idxPrices > -1) {
                                merge(priciesList[idxPrices], prices)
                            }else{
                                priciesList.push(prices)
                            }
                        })
                }

            });
        this.props.actionsItemsStorePrices.updateItemsStorePrices(priciesList);
        editAllValidationHandler(oldState)
    };

    cancelEditAllHandler = (data) => {
        const oldState      = {...this.props.dataTable};
        oldState.editing    = false;

        oldState.bodyData
        .forEach( (elem) => {
            const isChecked = find(elem.elements, { isChecked : true });
            let newItem = false;
            if(isChecked) {
                newItem = find(data, { id : elem.id })
            }

            elem.elements.forEach( (item) => {

                if (item.editable && newItem){
                    const consignePrice = parseFloat(newItem[item.name]);
                    const price =  !isNaN(consignePrice)  ? consignePrice.toFixed(2) : newItem[item.name];
                    item.value = price
                }
            })
        });
        this.props.editAllCancelHandler(oldState)
    };

    onLineCancelActionHandler = (evt, data) => {
        const id            = parseInt(evt.target.id);
        const oldState      = {...this.props.dataTable};
        oldState.editOne    = !oldState.editOne;
        oldState.bodyData
            .forEach( (elem) => {
                if( elem.id === id ) {
                    elem.elements.forEach( (item) => {
                        if (item.editable){
                            const price = parseFloat(data[item.name]);
                            item.value = price.toFixed(2)
                        }
                    })
                }
            });
        this.props.cancelActionHandler(oldState, id)
    };

    layoutEditAllAndValidateAll = () => {
        const {
            editAllHandler,
            itemsStorePrices,
            dataTable,
            translations
        } = this.props;

        let flagToNotEmptyValue = false;
        let isChecked           = false;

        dataTable.bodyData
                .forEach( (elem) => {
                    const editable = filter(elem.elements, { editable : true });
                    const checked = filter(elem.elements, { isChecked : true });

                    if(checked.length){
                        isChecked = true
                    }
                    editable.forEach( (item) => {
                        if(!item.value.length){
                            flagToNotEmptyValue = true
                        }

                    })
                });

        const layoutEditAll =
            dataTable.editAll ?
                <Fragment>
                    <ActionButton
                        actionHandler={this.ValidationeditAllOneClickHandler}
                        // text="Valider"
                        text={translations['common__text__save']}
                        bgColor="#04BF2A"
                        margin='true'
                        disabled={flagToNotEmptyValue}
                    />
                    <ActionButton
                        actionHandler={() => this.cancelEditAllHandler(itemsStorePrices.items.itemsStore)}
                        // text="Annuler"
                        text={translations['common__text__cancel']}
                        bgColor="#8D9097"
                    />
                </Fragment>
            :
                <Fragment>
                <UserAuthorizationLayout
                    name={userPrivileges.manage[5]}
                >
                    <ActionButton
                        link={CR.promoManager}
                        img={Sound}
                        text={translations['page__priceManagement__tablePrice_button_generatePromo']}
                        bgColor="#04BF2A"
                        margin='true'
                        disabled={!isChecked}
                    />
                </UserAuthorizationLayout>
                <UserAuthorizationLayout
                    name={userPrivileges.manage[4]}
                >
                    <ActionButton
                        actionHandler={dataTable.editOne ? null : editAllHandler}
                        img={WhiteEdit}
                        text={translations['common__text__massChange']}
                        disabled={dataTable.editOne || !isChecked}
                    />
                </UserAuthorizationLayout>
                </Fragment>;
        return layoutEditAll
    };

    childCheckboxChangeHandler = (e) => {
        const { checkboxChangeHandler, actionsItemsStorePrices, dataTable } = this.props;
        const id            = e.target.id;
        const actualState   = {
            editAll: dataTable.editAll,
            editing: dataTable.editing,
            editOne: dataTable.editOne,
            header: !dataTable.headerData[0].isChecked
        };

        actionsItemsStorePrices.selectedItemsStorePricesUpdate(id, actualState);
        checkboxChangeHandler(e)
    };

    render() {
        const {
            refCollection,
            itemsStorePrices,
            dataTable,
            editInputHandler,
            translations,
            initSortTableHandle,
        } = this.props;

        return (
            <PageWrapper>
                <LineTableHeader>
                    <TitleHeader>
                        {translations['page__priceManagement__tablePrice_title']}
                    </TitleHeader>

                    <ActionWrapper>
                        <LinkNoStyle
                            smooth
                            to={"#promo"}
                        >
                            {translations['page__priceManagement__tablePrice_link_viewPromo']}
                        </LinkNoStyle>

                        <UserAuthorizationLayout
                            name={userPrivileges.export[2]}
                        >
                            <ExportData
                                text={translations['common__text__exportData']}
                                scope={'items'}
                                getDateFormat={false}
                            />
                        </UserAuthorizationLayout>
                    </ActionWrapper>

                </LineTableHeader>

                <ContentTableWrapper>
                    <LineTableHeader>
                        <TitleHeaderTable color={theme.colors.primary}>
                            {
                                itemsStorePrices.items &&
                                    itemsStorePrices.items.storeCompany.companyStoreLblToDisplay
                            }
                        </TitleHeaderTable>
                        <ActionWrapper>
                            { this.layoutEditAllAndValidateAll() }
                        </ActionWrapper>
                    </LineTableHeader>
                    <TableLayout
                        headerData={dataTable.headerData}
                        initSortTableHandle={initSortTableHandle}
                        bodyData={dataTable.bodyData}
                        column={dataTable.headerData.length}
                        refCollection={refCollection}
                        editInputHandler={editInputHandler}
                        checkboxChangeHandler={this.childCheckboxChangeHandler}
                        editAllActive={dataTable.editAll}
                        translations={translations}
                    />
                </ContentTableWrapper>

                <SummarizePromo
                    translations={this.props.translations}
                />
            </PageWrapper>

        )
    }
}

const mapStateToProps = state => {
    return {
        itemsStorePrices: state.itemsStorePrices,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionsItemsStorePrices: bindActionCreators(itemsStorePricesActionCreators, dispatch),
    };
};

export default TableCheckboxEditable(connect(mapStateToProps, mapDispatchToProps)(PriceManager))
