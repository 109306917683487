import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types'
import { withRouter } from "react-router";
import { isUndefined } from 'lodash'
import moment from 'moment'

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { loginActionCreators, modalsActionCreators } from '../../state/actions';


// define global and local style
import GlobalStyle from '../../theme/GlobalStyle';
import { ThemeProvider } from 'styled-components';
import theme from '../../theme/theme';

//routes imports
import AppRoute from '../../route/AppRoute';

// helpers
import Store from '../../helpers/Store'
import Base64Format from '../../helpers/Base64Format'

class App extends PureComponent {

    static propTypes = {
        user:                   PropTypes.object,
        actions:                PropTypes.object,
        languages:              PropTypes.object,
        defaultLanguages:       PropTypes.object,
        languagesList:          PropTypes.object,
        getTranslation:         PropTypes.func,
        changeLanguageHandler:  PropTypes.func,
        getLanguagesEnabled:    PropTypes.func,
    };

    constructor( props ) {
        super( props );

        const { actions } = this.props;

        const userLocalStorage = Store.get( 'user' );
        if ( userLocalStorage ) {
            const token     = Base64Format.splitToken(userLocalStorage.jwttoken);
            const timeNow   = moment().unix();
            if( timeNow < token.body.exp ) {
                actions.getCurrentUser( userLocalStorage.jwttoken );
            }else{
                actions.getLogout()
            }
        }else{
            actions.getLogout()
        }
    }

    componentDidUpdate( prevProps ) {
        const { actions, user, history, actionsModal } = this.props;

        if ( !isUndefined(user.identity.jwttoken) ){
            if( user.identity.jwttoken !== prevProps.user.identity.jwttoken) {
                const token     = Base64Format.splitToken(user.identity.jwttoken);
                const exp       = token.body.exp - token.body.iat;
                const timeNow   = moment().unix();

                if( timeNow > token.body.exp ) {
                    actions.getLogout()
                }

                setTimeout( actions.getLogout, exp * 1000 )
            }
        }
        if( user.path !== prevProps.user.path && user.path.length ) {
            history.push(user.path);
            actionsModal.closeModal()
        }
    }

    render() {
        if( this.props.user.isLoading ){
            return <div></div>
        }
        return (
            <Fragment>
                <GlobalStyle/>
                <ThemeProvider theme={ theme }>
                    { AppRoute }
                </ThemeProvider>
            </Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        user:                   state.user,
    };
};

const mapDispatchToProps = dispatch => ( {
    actions: bindActionCreators( loginActionCreators, dispatch ),
    actionsModal : bindActionCreators( modalsActionCreators, dispatch ),
} );

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( App ) )