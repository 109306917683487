import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { map } from 'lodash'
import theme from "../../../theme/theme";

const ChartContentWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
    grid-template-rows: 49px;
    align-items: stretch;
    justify-items: stretch;
    background-color: ${ props => props.theme.colors.secondary };
`;
const ChartWrapper = styled.div`
    width: 1fr;
    grid-column: ${ props => props.isFull ? '1 / 3': 'auto'};
    background-color: ${ props => props.theme.colors.white };
`;
const ColoredNumber = styled.div`
    color: ${ props => props.color ? props.color : props.theme.colors.menu.link };
    font-weight: bold;
`;
const NumbersChartTable = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1fr;
    background-color: ${ props => props.theme.colors.white };
    border: 1px solid ${ props => props.theme.colors.borderTable };
`;

/**
 *
 * @param {function} actionHandler onClick action
 */
const NumbersStats = ({
    data,
    translations
}) => {
    return (
        <ChartWrapper
            isFull={data.fullWidth}
        >
            <ChartContentWrapper>
                {
                    map(data.contents, ( elem ) => (
                        map(elem.contents, (item, id) => (
                            <NumbersChartTable
                                key={id}
                            >
                                &nbsp;{`
                                ${translations[item.label]}
                            `}
                                <ColoredNumber
                                    color={
                                        id === 0 ?
                                            theme.colors.validation
                                            : id === 1 ?
                                            theme.colors.menu.link
                                            : theme.colors.primary
                                    }
                                >
                                    {/*TODO: add real currency*/}
                                    &nbsp;{`${item.value} €`}
                                </ColoredNumber>
                            </NumbersChartTable>
                        ))
                    ) )
                }
            </ChartContentWrapper>
        </ChartWrapper>
    )
};

NumbersStats.propTypes = {
    data:           PropTypes.object,
    translations:   PropTypes.object,
};

export default NumbersStats
