
class Base64Format {

    static keyDecode( key ) {
        return JSON.parse(atob(key))
    }

    static keyEncode( key ) {
        return btoa( key )
    }

    static splitToken( token ) {
        const tokenSplit = token.split( '.' );
        let tokenObjFormat = {
            'header':   this.keyDecode(tokenSplit[0]),
            'body'  :   this.keyDecode(tokenSplit[1]),
            'tail'  :   tokenSplit[2],
        };
        return tokenObjFormat
    }
}

export default Base64Format;