import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import CR from '../../constants/RouteConstants'

extendUpdate(update);

const errorManager = {

    [ActionsTypes.CREATE_ERROR]: (state) => {

        return update(state, {


        })
    },
    [ActionsTypes.CREATE_CRITICAL_ERROR]: (state) => {

        return update(state, {
            user:{
                path:{
                    $set: CR.criticalError
                }
            }

        })
    },

};

export default errorManager