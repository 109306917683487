import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import dotThree from '../../../assets/icones/global/dot-three.svg'

/////////////////////////////
//  START style
////////////////////////////
const ButtonWrapper = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${ props => props.size ? props.size + 'px' : '62px' };
    height: ${ props => props.size ? props.size + 'px' : '62px' };
    background-color: ${ props => props.disabled ? props.theme.colors.grey : props.theme.colors.primary };
    opacity: ${ props => props.disabled ? '0.5' : '1' };
    border-radius: 50%;
    cursor: ${ props => props.disabled ? 'not-allowed' : 'pointer' };
`;
const DotContent = styled.div`
    background-color: ${ props => props.theme.colors.white };
    border-radius: 50%;
    width: 12%;
    height: 12%;
    margin-right: ${ props => props.margin ? '10%' : '0'};
`;
/////////////////////////////
//  END style
////////////////////////////
/**
 *
 * @param   {function}      actionHandler   function callback on click action
 * @param   {string}        size            size of button
 * @param   {string}        link            route to link
 * @param   {boolean}       disabled
 * @returns {frameElement}
 */
const ActionButton = ({ actionHandler, size, link, disabled, dotSize }) => {
    return (
        <ButtonWrapper
            onClick={disabled ? null : actionHandler}
            size={size}
            to={disabled ? '#' : link}
            disabled={disabled}
        >
            {
                dotSize ?
                <>
                    <DotContent
                        margin
                    />
                    <DotContent
                        margin
                    />
                    <DotContent/>
                </>
                :
                <img src={dotThree} />

            }
        </ButtonWrapper>
    )
};

ActionButton.propTypes = {
    actionHandler: PropTypes.func.isRequired,
    size: PropTypes.string,
    link: PropTypes.string,
    disabled: PropTypes.bool,
};

export default ActionButton
