import { createSelector } from 'reselect'
import { find } from 'lodash'

const getUser               = state => state.user.identity;
const getUserKiosks         = state => state.user.identity.userKiosks;
const getKioskSelected      = state => state.user.identity.kioskSelected;

export const selectIdsUser = createSelector(
    [ getUser, getKioskSelected ],
    (user, idKiosk) => {
        const userIds = {
            userId          : user.id,
            idKiosk         : idKiosk,
            idStore         : user.idCompanyTyped,
            idDistributors  : user.idDistributor,
            jwt             : user.jwttoken,
            idUserLanguage  : user.preferedLanguage,
        };
        return userIds
    }
);

export const selectKioskSelected = createSelector(
    [ getUser, getKioskSelected ],
    (user, idKiosk) => (
        find( user.userKiosks, [ 'idKiosk', idKiosk ] )
    )
);
export const selectUserId = createSelector(
    [ getUser ],
    (user) => (
        user.id
    )
);
export const selectKioskStatus = createSelector(
    [ getKioskSelected, getUserKiosks ],
    ( kioskSelected, kiosksList ) => {
        let userStatusSelected = find( kiosksList, { idKiosk: kioskSelected } );
        const userKioskStatuskSelected = {
            'printIntegrity' :      userStatusSelected.printerIntegrity,
            'bankFlowIntegrity' :   userStatusSelected.bankFlowIntegrity,
            'simCardIntegrity' :    userStatusSelected.simCardIntegrity,
            'otherIntegrity' :      userStatusSelected.otherIntegrity,
            'globalIntegrity' :     userStatusSelected.globalIntegrity,
        };
        return userKioskStatuskSelected
    }
);