const theme = {
    colors: {
        primary: "#0885D5",
        secondary: "#FBFBFB",
        validation: "#04BF2A",
        warning: "#FFC13D",
        error: "#FF4E4E",
        white: "#FFFFFF",
        black: '#000000',
        menu: {
            wrapper: "#323232",
            link: "#4B4D52",
        },
        grey: 'grey',
        borderList: '#707070',
        cancel: "#8D9097",
        borderTable: '#E5E5E5',
        promoTag: '#FF00F7',
        whiteGrey: '#EEEEEE',
        logAlertBodyCard: '#FBFBFB',
        userSettingSlider: '#ccc',
        wedealBlueOther: '#0979C1',
        tableHeader:{
            white1: '#D9F1FF',
            white2: '#F5F6FA',
            grey1: '#A3A6B4',
        },
    },

    font: {
        fxs: "12px",
        fs: "13px",
        fm: "14px",
        fxl: '18px',
        fxxl: '20px',
        fxxxl: "24px",
      },
};

export default theme;
