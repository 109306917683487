import {
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';
import {
    userDetailActionCreators,
    globalLoadingActionCreators,
    errorManagerActionCreators,
    modalsActionCreators
} from '../actions';
import API from '../../constants/ApiUrl'
import * as R from '../../constants/ApiConstants'
import { selectIdsUser } from '../selectors/user'

const getUserDetail = function* ({payload}) {

    const idUser        = payload.idUser;
    const user          = yield select(selectIdsUser);
    user['idUser']      = idUser;
    const apiCall       = R.GET_USER_DETAIL(user);

    yield put(globalLoadingActionCreators.startLoading());

    try{

        const data = yield API(user.jwt).get(apiCall);

        yield put(userDetailActionCreators.getUserDetailSuccess(data.data));

    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(userDetailActionCreators.getUserDetailFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());

};
const updateUserDetail = function* ({payload}) {

    const idUser        = payload.idUser;
    const user          = yield select(selectIdsUser);
    user['idUser']      = idUser;
    const apiCall       = R.UPDATE_USER_DETAIL(user);
    const dataUser      = payload.user;
    let dataFomat       = {
        userAccountInput: {
            "idPreferedLanguage"    : dataUser.idPreferedLanguage,
            "firstName"             : dataUser.firstName,
            "lastName"              : dataUser.lastName,
            "email"                 : dataUser.email,
            "phoneNumber"           : dataUser.phoneNumber
        },
        enabled : dataUser.enabled,
        idRole  : dataUser.idRole,
        kiosks  : dataUser.kiosks
    };
    yield put(globalLoadingActionCreators.startLoading());

    try{

        const data = yield API(user.jwt).put(apiCall, dataFomat);

        yield put(userDetailActionCreators.updateUserDetailSuccess(data.data));

        const modal = {
            level: 'validation',
            title: 'page__userDetail__modal_userDetail_Update_title',
            key: 'page__userDetail__modal_userDetail_Update_body',
            show: true,
            path: ''
        };
        yield put( modalsActionCreators.hydrateModal(modal));

    } catch (e) {

        yield put(errorManagerActionCreators.createError(e.response));

        yield put(userDetailActionCreators.updateUserDetailFailure(e.response));

    }
    yield put(globalLoadingActionCreators.endLoading());

};

const UserDetailResetPassword = function* ({payload}) {

    const idUser        = payload.idUser;
    const user          = yield select(selectIdsUser);
    user['idUser']      = idUser;
    const apiCall       = R.USER_DETAIL_RESET_PASSWORD(user);

    yield put(globalLoadingActionCreators.startLoading());

    try{

        const data = yield API(user.jwt).put(apiCall);

        yield put(userDetailActionCreators.UserDetailResetPasswordSuccess(data.data));

        const modal = {
            level: 'validation',
            title: 'page__userDetail__modal_userDetail_passwordForceGeneration_title',
            key: 'page__userDetail__modal_userDetail_passwordForceGeneration_body',
            show: true,
            path: ''
        };
        yield put( modalsActionCreators.hydrateModal(modal));

    } catch (e) {

        yield put(errorManagerActionCreators.createError(e.response));

        yield put(userDetailActionCreators.UserDetailResetPasswordFailure(e.response));

    }
    yield put(globalLoadingActionCreators.endLoading());

};

const postUserCreate = function* ({payload}) {

    const user          = yield select(selectIdsUser);
    const apiCall       = R.POST_USER_CREATE(user);
    const dataUser      = payload.user;
    let dataFomat       = {
        userAccountInput: {
            "idPreferedLanguage"    : dataUser.idPreferedLanguage,
            "firstName"             : dataUser.firstName,
            "lastName"              : dataUser.lastName,
            "userName"              : dataUser.userName,
            "email"                 : dataUser.email,
            "phoneNumber"           : dataUser.phoneNumber
        },
        idRole  : dataUser.idRole,
        kiosks  : dataUser.kiosks
    };

    yield put(globalLoadingActionCreators.startLoading());

    try{

        yield API(user.jwt).post(apiCall, dataFomat);

        yield put(userDetailActionCreators.postUserCreateSuccess(dataUser));

        const modal = {
            level: 'validation',
            title: 'page__userDetail__modal_createUser_title',
            key: 'page__userDetail__modal_createUser_body',
            show: true,
            path: ''
        };

        yield put( modalsActionCreators.hydrateModal(modal));

    } catch (e) {

        yield put(userDetailActionCreators.postUserCreateSuccess(dataUser));

        yield put(errorManagerActionCreators.createError(e.response));

        yield put(userDetailActionCreators.postUserCreateFailure(e.response));

    }
    yield put(globalLoadingActionCreators.endLoading());

};



const sagas = function* () {
    yield takeLatest(ActionsTypes.GET_USER_DETAIL, getUserDetail);
    yield takeLatest(ActionsTypes.UPDATE_USER_DETAIL, updateUserDetail);
    yield takeLatest(ActionsTypes.USER_DETAIL_RESET_PASSWORD, UserDetailResetPassword);
    yield takeLatest(ActionsTypes.POST_USER_CREATE, postUserCreate);
};

export default sagas