import React, { Component } from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { map } from 'lodash'
import { Link } from 'react-router-dom'
import CR from '../../constants/RouteConstants'


// redux import
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { familiesActionCreators } from '../../state/actions';
import { itemsKioskActionCreators } from '../../state/actions';
import { selectKioskSelected } from '../../state/selectors/user'
import { 
    selectBrandsFamilies,
    selectFamilySelected,
    selectItemsSortBy,
} from '../../state/selectors/itemsKiosk'


// import components
import Dropdown from '../../components/Ui/Dropdown/'
import DropdownCheckbox from '../../components/Ui/DropdownCheckbox'
import ItemsCard from '../../components/Ui/Items/ItemsCard'
import ItemsList from '../../components/Ui/Items/ItemsList'
import InputFilter from '../../components/Ui/InputFilter'


// hoc
import ModalHOC from '../../Hoc/ModalHOC'

//assets import
import ListLayout from '../../assets/icones/item/productList/list-layout.png'
import CardLayout from '../../assets/icones/item/productList/card-layout.png'
import Book from '../../assets/icones/global/note-history.svg'

/////////////////////////////
//  START style
////////////////////////////
const ItemKioskWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(8, 94px);
    grid-column-gap: 16px;
    margin: 0 80px 0 64px;
`;
const ItemKioskFilterWrapper = styled.div`
    display: grid;
    grid-column-start: 1;
    grid-column-end: 9;
    height: 45px;
    margin-top: 30px;
    align-items: stretch;
`;
const SelectWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(6, min-content);
    grid-template-rows: 45px;
    grid-column-gap: 16px;
`;
const HistoriqueButton = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 160px;
    color: white;
    border-radius: 3px;
    font-weight: bold;
    text-decoration: underline;
    background-color: ${props => props.theme.colors.primary};
    cursor: pointer;
`;
const BookImg = styled.img`
    margin-right: 7px;
`;
const ItemsCardsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 424px);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    margin: 25px 0 0 0;
    box-sizing: border-box;
`;
const ItemsListWrapper = styled.div`
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: 1fr;
    grid-template-rows: 87px;
    grid-row-gap: 6px;
    margin: 25px 0 0 0;
    box-sizing: border-box;
`;
const ListOrCard = styled.img`
    display: flex;
    width: 44px;
    height: 44px;
    cursor: pointer;
`;
/////////////////////////////
//  END style
////////////////////////////

class ProductCatalogue extends Component {
    static propTypes = {
        actionsFamilies:        PropTypes.object,
        actionsItemsKiosk:      PropTypes.object,
        families:               PropTypes.object,
        brands:                 PropTypes.array,
        itemsKiosk:             PropTypes.object,
        userKiosk:              PropTypes.object,
        modalHandler:           PropTypes.func,
        familySelected:         PropTypes.object,
        itemsKioskFilter:       PropTypes.array,
        filterSortBy:           PropTypes.array,
    };

    state = {
        showActions: false,
        cardLayout: true,
        textInputFilter: '',
    };

    /**
     * get data from store
     * families by kiosk and brands by family slected
     */
    componentDidMount = () => {
        this.getFamiliesByKiosk()
    };

    getFamiliesByKiosk = () => {
        const { actionsFamilies } = this.props;
        actionsFamilies.getFamiliesAndBrandsByKiosk()
    };

    componentDidUpdate(prevProps) {
        const { familySelected, userKiosk } = this.props;
        // when getFamiliesByKiosk is finish get brands and get items
        if (familySelected !== prevProps.familySelected) {
            this.getItemsKioskByFamily()
        }
        // when user switch kiosk selected get the new data
        if ( userKiosk !== prevProps.userKiosk ) {
            this.getFamiliesByKiosk()
        }
    }

    getItemsKioskByFamily() {
        const { actionsItemsKiosk } = this.props;
       
        actionsItemsKiosk.getItemsKioskByFamily()
    }

    getItemKiosk = (data) => {
        const { modalHandler, actionsItemsKiosk } = this.props;
        actionsItemsKiosk.getItemKiosk(data.id);
        modalHandler(data)
    };

    selectFamilyHandler = (id) => {
        const { actionsFamilies } = this.props;
        actionsFamilies.updateFamilySelected(Number(id))
        
    };

    selectBrandsCheckedHandler= (id, isChecked) => {
        const {actionsFamilies} = this.props;
        actionsFamilies.updateBrandsSelected(id, isChecked)
    };

    selectedFilterHandler = (id) => {
        const { actionsItemsKiosk } = this.props;
        actionsItemsKiosk.updateItemsSortBy(id)
    };

    onChangeHandler = (evt) => {
        const { actionsItemsKiosk } = this.props;
        const text = evt.target.value;
        const oldState = {...this.state};
        
        oldState.textInputFilter = text;
        this.setState(
            {...oldState},
            () => actionsItemsKiosk.startUpdateItemsFilterByInput(this.state.textInputFilter)
        )
    
    };

    switchLayoutItemsHandler = () => {
        const oldState = { ...this.state };
        oldState.cardLayout = !oldState.cardLayout;
        this.setState({ ...oldState })
    };

    render(){
        const { 
            translations,
            families,
            brands,
            filterSortBy,
            itemsKioskFilter} = this.props;
        const { cardLayout } = this.state;
   
        // if(families.isLoading ) {
        //     return <div></div>
        // }
        return(
            <ItemKioskWrapper>
                <ItemKioskFilterWrapper>
                    <SelectWrapper>
                        <Dropdown
                            width='194'
                            PaperTopPosition='50'
                            upper
                            data={families.items}
                            selectActionsHandler={this.selectFamilyHandler}
                        />
                        <DropdownCheckbox
                            width='92'
                            PaperTopPosition='50'
                            data={brands}
                            selectActionsHandler={this.selectBrandsCheckedHandler}
                            title={translations['common__text__brand']} // TODO: translations
                        />
                        <InputFilter
                            onChangeHandler={this.onChangeHandler}
                            value={this.state.textInputFilter}
                        />
                        <Dropdown
                            width='64'
                            title={translations['common__text__sort']}
                            PaperTopPosition='50'
                            data={filterSortBy}
                            selectActionsHandler={this.selectedFilterHandler}
                            translations={translations}
                            toTranslate
                        />
                        <HistoriqueButton
                            to={CR.codeHistories}
                        >
                            <BookImg src={Book} />
                            {translations['page__itemCatalogue__buton_codeHistory']}
                        </HistoriqueButton>
                        <ListOrCard
                            src={ cardLayout ? ListLayout : CardLayout}
                            onClick={this.switchLayoutItemsHandler}
                        />
                    </SelectWrapper>
                </ItemKioskFilterWrapper>
                {
                    cardLayout ?
                            <ItemsCardsWrapper>
                                {
                                    map(itemsKioskFilter, (elem, id) => (
                                        <ItemsCard 
                                            key={id}
                                            data={elem}
                                            getItem={this.getItemKiosk}
                                            translations={translations}
                                        />
                                    ))
                                }
                            </ItemsCardsWrapper>
                        :
                            <ItemsListWrapper>
                                {
                                    map(itemsKioskFilter, (elem, id) => (
                                        <ItemsList 
                                            key={id}
                                            data={elem}
                                            getItem={this.getItemKiosk}
                                            translations={translations}
                                        />
                                    ))
                                }
                            </ItemsListWrapper>
                    }
            </ItemKioskWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        families: state.families,
        userKiosk: selectKioskSelected(state),
        brands: selectBrandsFamilies(state),
        familySelected: selectFamilySelected(state),
        itemsKioskFilter: selectItemsSortBy(state),
        filterSortBy: state.itemsKiosk.filter.sortBy,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionsFamilies: bindActionCreators(familiesActionCreators, dispatch),
        actionsItemsKiosk: bindActionCreators(itemsKioskActionCreators, dispatch),
    }
};

export default ModalHOC(connect(mapStateToProps,  mapDispatchToProps)(ProductCatalogue));