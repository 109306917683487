////////LIBRARY/////////
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
///////COMPONENTS///////
import { exportDataActionCreators } from "../../state/actions"
import Button from "../Ui/Buttons";
/////////ASSETS/////////
import ExportDataImg from "../../assets/icones/global/export-data.png"

class ExportData extends Component {
    static propTypes = {
        text:                   PropTypes.string,
        scope:                  PropTypes.string,
        actionsExportData:      PropTypes.object,
    }

   	///////////////////////////////// HANDLE ///////////////////////////////////////

    getExportData = () => {
        const { scope, getDateFormat, actionsExportData, idInput } = this.props
        const dateFormated = getDateFormat ? getDateFormat() : null
        actionsExportData.getExportData( dateFormated, scope, idInput )
    }

   	///////////////////////////////// RENDER ///////////////////////////////////////

    render() {
        const { text } = this.props;
        return(
            <Button
                actionHandler={this.getExportData}
                text={text}
                img={ExportDataImg}
            />
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {actionsExportData: bindActionCreators(exportDataActionCreators, dispatch)}
}

export default connect(null, mapDispatchToProps)(ExportData)
