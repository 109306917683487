import ActionsTypes from '../../constants/ActionsTypes';

const exportData = {
    getExportData: ( date, scope, id ) => ({
        type: ActionsTypes.GET_EXPORT_DATA,
        payload: { date, scope, id },
    }),
    getExportDataSuccess: () => ({
        type: ActionsTypes.GET_EXPORT_DATA_SUCCESS,
        payload: {},
    }),
    getExportDataFailure: (error) => ({
        type: ActionsTypes.GET_EXPORT_DATA_FAILURE,
        payload: {
            error,
        },
    }),

};

export default exportData;