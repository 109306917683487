import { createSelector } from 'reselect'
import { forEach, map } from 'lodash'

const getPaymentColletcion              = state => state.paymentCollection;
const getSalesOrderIncluded             = state => state.paymentCollection.detail;
const getSalesOrderIncludedList         = state => state.paymentCollection.detail.salesOrdersIncluded;
const getSalesOrderExcluded             = state => state.paymentCollection.excluded;

export const selectPaymentColletcionSelected = createSelector(
    [ getPaymentColletcion ],
    ( paymentCollection ) => {
        let data = null;
        if(paymentCollection.items) {
            data = paymentCollection.items.filter( elem => elem.id === paymentCollection.selected );
            return data[0]
        }
        return data
    }
);
export const selectSalesOrderIncluded = createSelector(
    [ getSalesOrderIncluded ],
    ( salesOrderIncluded ) => {
        const data = {...salesOrderIncluded};
        if(salesOrderIncluded) {
            data.totalPricesTTC = 0;
            forEach(data.salesOrdersIncluded, ( item ) => {
                switch(item.paymentStatus.subFlow) {
                    case '100':
                        data.buyQty = data.buyQty + 1;
                        break;
                    case '800':
                        data.repaymentQty = data.repaymentQty + 1;
                        break;
                    default: 
                        data.cancelQty = data.cancelQty + 1
                }
            });
            forEach(data.salesOrdersIncluded, ( item ) => {
                data.totalPricesTTC = parseFloat(data.totalPricesTTC) + parseFloat(item.priceTTC)
            });
            data.totalPricesTTC = data.totalPricesTTC.toFixed(2);
            return data
        }
        return null
    }
);
export const selectSalesOrderExcluded = createSelector(
    [ getSalesOrderExcluded ],
    ( salesOrderExcluded ) => {
        const data = {...salesOrderExcluded};
        if(salesOrderExcluded) {
            return data
        }
        return null
    }
);
export const selectSalesOrderToSend = createSelector(
    [ getSalesOrderIncludedList ],
    ( salesOrderIncluded ) => {
        const data = {
            salesOrders: 
                map(salesOrderIncluded, (item) => {
                    return {
                        id: item.id,
                        priceTTC: item.priceTTC,
                        idPaymentStatus: item.paymentStatus.subFlow === '100' ? 3 : item.paymentStatus.subFlow >= '900' ? 1 : null
                    }
                })
        };
        return data
    }
);

