import {
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';
import {
    errorManagerActionCreators,
    familiesActionCreators,
    globalLoadingActionCreators
} from '../actions';
import API from '../../constants/ApiUrl'
import { selectIdsUser } from '../selectors/user'

import * as R from '../../constants/ApiConstants'

const getFamiliesByKiosk = function* () {
    
    const user = yield select(selectIdsUser);
    yield put(globalLoadingActionCreators.startLoading());
    try{
        const data = yield API(user.jwt).get(R.GET_FAMILIES_AND_BRANDS_BY_KIOSK(user));

        yield put(familiesActionCreators.getFamiliesAndBrandsByKioskSuccess(data.data));

    } catch (e) {

        yield put(errorManagerActionCreators.createError(e.response));

        yield put(familiesActionCreators.getFamiliesAndBrandsByKioskFailure(e.response));
    }
    yield put(globalLoadingActionCreators.endLoading());
};

const sagas = function* () {
    yield takeLatest(ActionsTypes.GET_FAMILIES_AND_BRANDS_BY_KIOSK, getFamiliesByKiosk);
};

export default sagas