import React, { Fragment} from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { find } from 'lodash'
import RoleList from '../../constants/RoleList'

import Layout from '../Ui/Layout';
import Home from "../../containers/Home/Home";

const ReservedRoute = ({ component: Component, ...rest}) => {
    let authorized = false;
    if(rest.user.identity.rolePrivileges && RoleList.viewAutoPath[rest.path]){
        authorized = find(rest.user.identity.rolePrivileges.privileges, {label: RoleList.viewAutoPath[rest.path]})?.isAuthorize
    }
    return (
        <Fragment>

            {
                <Route
                    {...rest}
                    render={props =>
                        rest.user.isConnected
                            ?
                                authorized
                                    ?
                                        (
                                            <Layout component={<Component translations={rest.translations} {...props} {...rest}/>}/>
                                        )
                                    :
                                        (
                                            <Layout component={<Home translations={rest.translations} {...props} {...rest}/>}/>
                                        )
                            :
                                (
                                    <Redirect
                                        to={{
                                            pathname: "/login",
                                            state: { from: props.location }
                                        }}
                                    />
                                )
                    }
                />
            }

        </Fragment>
    )
};

ReservedRoute.propTypes = {
    user: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        user: state.user,
        translations: state.languages.translations
    }
};

export default connect(mapStateToProps)(ReservedRoute);