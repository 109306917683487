import React from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'


// components
import UserAuthorizationLayout
	from '../../../../../components/UserAuthorizationLayout'
import MenuLink from "./MenuLink";


////////////////////////
//  START styled
////////////////////////

////////////////////////
//  END styled
////////////////////////

/**
 *
 * @param {func} closeMenuHandler
 * @param {object} translations
 * @param {object} openMenuState
 * @param {func} handleOpenNested
 * @param {array} menuList
 * @param {string} transitionState
 * @return {array}
 * @constructor
 */
const MenuLinks = ( { closeMenuHandler, translations, openMenuState, handleOpenNested, menuList, transitionState } ) => {
	return (
		menuList.length &&
			map( menuList, ( item, id ) => (

				<UserAuthorizationLayout
					key={ id }
					name={ item.auth }
				>
					<MenuLink
						data={ item }
						toggleMenu={ closeMenuHandler }
						translations={ translations }
						handleOpenNested={ handleOpenNested }
						openMenuState={ openMenuState }
						transitionState={transitionState}
					/>
				</UserAuthorizationLayout>

			) )

	)
}

MenuLinks.propTypes = {
	closeMenuHandler: PropTypes.func,
	translations: PropTypes.object,
	openMenuState: PropTypes.object,
	handleOpenNested: PropTypes.func,
	menuList: PropTypes.array,
	transitionState: PropTypes.string,
};

export default MenuLinks
