import { createSelector } from 'reselect'
import { isNull, isEmpty, forEach, filter, } from 'lodash'

const getLogsHistory                = state => state.logsHistory;
const getFilter                     = state => state.logsHistory.filter;
const getFilterLevel                = state => state.logsHistory.filterLevel;


export const selectLogHistoryFiltered = createSelector(
    [ getLogsHistory, getFilter, getFilterLevel ],
    (logsHistory, logsHistoryFilter, logsHistoryFilterLevel) => {

        if(!isEmpty(logsHistory.items)){
            const itemsList = filter(logsHistory.items, ( items, )=> {
                let flag = false;
                forEach(logsHistoryFilter, ( elem ) => {
                    if( elem.active ) {
                        if( elem.id === items.createdBy.id ){
                            flag = true
                        }
                        if(
                            !isNull(items.raisedBy) &&
                            elem.id === items.raisedBy.id
                        ){
                            flag = true

                        }
                        if(
                            !isNull(items.fixedBy) &&
                            elem.id === items.fixedBy.id
                        ){
                            flag = true

                        }
                    }
                });
                let flagLevel = false;
                forEach(logsHistoryFilterLevel, (elem) => {
                    if(elem.active){
                        if(elem.id === items.logLevel.id){
                            flagLevel = true
                        }
                    }
                })
                if(flag && flagLevel){
                    return items
                }
            });
            return itemsList
        }else{
            return []
        }

    }
);
