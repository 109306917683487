import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import { withRouter } from "react-router";
import moment from 'moment'

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { paymentCollectionActionCreators } from '../../state/actions';
import { selectKioskSelected } from '../../state/selectors/user'

// component import
import ModalDatePicker from '../../Hoc/ModalDatePicker'
import TableNested from '../../Hoc/TableNested'
import TableLayout from '../TableLayout'
import UserAuthorizationLayout from "../../components/UserAuthorizationLayout";
import userPrivileges from "../../constants/RoleList"
import ExportData from '../../components/ExportData'
import CR from '../../constants/RouteConstants'
import Button from '../../components/Ui/Buttons'

//assets import
import Calendar from '../../assets/icones/global/calendarX2.png'
import Eye from '../../assets/icones/global/eye.png'
import SalesHistory from "../../assets/icones/global/pie-chart.png";

// helper import
import StringFormater from '../../helpers/StringFormater';

// style
import {
    PageWrapper,
    LineTableHeader,
    TitleHeader,
    ActionWrapper,
    TitleHeaderTable,
    ContentTableWrapper,
    CalendarButton,
    CalendarImage,
    CalendarText,
    DetailActionWrapper,
    ImgSized,
} from "../../theme/GlobalCss";
import theme from "../../theme/theme";


/////////////////////////////
//  START style
////////////////////////////
/////////////////////////////
//  END style
////////////////////////////

export class PaymentCollection extends Component {
    static propTypes = {
        actionsCodesHistories: PropTypes.object,
        actionsDatePicker: PropTypes.object,
        closeDateModalHandler: PropTypes.func,
        codesHistories: PropTypes.object,
        dataTable: PropTypes.object,
        datePicker: PropTypes.object,
        formatDate: PropTypes.func,
        getDateFormat: PropTypes.func,
        hydrateStateHandler: PropTypes.func,
        modalHandler: PropTypes.func,
        nestedOnClickHandler: PropTypes.func,
        openDateModalHandler: PropTypes.func,
        refCollection: PropTypes.array,
        userKiosk: PropTypes.object,
    };

    ///////////////////////////////////////////////////
    ///////     START INIT COMPONENT    ///////////////
    ///////////////////////////////////////////////////
    componentDidMount = () => {
        this.getPaymentCollectionByTimerange()
    };
    ///////////////////////////////////////////////////
    ///////     END INIT COMPONENT    /////////////////
    ///////////////////////////////////////////////////

    getPaymentCollectionByTimerange = () => {
        const { actionPaymentCollection, getDateFormat } = this.props;
        const dateFormat = getDateFormat();

        actionPaymentCollection.getPaymentCollection( dateFormat );
    };

    componentDidUpdate( prevProps ) {
        const {
            paymentCollection,
            hydrateStateHandler,
            datePicker,
            translations
        } = this.props;

        // // fetch data if date change
        if ( datePicker !== prevProps.datePicker ) {

            this.getPaymentCollectionByTimerange()
        }

        if ( this.props.userKiosk !== prevProps.userKiosk ) {

            this.getPaymentCollectionByTimerange()
        }

        // // hydrate parent STATE if Table data change
        if ( paymentCollection.items !== prevProps.paymentCollection.items ) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            hydrateStateHandler( data )
        }

        if ( translations !== prevProps.translations ) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            hydrateStateHandler( data )
        }
    }

    /**
     * data object to row layout
     */
    bodyData = () => {
        const {
            paymentCollection,
            translations
        } = this.props;
        const header = [
            {
                name: translations['page__paymentCollectionHistory__PCTable_tableHeader_date'],
                useName: 'date',
                sortTable: true,
            },
            {
                name: translations['page__paymentCollectionHistory__PCTable_tableHeader_id'],
                useName: 'number',
                sortTable: true,
            },
            {
                name: translations['page__paymentCollectionHistory__PCTable_tableHeader_status'],
                useName: 'status',
                sortTable: true,
            },
            {
                name: translations['page__paymentCollectionHistory__PCTable_tableHeader_buyRecredCancelled'],
                useName: 'salesCancel',
                sortTable: true,
            },
            {
                name: translations['page__paymentCollectionHistory__PCTable_tableHeader_amount'],
                useName: 'total',
                sortTable: true,
            },
            {
                name: translations['page__paymentCollectionHistory__PCTable_tableHeader_comparison'],
                useName: 'grouping',
                sortTable: true,
            },
            { name: translations['common__text__detail'], useName: 'detail' },
        ];
        return {
            header: header,
            body: map( paymentCollection.items, ( elem, ) => {
                let createDate = [
                    moment( elem.dateCreated ).format( 'DD/MM/YYYY' ),
                    moment( elem.dateCreated ).format( 'HH:mm:ss' ),
                ];
                return ( {
                    id: elem.id,
                    elements: [
                        {
                            name: 'date',
                            value: createDate,
                            bcgAll: theme.colors.white,
                            sortValue: createDate,
                        },
                        {
                            name: 'number',
                            value: elem.number,
                            bcgAll: theme.colors.white,
                            sortValue: elem.number,
                        },
                        {
                            name: 'status',
                            value: translations[elem.paymentCollectionStatus.translationKey],
                            bcg: elem.paymentCollectionStatus.colorCode,
                            type: 'button',
                            color: theme.colors.white,
                            bcgAll: theme.colors.white,
                            sortValue: translations[elem.paymentCollectionStatus.translationKey],
                        },
                        {
                            name: 'salesCancel',
                            value: `${ elem.buyQty }-${ elem.repaymentQty }-${ elem.cancelQty }`,
                            color: theme.colors.primary,
                            bcgAll: theme.colors.white,
                            sortValue: `${ elem.buyQty }-${ elem.repaymentQty }-${ elem.cancelQty }`,
                        },
                        {
                            name: 'total',
                            value: `${ elem.totalAmountTTC }${ elem.currency.symbol }`,
                            color: theme.colors.validation,
                            bold: true,
                            bcgAll: theme.colors.white,
                            sortValue: elem.totalAmountTTC,
                        },
                        {
                            name: 'grouping',
                            value: translations[elem.comparisonStatus.translationKey],
                            bcg: elem.comparisonStatus.colorCode,
                            type: 'button',
                            color: elem.comparisonStatus.colorCode !== theme.colors.white
                                ? theme.colors.white
                                : 'inherit',
                            bcgAll: theme.colors.white,
                            sortValue: translations[elem.comparisonStatus.translationKey],
                        },
                        {
                            name: 'detail',
                            bcgAll: theme.colors.white,
                            cursorStyle: elem.paymentCollectionStatus.subFlow > 899 && 'not-allowed',
                            value:
                                elem.paymentCollectionStatus.subFlow < 900 &&
                                <UserAuthorizationLayout
                                    name={ userPrivileges.view[5] }
                                >
                                    <DetailActionWrapper
                                        onClick={ () => this.payementCollectionSelectedHandler( elem.id ) }
                                    >
                                        <ImgSized src={ Eye } width={ 20 }/>
                                    </DetailActionWrapper>
                                </UserAuthorizationLayout>

                        },
                    ]
                } )
            } )
        };
    };

    payementCollectionSelectedHandler = ( id ) => {
        const { history, match, actionPaymentCollection } = this.props;
        actionPaymentCollection.updatePaymentCollectionSelected( id );
        history.push( `${ match.url }/${ id }` )
    };

    render() {
        const {
            formatDate,
            datePicker,
            openDateModalHandler,
            dataTable,
            refCollection,
            translations,
            initSortTableHandle,
            getDateFormat,
        } = this.props;

        return (
            <PageWrapper>
                <LineTableHeader>
                    <TitleHeader>
                        { translations['page__paymentCollectionHistory__PCTable_title'] }
                    </TitleHeader>
                    <ActionWrapper>
                        <UserAuthorizationLayout
                            name={ userPrivileges.export[7] }
                        >
                            <ExportData
                                text={ translations['common__text__exportData'] }
                                scope={ 'paymentscollection' }
                                getDateFormat={ getDateFormat }
                            />
                        </UserAuthorizationLayout>
                        <UserAuthorizationLayout
                            name={ userPrivileges.view[0] }>
                            <Button
                                link={ CR.salesHistories }
                                text={ translations['page__paymentCollectionHistory_button_showSalesOrders'] }
                                img={SalesHistory}
                            />
                        </UserAuthorizationLayout>
                    </ActionWrapper>
                </LineTableHeader>
                <ContentTableWrapper>
                    <LineTableHeader>
                        <TitleHeaderTable>
                            <CalendarButton
                                onClick={ openDateModalHandler }
                            >
                                <CalendarImage src={ Calendar }/>
                            </CalendarButton>
                            <CalendarText>
                                { StringFormater.ResponseMessageParser(
                                    translations['common__text__datePeriod_noHours'],
                                    [ formatDate( datePicker.startDate ), formatDate( datePicker.endDate ) ]
                                ) }
                            </CalendarText>
                        </TitleHeaderTable>
                    </LineTableHeader>
                    {/* TODO: component Table layout to config */ }
                    { dataTable.headerData.length &&
                    <TableLayout
                        headerData={ dataTable.headerData }
                        bodyData={ dataTable.bodyData }
                        refCollection={ refCollection }
                        initSortTableHandle={ initSortTableHandle }
                        translations={translations}
                    />
                    }
                </ContentTableWrapper>

            </PageWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        paymentCollection: state.paymentCollection,
        userKiosk: selectKioskSelected( state ),
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        actionPaymentCollection: bindActionCreators( paymentCollectionActionCreators, dispatch ),
    };
};

export default withRouter( ModalDatePicker( TableNested( connect( mapStateToProps, mapDispatchToProps )( PaymentCollection ) ) ) )
