import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import Store from '../../helpers/Store'
import { findIndex, cloneDeep, concat, countBy } from 'lodash'

extendUpdate(update);

const user = {

    [ActionsTypes.UPDATE_USER_KIOSK_SELECTED]: (state, payload) => {
        let oldUser = {...state.user};
        oldUser.identity.kioskSelected = payload.id;
        Store.set('user', oldUser.identity);
        return update(state, {
            user:{
                $set: {...oldUser}
            },
    
        })        
    },
    [ActionsTypes.GET_USER_SETTINGS]: (state,) => {

        return update(state, {
            userSettings: {
                isLoading:{
                    $set: true
                }
            }
        })
    },
    [ActionsTypes.GET_USER_SETTINGS_SUCCESS]: (state, payload) => {
        let settings = payload.settings;
        const countIsTrue = countBy(settings.userShorcuts, 'isSelected');

        return update(state, {
            userSettings: {
                isLoading:{
                    $set: false
                },
                countIsTrue: {
                    $set: countIsTrue.true
                },
                items:{
                    $set: settings
                },
            }
        })
    },
    [ActionsTypes.GET_USER_SETTINGS_FAILURE]: (state, payload) => {

        return update(state, {
            userSettings:{
                error:{
                    $set: payload.error
                },
                isLoading:{
                    $set: false
                }
            },
        })
    },

    [ActionsTypes.PUT_USER_SETTINGS]: (state,) => {

        return update(state, {
            userSettings: {
                isLoading:{
                    $set: true
                }
            }
        })
    },
    [ActionsTypes.PUT_USER_SETTINGS_SUCCESS]: (state, payload) => {
        const settingsInput             = payload.settings;
        const oldSettings               = cloneDeep(state.userSettings.items.userShorcuts);
        let oldUserIdentity             = cloneDeep(state.user.identity.shorcuts);
        const idxSetting                = findIndex(oldSettings,{id: settingsInput.id} );
        const idxUsrIdentity            = findIndex(oldUserIdentity,{id: settingsInput.id} );
        oldSettings[idxSetting]         = settingsInput;

        if(idxUsrIdentity > -1) {
            oldUserIdentity[idxUsrIdentity] = settingsInput
        }else{
            oldUserIdentity = concat(oldUserIdentity, settingsInput)
        }
        const countIsTrue = countBy(oldSettings, 'isSelected');

        return update(state, {
            userSettings: {
                isLoading:{
                    $set: false
                },
                countIsTrue: {
                    $set: countIsTrue.true
                },
                items:{
                    userShorcuts:{
                        $set: [...oldSettings]
                    },
                },
            },
            user:{
                identity:{
                    shorcuts:{
                        $set: oldUserIdentity
                    }
                }
            }
        })
    },
    [ActionsTypes.PUT_USER_SETTINGS_FAILURE]: (state, payload) => {

        return update(state, {
            userSettings:{
                error:{
                    $set: payload.error
                },
                isLoading:{
                    $set: false
                }
            },
        })
    },
    [ActionsTypes.PUT_USER_NOTIF_SETTINGS]: (state,) => {

        return update(state, {
            userSettings: {
                isLoading:{
                    $set: true
                }
            }
        })
    },
    [ActionsTypes.PUT_USER_NOTIF_SETTINGS_SUCCESS]: (state, payload) => {
        const settingsInput             = payload.settings;
        const oldSettings               = cloneDeep(state.userSettings.items.userNotifications);
        const idxSetting                = findIndex(oldSettings,{id: settingsInput.id} );
        oldSettings[idxSetting]         = settingsInput;

        return update(state, {
            userSettings: {
                isLoading:{
                    $set: false
                },

                items:{
                    userNotifications:{
                        $set: [...oldSettings]
                    },
                },
            },
        })
    },
    [ActionsTypes.PUT_USER_NOTIF_SETTINGS_FAILURE]: (state, payload) => {

        return update(state, {
            userSettings:{
                error:{
                    $set: payload.error
                },
                isLoading:{
                    $set: false
                }
            },
        })
    },
};

export default user

