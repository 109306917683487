import PropTypes from 'prop-types'
import styled from 'styled-components';
import { withRouter } from "react-router";
import { map, find, isUndefined, isNull } from 'lodash'
import React, { Component } from 'react'

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { createPromoActionCreators } from '../../state/actions';

// component import
import TableNested from '../../Hoc/TableNested'
import TableLayout from '../TableLayout'
import CR from '../../constants/RouteConstants'
import ModalHOC from '../../Hoc/ModalHOC/'
import Button from '../../components/Ui/Buttons'
import DatePickerFutureCreate from '../../Hoc/DatePickerFutureCreate'


//assets import
import Calendar from '../../assets/icones/global/calendarX2.png'
import StringFormater from '../../helpers/StringFormater';
import theme from "../../theme/theme";



/////////////////////////////
//  START style
////////////////////////////
const SalesHistoriesWrapper = styled.div`
    grid-column: 1 / 13;
`;
const HistoriesHeader = styled.div`
    display: flex;
    height: 36px;
    margin-top: 27px;
    margin-bottom: 14px;
    justify-content: space-between;
`;
const TitleHeader = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    color: ${ props => props.theme.colors.cancel };
    margin-left: 15px;
    width: 100%;
    height: 36px;
`;
const ActionWrapper = styled.div`
    display: flex;
    height: 36px;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
`;
const AddLineHeader = styled.div`
    display: flex;
    align-items: center;
    height: 36px;
    width: 100%;
    margin: 14px 0;
    box-sizing: border-box;
`;
const ContentWrapper = styled.div`
    background-color: ${ props => props.theme.colors.white };
    border: 1px solid ${ props => props.theme.colors.borderTable };
    padding: 20px;
`;
const ContentActionHeader = styled(HistoriesHeader)`
    padding: 0;
    margin: 0% 0% 20px;
`;
const TableTitleHeader = styled(TitleHeader)`
    align-items: center;
    color: ${ props => props.color};
    font-weight: bold;
    margin-left: 0;
`;
const TableActionWrapper = styled(ActionWrapper)`
    width: 250px;
    flex-direction: row;
`;
const CalendarLink = styled.div`
    display: flex;
    align-items: center;
    padding: 9px;
    font-weight: bold;
    color: ${ props => props.theme.colors.white };
    cursor: pointer;
    font-size: 13px;
    background-color: ${ props => props.theme.colors.primary };
    border-radius: 3px;
    margin-right: 15px;
`;
const CalendarImage = styled.img`
    max-height: 18px;
    margin-right: 9px;
`;
const DateTextWrapper = styled.div`
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const BoxCanceld = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 27px;
    width: 27px;
    color: ${ props => props.theme.colors.white };
    font-weight: bold;
    border-radius: 3px;
    cursor: pointer;
`;
const PromoName = styled.input`
    width: 208px;
    height: 44px;
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 3px;
    display: flex;
    align-items: center;
    text-align: left;
    box-sizing: border-box;
    padding-left: 10px;
    ::first-letter{
        text-transform: capitalize;
    }
    color: ${ props => props.theme.colors.menu.wrapper };
    margin-right: 10px;
`;
const ErrorMessage = styled.p`
    color: ${ props => props.theme.colors.error};
`;
/////////////////////////////
//  END style
////////////////////////////

// TODO: test more push needed ( e.g check item and after delete object in BDD)
export class PromoManager extends Component {
    static propTypes = {
        actionsDatePicker:          PropTypes.object,
        closeDateModalHandler:      PropTypes.func,
        codesHistories:             PropTypes.object,
        dataTable:                  PropTypes.object,
        datePicker:                 PropTypes.object,
        formatDate:                 PropTypes.func,
        getDateFormat:              PropTypes.func,
        hydrateStateHandler:        PropTypes.func,
        modalHandler:               PropTypes.func,
        nestedOnClickHandler:       PropTypes.func,
        openDateModalHandler:       PropTypes.func,
        refCollection:              PropTypes.array,
        userKiosk:                  PropTypes.object,
        editInputHandler:           PropTypes.func,
    };

    state = {
        promotionName: '',
    };

    ///////////////////////////////////////////////////
    ///////     START INIT COMPONENT    ///////////////
    ///////////////////////////////////////////////////
    componentDidMount = () => {
        const { 
            actionsCreatePromo,
        } = this.props;
        actionsCreatePromo.initCreatePromo()
    };
    ///////////////////////////////////////////////////
    ///////     END INIT COMPONENT    /////////////////
    ///////////////////////////////////////////////////

    componentDidUpdate(prevProps) {
        const { itemsPromo, hydrateStateHandler, history, translations } = this.props;
        if( itemsPromo !== prevProps.itemsPromo ) {
            if( !itemsPromo.items.itemsStore ) {
                history.push(CR.priceManagment)
            }else {
                const createTable = this.bodyData();
                const data = {
                    header: createTable.header,
                    body: createTable.body,
                };
                hydrateStateHandler(data)
            }
        }
        if( translations !== prevProps.translations) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            hydrateStateHandler(data)
        }
    }

    /**
     * data object to row layout
     */
    bodyData = () => {
        const {
            itemsPromo,
            translations
            } = this.props;
                    
        const header = [
            {name: translations['page__promoDetail__itemTable_tableHeader_familly'], useName: 'famille'},
            {name: translations['page__promoDetail__itemTable_tableHeader_brand'], useName: 'marque'},
            {name: translations['page__promoDetail__itemTable_tableHeader_item'], useName: 'produit'},
            {name: translations['page__promoDetail__itemTable_tableHeader_itemType'], useName: 'typeProduit'},
            {name: translations['page__promoDetail__itemTable_tableHeader_salePrice'], useName: 'standardPrice'},
            {name: translations['page__promoDetail__itemTable_tableHeader_depositPrice'], useName: 'depositPrice'},
            {name: translations['page__promoDetail__itemTable_tableHeader_availableStock'], useName: 'stock'},
            {name: translations['page__promoDetail__itemTable_tableHeader_kioskCapacity'], useName: 'stockMax'},
        ];

        return{
            header: header,
            body:   map(itemsPromo.items.itemsStore, (elem, id) => {
                const itemChecked   = itemsPromo.checkedItems[id];
                const consignePriceSecondLine   = itemChecked.depositPrice;
                const consignePrice             = elem.depositPrice;
                const prices = {
                    priceItemStore: elem.standardPrice,
                    priceConsigne: !isNull(consignePrice) ? consignePrice.toFixed(2) : '-'
                };
                const pricesSecondLine = {
                    priceItemStore: itemChecked.standardPrice,
                    priceConsigne: !isNull(consignePriceSecondLine) ? consignePriceSecondLine : '-'
                };
                switch (elem.step) {
                    case 0:
                        return ({
                            id: elem.id,
                            elements: [
                                {name :'famille', value: translations[elem.itemFamily.translationKey], bcgAll:theme.colors.white},
                                {name :'marque', value: elem.itemBrand.label, bcgAll:theme.colors.white},
                                {name :'produit', value: elem.label, bcgAll:theme.colors.white},
                                {name :'typeProduit', value: elem.itemType, bcgAll:theme.colors.white},
                                {name :'standardPrice', value: prices.priceItemStore.toFixed(2), price: true, currency: itemChecked.currency.symbol, bcgAll:theme.colors.white},
                                {name :'depositPrice', value: prices.priceConsigne, price: true, currency: itemChecked.currency.symbol, bcgAll:theme.colors.white},
                                {name :'stock', value: elem.advailableStock, bcgAll:theme.colors.white },
                                {name :'stockMax', value: elem.kioskCapacity, bcgAll:theme.colors.white},
                                {name :'famille', value: elem.message, column:'1 / 8', bcgAll: elem.bcg, color: elem.color},
                                {
                                    name: 'actions',
                                    display: 'flex',
                                    bcgAll: elem.bcg,
                                    value:
                                        <BoxCanceld
                                            id={elem.id}
                                            onClick={() => this.deleteItemsHandler( elem.id )}
                                        >
                                            X
                                        </BoxCanceld>
                                },
                            ]
                        });
                    case 1:
                        return ({
                            id: itemChecked.id,
                            elements: [
                                {name :'famille', value: translations[elem.itemFamily.translationKey], bcgAll:theme.colors.white},
                                {name :'marque', value: elem.itemBrand.label, bcgAll:theme.colors.white},
                                {name :'produit', value: elem.label, bcgAll:theme.colors.white},
                                {name :'typeProduit', value: elem.itemType, bcgAll:theme.colors.white},
                                {name :'standardPrice', value: prices.priceItemStore.toFixed(2), price: true, currency: itemChecked.currency.symbol, bcgAll:theme.colors.white},
                                {name :'depositPrice', value: prices.priceConsigne, price: true, currency: itemChecked.currency.symbol, bcgAll:theme.colors.white},
                                {name :'stock', value: elem.advailableStock, bcgAll:theme.colors.white },
                                {name :'stockMax', value: elem.kioskCapacity, bcgAll:theme.colors.white},
        
                                {name :'famille', value: elem.message, column:'1 / 5', bcgAll: elem.bcg, color: elem.color},
                                {name :'standardPrice', value: pricesSecondLine.priceItemStore, active:elem.step, currency: elem.currency.symbol, bcgAll: elem.bcg, onBlur: (evt)=>this.inputChangeHandler(evt) },
                                {name :'depositPrice', value: pricesSecondLine.priceConsigne, active: elem.step && !isNull(elem.depositPrice), currency: elem.currency.symbol, bcgAll: elem.bcg, onBlur: (evt)=>this.inputChangeHandler(evt) },
                                {name :'advailableStock', value: itemChecked.advailableStock, active:elem.step,  bcgAll: elem.bcg, onBlur: (evt)=>this.inputChangeHandler(evt) },
                                {
                                    name: 'actions',
                                    display: 'flex',
                                    bcgAll: elem.bcg,
                                    value:
                                        <BoxCanceld
                                            id={itemChecked.id}
                                            onClick={() => this.deleteItemsHandler( itemChecked.id )}
                                        >
                                            X
                                        </BoxCanceld>
                                },
                            ]
                        })
                }
            })
        };
    };

    changePromoNameHandler = (evt) => {
        const promoName = evt.target.value;
        const oldState = this.state;
        oldState.promotionName = promoName;
        this.setState({...oldState})
    };

    inputChangeHandler = (evt) => {
        const { actionsCreatePromo } = this.props;
        const itemId    = parseInt(evt.target.id);
        const field     = evt.target.name;
        let price       = field === 'advailableStock' ? parseInt(evt.target.value) : Number(Number(evt.target.value).toFixed(2));

        actionsCreatePromo.updateItemPromoPrices(price, itemId, field)
    };
    deleteItemsHandler = ( id ) => {
        const { actionsCreatePromo } = this.props;
        const itemId = parseInt(id);
        actionsCreatePromo.deleteItemPromoPrices(itemId)
    };
    findIfAllItemsIsValidated = () => {
        const { itemsPromo }    = this.props;
        const data              = find(itemsPromo.items.itemsStore, { 'step' : 0 });

        return !isUndefined( data )
    };
    postPromotionHandler = () => {
        const { actionsCreatePromo, itemsPromo, date } = this.props;
        const dateToSend = {
            "dateStart" :		date.startDate,
            "dateEnd" : 		date.endDate,
        };
        const itemsStore    = itemsPromo.checkedItems;
        const name          = this.state.promotionName;
        actionsCreatePromo.createPromo(dateToSend, name, itemsStore)
    };

    render() {
        const { 
            itemsPromo,
            history,
            dataTable,
            refCollection,
            openDateModalHandler,
            formatDate,
            date,
            editInputHandler,
            translations
        } = this.props;

        if(!dataTable.headerData.length) {
            return <div> </div> 
        }
        return (
            <SalesHistoriesWrapper>
                <AddLineHeader>
                    <Button
                        goBack
                        weight='bold'
                        actionHandler={history.goBack} 
                        text={translations['common__text__previousPage']}
                    />
                </AddLineHeader>
                <AddLineHeader>
                    <CalendarLink
                        onClick={openDateModalHandler}
                    >
                        <CalendarImage src={Calendar} />

                    </CalendarLink>
                        { 
                            date.touch && date.validate
                                ? 
                                    <DateTextWrapper>
                                        {StringFormater.ResponseMessageParser(translations['common__text__datePeriod_noHours'], [formatDate(date.startDate),formatDate(date.endDate)])}
                                    </DateTextWrapper>
                                :
                                    date.message
                        }
                </AddLineHeader>
                <AddLineHeader>
                    <PromoName 
                        placeholder={translations['page__promoDetail__promoName_prefilled']}
                        value={this.state.promotionName}
                        onChange={(evt) => this.changePromoNameHandler(evt)}
                    />
                    {
                        date.touch && date.validate && !this.state.promotionName.length &&
                            <p>{translations['page__promoDetail__promoName_pleaseEnterName']}</p>
                    }
                </AddLineHeader>
                {
                    itemsPromo.error &&
                        <ErrorMessage>
                            {itemsPromo.error}
                        </ErrorMessage>
                }
                <HistoriesHeader>
                    <TitleHeader>
                        {translations['page__promoDetail__itemTable_title']}
                    </TitleHeader>
                </HistoriesHeader>
                <ContentWrapper>
                    <ContentActionHeader>
                        <TableTitleHeader color={ theme.colors.primary }>
                            { itemsPromo.items.store &&
                                itemsPromo.items.store.companyStoreLblToDisplay
                            }
                        </TableTitleHeader>
                        <TableActionWrapper>
                            <Button
                                actionHandler={this.postPromotionHandler}
                                text={translations['common__text__save']}
                                bgColor={ theme.colors.validation }
                                disabled={(
                                    itemsPromo.error ? true : this.findIfAllItemsIsValidated() || !date.validate
                                    || !this.state.promotionName.length
                                    )}
                            />
                            <Button
                                text={translations['common__text__cancel']}
                                bgColor={theme.colors.cancel}
                                actionHandler={history.goBack}
                                disabled={!date.validate}
                            />
                        </TableActionWrapper>
                    </ContentActionHeader>
                    {dataTable.headerData.length &&
                        <TableLayout
                            headerData={dataTable.headerData}
                            bodyData={dataTable.bodyData}
                            column={dataTable.headerData.length}
                            refCollection={refCollection}
                            editInputHandler={editInputHandler}
                            translations={translations}
                        />
                    }
                </ContentWrapper>

            </SalesHistoriesWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        itemsPromo:     state.createPromoItemsStore,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionsCreatePromo: bindActionCreators(createPromoActionCreators, dispatch),
    };
};

export default withRouter(DatePickerFutureCreate(TableNested(ModalHOC(connect(mapStateToProps, mapDispatchToProps)(PromoManager)))))
