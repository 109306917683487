import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import { cloneDeep, forEach, find, orderBy } from 'lodash';

extendUpdate(update);

const dashBoard = {

    [ActionsTypes.GET_DASHBOARD_SETTINGS]: (state) => update(state, {
        dashBoard : {
            isLoading: {
                $set: true,
            },
        }
    }),
    [ActionsTypes.GET_DASHBOARD_SETTINGS_SUCCESS]: (state, payload) => {
        const dashBoardSetting = orderBy(payload.dashBoardSetting, ['rank'], ['asc'] );

        return update(state, {
            dashBoard : {
                isLoading: {
                    $set: false,
                },
                items:{
                    $set: dashBoardSetting
                }
            }

        })
    },
    [ActionsTypes.GET_DASHBOARD_SETTINGS_FAILURE]: (state, payload) => update(state, {
        dashBoard : {
            isLoading: {
                $set: false,
            },
            error:{
                $set: payload.error,
            }
        }
    }),

    [ActionsTypes.UPDATE_DASHBOARD_SETTINGS_SELECTED]: (state, payload) => {
        const oldState  = cloneDeep(state.dashBoard.items);
        const idItem    = parseInt(payload.id)

        forEach(oldState, (elem) => {
            if (elem.id === idItem) {
                elem.isSelected = payload.isChecked
            }
        });

        return update(state, {
            dashBoard: {
                items:{
                    $set: oldState
                }
            },

        })
    },

    [ActionsTypes.PUT_DASHBOARD_SETTINGS]: (state) => update(state, {
        dashBoard : {
            isLoading: {
                $set: true,
            },
        }
    }),
    [ActionsTypes.PUT_DASHBOARD_SETTINGS_SUCCESS]: (state, payload) => {
        const dashBoardSetting = payload.dashBoardSetting;
        return update(state, {
            dashBoard : {
                isLoading: {
                    $set: false,
                },
                items:{
                    $set: dashBoardSetting
                }
            }

        })
    },
    [ActionsTypes.PUT_DASHBOARD_SETTINGS_FAILURE]: (state, payload) => update(state, {
        dashBoard : {
            isLoading: {
                $set: false,
            },
            error:{
                $set: payload.error,
            }
        }
    }),


    [ActionsTypes.GET_DASHBOARD]: (state) => update(state, {
        dashBoard : {
            isLoading: {
                $set: true,
            },
        }
    }),
    [ActionsTypes.GET_DASHBOARD_SUCCESS]: (state, payload) => {
        const dashBoard = payload.dashBoard;
        return update(state, {
            dashBoard : {
                isLoading: {
                    $set: false,
                },
                tables:{
                    $set: dashBoard
                }
            }

        })
    },
    [ActionsTypes.GET_DASHBOARD_FAILURE]: (state, payload) => update(state, {
        dashBoard : {
            isLoading: {
                $set: false,
            },
            error:{
                $set: payload.error,
            }
        }
    }),

    [ActionsTypes.UPDATE_DASHBOARD_SETTINGS_ORDER]: (state, payload) => {
        const settingsDashboard         = cloneDeep(state.dashBoard.items);
        const idItem                    = payload.idItem;
        const endIdxItem                = payload.endIdx;
        const startIdxItem              = payload.startIdx;
        const itemMove                  = find( settingsDashboard, { id: idItem });

        settingsDashboard.splice(startIdxItem, 1);
        settingsDashboard.splice(endIdxItem, 0, itemMove);

        forEach(settingsDashboard, (elem, idx) => elem.rank = idx + 1 );

        return update(state, {
            dashBoard : {
                isLoading: {
                    $set: false,
                },
                items:{
                    $set: settingsDashboard
                }
            }

        })
    },

};

export default dashBoard