import ActionsTypes from '../../constants/ActionsTypes';

const modals = {

    hydrateModal: ( data ) => ({
        type: ActionsTypes.HYDRATE_MODAL,
        payload: {
            data
        },
    }),
    closeModal: ( ) => ({
        type: ActionsTypes.CLOSE_MODAL,
        payload: {},
    }),
    closeModalRedirect: (  ) => ({
        type: ActionsTypes.CLOSE_MODAL_REDIRECT,
        payload: {},
    }),

};

export default modals;