import ActionsTypes from "../../constants/ActionsTypes";

const languages = {
  getTranslationByLanguages: (idLanguage) => ({
    type: ActionsTypes.GET_TRANSLATIONS_BY_LANGUAGES,
    payload: { idLanguage },
  }),
  getTranslationByLanguagesSuccess: (translations, idLanguage) => ({
    type: ActionsTypes.GET_TRANSLATIONS_BY_LANGUAGES_SUCCESS,
    payload: {
      translations,
      idLanguage,
    },
  }),
  getTranslationByLanguagesFailure: (error) => ({
    type: ActionsTypes.GET_TRANSLATIONS_BY_LANGUAGES_FAILURE,
    payload: {
      error,
    },
  }),
  getLanguagesEnabled: () => ({
    type: ActionsTypes.GET_LANGUAGES_ENABLED,
    payload: {},
  }),
  getLanguagesEnabledSuccess: (languages) => ({
    type: ActionsTypes.GET_LANGUAGES_ENABLED_SUCCESS,
    payload: {
      languages,
    },
  }),
  getLanguagesEnabledFailure: (error) => ({
    type: ActionsTypes.GET_LANGUAGES_ENABLED_FAILURE,
    payload: {
      error,
    },
  }),
  getTranslationPublicPage: (idLanguage) => ({
    type: ActionsTypes.GET_TRANSLATIONS_PUBLIC_PAGE,
    payload: {
      idLanguage,
    },
  }),
  getTranslationPublicPageSuccess: (translations, idLanguage) => ({
    type: ActionsTypes.GET_TRANSLATIONS_PUBLIC_PAGE_SUCCESS,
    payload: {
      translations,
      idLanguage,
    },
  }),
  getTranslationPublicPageFailure: (error) => ({
    type: ActionsTypes.GET_TRANSLATIONS_PUBLIC_PAGE_FAILURE,
    payload: {
      error,
    },
  }),
};

export default languages;
