import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { map, isEmpty, isNull } from 'lodash'
import { withRouter } from "react-router";

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { paymentCollectionActionCreators } from '../../state/actions';

// selector
import {
    selectPaymentColletcionSelected,
    selectSalesOrderIncluded,
    selectSalesOrderExcluded
} from '../../state/selectors/paymentCollection'

import moment from 'moment'

// component import
import TableNested from '../../Hoc/TableNested'
import TableLayout from '../TableLayout'
import Button from '../../components/Ui/Buttons'
import CR from '../../constants/RouteConstants'
import ModalSummerize from '../Modals/ModalSummerize'
import ModalBody from '../../components/Ui/Modals/ModalBody'
import UserAuthorizationLayout from '../../components/UserAuthorizationLayout'
import userPrivileges from '../../constants/RoleList'

//assets import
import Add from '../../assets/icones/global/add-button.png'
import Remove from '../../assets/icones/global/remove-button.png'
import PieChart from '../../assets/icones/global/pie-chart.png'

import StringFormater from '../../helpers/StringFormater';

// style
import {
    PageWrapper,
    LineTableHeader,
    TitleHeader,
    ActionWrapper,
    TitleHeaderTable,
    ContentTableWrapper,
} from "../../theme/GlobalCss";
import theme from "../../theme/theme";

/////////////////////////////
//  START style
////////////////////////////
const AddLineHeader = styled.div`
    display: flex;
    align-items: center;
    height: 36px;
    width: 100%;
    margin: 14px 0;
    box-sizing: border-box;
`;
const AddLineTable = styled( AddLineHeader )`
    height: 64px;
    background-color: ${ props => props.theme.colors.white };
    border: 1px solid ${ props => props.theme.colors.primary };
    padding: 0 20px 0 20px;
    display: grid;
    grid-template-columns: auto auto auto 1fr;
    grid-auto-rows: auto;
    grid-column-gap: 30px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
`;
const NestedTable = styled( AddLineTable )`
    height: 62px;
    border: none;
    padding: 0;
    background-color: none;
    justify-content: flex-end;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    width: 330px;
    margin: 0;
`;
const AddLineItem = styled.div`
    display: flex;
    flex: 1;
    padding: 0;
    justify-content: ${ props => props.end ? 'flex-end' : 'center' }; 
    align-items: center;
`;
const TotalPricesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: ${ props => props.theme.font.fm };
    font-weight: bold;
    width: ${ props => `${ props.width }px` };
    margin-right: ${ props => props.margin ? '100px' : '0' };
    height: 50px;
    background-color: ${ props => props.color ? props.color : props.theme.colors.primary };
    color: ${ props => props.theme.colors.white };
`;
const TotalPricesText = styled.div`
    font-size: ${ props => props.theme.font.fm };
    font-weight: bold;
    line-height: 17px;
`;
const SpanText = styled.span`
    color: ${ props => props.color };
    font-weight: ${ props => props.bold ? 'bold' : 'none' }
`;
const TwoLineText = styled.div`
    display: flex;
    color: ${ props => props.color ? props.color : 'inherit' };
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;
const TextContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 15px;
    background-color: ${ props => props.bcg ? props.bcg : 'inherit' };
    font-weight: ${ props => props.bold ? 'bold' : 'none' };
    color: ${ props => props.theme.colors.white };
    margin-left: 5px;
`;
const RemoveItemWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ImgAddOrRemove = styled.img`
    max-width: 32px;
`;
const ButtonWrapper = styled.div`
    display: flex;
    height: 30px;
`;
const TextModal = styled.div`
    margin: 0;
    padding: 15px 0;
`;
/////////////////////////////
//  END style
////////////////////////////

export class PaymentCollectionDetail extends Component {
    static propTypes = {
        dataTable:                          PropTypes.object,
        hydrateStateHandler:                PropTypes.func,
        refCollection:                      PropTypes.array,
        selectPaymentColletcionSelected:    PropTypes.array,
        salesOrderIncluded:                 PropTypes.object,
        salesOrderExcluded:                 PropTypes.object,
        paymentCollection:                  PropTypes.object,
        actionPaymentCollection:            PropTypes.object,

    };

    state = {
        showExcluded: false,
        modalAddSalesOrder: {
            showModal: false,
            idElem: null,
            type: null,
            salesType: null,
            value: null,
        },
        modalCancelGrouping: {
            showModal: false,
        },
        modalSuccess: {
            showModal: false,
        }
    };

    ///////////////////////////////////////////////////
    ///////     START INIT COMPONENT    ///////////////
    ///////////////////////////////////////////////////
    componentDidMount = () => {
        this.getPaymentCollectionDetail()
    };
    ///////////////////////////////////////////////////
    ///////     END INIT COMPONENT    /////////////////
    ///////////////////////////////////////////////////

    getPaymentCollectionDetail = () => {
        const { actionPaymentCollection, match } = this.props;

        const idPaymentCollection = match.params.id;
        actionPaymentCollection.getPaymentCollectionDetail( idPaymentCollection );
    };

    getSalesOrderExcluded = () => {
        const { actionPaymentCollection, match } = this.props;

        const idPaymentCollection = match.params.id;
        actionPaymentCollection.getSalesOrderExcluded( idPaymentCollection )
    };

    componentDidUpdate( prevProps ) {
        const {
            paymentCollectionDetail,
            salesOrderIncluded,
            hydrateStateHandler,
            dataTable,
            salesOrderExcluded,
            history,
        } = this.props;

        if (
                !isNull(salesOrderIncluded)
                && salesOrderIncluded !== prevProps.salesOrderIncluded
                && salesOrderIncluded.validation
        ) {
            this.setState( {
                modalSuccess: {
                    showModal: true,
                }
            } )
        }

        if ( paymentCollectionDetail.items ) {
            // // hydrate parent STATE if Table data change
            if (    paymentCollectionDetail.excluded !== prevProps.paymentCollectionDetail.excluded
                    && salesOrderExcluded
            ) {
                let createTable = this.bodyData( salesOrderExcluded.salesOrdersExclused, 0 );
                let data = {
                    header: {
                        excluded: createTable.header,
                        included: dataTable.headerData.included || []
                    },
                    body: {
                        excluded: createTable.body,
                        included: dataTable.bodyData.included || []
                    },
                };
                const updateTable = this.bodyData( salesOrderIncluded.salesOrdersIncluded, 1 );
                const updateData = {
                    header: {
                        included: updateTable.header,
                        excluded: data.header.excluded || []
                    },
                    body: {
                        included: updateTable.body,
                        excluded: data.body.excluded || []
                    },
                };
                hydrateStateHandler( updateData )
            }
            // // hydrate parent STATE if Table data change
            if (    paymentCollectionDetail.detail !== prevProps.paymentCollectionDetail.detail
                    && salesOrderIncluded
            ) {
                const createTable = this.bodyData( salesOrderIncluded.salesOrdersIncluded, 1 );
                const data = {
                    header: {
                        included: createTable.header,
                        excluded: dataTable.headerData.excluded || []
                    },
                    body: {
                        included: createTable.body,
                        excluded: dataTable.bodyData.excluded || []
                    },
                };
                if ( salesOrderExcluded ) {
                    const updateTable = this.bodyData( salesOrderExcluded.salesOrdersExclused, 0 );
                    const updateData = {
                        header: {
                            excluded: updateTable.header,
                            included: data.header.included || []
                        },
                        body: {
                            excluded: updateTable.body,
                            included: data.body.included || []
                        },
                    };
                    hydrateStateHandler( updateData )
                } else {
                    hydrateStateHandler( data )
                }
            }
            // if page is reload push to the parent page '/payment-collection'
        } else {
            history.push( CR.telecollection )
        }
    }

    /**
     * data object to row layout
     */
    bodyData = ( data, flag ) => {
        const {
            translations,
        } = this.props;

        const header = [
            { name: translations['page__paymentCollectionDetail__salesIncluded_tableHeader_date'], useName: 'date' },
            { name: translations['page__paymentCollectionDetail__salesIncluded_tableHeader_salesType'], useName: 'type' },
            { name: translations['page__paymentCollectionDetail__salesIncluded_tableHeader_paymentStatus'], useName: 'paiment' },
            { name: translations['page__paymentCollectionDetail__salesIncluded_tableHeader_familly'], useName: 'family' },
            { name: translations['page__paymentCollectionDetail__salesIncluded_tableHeader_item'], useName: 'label' },
            { name: translations['page__paymentCollectionDetail__salesIncluded_tableHeader_accessory'], useName: 'accessories' },
            { name: translations['page__paymentCollectionDetail__salesIncluded_tableHeader_paymentType'], useName: 'paimentType' },
            { name: translations['page__paymentCollectionDetail__salesIncluded_tableHeader_price'], useName: 'price' },
            {
                name:
                    this.state.showExcluded
                        ?
                        flag ?
                            translations['page__paymentCollectionDetail__salesIncluded_tableHeader_remove']
                            // 'RETIRER'
                            :
                            translations['page__paymentCollectionDetail__salesExcluded_tableHeader_add']
                            // 'AJOUTER'
                        :
                        null
                , useName: 'actions'
            },
        ];

        return{
            header: header,
            body: map( data, ( elem, ) => {
                let createDate = moment( elem.dateCreated ).format( 'DD/MM/YYYY - HH:mm:ss' );
                return ({
                    id: elem.id,
                    elements: [
                        { name: 'date', value: createDate, bcgAll: theme.colors.white },
                        {
                            name: 'type',
                            // value: elem.saleOrderType.label,
                            value: translations[elem.saleOrderType.translationKey],
                            bcg: elem.salesOrderStatus.colorCode,
                            type: 'button',
                            color: theme.colors.white,
                            bcgAll: theme.colors.white
                        },
                        {
                            name: 'paiment',
                            // value: elem.paymentStatus.description,
                            value: translations[elem.paymentStatus.translationKey],
                            type: 'button',
                            color: elem.paymentStatus.colorCode,
                            bcgAll: theme.colors.white
                        },
                        {
                            name: 'family',
                            // value: elem.itemFamily.label || '-',
                            value: translations[elem.itemFamily.translationKey] || '-',
                            bcgAll: theme.colors.white
                        },
                        {
                            name: 'label',
                            value: elem.labelToDisplay,
                            bcgAll: theme.colors.white
                        },
                        {
                            name: 'accessories',
                            value: elem.accessoryIsPresent ? translations['common__text__yes'] : translations['common__text__no'],
                            bcgAll: theme.colors.white
                        },
                        {
                            name: 'paimentType',
                            value: elem.paymentType.label,
                            bcgAll: theme.colors.white
                        },
                        {
                            name: 'price',
                            value: `${ elem.priceTTC }${ elem.currency.symbol }`,
                            bcgAll: theme.colors.white,
                            color: theme.colors.validation,
                            bold: true,
                        },
                        {
                            name: 'detail', bcgAll: theme.colors.white,
                            value:
                                this.state.showExcluded
                                    ?
                                    flag
                                        ?
                                        <RemoveItemWrapper
                                            onClick={ () => this.addAndRemoveSalesOrder( elem.id, 0 ) }
                                        >
                                            <ImgAddOrRemove
                                                src={ Remove }
                                                name='add'
                                            />
                                        </RemoveItemWrapper>
                                        :
                                        <RemoveItemWrapper
                                            onClick={ elem.number ? () => this.openModalAddSalesOrder( elem.id, 1, elem.saleOrderType.label, elem.number ) : () => this.addAndRemoveSalesOrder( elem.id, 1 ) }
                                        >
                                            <ImgAddOrRemove
                                                src={ Add }
                                                name='remove'
                                            />
                                        </RemoveItemWrapper>
                                    :
                                    null
                        },
                    ]
                } )
            } )
        };
    };

    modifyPaymentCollectionHandler = () => {
        const { showExcluded } = this.state;

        this.setState( {
                showExcluded: !this.state.showExcluded
            },
            () => !showExcluded && this.getSalesOrderExcluded()
        )
    };
    addAndRemoveSalesOrder = ( id, method, modalSelected ) => {
        const { actionPaymentCollection } = this.props;

        actionPaymentCollection.updateSalesOrderExcludedAndIncluded( id, method );

        modalSelected && this.closeModalHandler( modalSelected );
    };

    validationPaymentCollectionModify = () => {
        const { actionPaymentCollection, match } = this.props;
        const idPaymentCollection = match.params.id;

        this.setState( {
                showExcluded: false
            },
            () => actionPaymentCollection.updatePaymentCollectionSalesOrder( idPaymentCollection )
        )

    };
    cancelPaymentCollectionModify = () => {

        this.setState( {
                showExcluded: false
            },
            () => this.getPaymentCollectionDetail()
        )
    };

    openModalAddSalesOrder = ( idElem, type, salesType, value ) => {
        this.setState(
            {
                modalAddSalesOrder: {
                    showModal: true,
                    idElem: idElem,
                    type: type,
                    salesType: salesType,
                    value: value,
                }
            } )
    };

    openModalCancelGroupingHandler = () => {
        this.setState(
            {
                modalCancelGrouping: {
                    showModal: true,
                }
            } )
    };

    closeModalHandler = ( selectedModal, callBack ) => {
        const oldState = { ...this.state };

        oldState[selectedModal].showModal = false;
        this.setState( { ...oldState } );
        callBack && callBack()
    };

    render() {
        const {
            dataTable,
            refCollection,
            history,
            paymentCollection,
            salesOrderIncluded,
            translations
        } = this.props;

        const { modalAddSalesOrder } = this.state;

        if ( isNull( salesOrderIncluded ) || isEmpty( paymentCollection ) ) {
            return <div></div>
        }
        let comparisonColor = theme.colors.error;
        let totalPriceColor = theme.colors.error;

        if ( paymentCollection.totalAmountTTC === parseFloat( salesOrderIncluded.totalPricesTTC ) ) {
            totalPriceColor = theme.colors.validation
        }

        if (
            paymentCollection.buyQty === salesOrderIncluded.buyQty &&
            paymentCollection.repaymentQty === salesOrderIncluded.repaymentQty &&
            paymentCollection.cancelQty === salesOrderIncluded.cancelQty
        ) {
            comparisonColor = theme.colors.validation
        }

        const borderColor = comparisonColor === theme.colors.validation && totalPriceColor === theme.colors.validation ? theme.colors.validation : theme.colors.error;
        return (
            <PageWrapper>
                {/* add line modal if line is already in payment collection */ }
                <ModalSummerize
                    showModal={ this.state.modalAddSalesOrder.showModal }
                    closeModalHandler={ () => this.closeModalHandler( 'modalAddSalesOrder' ) }
                    title={translations['common__text__warning']}
                    level='warning'
                >
                    <ModalBody>
                        <TextModal>
                            {StringFormater.ResponseMessageParser(translations['page__paymentCollectionDetail__ModalAddItemInOrderPaymentCollection_ConfirmRemoveFromOther'], [modalAddSalesOrder.value])}
                        </TextModal>
                        <ButtonWrapper>
                            <Button
                                actionHandler={ () => this.addAndRemoveSalesOrder( modalAddSalesOrder.idElem, 1, 'modalAddSalesOrder' ) }
                                text={translations['common__text__save']}
                                margin='true'
                                bgColor={theme.colors.validation}
                            />
                            <Button
                                actionHandler={ () => this.closeModalHandler( 'modalAddSalesOrder' ) }
                                text={translations['common__text__cancel']}
                                bgColor={theme.colors.cancel}
                            />
                        </ButtonWrapper>
                    </ModalBody>
                </ModalSummerize>
                {/* modal on cancel comparison */ }
                <ModalSummerize
                    showModal={ this.state.modalCancelGrouping.showModal }
                    closeModalHandler={ () => this.closeModalHandler( 'modalCancelGrouping' ) }
                    // title='Attention'
                    title={translations['common__text__warning']}
                    level='warning'
                >
                    <ModalBody>
                        <TextModal>
                            {/* { `Voulez-vous vraiment annuler votre rapprochement ?` }  */}
                            {translations['page__paymentCollectionDetail__ModalCancel_ConfirmCancelComparison']}
                        </TextModal>
                        <ButtonWrapper>
                            <Button
                                actionHandler={ () => this.closeModalHandler( 'modalCancelGrouping', () => this.cancelPaymentCollectionModify() ) }
                                // text="Oui"
                                text={translations['common__text__yes']}
                                margin='true'
                                bgColor={theme.colors.validation}
                            />
                            <Button
                                actionHandler={ () => this.closeModalHandler( 'modalCancelGrouping' ) }
                                // text="Non"
                                text={translations['common__text__no']}
                                bgColor={theme.colors.cancel}
                            />
                        </ButtonWrapper>
                    </ModalBody>
                </ModalSummerize>
                {/* modal on success grouping send */ }
                <ModalSummerize
                    showModal={ this.state.modalSuccess.showModal }
                    closeModalHandler={ () => this.closeModalHandler( 'modalSuccess' ) }
                    title={translations['page__paymentCollectionDetail__ModalUpdate_success_title']}
                    level='validation'
                >
                    <ModalBody>
                        <TextModal>
                            {translations['page__paymentCollectionDetail__ModalUpdate_success']}
                        </TextModal>
                        <ButtonWrapper>
                            <Button
                                actionHandler={ () => this.closeModalHandler( 'modalSuccess', () => history.goBack() ) }
                                text={translations['common__text__ok']}
                                margin='true'
                                bgColor={theme.colors.validation}
                            />
                        </ButtonWrapper>
                    </ModalBody>
                </ModalSummerize>
                <LineTableHeader>
                    <ActionWrapper>
                        <Button
                            goBack
                            weight='bold'
                            actionHandler={ history.goBack }
                            text={translations['common__text__previousPage']}
                        />
                    </ActionWrapper>
                    <ActionWrapper>
                        {
                            this.state.showExcluded
                                ?
                                <Fragment>
                                    <Button
                                        actionHandler={ this.validationPaymentCollectionModify }
                                        text={translations['common__text__save']}
                                        margin='true'
                                        bgColor={theme.colors.validation}
                                        disabled={ borderColor === theme.colors.error }
                                    />
                                    <Button
                                        actionHandler={ this.openModalCancelGroupingHandler }
                                        text={translations['common__text__cancel']}
                                        bgColor={theme.colors.cancel}
                                    />
                                </Fragment>
                                :
                                <UserAuthorizationLayout
                                    name={userPrivileges.manage[5]}
                                >
                                    <Button
                                        actionHandler={ this.modifyPaymentCollectionHandler }
                                        text={translations['page__paymentCollectionDetail__PCHeader_button_updatePC']}
                                        img={PieChart}
                                    />
                                </UserAuthorizationLayout>
                        }
                    </ActionWrapper>
                </LineTableHeader>
                <LineTableHeader>
                    <TitleHeader>
                        {translations['page__paymentCollectionDetail__PCHeader_title']}
                    </TitleHeader>
                </LineTableHeader>
                <AddLineTable
                    column='4'
                >
                    <AddLineItem>
                        {
                            <TwoLineText>
                                <SpanText color={theme.colors.primary} bold>
                                    {translations['page__paymentCollectionDetail__PCHeader_subTitle']}
                                    { paymentCollection.number }
                                </SpanText>
                                <SpanText>
                                    { `du ${ moment( paymentCollection.dateCreated ).format( "DD/MM/YYYY à HH:mm:ss" ) }` }
                                </SpanText>
                            </TwoLineText>
                        }
                    </AddLineItem>
                    <AddLineItem>
                    {translations['page__paymentCollectionDetail__PCHeader_status']+" :"}

                        <TextContent
                            bcg={ paymentCollection.paymentCollectionStatus.colorCode }>
                            {/* { paymentCollection.paymentCollectionStatus.description } */}
                            { translations[paymentCollection.paymentCollectionStatus.translationKey] }
                        </TextContent>
                    </AddLineItem>
                    <AddLineItem>
                        {translations['page__paymentCollectionDetail__PCHeader_comparison']+" :"}
                        <TextContent
                            bcg={ paymentCollection.comparisonStatus.colorCode }>
                            {/* { paymentCollection.comparisonStatus.description } */}
                            { translations[paymentCollection.comparisonStatus.translationKey] }
                        </TextContent>
                    </AddLineItem>
                    <AddLineItem
                        end='true'
                    >
                        <NestedTable>
                            <AddLineItem>
                                <TotalPricesWrapper
                                    width='168'
                                >
                                    <TotalPricesText>
                                        {translations['page__paymentCollectionDetail__PCHeader_buyRecredCancelled']}
                                    </TotalPricesText>
                                    <TotalPricesText>
                                        { `${ paymentCollection.buyQty }-${ paymentCollection.repaymentQty }-${ paymentCollection.cancelQty }` }
                                    </TotalPricesText>
                                </TotalPricesWrapper>
                            </AddLineItem>
                            <AddLineItem>
                                <TotalPricesWrapper
                                    width='116'
                                >
                                    <TotalPricesText>
                                        {translations['page__paymentCollectionDetail__PCHeader_totalAmount']}
                                    </TotalPricesText>
                                    <TotalPricesText>
                                        { `${ paymentCollection.totalAmountTTC }${ paymentCollection.currency.symbol }` }
                                    </TotalPricesText>
                                </TotalPricesWrapper>
                            </AddLineItem>
                        </NestedTable>
                    </AddLineItem>
                </AddLineTable>
                <ContentTableWrapper
                    margin='20px 0'
                    borderColor={ borderColor }
                >
                    <LineTableHeader>
                        <TitleHeaderTable color={theme.colors.primary}>
                            {translations['page__paymentCollectionDetail__salesIncluded_subTitle']+" :"}
                        </TitleHeaderTable>
                        <ActionWrapper>
                            <NestedTable>
                                <AddLineItem
                                    nested
                                >
                                    <TotalPricesWrapper
                                        color={ comparisonColor }
                                        width='168'
                                    >
                                        <TotalPricesText>
                                            {translations['page__paymentCollectionDetail__PCHeader_buyRecredCancelled']}
                                        </TotalPricesText>
                                        <TotalPricesText>
                                            { `${ salesOrderIncluded.buyQty }-${ salesOrderIncluded.repaymentQty }-${ salesOrderIncluded.cancelQty }` }
                                        </TotalPricesText>
                                    </TotalPricesWrapper>
                                </AddLineItem>
                                <AddLineItem
                                    nested
                                >
                                    <TotalPricesWrapper
                                        color={ totalPriceColor }
                                        width='116'
                                    >
                                        <TotalPricesText>
                                            {translations['page__paymentCollectionDetail__PCHeader_totalAmount']}
                                        </TotalPricesText>
                                        <TotalPricesText>
                                            { `${ salesOrderIncluded.totalPricesTTC }${ salesOrderIncluded.currency.symbol }` }
                                        </TotalPricesText>
                                    </TotalPricesWrapper>
                                </AddLineItem>
                            </NestedTable>
                        </ActionWrapper>
                    </LineTableHeader>
                    {/* TODO: component Table layout to config */ }
                    <TableLayout
                        headerData={ dataTable.headerData.included }
                        bodyData={ dataTable.bodyData.included }
                        refCollection={ refCollection }
                        translations={translations}
                    />

                </ContentTableWrapper>
                {
                    this.state.showExcluded &&

                    <ContentTableWrapper>
                        <LineTableHeader>
                            <TitleHeaderTable color={theme.colors.primary}>
                                {translations['page__paymentCollectionDetail__salesExcluded_subTitle']+" :"}
                            </TitleHeaderTable>
                        </LineTableHeader>
                        <TableLayout
                            headerData={ dataTable.headerData.excluded }
                            bodyData={ dataTable.bodyData.excluded }
                            refCollection={ refCollection }
                            translations={translations}
                        />

                    </ContentTableWrapper>
                }

            </PageWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        paymentCollection: selectPaymentColletcionSelected( state ),
        salesOrderIncluded: selectSalesOrderIncluded( state ),
        salesOrderExcluded: selectSalesOrderExcluded( state ),
        paymentCollectionDetail: state.paymentCollection,
    };
};

const mapDispatchToProps = ( dispatch ) => {
    return {
        actionPaymentCollection: bindActionCreators( paymentCollectionActionCreators, dispatch ),
    };
};

export default withRouter( TableNested( connect( mapStateToProps, mapDispatchToProps )( PaymentCollectionDetail ) ) )
