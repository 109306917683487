import {
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';
import {
    globalLoadingActionCreators,
    errorManagerActionCreators,
    adminUserActionCreators
} from '../actions';
import API from '../../constants/ApiUrl'
import * as R from '../../constants/ApiConstants'
import { selectIdsUser } from '../selectors/user'

const getAdminUser = function* () {
    
    const user          = yield select(selectIdsUser);
    const apiCall       = R.GET_ADMIN_USER( user );

    yield put(globalLoadingActionCreators.startLoading());

    try{

        const data = yield API(user.jwt).get(apiCall);
        yield put(adminUserActionCreators.getAdminUserSuccess(data.data));

    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(adminUserActionCreators.getAdminUserFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());

};
const deleteAdminUser = function* ({ payload }) {

    const user          = yield select(selectIdsUser);
    const idUser        = payload.idUser;
    user['idUser']      = idUser;
    const scope         = payload.scope;
    let apiCall;

    yield put(globalLoadingActionCreators.startLoading());

    if( scope === 'delete' ){
        apiCall = R.DELETE_ADMIN_USER( user );
    }
    if( scope === 'disabled'){
        apiCall = R.DISABLED_ADMIN_USER( user );
    }

    try{

        const data = yield API(user.jwt).put(apiCall);

        yield put(adminUserActionCreators.deleteAdminUserSuccess(data.data, idUser, scope));

    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(adminUserActionCreators.deleteAdminUserFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());

};

const sagas = function* () {
    yield takeLatest(ActionsTypes.GET_ADMIN_USER, getAdminUser);
    yield takeLatest(ActionsTypes.DELETE_ADMIN_USER, deleteAdminUser);
};

export default sagas