////////LIBRARY/////////
import React from 'react'
import PropTypes from 'prop-types'
///////COMPONENTS///////
import BarChart from "./BarChart"
import DoughnutChart from "./DoughnutChart"
import LineChart from "./LineChart"
import NumbersStats from "./NumbersStats"
import TableSalesHistory from "./TableSalesHistory"
import TableUserLog from "./TableUserLogs"

/**
 *
 * @param {function} actionHandler onClick action
 */
const ChartFactory = ({
    data,
    translations
}) => {

    ///////////////////////////////// RENDER ///////////////////////////////////////

    return (

            data.typeId === 1 && data.contents ?
                <BarChart
                    data={data}
                    translations={translations}
                />
            : data.typeId === 2 && data.contents ?
                <DoughnutChart
                    data={data}
                    translations={translations}
                />
            : data.typeId === 3 && (data.contentsSalesAmount && data.contentsSalesCount) ?
                <LineChart
                    data={data}
                    translations={translations}
                />
            : data.typeId === 5 && data.contents ?
                <NumbersStats
                    data={data}
                    translations={translations}
                />
            : data.typeId === 4 && data.contents  ?
                <TableSalesHistory
                    data={data}
                    translations={translations}
                />
            : data.typeId === 6 && data.contents &&
                <TableUserLog
                    data={data}
                    translations={translations}
                />
    )
}

ChartFactory.propTypes = {
    data : PropTypes.object,
    translations: PropTypes.object,
}


export default ChartFactory
