import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { map } from 'lodash'
import Eye from '../../../assets/icones/global/eye.png'
import moment from "moment";
import CR from '../../../constants/RouteConstants'
import Button from "../Buttons";
import theme from "../../../theme/theme";

const ChartContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    align-items: stretch;
    justify-items: stretch;
    padding: 15px;
    background-color: ${ props => props.theme.colors.white };
    border: 1px solid ${ props => props.theme.colors.borderTable };
`;
const ChartWrapper = styled.div`
    width: 1fr;
    grid-column: ${ props => props.isFull ? '1 / 3' : 'auto' };
`;
const TitleWrapper = styled.div`
    display: flex;
    font-weight: bold;
    color: ${ props => props.theme.colors.menu.link };
`;
const TitleAndButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 18px 50px 18px;
    height: 32px;
`;
const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(max-content, 1fr));
    grid-auto-rows: 25px;
    grid-column-gap: 15px;
    grid-row-gap: 10px;
    align-items: stretch;
    justify-items: stretch;
    width: 100%;
`;
const RawContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${ props => props.color ? props.color : "none" };
`;
const DateBoldWrapper = styled.span`
    font-weight: bold;
`;
const ImgLink = styled.img`
    max-width: 20px;
`;
const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1 / -1;
`



/**
 *
 * @param {function} actionHandler onClick action
 */
const TableUserLog = ( {
                           data,
                           translations
                       } ) => {
    return (
        <ChartWrapper
            isFull={ data.fullWidth }
        >
            <ChartContentWrapper>
                <TitleAndButtonWrapper>
                    <TitleWrapper>
                        { translations[data.tradKeyTitle] }
                    </TitleWrapper>
                    <Button
                        link={ CR.logHistory }
                        text={ translations['common__text__viewAll'] }
                    />
                </TitleAndButtonWrapper>
                <ContentWrapper>
                    {
                        data.contents.length
                            ?
                                map( data.contents, ( elem ) => (
                                    map( elem.contents, ( item, id) => (
                                        <Fragment
                                            key={ id }
                                        >
                                            <RawContentWrapper>

                                                <DateBoldWrapper>
                                                    {
                                                        moment( item.dateCreated ).locale( 'fr-FR' ).format( 'ddd DD/MM/YY - HH:mm:ss' )
                                                    }
                                                </DateBoldWrapper>


                                            </RawContentWrapper>
                                            <RawContentWrapper
                                                color={ theme.colors.primary }
                                            >
                                                {
                                                    translations[item.logEvent.translationKey]
                                                }

                                            </RawContentWrapper>
                                            <RawContentWrapper>
                                                <div></div>
                                                <Link to={ `${ CR.logHistory }` }
                                                >
                                                    <ImgLink src={ Eye }/>
                                                </Link>
                                            </RawContentWrapper>
                                        </Fragment>
                                    ))
                                ) )
                            :
                                <NoData>
                                    No data to display
                                </NoData>
                    }
                </ContentWrapper>
            </ChartContentWrapper>
        </ChartWrapper>
    )
};

TableUserLog.propTypes = {
    data: PropTypes.object,
    translations: PropTypes.object,
};

export default TableUserLog
