import ActionsTypes from '../../constants/ActionsTypes';

const datePicker = {
    updateDate: (date) => ({
        type: ActionsTypes.UPDATE_DATE,
        payload: {
            date
        },
    }),
};

export default datePicker;