import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import { forEach, assign, map, find, cloneDeep } from 'lodash'

extendUpdate(update);

const kiosks = {
    [ActionsTypes.GET_KIOSKS]: (state) => update(state, {
        kiosksManager: {
            isLoading: {
                $set: true,
            },
            error: {
                $set: false,
            },
            items: {
                $set: false
            }
        },
    }),
    [ActionsTypes.GET_KIOSKS_SUCCESS]: (state, payload) => {
        forEach(payload.kiosks.Kiosks, (elem) => {
            assign(elem, {active: false})
        });
        return update(state, {
            kiosksManager: {
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: false,
                },
                items: {
                    $set: payload.kiosks
                }
            },
        })
    },
    [ActionsTypes.GET_KIOSKS_FAILURE]: (state, payload) => update(state, {
        kiosksManager: {
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
            items: {
                $set: false,
            }
        },
    }),
    [ActionsTypes.UPDATE_KIOSKS_SELECTED_SUCCESS]: (state, payload) => {
        const newState          = payload.kiosksList.updated;
        const oldState          = {...state.kiosksManager.items.Kiosks};
        const oldKioskList      = cloneDeep(state.user.identity.userKiosks);

        const newKioskList = map(oldKioskList, (elem) => {
            const newItem = find(newState, {idKiosk:elem.idKiosk});

            if(newItem){
                return newItem
            }
            return elem
        });

        const data = map(oldState, (elem) => {

            const newItem = find(newState, {idKiosk:elem.idKiosk});

            if(newItem){
                return newItem
            }
            return elem
        });

        return update(state, {
            user:{
                identity:{
                    userKiosks:{
                        $set: newKioskList
                    }
                }
            },
            kiosksManager: {
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: false,
                },
                items: {
                    Kiosks: {
                        $set: {...data}
                    }
                }
            },
        })
    },
    [ActionsTypes.UPDATE_KIOSKS_SELECTED_FAILURE]: (state, payload) => {
        // const oldState = {...state.itemsStorePrices.items}

        // forEach(oldState.itemsStore, (elem) => {
        //     if (elem.idItemStore == payload.id) {
        //         elem.active = payload.checked
        //     }else if (payload.id === 'ALL') {
        //         elem.active = payload.checked
        //     }
            
        // })
        // return update(state, {
        //     itemsStorePrices: {
        //         isLoading: {
        //             $set: false,
        //         },
        //         error: {
        //             $set: false,
        //         },
        //         items: {
        //             $set: oldState,
        //         }
        //     },
        // })
    },
};

export default kiosks;
