import update from 'immutability-helper'
import extendUpdate from '../../helpers/extendUpdate'
import ActionsTypes from '../../constants/ActionsTypes'
import { forEach, assign, reject, find, findIndex, cloneDeep, map } from 'lodash'

// selector
import { selectItemsPriciesSelected, selectItemStoreFromPromo } from '../../state/selectors/itemsKiosk'

// helper
import StringFormater from '../../helpers/StringFormater'
import theme from "../../theme/theme";

extendUpdate(update);

const createPromo = {
  [ActionsTypes.INIT_CREATE_PROMO]: (state) => {
    const translations        = state.languages.translations;
    const itemsStoreSelected  = cloneDeep(selectItemsPriciesSelected(state));
    forEach(itemsStoreSelected.itemsStore, (elem) => {
      assign(elem, { step: 0 });
      assign(elem, { message: translations['page__promoDetail__promoDates_pleaseSelectDates'] });
      assign(elem, { bcg: 'grey' });
      assign(elem, { color: 'white' })
    });

    return update(state, {
      createPromoItemsStore: {
        isLoading: {
          $set: false
        },
        isStarted: {
          $set: false
        },
        error: {
          $set: false
        },
        items: {
          $set: itemsStoreSelected
        },
        checkedItems: {
          $set: cloneDeep(itemsStoreSelected.itemsStore)
        }
      }
    })
  },
  [ActionsTypes.CHECK_CREATE_PROMO_VALIDATION]: (state) => {
    return update(state, {
      createPromoItemsStore: {
        isLoading: {
          $set: true
        },
        error: {
          $set: false
        }
      }
    })
  },
  [ActionsTypes.CHECK_CREATE_PROMO_VALIDATION_FAILURE]: (state, payload) => {
    const error         = payload.error.data;
    const translations  = state.languages.translations;
    const message       = StringFormater.ResponseMessageParser(translations[error.traductionKey], error.msgParameters);

    return update(state, {
      createPromoItemsStore: {
        isLoading: {
          $set: false
        },
        error: {
          $set: message
        }
      }
    })
  },
  [ActionsTypes.CHECK_CREATE_PROMO_VALIDATION_SUCCESS]: (state, payload) => {
    const itemsPromo          = payload.itemsPromo;
    const isStarted           = state.createPromoItemsStore.isStarted;
    const itemsStoreSelected  = cloneDeep(state.createPromoItemsStore.items.itemsStore);
    let isValid               = { ...state.createPromoItemsStore.isValid };
    const translations        = state.languages.translations;
    if (!isStarted) {

      forEach(itemsStoreSelected, (elem) => {
        let item = find(itemsPromo, { id: elem.id });
        let message = StringFormater.ResponseMessageParser(translations[item.traductionKey], item.msgParameters);
        elem['step'] = item.errorCode <= 10 ? 1 : 0;
        elem['message'] = message;
        elem['bcg'] =
                    item.errorCode < 10
                      ? theme.colors.primary
                      : item.errorCode >= 10 && item.errorCode <= 20
                        ? theme.colors.warning
                        : item.errorCode >= 30 && theme.colors.error;
        elem['color'] = (
            item.errorCode < 10 && 'white' ||
            item.errorCode >= 10 && 'black'
        )
      })
    } else {
      forEach(itemsStoreSelected, (elem) => {
        let item = find(itemsPromo, { id: elem.id });
        let message = StringFormater.ResponseMessageParser(translations[item.traductionKey], item.msgParameters);
        isValid = item.errorCode < 10;
        elem['message'] = message;
        elem['bcg'] =
                    item.errorCode < 10
                      ? theme.colors.cancel
                      : item.errorCode >= 10 && item.errorCode <= 20
                        ? theme.colors.warning
                        : item.errorCode >= 30 && theme.colors.error;

        elem['color'] =
                    item.errorCode < 10
                      ? 'white'
                      : item.errorCode >= 10 && 'black'
      })
    }

    return update(state, {
      createPromoItemsStore: {
        isValid: {
          $set: isValid
        },
        isLoading: {
          $set: false
        },
        error: {
          $set: false
        },
        items: {
          itemsStore: {
            $set: [...itemsStoreSelected]
          }
        }
      }
    })
  },
  [ActionsTypes.CREATE_PROMO]: (state) => {
    return update(state, {
      createPromoItemsStore: {
        isLoading: {
          $set: false
        },
        error: {
          $set: false
        }
      }
    })
  },

  [ActionsTypes.CREATE_PROMO_FAILURE]: (state, payload) => {
    return update(state, {
      createPromoItemsStore: {
        isLoading: {
          $set: false
        },
        error: {
          $set: payload.error
        }
      }
    })
  },
  [ActionsTypes.CREATE_PROMO_SUCCESS]: (state) => {
    const translations = state.languages.translations;

    const modal = {
      key: translations['page__promoDetail__modalGenerateSuccess_title'],
      title: translations['page__promoDetail__modalGenerateSuccess_title'],
      level: 'validation',
      show: true
    };
    return update(state, {
      modal: {
        $set: modal
      }
    })
  },
  [ActionsTypes.UPDATE_ITEM_PRICES_PROMO]: (state, payload) => {
    const idInput             = payload.id;
    const priceOrStockInput   = payload.price;
    const field               = payload.field;
    const oldData             = cloneDeep(state.createPromoItemsStore.checkedItems);
    const indexItem           = findIndex(oldData, { id: idInput });

    oldData[indexItem][field] = priceOrStockInput;
    return update(state, {
      createPromoItemsStore: {
        checkedItems: {
          $set: [...oldData]
        }
      }
    })
  },
  [ActionsTypes.DELETE_ITEM_PRICES_PROMO]: (state, payload) => {
    const idInput           = payload.id;
    const oldData           = cloneDeep(state.createPromoItemsStore.items.itemsStore);
    const checkedItems      = cloneDeep(state.createPromoItemsStore.checkedItems);
    const newCheckedItems   = reject(checkedItems, { id: idInput });
    const newState          = reject(oldData, { 'id': idInput });

    return update(state, {
      createPromoItemsStore: {
        checkedItems: {
          $set: newCheckedItems
        },
        items: {
          itemsStore: {
            $set: newState
          }
        }
      }
    })
  },
  [ActionsTypes.INIT_UPDATE_PROMO]: (state) => {
    return update(state, {
      createPromoItemsStore: {
        isLoading: {
          $set: true
        },
        error: {
          $set: false

        }
      }
    })
  },
  [ActionsTypes.INIT_UPDATE_PROMO_SUCCESS]: (state, payload) => {
    const data            = selectItemStoreFromPromo(state, payload.idPromo);
    const canUpdateItem   = data.promo.promoStatus.subFlow === '001';
    const translations    = state.languages.translations;

    forEach(data.itemsStore, (elem) => {
      assign(elem, { step: 1 });
      assign(elem, { message: canUpdateItem ? translations['common__message__promo_itemChecking_Update_isPromotionItemOK'] : translations['common__message__promo_itemChecking_Update_isPromotionItemNotOK'] });
      assign(elem, { bcg: ( (canUpdateItem && theme.colors.primary) || theme.colors.grey ) });
      assign(elem, { color: 'white' })
    });
    const checkedItems = map(data.promo.itemStorePromo, (elem) => {
      elem['depositPrice']      = elem['promoDepositPrice'];
      elem['standardPrice']     = elem['promoPrice'];
      elem['advailableStock']   = elem['allocatedStock'];
      elem['id']                = elem['idItemStore'];
      elem['itemFamily']        = elem['itemFamilly'];

      assign(elem, { step: 1 });
      assign(elem, { message: canUpdateItem ? translations['common__message__promo_itemChecking_Update_isPromotionItemOK']  : translations['common__message__promo_itemChecking_Update_isPromotionItemNotOK'] });
      assign(elem, { bcg: ( (canUpdateItem && theme.colors.primary) || theme.colors.grey) });
      assign(elem, { color: 'white' });
      return elem
    });

    return update(state, {
      createPromoItemsStore: {
        isStarted: {
          $set: !canUpdateItem
        },
        checkedItems: {
          $set: checkedItems
        },
        isLoading: {
          $set: false
        },
        items: {
          $set: data
        },
        error: {
          $set: false
        }
      }
    })
  },
  [ActionsTypes.INIT_UPDATE_PROMO_FAILURE]: (state, payload) => {
    return update(state, {
      createPromoItemsStore: {
        isLoading: {
          $set: false
        },
        error: {
          $set: payload.error
        }
      }
    })
  },

  [ActionsTypes.UPDATE_PROMO]: (state) => update(state, {
    promoItemStore: {
      isLoading: {
        $set: true
      },
      error: {
        $set: false
      }
    }
  }),
  [ActionsTypes.UPDATE_PROMO_SUCCESS]: (state) => {
    return update(state, {
      promoItemStore: {
        isLoading: {
          $set: false
        },
      }
    })
  },
  [ActionsTypes.UPDATE_PROMO_FAILURE]: (state, payload) => update(state, {
    createPromoItemsStore: {
      isLoading: {
        $set: false
      },
      error: {
        $set: payload.error
      },
      items: {
      }
    }
  })

};

export default createPromo
