import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { map, filter, isNull } from 'lodash'

// import component
import ActionButton from '../../ActionButton'

// constant
import CR from '../../../../constants/RouteConstants'
import imageImport from "../../../../helpers/imagesImport";
import theme from "../../../../theme/theme";

/// //////////////////////////
//  START style
/// /////////////////////////
const ItemsCardWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    background-color: ${ props => props.theme.colors.white };
    border: 1px solid ${ props => props.theme.colors.borderTable };
    padding: 9px 8px;
    height: 320px;
`;
const ImageWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ImageBottle = styled.img`
    max-height: 260px;
    max-width: 195px;
`;
const ContentWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;
const TextContentTitle = styled.p`
    color: ${ props => props.color ? props.color : 'inherit' };
    margin: 2px 0;
    font-weight: 900;
`;
const StdPromPriceWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-items: center;
    font-weight: 900;
    text-decoration: ${ props => props.promo ? 'none' : 'line-through' };
    background-color: ${ props => props.promo ? props.theme.colors.primary : 'none' };
    color: ${ props => props.promo ? props.theme.colors.white : props.theme.colors.cancel };
    padding: 4px 8px;
    margin-top: 7px;
    margin-right: ${ props => props.promo ? '12px' : '0' };
    margin-bottom: 13px;
`;
const PriceWrapper = styled.div`
    display: flex;
`;
const FeaturesPara = styled.p`
    margin: 0;
    height: 17px;
    text-decoration: ${ props => props.deco ? 'underline' : 'none' }
`;
const MainAccessory = styled.div`
    color: ${ props => props.theme.colors.primary };
    margin: 10px 0 0;
`;
const AccessoryPrice = styled( MainAccessory )`
    font-weight: 900;
    margin-top: 2px;
`;
const DepositContent = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${ props => props.theme.colors.primary };
`;
const DepositPrice = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    border: 1px solid ${ props => props.theme.colors.primary };
    margin-left: 6px;
    height: 25px;
    width: 60px;
    font-weight: 900;
`;
const PromoTag = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: ${ props => props.theme.colors.promoTag };
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 60px;
    color: ${ props => props.theme.colors.white };
`;
const StockTag = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: ${ props => props.stockAlerte };
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 23px;
    color: ${ props => props.theme.colors.white };
`;
const ActionButtonWrapper = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
`;
const TwoLinesTextWrapper = styled.div`
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   line-height: 19px;     /* fallback */
   height: 38px;      /* fallback */
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
`;

/// //////////////////////////
//  END style
/// /////////////////////////
/**
 *
 * @param {array} data
 * @param {function} getItem
 * @param {object} translations
 * @returns {jsx}
 * @constructor
 */
const ItemsCard = ( { data, getItem, translations } ) => {
    // get 3 accessories to display in card
    const mainFeature = filter( data.itemFeatures, [ 'mainItemFeature', true ] );

    let tagStockColor = '';
    let tagText = '';

    if ( data.lockerStock <= data.stockAlert && data.lockerStock > 0 ) {
        tagStockColor = theme.colors.warning;
        tagText = ` ${ translations['page__itemCatalogue__itemCard_inStock'] } ${ data.lockerStock } / ${ data.kioskCapacity }`
    } else if ( data.lockerStock <= 0 ) {
        tagStockColor = theme.colors.error;
        tagText = ` ${ translations['page__itemCatalogue__itemCard_noStock'] } ${ data.lockerStock } / ${ data.kioskCapacity }`
    } else if ( data.lockerStock > data.stockAlert ) {
        tagStockColor = theme.colors.validation;
        tagText = ` ${ translations['page__itemCatalogue__itemCard_inStock'] } ${ data.lockerStock } / ${ data.kioskCapacity }`
    }

    return (
        <ItemsCardWrapper>

            <ImageWrapper>
                {
                    !isNull( data.promoPrice ) &&
                    <PromoTag>
                        {/* promo */ }
                        { translations['page__itemCatalogue__itemCard_promoTag'] }
                    </PromoTag>
                }

                <ImageBottle
                    src={ imageImport( data.picture ) }
                />

                <StockTag
                    stockAlerte={ tagStockColor }
                >
                    { tagText }
                </StockTag>
            </ImageWrapper>
            <ContentWrapper>
                <TextContentTitle
                    color={theme.colors.primary}
                >
                    { data.itemBrand.label }
                </TextContentTitle>
                <TwoLinesTextWrapper>
                    <TextContentTitle>
                        { data.label }
                    </TextContentTitle>
                </TwoLinesTextWrapper>
                <PriceWrapper>
                    {
                        !isNull( data.promoPrice )
                            ?
                            <Fragment>
                                <StdPromPriceWrapper promo>
                                    { data.promoPrice.toFixed( 2 ) + data.currency.symbol }
                                </StdPromPriceWrapper>
                                <StdPromPriceWrapper>
                                    { data.standardPrice.toFixed( 2 ) + data.currency.symbol }
                                </StdPromPriceWrapper>
                            </Fragment>
                            : <StdPromPriceWrapper promo>
                                { data.standardPrice.toFixed( 2 ) + data.currency.symbol }
                            </StdPromPriceWrapper>
                    }
                </PriceWrapper>
                {
                    map( mainFeature, ( elem, id ) => (
                        <TwoLinesTextWrapper key={ id }>
                            <FeaturesPara deco>
                                { `${ elem.label } : ` }
                            </FeaturesPara>
                            <FeaturesPara>
                                { elem.value }
                            </FeaturesPara>

                        </TwoLinesTextWrapper>
                    ) )
                }
                { data.mainAccessory &&
                <MainAccessory>
                    { data.mainAccessory.label }
                    {
                        <AccessoryPrice>
                            { data.mainAccessory.promoPrice
                                ? data.mainAccessory.promoPrice.toFixed( 2 ) + data.mainAccessory.currency.symbol
                                : data.mainAccessory.standardPrice.toFixed( 2 ) + data.mainAccessory.currency.symbol
                            }
                        </AccessoryPrice>
                    }
                </MainAccessory>
                }
                { data.withDeposit &&
                <DepositContent>
                    {/* Consigne */ }
                    { translations['common__text__deposit'] }
                    <DepositPrice>
                        {
                            !isNull( data.promoDepositPrice )
                                ? `${ data.promoDepositPrice.toFixed( 2 ) }${ data.currency.symbol }`
                                : `${ data.depositPrice.toFixed( 2 ) }${ data.currency.symbol }`
                        }
                    </DepositPrice>
                </DepositContent>
                }
                <ActionButtonWrapper>
                    <ActionButton
                        link={ `${ CR.kioskProduct }${ CR.modal.kioskProduct }` }
                        actionHandler={ () => getItem( data ) }
                        dotSize={true}
                    />
                </ActionButtonWrapper>
            </ContentWrapper>
        </ItemsCardWrapper>
    )
};

ItemsCard.propTypes = {
    data: PropTypes.object,
    getItem: PropTypes.func,
    translations: PropTypes.object,
};

export default ItemsCard
