import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { isUndefined } from "lodash";

import Header from "../../../containers/Header/Header";
import styled from "styled-components";
import LoadingManager from "../../../Hoc/LoadingManager";
import ModalSummerize from "../../../containers/Modals/ModalSummerize/ModalSummerize";
import ModalBody from "../Modals/ModalBody";
import Button from "../Buttons";
import TranslationHandler from "../../../Hoc/TranslationHandler/TranslationHandler";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { modalsActionCreators } from "../../../state/actions";
import StringFormater from "../../../helpers/StringFormater";
import theme from "../../../theme/theme";

const AppWrapper = styled.div`
  width: 1024px;
  margin: auto;
  box-sizing: border-box;
`;
const BodyWrapper = styled.div`
  margin: auto;
  display: grid;
  grid-template-columns: repeat(12, 68px);
  grid-column-gap: 16px;
  margin: 0 16px 0 16px;
  box-sizing: border-box;
  margin-top: 120px;
`;
const HeaderWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 15;
`;
const ButtonWrapper = styled.div`
  display: flex;
  height: 30px;
`;
const TextModal = styled.div`
  margin: 0;
  padding: 15px 0;
`;

const Layout = (props) => {
  const {
    component,
    modal,
    modalsActions,
    changeLanguageHandler,
    languagesList,
    languages,
  } = props;
  const breadCrumb = component.props.breadcrumb;
  const translations = component.props.translations;
  const showHeader = isUndefined(component.props.noHeader);
  return (
    <AppWrapper>
      <ModalSummerize
        showModal={modal.show}
        closeModalHandler={
          !modal.path.length
            ? modalsActions.closeModal
            : modalsActions.closeModalRedirect
        }
        title={modal.title}
        level={modal.level}
      >
        <ModalBody>
          <TextModal>
            {StringFormater.ResponseMessageParser(
              modal.key,
              modal.errorMsgParams
            ) || modal.key}
          </TextModal>
          <ButtonWrapper>
            <Button
              actionHandler={
                !modal.path.length
                  ? modalsActions.closeModal
                  : modalsActions.closeModalRedirect
              }
              // text='Retour'
              text={
                modal.path.length
                  ? translations["common__text__previous"]
                  : translations["common__text__ok"]
              }
              margin="true"
              bgColor={theme.colors.primary}
            />
          </ButtonWrapper>
        </ModalBody>
      </ModalSummerize>

      {showHeader && (
        <HeaderWrapper>
          <Header
            breadCrumb={translations[breadCrumb]}
            translations={translations}
            changeLanguageHandler={changeLanguageHandler}
            languagesList={languagesList}
            languages={languages}
          />
        </HeaderWrapper>
      )}

      <BodyWrapper>{component}</BodyWrapper>
    </AppWrapper>
  );
};

Layout.propTypes = {
  component: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  modalsActions: bindActionCreators(modalsActionCreators, dispatch),
});

export default withRouter(
  TranslationHandler(
    LoadingManager(connect(mapStateToProps, mapDispatchToProps)(Layout))
  )
);
