import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import {
    paymentCollectionActionCreators,
} from '../../../../state/actions';

// import components
import ModalActionWrapper from '../ModalActionsWrapper'
import UserAuthorizationLayout
    from '../../../../components/UserAuthorizationLayout'
import userPrivileges from '../../../../constants/RoleList'
import Button from "../../../../components/Ui/Buttons";

// constant
import CR from '../../../../constants/RouteConstants'


/// //////////////////////////
//  START style
/// /////////////////////////

/// //////////////////////////
//  END style
/// /////////////////////////

export class ModalActionSalesHistories extends Component {
    static propTypes = {
        actionPaymentCollection: PropTypes.object,
        elem: PropTypes.object,
        closeModalHandler: PropTypes.func,
        translations: PropTypes.object,
    };

    componentDidUpdate(prevProps) {
        const { paymentCollection, history, elem } = this.props;
        if (paymentCollection !== prevProps.paymentCollection && paymentCollection) {
            history.push(`${CR.telecollection}/${elem.idPaymentCollection}`)
        }
    }

    render() {
        const {
            elem,
            closeModalHandler,
            translations,
            match,
            actionPaymentCollection
        } = this.props;
        const title = `${ elem.saleOrderType.label } n° ${ elem.idExtern }`;
        return (

            <ModalActionWrapper
                title={title}
                closeModal={closeModalHandler}
            >
                {
                    elem.paymentType.idPaymentType !== 2 &&
                    <Fragment>
                        <UserAuthorizationLayout
                            name={userPrivileges.view[1]}
                        >
                            <Button
                                link={`${CR.receipt.merchant.text}/${elem.id}`}
                                target="_blank"
                                text={translations['page__salesHistory__salesTable_modalAction_button_salesTicket']}
                                bold
                                caps
                            />
                        </UserAuthorizationLayout>

                        <UserAuthorizationLayout
                            name={userPrivileges.view[31]}
                        >
                            <Button
                                link={`${CR.receipt.customer.text}/${elem.id}`}
                                target="_blank"
                                text={translations['page__tpeTicket__ticketType_client']}
                                bold
                                caps
                            />
                        </UserAuthorizationLayout>
                    </Fragment>

                }


                <UserAuthorizationLayout
                    name={userPrivileges.view[2]}
                >
                    <Button
                        link={'#'}
                        text={translations['page__salesHistory__salesTable_modalAction_button_printInvoice']}
                        bold
                        caps
                    />
                </UserAuthorizationLayout>
                <UserAuthorizationLayout
                    name={userPrivileges.view[3]}
                >
                    {
                        elem.paymentStatus.subFlow < 900 &&
                        <Button
                            text={translations['page__salesHistory__salesTable_modalAction_button_updateCustomerInfo']}
                            link={`${match.url}${CR.modal.userConsignementInfo}`}
                            bold
                            caps
                        />
                    }
                </UserAuthorizationLayout>

                {/*  <UserAuthorizationLayout
                    name={ userPrivileges.view[4] }
                >
                    <Button
                        disabled={ !elem.idPaymentCollection }
                        actionHandler={ elem.idPaymentCollection
                            ? () => actionPaymentCollection.getSalesOrderPaymentCollection( elem.idPaymentCollection )
                            : null
                        }
                        text={ translations['page__salesHistory__salesTable_modalAction_button_displayPaymentCollection'] }

                        bold
                        caps
                    />
                </UserAuthorizationLayout> */}


                <UserAuthorizationLayout
                    name={userPrivileges.view[6]}
                >
                    <Button
                        text={translations['page__salesHistory__salesTable_modalAction_button_solveSaleWithIssue']}
                        link={'#'}
                        caps
                        bold
                    />
                </UserAuthorizationLayout>
            </ModalActionWrapper>

        )
    }

}

ModalActionSalesHistories.propTypes = {
    elem: PropTypes.object,
    closeModalHandler: PropTypes.func
};

const mapStateToProps = state => {
    return {
        paymentCollection: state.paymentCollection.items,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionPaymentCollection: bindActionCreators(paymentCollectionActionCreators, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalActionSalesHistories)
