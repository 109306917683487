import React, { Component } from 'react'
import PropTypes from 'prop-types'

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { salesOrdersActionCreators,  codesHistoriesActionCreators} from '../../../../state/actions';

// import components
import ModalActionWrapper from '../ModalActionsWrapper'
import UserAuthorizationLayout from "../../../../components/UserAuthorizationLayout"
import userPrivileges from "../../../../constants/RoleList";
import Button from "../../../../components/Ui/Buttons";

// constant
import CR from '../../../../constants/RouteConstants'

/////////////////////////////
//  START style
////////////////////////////
/////////////////////////////
//  END style
////////////////////////////

class ModalActionsCodesHistories extends Component {
    static propTypes = {
        actionsSaleOrder        : PropTypes.object,
        actionsCodesHistories   : PropTypes.object,
        elem                    : PropTypes.object,
        closeModalHandler       : PropTypes.func,
    };

    printAndSendCodeHandler = () => {
        const { actionsSaleOrder, elem } = this.props;
        actionsSaleOrder.getSalesOrderCodes(elem.id, elem)
    };

    cancelCodesHistoriesItem = () => {
        const { actionsCodesHistories, elem, closeModalHandler } = this.props;
        actionsCodesHistories.deleteCodesHistoriesItem( elem.id );
        closeModalHandler();
    };

    render() {
        const { elem, closeModalHandler, translations }   = this.props;
        const title                         = `code ${elem.generatedCode}`;
        const subFlowNumber                 = Number(elem.salesOrderStatus.subFlow);
        return (
            <ModalActionWrapper
                title={title}
                closeModal={closeModalHandler}
            >
                {
                   (
                       (!(subFlowNumber >= 100) && !(subFlowNumber < 200)) ||
                       (!(subFlowNumber === 999))
                   ) &&
                   <UserAuthorizationLayout
                        name={userPrivileges.manage[2]}
                    >
                       <Button
                           actionHandler={ this.cancelCodesHistoriesItem }
                           text={translations['page__codesHistory__codesTable_modalAction_button_deleteCode']}
                           bold
                           caps
                       />
                    </UserAuthorizationLayout>
                }

                {
                    subFlowNumber < 100 &&
                    // TODO: WHAT AUTH TO APPLY ?
                    <Button
                        link={`${CR.codeHistories}${CR.modal.generatedCode}`}
                        actionHandler={ this.printAndSendCodeHandler }
                        text={translations['page__codesHistory__codesTable_modalAction_button_printCode']}
                        bold
                        caps
                    />
                }

                {
                    (subFlowNumber === 900) &&
                    <UserAuthorizationLayout
                        name={userPrivileges.manage[3]}
                    >
                        <Button
                            link={`${CR.codeHistories}${CR.modal.codeRenew}`}
                            text={translations['page__codesHistory__codesTable_modalAction_button_renewCode']}
                            bold
                            caps
                        />
                    </UserAuthorizationLayout>
                }

            </ModalActionWrapper>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actionsSaleOrder        : bindActionCreators(salesOrdersActionCreators, dispatch),
        actionsCodesHistories   : bindActionCreators(codesHistoriesActionCreators, dispatch),
    };
};

export default connect(null, mapDispatchToProps)(ModalActionsCodesHistories)
