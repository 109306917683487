import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

// import store
import { Provider } from 'react-redux';
import storeConfig from "./state/configStore";

// import route
import { BrowserRouter as Router } from 'react-router-dom';

//import components
import App from './containers/App/App';

const store = storeConfig

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
