import React  from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'


/// //////////////////////////
//  START style
/// /////////////////////////
const Slider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${ props => props.theme.colors.userSettingSlider };
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 13px;

    ::before{
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 3px;
        background-color: ${ props => props.theme.colors.white };
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 13px;
    }    
`;

const SwitchToggle = styled.label`
    position: relative;
    display: inline-block;
    width: 56px;
    height: 26px;
    
`;
const InputSwitchToggle = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
    :checked + ${Slider} {
      background-color: ${ props => props.theme.colors.validation };
    }
    
    :focus + ${Slider} {
      box-shadow: 0 0 1px ${ props => props.theme.colors.primary};
    }
    
    :checked + ${Slider}:before {
      transform: translateX(26px);
    }
`;
/// //////////////////////////
//  END style
/// /////////////////////////

/**
 *
 * @param checked
 * @param onChangeHandler
 * @param id
 * @returns {*}
 */
const ToggleUserSettings =
    ( {
        checked,
        onChangeHandler,
        id
      } ) => {

        return (
            <SwitchToggle>
                <InputSwitchToggle
                    type={'checkbox'}
                    checked={checked}
                    onChange={  () => onChangeHandler(id, !checked) }
                />
                <Slider/>
            </SwitchToggle>
        )
    };

ToggleUserSettings.propTypes = {
    checked: PropTypes.bool,
    onChangeHandler: PropTypes.func,
    id: PropTypes.number,
};

export default ToggleUserSettings
