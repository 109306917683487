import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import PropTypes from "prop-types";

import {
  forEach,
  forIn,
  isNull,
  cloneDeep,
  map,
  isEmpty,
  isEqual,
  find,
} from "lodash";

//components
import HeaderTop from "../../components/Ui/Header/HeaderTop";
import Menu from "../../components/Ui/Menu";
import SwitchMachineSlider from "../../components/Ui/Modals/SwitchMachineSlider";

// redux import
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  logsActionCreators,
  userActionCreators,
  loginActionCreators,
} from "../../state/actions";

import { Transition } from "react-transition-group";

// selector
import {
  selectKioskSelected,
  selectKioskStatus,
} from "../../state/selectors/user";

// assets
import AlertLog from "../../assets/icones/header/alert-log.png";
import Refresh from "../../assets/icones/header/refresh.png";
import Home from "../../assets/icones/header/home.png";
import LogsAlert from "../../components/Ui/Modals/LogsAlert";
import ModalSummerize from "../Modals/ModalSummerize";
import ModalBody from "../../components/Ui/Modals/ModalBody";
import Button from "../../components/Ui/Buttons";
import moment from "moment";
import TagImgAndText from "../../components/Ui/TagImgAndText";
import theme from "../../theme/theme";

/////////////////////////////
//  START style
////////////////////////////
const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0px;
  width: 1024px;
  z-index: 15;
`;
const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  grid-column-start: 1;
  grid-column-end: 15;
`;
const NavHeader = styled.div`
  background-color: ${(props) => props.theme.colors.menu.wrapper};
  height: 29px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const BreadCrumb = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.font.fxs};
`;
const HomeIMG = styled.img`
  height: 18px;
  width: 18px;
  margin-right: 7px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  height: 30px;
`;

const ButtonWrapperNotif = styled(ButtonWrapper)`
  justify-content: center;
`;
const TextModal = styled.div`
  margin: 0;
  padding: 15px 0;
  text-align: center;
`;
const CategoriesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  justify-items: flex-start;
`;
const CategoriesEmbed = styled.span`
  font-weight: 900;
`;
const UsersLogsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;
`;
const UserLogText = styled.p`
  justify-items: flex-start;
  margin: 5px 0;
`;
/////////////////////////////
//  END style
////////////////////////////

class Header extends Component {
  static propTypes = {
    actions: PropTypes.object,
    kioskSelected: PropTypes.object,
    login: PropTypes.object,
    user: PropTypes.object,
    breadCrumb: PropTypes.string,
    translations: PropTypes.object,
    logs: PropTypes.object,
    logsAlert: PropTypes.array,
    selectKioskStatus: PropTypes.object,
  };

  state = {
    modals: {
      showMenu: false,
      showSwitchMachine: false,
      showLogAlert: false,
      showLogAlertDetail: false,
      showCloseAllModal: false,
    },
    logAlertSelectedElement: {},
    currentLanguagesList: [],
  };

  refCollection = [];

  componentDidMount() {
    const { user, languagesList, languages } = this.props;
    if (!isEmpty(user.identity.userKiosks)) {
      this.pollingLogs();
    }
    if (
      !isEmpty(languagesList) &&
      !isEqual(this.state.currentLanguagesList, languagesList) &&
      !isNull(languages.selectedLanguage)
    ) {
      let alphaOrder = cloneDeep(languagesList);
      alphaOrder.sort((a, b) => a.labelEnglish < b.labelEnglish);
      let ord = [
        find(languagesList, (o) => o.id === languages.selectedLanguage),
      ];
      alphaOrder.forEach((elem) => {
        if (elem.id !== languages.selectedLanguage) {
          ord.push(elem);
        }
      });
      this.setState({ currentLanguagesList: ord });
    }
  }

  pollingLogs = () => {
    const { logs } = this.props;
    logs.getLogsAlert();
  };

  closeMenuOutsideClickHandler = (evt) => {
    let closeModal = true;
    // check if click event is on containers Ref
    forIn(this.refCollection, (item) => {
      if (!isNull(item)) {
        if (item.contains(evt.target)) {
          closeModal = false;
        }
      }
    });
    // if click event is not in containers ref close modal
    if (closeModal) {
      this.closeMenuHandler();
    }
  };

  toggleMenuHandler = (evt) => {
    const elemToDisplay = evt.target.name;
    const oldState = { ...this.state.modals };

    forEach(oldState, (elem, id) => {
      if (id === elemToDisplay) {
        oldState[id] = !elem;
      } else {
        oldState[id] = false;
      }
    });
    this.setState({ modals: { ...oldState } });
    document.removeEventListener("click", this.closeMenuOutsideClickHandler);
    document.addEventListener("click", this.closeMenuOutsideClickHandler);
  };

  closeMenuHandler = () => {
    const oldState = { ...this.state.modals };
    forEach(oldState, (elem, id) => {
      oldState[id] = false;
    });
    this.setState({ modals: { ...oldState } });
    document.removeEventListener("click", this.closeMenuOutsideClickHandler);
  };

  switchMachineHandler = (id) => {
    const { actions, user } = this.props;
    actions.updateKioskSelected(user, id);
    this.closeMenuHandler();
  };

  openDetailHandler = (elem) => {
    const oldState = cloneDeep(this.state);
    this.closeMenuHandler();
    oldState.logAlertSelectedElement = { ...elem };
    oldState.modals.showLogAlertDetail = true;
    this.setState({ ...oldState });
  };
  closeDetailHandler = () => {
    const oldState = { ...this.state.modals };
    oldState.showLogAlertDetail = false;
    this.setState({ modals: { ...oldState } });
  };
  callAndCloseDetailHandler = (method) => {
    const { logAlertSelectedElement } = this.state;
    const { logs } = this.props;

    method && logs[method](logAlertSelectedElement.id);

    this.closeDetailHandler();
  };

  // handller to open modal if close all notif  handlerCloseAll
  openCloseAllNotif = () => {
    const oldState = cloneDeep(this.state.modals);
    this.closeMenuHandler();
    //oldState.showCloseAllModal = true
    this.setState({
      modals: { ...this.state.modals, showCloseAllModal: true },
    });
  };

  // handler to close modal if close all notif closerCloseAll
  closerCloseAllNotif = () => {
    const oldState = cloneDeep(this.state.modals);
    //oldState.showCloseAllModal = false
    this.setState({
      modals: { ...this.state.modals, showCloseAllModal: false },
    });
  };

  sendCloseAllNotif = () => {
    const ids = [];
    const { logs } = this.props;
    map(this.props.logsAlert, (elem) => {
      elem.logLevel.id === 6 && ids.push(elem.id);
    });
    logs.putResolvedAllLogAlert(ids);
    this.closerCloseAllNotif();
  };

  render() {
    const {
      user,
      kioskSelected,
      login,
      breadCrumb,
      translations,
      logsAlert,
      kioskStatusSelected,
      changeLanguageHandler,
      languages,
    } = this.props;
    const { logAlertSelectedElement, currentLanguagesList } = this.state;

    return (
      <Container>
        <HeaderWrapper>
          <Transition
            in={this.state.modals.showMenu}
            timeout={{
              appear: 0,
              enter: 0,
              exit: 300,
            }}
            mountOnEnter
            unmountOnExit
          >
            {(state) => (
              <Menu
                toogleMenu={this.toggleMenuHandler}
                closeMenuHandler={this.closeMenuHandler}
                refCollection={this.refCollection}
                logout={login.getLogout}
                translations={translations}
                languagesList={currentLanguagesList}
                changeLanguageHandler={changeLanguageHandler}
                menuTransition={state}
                selectedLanguage={languages.selectedLanguage}
              />
            )}
          </Transition>

          <HeaderTop
            toogleMenu={this.toggleMenuHandler}
            translations={translations}
            user={user}
            logsAlert={logsAlert}
            kioskStatusSelected={kioskStatusSelected}
          />

          <NavHeader>
            <BreadCrumb>
              <Link to="/home">
                <HomeIMG src={Home} />
              </Link>
              {`${translations["header__breadcrumb_home"]} ${breadCrumb || ""}`}
            </BreadCrumb>
          </NavHeader>
          <Transition
            in={this.state.modals.showSwitchMachine}
            timeout={{
              appear: 0,
              enter: 0,
              exit: 300,
            }}
            mountOnEnter
            unmountOnExit
          >
            {(state) => (
              <SwitchMachineSlider
                titleImg={Refresh}
                title={translations["header__kioskSelection_title"]}
                titleContent={`${translations["header__kioskSelection_currentKiosk"]} :`}
                textColor={`${kioskSelected.idExternOwner} - ${kioskSelected.idExternStore}`}
                subTitle={`${translations["header__kioskSelection_otherKiosks"]} :`}
                content={user.identity.userKiosks}
                refCollection={this.refCollection}
                closeMenuHandler={this.closeMenuHandler}
                switchMachineHandler={this.switchMachineHandler}
                translations={translations}
                menuTransition={state}
              />
            )}
          </Transition>

          <Transition
            in={this.state.modals.showLogAlert}
            timeout={{
              appear: 0,
              enter: 0,
              exit: 300,
            }}
            mountOnEnter
            unmountOnExit
          >
            {(state) => (
              <LogsAlert
                openDetailHandler={this.openDetailHandler}
                titleImg={AlertLog}
                side="right"
                title={translations["header__notif_title"]}
                content={logsAlert}
                refCollection={this.refCollection}
                closeMenuHandler={this.closeMenuHandler}
                translations={translations}
                menuTransition={state}
                opencloseAllNotif={this.openCloseAllNotif}
              />
            )}
          </Transition>
          {/* MODAL CLOSE ALL NOTIF */}
          {this.state.modals.showCloseAllModal && (
            <ModalSummerize
              showModal={this.state.modals.showCloseAllModal}
              closeModalHandler={this.closerCloseAllNotif}
              title={
                translations["header__admin__notif__modal__deleteAll_title"]
              }
              name="header"
              level="error"
              translations={translations}
            >
              <ModalBody>
                <TextModal>
                  {
                    translations[
                      "header__admin__notif__modal__deleteAll__text_warning"
                    ]
                  }
                </TextModal>
                <ButtonWrapperNotif>
                  <Button
                    actionHandler={this.closerCloseAllNotif}
                    text={translations["common__text__cancel"]}
                    bgColor={theme.colors.error}
                    margin="true"
                  ></Button>
                  <Button
                    actionHandler={this.sendCloseAllNotif}
                    text={
                      translations[
                        "header__admin__notif__modal__deleteAll__button_close"
                      ]
                    }
                    bgColor={theme.colors.validation}
                    margin="true"
                  ></Button>
                </ButtonWrapperNotif>
              </ModalBody>
            </ModalSummerize>
          )}
          {/* MODAL LOGS ON DETAIL START */}
          {this.state.modals.showLogAlertDetail && (
            <ModalSummerize
              showModal={this.state.modals.showLogAlertDetail}
              closeModalHandler={this.closeDetailHandler}
              title={translations["modal__logDetail__title"]}
              name="header"
              translations={translations}
            >
              <ModalBody>
                <TagImgAndText
                  idLevel={logAlertSelectedElement.logLevel.id}
                  text={
                    translations[
                      logAlertSelectedElement.logLevel.translationKey
                    ]
                  }
                  width="30px"
                  bold
                />
                <TextModal>
                  <p>
                    <CategoriesEmbed>
                      {`[ ${moment(logAlertSelectedElement.dateCreated)
                        .locale("fr")
                        .format("D/M/YY - HH:mm:ss")} - ${
                        logAlertSelectedElement.logEvent.label
                      }]`}
                    </CategoriesEmbed>
                  </p>
                  <UsersLogsList>
                    {!isNull(logAlertSelectedElement.createdBy) && (
                      <UserLogText>
                        <CategoriesEmbed>
                          {translations["modal__logDetail__body_createdBy"] +
                            " :"}
                        </CategoriesEmbed>
                        {` ${logAlertSelectedElement.createdBy.firstName} ${logAlertSelectedElement.createdBy.lastName}`}
                      </UserLogText>
                    )}
                    {!isNull(logAlertSelectedElement.raisedBy) && (
                      <UserLogText>
                        <CategoriesEmbed>
                          {translations["modal__logDetail__body_raisedBy"] +
                            " :"}
                        </CategoriesEmbed>
                        {` ${logAlertSelectedElement.raisedBy.firstName} ${logAlertSelectedElement.raisedBy.lastName}`}
                      </UserLogText>
                    )}
                    {!isNull(logAlertSelectedElement.fixedBy) && (
                      <UserLogText>
                        <CategoriesEmbed>
                          {translations["modal__logDetail__body_fixedBy"] +
                            " :"}
                        </CategoriesEmbed>
                        {` ${logAlertSelectedElement.fixedBy.firstName} ${logAlertSelectedElement.fixedBy.lastName}`}
                      </UserLogText>
                    )}
                  </UsersLogsList>
                  <CategoriesWrapper>
                    <p>
                      <CategoriesEmbed>
                        {translations["modal__logDetail__body_category"] + " :"}
                      </CategoriesEmbed>
                      {
                        translations[
                          logAlertSelectedElement.logCategorie.translationKey
                        ]
                      }
                    </p>
                    <p>
                      <CategoriesEmbed>
                        {translations["modal__logDetail__body_subCategory"] +
                          " :"}
                      </CategoriesEmbed>
                      {
                        translations[
                          logAlertSelectedElement.logSubCategorie.translationKey
                        ]
                      }
                    </p>
                  </CategoriesWrapper>
                  <p>{`${logAlertSelectedElement.message}`}</p>
                </TextModal>
                <ButtonWrapper>
                  <Button
                    actionHandler={this.closeDetailHandler}
                    text={translations["common__text__cancel"]}
                    bgColor={theme.colors.error}
                    margin="true"
                  />

                  {logAlertSelectedElement.logLevel.id === 6 &&
                    logAlertSelectedElement.logStatus.subFlow > "001" &&
                    logAlertSelectedElement.logStatus.subFlow < "030" && (
                      <Button
                        actionHandler={() =>
                          this.callAndCloseDetailHandler("putResolvedLogAlert")
                        }
                        text={
                          // translations['modal__logDetail__button_issueSolved']
                          "Clore la notification"
                        }
                        margin="true"
                        bgColor={theme.colors.validation}
                      />
                    )}

                  {(logAlertSelectedElement.logLevel.id === 2 ||
                    logAlertSelectedElement.logLevel.id === 3) &&
                    logAlertSelectedElement.logStatus.subFlow > "001" &&
                    logAlertSelectedElement.logStatus.subFlow < "030" && (
                      <Button
                        actionHandler={() =>
                          this.callAndCloseDetailHandler("putResolvedLogAlert")
                        }
                        text={
                          translations["modal__logDetail__button_issueSolved"]
                        }
                        margin="true"
                        bgColor={theme.colors.validation}
                      />
                    )}

                  {(logAlertSelectedElement.logLevel.id === 2 ||
                    logAlertSelectedElement.logLevel.id === 3) &&
                    (logAlertSelectedElement.logStatus.subFlow === "010" ||
                      logAlertSelectedElement.logStatus.subFlow === "110") && (
                      <Button
                        actionHandler={() =>
                          this.callAndCloseDetailHandler("putRemotedLogAlert")
                        }
                        text={
                          translations["modal__logDetail__button_raiseIssue"]
                        }
                        bgColor={theme.colors.warning}
                      />
                    )}
                </ButtonWrapper>
              </ModalBody>
            </ModalSummerize>
          )}
          {/* MODAL END */}
        </HeaderWrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    kioskSelected: selectKioskSelected(state),
    logsAlert: state.logs.items,
    kioskStatusSelected: selectKioskStatus(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(userActionCreators, dispatch),
  login: bindActionCreators(loginActionCreators, dispatch),
  logs: bindActionCreators(logsActionCreators, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
