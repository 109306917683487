import React, { Component } from 'react'
import PropTypes from 'prop-types'

// connect redux
import { bindActionCreators } from "redux";
import {
    salesOrdersActionCreators
} from "../../../../../state/actions";
import { connect } from "react-redux";

// import components
import ModalActionWrapper from '../../ModalActionsWrapper'
import Button from "../../../../../components/Ui/Buttons";

// constant


/// //////////////////////////
//  START style
/// /////////////////////////
/// //////////////////////////
//  END style
/// /////////////////////////
export class ModalActionsSalesHistoriesItem extends Component {

    static propTypes = {
        elem: PropTypes.object,
        closeModalHandler: PropTypes.func,
        contractURL: PropTypes.string,
        salesOrders: PropTypes.object,
    };

    depositContractHandler = () => {
        const { salesOrders, elem } = this.props;
        salesOrders.putSalesOrderDepositContract(elem.idSalesOrderLineDeposit)
    };

    render() {

        const { elem, closeModalHandler, translations } = this.props;
        const title = `${elem.productLabel}`;

        return (
            <ModalActionWrapper
                title={title}
                closeModal={closeModalHandler}
            >
                <Button
                    actionHandler={this.depositContractHandler}
                    text={translations['page__salesHistory__salesTable_expand_modalAction_button_printDepositContract']}
                    caps
                    bold
                />
            </ModalActionWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        contractURL: state.salesHistories.contractURL.items
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        salesOrders: bindActionCreators(salesOrdersActionCreators, dispatch),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ModalActionsSalesHistoriesItem)
