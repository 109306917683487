import {
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';
import {
    userActionCreators,
    errorManagerActionCreators,
    globalLoadingActionCreators,
} from '../actions';
import API from '../../constants/ApiUrl'
import { selectIdsUser } from '../selectors/user'

import * as R from '../../constants/ApiConstants'

const getUserSettings = function* () {

    const user = yield select(selectIdsUser);
    yield put( globalLoadingActionCreators.startLoading() );

    try{
        const data = yield API(user.jwt).get(R.GET_USER_SETTING());
        
        yield put(userActionCreators.getUserSettingsSuccess(data.data));
    } catch (e) {

        yield put(errorManagerActionCreators.createError(e.response));
        yield put(userActionCreators.getUserSettingsFailure(e.response));
    }
    yield put( globalLoadingActionCreators.endLoading() );
};

const putUserSettings = function* ({ payload }) {

    const id        = parseInt(payload.id);
    const checked   = {
        selected: payload.checked
    };

    const user      = yield select(selectIdsUser);

    try{
        const data = yield API(user.jwt).put(R.PUT_USER_SETTING(id), checked);
        yield put(userActionCreators.putUserSettingsSuccess(data.data));
    } catch (e) {

        yield put(errorManagerActionCreators.createError(e.response));
        yield put(userActionCreators.putUserSettingsFailure(e.response));
    }
};

const putUserNotifSettings = function* ({ payload }) {

    const id        = parseInt(payload.id);
    const checked   = {
        selected: payload.checked
    };

    const user      = yield select(selectIdsUser);

    try{
        const data = yield API(user.jwt).put(R.PUT_USER_SETTING_NOTIF(id), checked);
        yield put(userActionCreators.putUserNotifSettingsSuccess(data.data));
    } catch (e) {

        yield put(errorManagerActionCreators.createError(e.response));
        yield put(userActionCreators.putUserNotifSettingsFailure(e.response));
    }
};

const sagas = function* () {
    yield takeLatest(ActionsTypes.GET_USER_SETTINGS, getUserSettings);
    yield takeLatest(ActionsTypes.PUT_USER_SETTINGS, putUserSettings);
    yield takeLatest(ActionsTypes.PUT_USER_NOTIF_SETTINGS, putUserNotifSettings);
};

export default sagas