import {
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';
import {
    stockManagerActionCreators,
    globalLoadingActionCreators,
    errorManagerActionCreators, modalsActionCreators
} from '../actions';
import API from '../../constants/ApiUrl'
import * as R from '../../constants/ApiConstants'
import { selectIdsUser } from '../selectors/user'
import { map } from 'lodash'


const getItemsKiosksStock = function* ({payload}) {

    let apiCall;
    const user      = yield select(selectIdsUser);
    const idItem    = payload.id;

    if(idItem) {
        user['item']    = idItem;
        apiCall         = R.GET_ONE_ITEMS_KIOSKS_STOCK(user)
    }else{
        apiCall     = R.GET_ITEMS_KIOSKS_STOCK(user)
    }

    yield put(globalLoadingActionCreators.startLoading());

    try{
        const data = yield API(user.jwt).get(apiCall);

        yield put(stockManagerActionCreators.getItemsKiosksStockSuccess(data.data));

    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(stockManagerActionCreators.getItemsKiosksStockFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());

};

const updateItemsKiosksStock = function* ({payload}) {

    const user              = yield select(selectIdsUser);
    const kiosksStockList   = map(payload.itemsKiosksStock, (elem) => {
        const item = {
            id: elem.id,
            alertStock: elem.alertStock,
        };
        return item
    });
    yield put(globalLoadingActionCreators.startLoading());

    const apiCall   = R.UPDATE_ITEMS_KIOSKS_STOCK();

    try{
        const data = yield API(user.jwt).put(apiCall, kiosksStockList);

        yield put(stockManagerActionCreators.updateItemsKiosksStockSuccess(data.data));

        if(data.data.error.length){
            yield put(errorManagerActionCreators.createError(data));
        }else{
            if( data.status === 200 ) {
                const modal = {
                    level: 'validation',
                    title: "page__stockManagement__modalUpdateSuccess_title",
                    key: 'page__stockManagement__modalUpdateSuccess_body',
                    show: true,
                    path: ''
                };
                yield put(modalsActionCreators.hydrateModal(modal))
            }
        }

    } catch (e) {



        yield put(errorManagerActionCreators.createError(e.response));

        yield put(stockManagerActionCreators.updateItemsKiosksStockFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());

};

const sagas = function* () {
    yield takeLatest(ActionsTypes.GET_ITEMS_KIOSKS_STOCK, getItemsKiosksStock);
    yield takeLatest(ActionsTypes.UPDATE_ITEMS_KIOSKS_STOCK, updateItemsKiosksStock);
};

export default sagas