import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import { forEach, assign, map, find } from 'lodash'

extendUpdate(update);

const itemsStorePrices = {
    [ActionsTypes.GET_ITEMS_STORE_PRICES]: (state) => update(state, {
        itemsStorePrices: {
            isLoading: {
                $set: true,
            },
            error: {
                $set: false,
            },
            items: {
                $set: false
            }
        },
    }),
    [ActionsTypes.GET_ITEMS_STORE_PRICES_SUCCESS]: (state, payload) => {
        forEach(payload.itemsStorePrices.itemsStore, (elem) => {            
            assign(elem, {active: false, selected: false})
        });
        return update(state, {
            itemsStorePrices: {
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: false,
                },
                items: {
                    $set: payload.itemsStorePrices
                }
            },
        })
    },
    [ActionsTypes.GET_ITEMS_STORE_PRICES_FAILURE]: (state, payload) => update(state, {
        itemsStorePrices: {
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
            items: {
                $set: false,
            }
        },
    }),
    [ActionsTypes.GET_ITEMS_STORE_PRICES]: (state) => update(state, {
        itemsStorePrices: {
            isLoading: {
                $set: true,
            },
        },
    }),
    [ActionsTypes.SELECTED_ITEMS_STORE_PRICES_UPDATE]: (state, payload) => {
        const id        = payload.id;
        const oldState  = state.itemsStorePrices.items.itemsStore;
        const condition = payload.condition;

        if(!condition.editing && !condition.editOne){
            if( id === 'ALL' ) {
                forEach(oldState, (elem) => {
                    elem.selected = condition.header
                })
            }else {
                if( !condition.editAll ) {
                    forEach(oldState, (elem) => {
                        if( elem.id === parseInt(id) ) {
                            elem.selected = !elem.selected
                        }
                    })
                }
            }
        }
        
        return update(state, {
            itemsStorePrices: {
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: false,
                },
                items: {
                    itemsStore: {
                        $set: oldState
                    }
                }
            },
        })
    },
    [ActionsTypes.UPDATE_ITEMS_STORE_PRICES_SELECTED_SUCCESS]: (state, payload) => {
        const newState = payload.pricesList.updated;
        const oldState = {...state.itemsStorePrices.items.itemsStore};

        const data = map(oldState, (elem) => {
            
            const newItem = find(newState, {id:elem.id});

            if(newItem){
                return newItem
            }
            return elem
        });
        return update(state, {
            itemsStorePrices: {
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: false,
                },
                items: {
                    itemsStore: {
                        $set: [...data]
                    }
                }
            },
        })
    },
    [ActionsTypes.UPDATE_ITEMS_STORE_PRICES_SELECTED_FAILURE]: (state) => {
            
        return update(state, {
            // itemsStorePrices: {
            //     isLoading: {
            //         $set: false,
            //     },
            //     error: {
            //         $set: false,
            //     },
            //     items: {
            //         $set: oldState,
            //     }
            // },
        })
    },
};

export default itemsStorePrices;
