import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// import components
import ModalActionWrapper from '../ModalActionsWrapper'
import UserAuthorizationLayout
    from '../../../../components/UserAuthorizationLayout'
import userPrivileges from '../../../../constants/RoleList'
import Button from "../../../../components/Ui/Buttons";

// constant
import CR from '../../../../constants/RouteConstants'

/// //////////////////////////
//  START style
/// /////////////////////////

/// //////////////////////////
//  END style
/// /////////////////////////

/**
 *
 * @param {object} elem
 * @param {boolean} deposit
 * @param {object} match
 * @param {function} closeModalHandler
 * @param {object} translations
 * @returns {*}
 * @constructor
 */
const ModalActionsProductList = ({
    elem,
    deposit,
    match,
    closeModalHandler,
    translations
}) => {

    return (
        <ModalActionWrapper
            title={elem.label}
            closeModal={closeModalHandler}
        >
            {
                match.path === CR.kioskProduct &&
                <UserAuthorizationLayout
                    name={userPrivileges.view[10]}
                >
                    <Button
                        caps
                        bold
                        display={`${match.url.includes('product-detail')}`}
                        link={`${CR.productDetail.text}${elem.id}`}
                        text={translations['page__itemCatalogue__itemCard_actionModal_button_itemDetails']}
                    />

                </UserAuthorizationLayout>
            }

            <UserAuthorizationLayout
                name={userPrivileges.manage[3]}
            >
                <Button
                    caps
                    bold
                    disabled={!(elem.lockerStock > 0) || !elem.canGenerateSale}
                    link={!(elem.lockerStock > 0) ? '#' : `${match.url}${CR.modal.SaleCode}`}
                    text={translations['page__itemCatalogue__itemCard_actionModal_button_generateSaleCode']}
                />

            </UserAuthorizationLayout>

            <UserAuthorizationLayout
                name={userPrivileges.manage[3]}
            >
                <Button
                    caps
                    bold
                    disabled={!(elem.lockerStock > 0) || !elem.canGenerateExchange}
                    link={!(elem.lockerStock > 0) ? '#' : `${match.url}${CR.modal.depositSaleCode}`}
                    text={translations['page__itemCatalogue__itemCard_actionModal_button_generateExchangeCode']}
                />
            </UserAuthorizationLayout>

            <UserAuthorizationLayout
                name={userPrivileges.view[0]}
            >
                <Button
                    caps
                    bold
                    link={`${CR.salesHistories}-by-items/${elem.id}`}
                    text={translations['page__itemCatalogue__itemCard_actionModal_button_itemsSales']}
                />

            </UserAuthorizationLayout>

            <UserAuthorizationLayout
                name={userPrivileges.view[13]}
            >
                <Button
                    caps
                    bold
                    link={`${CR.mangeStockKiosk}/${elem.id}`}
                    text={translations['page__itemCatalogue__itemCard_actionModal_button_itemStock']}
                />

            </UserAuthorizationLayout>

        </ModalActionWrapper>
    )
};

ModalActionsProductList.propTypes = {
    elem: PropTypes.object,
    deposit: PropTypes.bool,
    match: PropTypes.object,
    closeModalHandler: PropTypes.func,
    translations: PropTypes.object,

};

const mapStateToProps = state => {
    return {
        itemsKiosk: state.itemsKiosk.items
    }
};

export default connect(mapStateToProps)(ModalActionsProductList)
