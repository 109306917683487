import ActionsTypes from '../../constants/ActionsTypes';

const adminUser = {
    getAdminUser: () => ({
        type: ActionsTypes.GET_ADMIN_USER,
        payload: {},
    }),
    getAdminUserSuccess: (adminUser) => ({
        type: ActionsTypes.GET_ADMIN_USER_SUCCESS,
        payload: {
            adminUser,
        },
    }),
    getAdminUserFailure: (error) => ({
        type: ActionsTypes.GET_ADMIN_USER_FAILURE,
        payload: {
            error,
        },
    }),

    deleteAdminUser: ( idUser, scope ) => ({
        type: ActionsTypes.DELETE_ADMIN_USER,
        payload: {
            idUser,
            scope,
        },
    }),
    deleteAdminUserSuccess: (  adminUser, idUser, scope ) => ({
        type: ActionsTypes.DELETE_ADMIN_USER_SUCCESS,
        payload: {
            adminUser,
            idUser,
            scope,
        },
    }),
    deleteAdminUserFailure: (error) => ({
        type: ActionsTypes.DELETE_ADMIN_USER_FAILURE,
        payload: {
            error,
        },
    }),
};

export default adminUser;