import { put, takeLatest } from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';
import {
    errorManagerActionCreators,
    globalLoadingActionCreators,
    loginActionCreators
} from '../actions';
import * as R from '../../constants/ApiConstants'
import { API } from '../../constants/ApiUrl'

const getLogin = function* ({payload}) {

    const userCredentials = {
        "username"    : payload.login,
        "password"    : payload.password,
        "idLanguage"  : payload.idLanguage
    };

    const apiCall = R.GET_BEARER_TOKEN();

    yield put(globalLoadingActionCreators.startLoading());

    try {
        const token = yield API.post( apiCall, userCredentials );

        const dataOld = yield API.get( '/current-user', { headers: { "Authorization": `Bearer ${ token.data.jwttoken }` } } );
        dataOld.data['jwttoken'] = token.data.jwttoken;

        yield put( loginActionCreators.getLoginSuccess( dataOld.data ) );

    } catch (e) {
        yield put(loginActionCreators.getLoginFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());
};

const getCurrentUser = function* ({payload}) {

    const token     = payload.token;
    const apiCall   = R.GET_CURRENT_USER();
    yield put(globalLoadingActionCreators.startLoading());

    try {

        const data = yield API.get( apiCall, { headers: { "Authorization": `Bearer ${ token }` } } );
        data.data['jwttoken'] = token;
        yield put( loginActionCreators.getLoginSuccess( data.data ) );

    } catch (e) {
        yield put(errorManagerActionCreators.criticalError(e.response));

        yield put(loginActionCreators.getLogout());

        yield put(loginActionCreators.getLoginFailure(e.response));

    }

    yield put(globalLoadingActionCreators.endLoading());
};

const postForgotPassword = function* ({payload}) {

    const userCredentials = {
        "userName"    : payload.login,
    };

    const apiCall = R.POST_FORGOT_PASSWORD();

    yield put(globalLoadingActionCreators.startLoading());

    try {
        const data = yield API.post( apiCall, userCredentials );
        yield put( loginActionCreators.postForgotPasswordSuccess( data.status ) );

    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(loginActionCreators.postForgotPasswordFailure(e.response));

    }

    yield put(globalLoadingActionCreators.endLoading());
};

const sagas = function* () {
    yield takeLatest(ActionsTypes.GET_LOGIN, getLogin);
    yield takeLatest(ActionsTypes.GET_CURRENT_USER, getCurrentUser);
    yield takeLatest(ActionsTypes.POST_FORGOT_PASSWORD, postForgotPassword);
};

export default sagas