import ActionsTypes from '../../constants/ActionsTypes';

const globalLoading = {
    startLoading: () => ({
        type: ActionsTypes.START_LOADING,
        payload: {},
    }),
    endLoading: () => ({
        type: ActionsTypes.END_LOADING,
        payload: {},
    }),
};

export default globalLoading;