import {
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';
import { brandsActionCreators, errorManagerActionCreators } from '../actions';
import API from '../../constants/ApiUrl'
import { selectIdsUser } from '../selectors/user'

import * as R from '../../constants/ApiConstants'

const getBrandsByFamilY = function* ({payload}) {
    
    const { idFamily } = payload;
    
    const user = yield select(selectIdsUser);

    try{
        const data = yield API(user.jwt).get(R.GET_BRANDS_BY_FAMILIES(user, idFamily));
        
        yield put(brandsActionCreators.getBrandsByFamilySuccess(data.data));
    } catch (e) {

        yield put(errorManagerActionCreators.createError(e.response));
        yield put(brandsActionCreators.getBrandsByFamilyFailure(e.response));
    }
};

const sagas = function* () {
    yield takeLatest(ActionsTypes.GET_BRANDS_BY_FAMILY, getBrandsByFamilY);
};

export default sagas