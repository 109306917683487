import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { map, cloneDeep } from 'lodash'

import MenuLinks from "./MenuLinks";
import { MenuList } from '../../../../constants/MenuList'
import styled from "styled-components";

////////////////////////
//  START styled
////////////////////////
const LinksWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    align-items: stretch;
    justify-content: stretch;
    row-gap: 2px;
`;
////////////////////////
//  END styled
////////////////////////

class MenuLinksContainer extends Component {
	state = {
		products: false,
		sales: false,
		stocks: false,
		admin: false,
	};

	menuExtendHandler = ( evt ) => {
		const newState = cloneDeep(this.state);

		map( newState, ( elem, id ) => {
			id === evt.currentTarget.id ?
				newState[id] = !newState[id] :
				newState[id] = false;
			return newState[id];
		} );

		this.setState( {
			...newState
		} )
	};

	render() {
		const { closeMenuHandler, translations } = this.props;
		return (
			<LinksWrapper>
				<MenuLinks
					closeMenuHandler={ closeMenuHandler }
					translations={ translations }
					openMenuState={ this.state }
					handleOpenNested={ this.menuExtendHandler }
					menuList={MenuList}
				/>
			</LinksWrapper>
		)
	}
}

MenuLinksContainer.propTypes = {
	closeMenuHandler: PropTypes.func,
	translations: PropTypes.object
};

export default MenuLinksContainer
