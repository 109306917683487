import React, { Component, } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { map, forEach, isEmpty, find, isNull } from 'lodash'

// import components
import Modal from '../../../components/Ui/Modals/Modal'
import CR from '../../../constants/RouteConstants'

// redux import
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	salesOrdersActionCreators,
	itemsKioskActionCreators
} from '../../../state/actions';

// helpers
import StringFormater from '../../../helpers/StringFormater';
import imageImport from "../../../helpers/imagesImport";
import Button from "../../../components/Ui/Buttons";
import theme from "../../../theme/theme";

/////////////////////////////
//  START style
////////////////////////////
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 643px;
    padding: 35px 0;
`;
const ActionTittle = styled.div`
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${ props => props.theme.colors.menu.wrapper };
    width: 350px;
`;
const CheckBoxWraper = styled.div`
    display: flex;
    flex-direction: column;
`;
const TextTopCheckbox = styled.p`
    justify-self: flex-start;
    margin: 0;
    color: ${ props => props.theme.colors.primary };
    margin: 22px 0 15px 64px;
`;
const ColorElement = styled.span`
    color: ${ props => props.colors };
`;
const AccessoriesWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat( auto-fit, 146px);
    grid-column-gap: 38px;
    grid-row-gap: 18px;
    justify-content: center;
    align-items: center;
    width: 640px;
`;
const AccessoryImageWrapper = styled.label`
    position: relative;
    display: flex;
    max-width: 146px;
    max-height: 146px;
    cursor: pointer;
`;
const AccessoryImage = styled.img`
    display: flex;
    max-width: 146px;
    max-height: 146px;
    border: ${ props => props.selected ? `2px solid ${ props.theme.colors.primary }` : 'none' };
    box-sizing: border-box;
`;
const CheckboxElment = styled.input`
    position: absolute;
    top: 5px;
    left: 5px;
`;
const AccessoriesTextWrapper = styled.div`
    display: flex;
    display: table-column;
    justify-content: center;
    align-items: center;
    margin-top: 18px;
`;
const AccessoriesText = styled.p`
    margin: 0;
    text-align: center;
    color: ${ props => props.colored ? props.colored : props.theme.colors.black };
`;
const SelectedDateWrapper = styled.div`
    margin-top: 38px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const SelectedDateButton = styled.input`
    margin-right: 12px;
`;
const SelectedDateLabel = styled.label`
    margin-right: ${ props => props.margin ? '22px' : '0' };
`;
const SelectedDateText = styled.p`
    margin: 0 33px 0 0;
    color: ${ props => props.theme.colors.primary };
    padding-left: 72px;
`;
const TotalPriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const TotalPriceText = styled.p`
    font-weight: bold;
    text-decoration: underline;
    text-align: center;
    margin: 48px 0 8px 0;
`;
const TotalPriceNumber = styled.div`
    width: 515px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: ${ props => props.theme.font.fxxl };
    color: ${ props => props.theme.colors.validation };
    font-weight: bold;
    border: 1px solid ${ props => props.theme.colors.validation };
    padding: 8px 0;
`;
const LabelTTC = styled.div`
    font-size: ${ props => props.theme.font.fm };
`;
const ButtonWrapper = styled.div`
    width: 603px;
    height: 40px;
    margin-top: 52px;
    padding-right: 40px;
    display: flex;
    justify-content: flex-end;
`;
/////////////////////////////
//  END style
////////////////////////////

export class ModalSaleCode extends Component {
	static propTypes = {
		elem: PropTypes.object,
		itemKiosk: PropTypes.object,
		type: PropTypes.bool,
		closeModalHandler: PropTypes.func,
		actions: PropTypes.object,
		idUser: PropTypes.number,
	};

	state = {
		checkbox: [],
		day: '1',
	};

	componentDidMount() {
		const { actionsItemsKiosk, itemKiosk } = this.props
		actionsItemsKiosk.postItemKioskCheckOrderValidation( itemKiosk.id, itemKiosk.idCurrentClassicPromotionLineInProgres )
	}

	componentDidUpdate( prevProps, prevState, snapshot ) {
		const { modalError, itemKiosk } = this.props
		if ( itemKiosk !== prevProps.itemKiosk ) {
			this.updateStateCheckbox()
		}
		if (
			(modalError !== prevProps.modalError) &&
			(prevProps.modalError.show ) &&
			( !modalError.modalError )
		) {
			window.location.reload()
		}
	}

////////////////////////////////////////////////////////////////////////:
	/**
	 * initialize state with accessories item pass in props
	 */
	updateStateCheckbox = () => {
		const { itemKiosk } = this.props;
		const data = map( itemKiosk.accessories, ( itemKiosk ) => {	
			return { ...itemKiosk, checked: false }
		} );
		this.setState( { checkbox: { ...data } } )
	};// end function
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
	/**
	 * modify state to 1 checkbox can be checked
	 */
	checkboxChangeHandler = ( evt ) => {
		const targetID = parseInt( evt.target.id );
		const oldState = this.state.checkbox;
		// pass checked true on target off click and false other
		forEach( oldState, ( item, ) => {
			if ( item.id === targetID ) {
				item.checked = !item.checked
			} else {
				item.checked = false
			}
		} );
		this.setState( { checkbox: oldState } )
	};// end function
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
	/**
	 * modify state to selected radio button
	 */
	radioChangeHandler = ( evt ) => {
		const targetValue = evt.target.value; // target value: String

		this.setState( { day: targetValue } )
	};// end function
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
	/**
	 * calculate total price. price cylinder + accessories if selected + desposit price if deposit
	 */
	getTotalPrice = () => {
		const { checkbox } = this.state;
		const { itemKiosk, type } = this.props;
		let totalPrice = null;
		let depositPrice = null;
		let accessoryPrice = null;
		let cylinderPrice = null;
		// get accessories selected
		const accessory = find( checkbox, { 'checked': true } );

		// add accessories selected price
		if ( !isEmpty( accessory ) ) {
			if ( !isNull( accessory.promoPrice ) ) {
				accessoryPrice = accessory.promoPrice
			} else {
				accessoryPrice = accessory.standardPrice
			}
		}
		// add cylinder price
		if ( !isEmpty( itemKiosk ) ) {
			if ( !isNull( itemKiosk.promoPrice ) ) {
				cylinderPrice = itemKiosk.promoPrice
			} else {
				cylinderPrice = itemKiosk.standardPrice
			}
		}
		// if is not deposit add depositPrice
		if ( !type ) {
			depositPrice = !isNull( itemKiosk.promoDepositPrice ) ? itemKiosk.promoDepositPrice : itemKiosk.depositPrice
		}

		totalPrice = !isNull( cylinderPrice ) ? cylinderPrice.toFixed( 2 ) + itemKiosk.currency.symbol : '';

		totalPrice += !isNull( depositPrice ) ? ' + ' + depositPrice.toFixed( 2 ) + itemKiosk.currency.symbol : '';

		totalPrice += !isNull( accessoryPrice ) ? ' + ' + accessoryPrice.toFixed( 2 ) + itemKiosk.currency.symbol : '';

		let countTotal = depositPrice + accessoryPrice + cylinderPrice;
		totalPrice += ' = ' + countTotal.toFixed( 2 ) + itemKiosk.currency.symbol;


		return totalPrice ? totalPrice : null
	};// end function
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
	/**
	 * get the length of item accessories to create the grid to display 3 max
	 * @returns [jsx] grid off item accessories with label text
	 */
	checkboxLayout = () => {
		const { checkbox } = this.state;
		const { translations } = this.props;
		// checkbox JSX
		const checkboxElement = map( checkbox, ( item, id ) => {
			return (
				<CheckBoxWraper
					key={ id }
				>
					<AccessoryImageWrapper
						id={ item.id }
					>
						<AccessoryImage
							selected={ item.checked }
							src={ imageImport( item.picture ) }
						/>
						<CheckboxElment
							type='checkbox'
							id={ item.id }
							checked={ item.checked }
							onChange={ this.checkboxChangeHandler }
						/>
					</AccessoryImageWrapper>
					<AccessoriesTextWrapper
						key={ id }
					>
						<AccessoriesText>
							{ item.label }
						</AccessoriesText>
						<AccessoriesText colored={ theme.colors.validation }>
							{
								item.promoPrice ?
									`${ item.promoPrice.toFixed( 2 ) }${ item.currency.symbol }`
									:
									`${ item.standardPrice.toFixed( 2 ) }${ item.currency.symbol }`
							}
						</AccessoriesText>
					</AccessoriesTextWrapper>
				</CheckBoxWraper>
			)
		} );
		return (
			<AccessoriesWrapper>
				{
					checkboxElement.length > 0 ?
						checkboxElement
						:
						<CheckBoxWraper>
							<AccessoriesTextWrapper>
								<AccessoriesText colored='red'>
									{/* No accessories available */ }
									{ translations['modal__generateCode__noAccessoryAvailable'] }
								</AccessoriesText>
							</AccessoriesTextWrapper>
						</CheckBoxWraper>

				}
			</AccessoriesWrapper>
		)
	};// end function
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
	/**
	 * send a sales orders
	 */
	createSalesOrders = () => {
		const { checkbox, day } = this.state;
		const { idUser, actions, type, itemKiosk } = this.props;
		const accessory = find( checkbox, { 'checked': true } );
		let idItems = null;
		if ( accessory ) {
			idItems = [
				{
					"id": itemKiosk.id,
					"idCurrentClassicPromotionLineInProgres": itemKiosk.idCurrentClassicPromotionLineInProgres
				},
				{
					id: accessory.id,
					"idCurrentClassicPromotionLineInProgres": accessory.idCurrentClassicPromotionLineInProgres
				}
			];
		} else {
			idItems = [ {
				"id": itemKiosk.id,
				"idCurrentClassicPromotionLineInProgres": itemKiosk.idCurrentClassicPromotionLineInProgres
			}, ]
		}

		const salesOrders = {                   // salesOrders object
			"id_user_connected": idUser,
			"durationDays": day,
			"withDeposit": !type,
			"clientFirstname": "",
			"clientLastname": "",
			"clientEmail": "",
			"itemsKiosk": idItems,
			'type': type,

		};
		actions.updateSalesOrders( salesOrders )
	};//end function
////////////////////////////////////////////////////////////////////////

	render() {
		const { itemKiosk, type, closeModalHandler, match, translations } = this.props;
		if ( isEmpty( itemKiosk ) ) {
			return <div></div>
		}
		const deposit = type ? translations['common__text__exchange'] : translations['common__text__sales'];
		return (
			<Modal
				headerTittle={ translations['modal__generateCode__title'] }
				closeModal={ closeModalHandler }
			>
				<ActionWrapper>
					<ActionTittle>
						{ StringFormater.ResponseMessageParser( translations['modal__generateCode__description'], [ deposit ] ) }
						<ColorElement colors={ theme.colors.primary }>
							{ itemKiosk.itemBrand.label }
						</ColorElement>
						{ ` - ${ itemKiosk.label } ]` }
					</ActionTittle>
					<TextTopCheckbox>
						{ translations['modal__generateCode__selectAccessory']+" :" }
					</TextTopCheckbox>
					{ this.checkboxLayout() }
					<SelectedDateWrapper>
						<SelectedDateText>
							{ translations['modal__generateCode__expirationTime']+" :" }
						</SelectedDateText>
						<SelectedDateButton
							id='1'
							value='1'
							type='radio'
							onChange={ this.radioChangeHandler }
							checked={ this.state.day === '1' }
						/>

						<SelectedDateLabel margin>
							{ translations['common__text__1day'] }
						</SelectedDateLabel>
						<SelectedDateButton
							id='2'
							value='2'
							type='radio'
							onChange={ this.radioChangeHandler }
							checked={ this.state.day === '2' }
						/>

						<SelectedDateLabel margin>
							{ translations['common__text__2days'] }
						</SelectedDateLabel>
						<SelectedDateButton
							id='3'
							value='3'
							type='radio'
							onChange={ this.radioChangeHandler }
							checked={ this.state.day === '3' }
						/>

						<SelectedDateLabel margin>
							{ translations['common__text__3days'] }
						</SelectedDateLabel>
					</SelectedDateWrapper>
					<TotalPriceWrapper>
						<TotalPriceText>
							{ translations['modal__generateCode__totalAmount']+" :"}
						</TotalPriceText>
						<TotalPriceNumber>
							{ this.getTotalPrice() }
							<LabelTTC>
								{ translations['common__text__VAT_Included'] }
							</LabelTTC>
						</TotalPriceNumber>

					</TotalPriceWrapper>
					<ButtonWrapper>
						<Button
							bgColor={ theme.colors.error }
							margin='21px'
							actionHandler={ closeModalHandler }
							text={ translations['modal__generateCode__button_cancel'] }
						/>
						<Button
							bgColor={ theme.colors.validation }
							actionHandler={ this.createSalesOrders }
							link={ `${ match.url }${ CR.modal.userConsignementInfo }` }
							text={ translations['modal__generateCode__button_validAndGenerate'] }
						/>
					</ButtonWrapper>
				</ActionWrapper>
			</Modal>
		)
	}
}

const mapStateToProps = state => {
	return {
		idUser: state.user.identity.id,
		itemKiosk: state.itemsKiosk.selectedItem,
		modalError: state.modal
	};
};
const mapDispatchToProps = ( dispatch ) => {
	return {
		actions: bindActionCreators( salesOrdersActionCreators, dispatch ),
		actionsItemsKiosk: bindActionCreators( itemsKioskActionCreators, dispatch )
	}
};

export default connect( mapStateToProps, mapDispatchToProps )( ModalSaleCode )
