import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { orderBy, find, cloneDeep, map } from 'lodash'

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { itemsStorePricesActionCreators } from '../../state/actions';


/////////////////////////////
//  START style
////////////////////////////

/////////////////////////////
//  END style
////////////////////////////


export default function(ComposedComponent) {
    class TableCheckboxEditable extends Component {
        static propTypes = {
            selectedElement: PropTypes.object,       // element selected on clic action button
            type: PropTypes.bool,                    // with deposit or not
        };
        state = {
            editAll: false,
            editing: false,
            editOne: false,
            headerData: [
    
            ],
            bodyData: [
    
            ],
            sort: {
                column: "",
                sortType: "",
            },
        };
        refCollectionCheckbox = [];


        hydrateStateHandler = (data) => {
            this.setState({
                headerData: data.header,
                bodyData: data.body,
            })
        };

        initSortTableHandle = (column) => {
            const { sort, bodyData } = this.state;
            const oldBodyData   = cloneDeep(bodyData);
            const oldSort       = cloneDeep(sort);

            this.setState({ flag : false});

            if( oldSort.column !== column) {
                oldSort.column      = column;
                oldSort.sortType    = ''
            }

            if ( oldSort.sortType === 'asc' ) {
                oldSort.sortType = 'desc'
            } else {
                oldSort.sortType = 'asc'
            }

            let allElem = map(oldBodyData, (elem,) => {
                let item = find(elem.elements, {name: oldSort.column});
                return {
                    id : elem.id,
                    elem: item.isNumber ? parseFloat(item.sortValue) : item.sortValue,
                }
            });
            allElem = orderBy(allElem, ['elem'], [oldSort.sortType]);
            allElem = map(allElem, elem => {
                return find(oldBodyData, { id : elem.id})
            });

            this.setState({
                bodyData: allElem,
                sort: oldSort,
            })
        };


        checkboxChangeHandler = (e) => {
            const id        = e.target.id;
            const oldState  = {...this.state};

            if(!oldState.editing && !oldState.editOne){
                if( id === 'ALL' ) {
                    oldState.headerData
                    .forEach( (elem) => {
                        if( elem.useName === 'checkBox' ) {
                            elem.isChecked = !elem.isChecked
                        }
                    });
                    oldState.bodyData
                        .forEach( (elem) => {
                            if( id === 'ALL' ) {
                                elem.elements.forEach( (item) => {
                                    if( item.name === 'checkBox' ) {
                                        item.isChecked = oldState.headerData[0].isChecked
                                    }
                                })
                            }
                        })
    
                }else {
                    if( !this.state.editAll ) {
                        oldState.bodyData
                            .forEach( (elem) => {
                                if( elem.id === parseInt(id) ) {
                                    elem.elements.forEach( (item) => {
                                        if( item.name === 'checkBox' ) {
                                            item.isChecked = !item.isChecked
                                        }
                                    })
                                }
                            })
                    }
                }
            }
            this.setState({...oldState})
        };

        editedOnCLickHandler = (evt) => {
            const oldState          = {...this.state};
            oldState.editOne        = !oldState.editOne;
            const id                = parseInt(evt.target.id);
            this.refCollectionCheckbox[id].actions.children[0].style.display    = 'none';
            this.refCollectionCheckbox[id].actions.children[1].style.display    = 'flex';

            this.setState({...oldState}, () => this.displayInputHandler(id))
        };

        displayInputHandler = (id) => {
            const oldState  = {...this.state};

            oldState.bodyData
                .forEach( (elem) => {
                    if( elem.id === id ) {
                        elem.elements.forEach( (item) => {
                            if (item.editable){
                                item.active = !item.active
                            }
                        })
                    }
                    if( elem.id !== id ) {
                        elem.elements
                            .forEach( (item) => {
                                if (item.name === 'actions') {
                                    item.display = item.display === 'flex' ? 'none' : 'flex'
                                }
                            })
                    }
                });
            this.setState({...oldState})
        };

        displayInputAllHandler = () => {
            const oldState      = {...this.state};
            oldState.editAll    = !oldState.editAll;
            
            oldState.bodyData
                .forEach( (elem) => {                    
                    if( elem.elements[0].isChecked ) {
                        elem.elements.forEach( (item) => {
                            if (item.editable){
                                item.active = !item.active
                            }                        
                        })
                    }
                    elem.elements.forEach( (item) => {
                        if (item.name === 'actions') {
                            item.display = item.display === 'flex' ? 'none' : 'flex'
                        }
                    })
                });
            this.setState({...oldState})
        };

        validationActionHandler = (newDataTable, id) => {
            this.refCollectionCheckbox[id].actions.children[1].children[1].style.display = 'none';
            this.refCollectionCheckbox[id].actions.children[1].children[0].style.display = 'flex';
            this.refCollectionCheckbox[id].actions.children[0].style.display = 'flex';
            this.refCollectionCheckbox[id].actions.children[1].style.display = 'none';
            this.setState({...newDataTable}, () => this.displayInputHandler(id))
        };

        cancelActionHandler = (data, id) => {
            this.refCollectionCheckbox[id].actions.children[1].children[1].style.display = 'none';
            this.refCollectionCheckbox[id].actions.children[1].children[0].style.display = 'flex';
            this.refCollectionCheckbox[id].actions.children[0].style.display = 'flex';
            this.refCollectionCheckbox[id].actions.children[1].style.display = 'none';
            this.displayInputHandler(id);
            this.setState({...data})
        };
        
        editInputHandler = (evt) => {
            const id            = parseInt(evt.target.id);
            const name          = evt.target.name;
            const inputValue    = evt.target.value;
            const oldState      = {...this.state};
            if( !inputValue.length ) {
                this.refCollectionCheckbox[id].actions.children[1].children[1].style.display = 'flex';
                this.refCollectionCheckbox[id].actions.children[1].children[0].style.display = 'none'
            }else{
                this.refCollectionCheckbox[id].actions.children[1].children[1].style.display = 'none';
                this.refCollectionCheckbox[id].actions.children[1].children[0].style.display = 'flex'
            }

            oldState.bodyData
                .forEach( (elem) => {
                    if( elem.id === id ) {
                        elem.elements.forEach( (item) => {
                            if (item.name === name){
                                item.value = inputValue
                            }
                        }) 
                    }
                });

            this.setState({...oldState})
        };
        
        editAllValidationHandler = (newState) => {
            this.setState({...newState}, () => this.displayInputAllHandler())
        };

        editAllCancelHandler = (data) => {
            this.refCollectionCheckbox
                .forEach( (elem) => {
                    elem.actions.children[1].children[0].style.display = 'flex';
                    elem.actions.children[1].children[1].style.display = 'none'
                });
            
            this.setState({...data}, () => this.displayInputAllHandler())
        };

        editAllHandler = () => {
            const oldState      = {...this.state};
            oldState.editing    = true;
            
            this.setState({...oldState}, () => this.displayInputAllHandler())
        };

        render() {
            return (
                <Fragment>

                    <ComposedComponent
                        hydrateStateHandler=        {this.hydrateStateHandler}
                        refCollection=              {this.refCollectionCheckbox}
                        editedOnCLickHandler=       {this.editedOnCLickHandler}
                        validationActionHandler=    {this.validationActionHandler}
                        editInputHandler=           {this.editInputHandler}
                        cancelActionHandler=        {this.cancelActionHandler}
                        dataTable=                  {this.state}
                        editAllHandler=             {this.editAllHandler}
                        editAllValidationHandler=   {this.editAllValidationHandler}
                        editAllCancelHandler=       {this.editAllCancelHandler}
                        checkboxChangeHandler=      {this.checkboxChangeHandler}
                        initSortTableHandle=        {this.initSortTableHandle}
                        { ...this.props } 
                    />
                </Fragment>
            )
        }
    }

    const mapDispatchToProps = (dispatch) => {
        return {
            actionsItemsStorePrices: bindActionCreators(itemsStorePricesActionCreators, dispatch),
        };
    };

    return connect( null, mapDispatchToProps )(TableCheckboxEditable)
}

