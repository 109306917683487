import { createSelector } from 'reselect'
import { find, isEmpty } from 'lodash'

const getLanguages     = state => state.languages;
const getTranslations  = state => state.languages.translations;


export const selectLanguagesDefault = createSelector(
    [ getLanguages ],
    ( languages ) => {
        if (!isEmpty(languages.items)) {
            return find(languages.items, { defaultLanguage: true })
        }
        return {}
    }
);
export const selectTranslations = createSelector(
    [ getTranslations ],
    ( translations ) => ( translations || [] )
);
