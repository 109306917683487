import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from "moment";
import { map, isNull } from 'lodash'

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import {
    exportDataActionCreators,
    logsActionCreators,
} from '../../state/actions';

//assets import
import Eye from '../../assets/icones/global/eye.png'
import Calendar from "../../assets/icones/global/calendarX2.png";

// selector
import { selectIdsUser } from "../../state/selectors/user";
import { selectLogHistoryFiltered } from "../../state/selectors/logsHistory";

// helper
import StringFormater from "../../helpers/StringFormater";

// constant
import userPrivileges from "../../constants/RoleList";
import CR from "../../constants/RouteConstants";

// component import
import TableLayout from '../TableLayout'
import TableNested from '../../Hoc/TableNested'
import ModalDatePicker from "../../Hoc/ModalDatePicker";
import Button from "../../components/Ui/Buttons";
import ModalSummerize from "../Modals/ModalSummerize";
import ModalBody from "../../components/Ui/Modals/ModalBody";
import TagImgAndText from "../../components/Ui/TagImgAndText";
import DropdownCheckbox
    from "../../components/Ui/DropdownCheckbox/DropdownCheckbox";
import ExportData from "../../components/ExportData/ExportData";
import UserAuthorizationLayout
    from "../../components/UserAuthorizationLayout/UserAuthorizationLayout";

// style
import {
    PageWrapper,
    LineTableHeader,
    TitleHeader,
    ActionWrapper,
    TitleHeaderTable,
    ContentTableWrapper,
    CalendarButton,
    CalendarImage,
    CalendarText,
    ImgSized,

} from "../../theme/GlobalCss";
import theme from "../../theme/theme";



/////////////////////////////
//  START style
////////////////////////////
const ButtonWrapper = styled.div`
    display: flex;
    height: 30px;
`;
const TextModal = styled.div`
    margin: 0;
    padding: 15px 0;
    text-align: center;
`;
const CategoriesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  justify-items: flex-start;
`;
const CategoriesEmbed = styled.span`
  font-weight: 900;
`;
const UsersLogsList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: start;
`;
const UserLogText = styled.p`
    justify-items: flex-start;
    margin: 5px 0;
`;

/////////////////////////////
//  END style
////////////////////////////

export class LogsHistory extends Component {
    static propTypes = {
        actionsLogsHistory:                 PropTypes.object,
        actionsDatePicker:                  PropTypes.object,
        closeDateModalHandler:              PropTypes.func,
        logsHistory:                        PropTypes.object,
        dataTable:                          PropTypes.object,
        datePicker:                         PropTypes.object,
        formatDate:                         PropTypes.func,
        getDateFormat:                      PropTypes.func,
        hydrateStateHandler:                PropTypes.func,
        modalHandler:                       PropTypes.func,
        nestedOnClickHandler:               PropTypes.func,
        openDateModalHandler:               PropTypes.func,
        refCollection:                      PropTypes.array,
        userKiosk:                          PropTypes.object,
        logHistoryItemsFiltered:            PropTypes.array,
        actionsExportData:                  PropTypes.object,
    };

    state = {
        showLogAlertDetail: false,
        logAlertSelectedElement: {},
        scope: '',
        exportData: {
            idUser: '',
            scope: '',
        }
    };

///////////////////////////////////////////////////
///////     START INIT COMPONENT    ///////////////
///////////////////////////////////////////////////
    componentDidMount = () => {
        this.FetchLogsHistory()
    };
///////////////////////////////////////////////////
///////     END INIT COMPONENT    /////////////////
///////////////////////////////////////////////////

    FetchLogsHistory = () => {
        const {
            getDateFormat,
            actionsLogsHistory,
            match,
        } = this.props;

        const dateFormat    = getDateFormat();
        const witchUrl      = match.path;
        const idUser        = match.params.id || false;
        let scope;
        let exportData;

        if( witchUrl === CR.logHistory ){
            scope = 'kiosks';
            exportData = {
                idUser: '',
                scope: 'kiosksLogs',
            }
        }else if( witchUrl === CR.logHistoryUser.route ){
            scope = 'user';
            exportData = {
                idUser: idUser,
                scope: 'userLogs',
            }
        }else if( witchUrl === CR.logHistoryUsers ){
            scope = "users";
            exportData = {
                idUser: '',
                scope: 'usersLogs',
            }
        }
        this.setState({
            scope : scope,
            exportData : exportData,
        });
        actionsLogsHistory.getLogsHistory(dateFormat, scope, idUser);
    };

    /**
     * data object to row layout
     */
    bodyData = () => {
        const {
            translations,
            logHistoryItemsFiltered,
        } = this.props;

        const header = [
            {name: translations['page__logHistory__logTable_tableHeader_date'], useName: 'date', sortTable: true, },
            {name: translations['page__logHistory__logTable_tableHeader_status'], useName: 'status', sortTable: true, },
            {name: translations['page__logHistory__logTable_tableHeader_user'], useName: 'user', sortTable: true, },
            {name: translations['page__logHistory__logTable_tableHeader_description'], useName: 'event', sortTable: true, },
            {name: translations['page__logHistory__logTable_tableHeader_level'], useName: 'level', sortTable: true, },
            {name: translations['common__text__actions'], useName: 'actions'}
        ];
        return {
            header: header,
            body : map(logHistoryItemsFiltered, (elem,) => {
            let dateCreated = [
                moment(elem.dateCreated).format('DD/MM/YYYY'),
                moment(elem.dateCreated).format('HH:mm:ss'),
            ];
                return ({
                    id: elem.id,
                    elements: [
                        {name :'date', value: dateCreated, sortValue: dateCreated, },
                        {
                            name :'status',
                            value: translations[elem.logStatus.translationKey],
                            bcg: elem.logStatus.colorCode,
                            color: theme.colors.white,
                            sortValue: translations[elem.logStatus.translationKey],
                        },
                        {name :'user', value: `${elem.createdBy.firstName} ${elem.createdBy.lastName}`, sortValue: `${elem.createdBy.firstName} ${elem.createdBy.lastName}`,},
                        {name :'event', value: translations[elem.logEvent.translationKey], sortValue: translations[elem.logEvent.translationKey],},
                        {
                            name :'level',
                            value:
                                <TagImgAndText
                                    text={translations[elem.logLevel.translationKey]}
                                    idLevel={elem.logLevel.id}
                                    width='15'
                                />,
                            color: elem.logLevel.colorCode,
                            sortValue: elem.logLevel.id,
                        },
                        {name: 'actions', display: 'flex',
                            value:
                            <ImgSized
                                width={25}
                                src={Eye}
                                onClick={ () => this.openDetailHandler(elem)}
                                cursor={'true'}
                            />
                        },
                    ]
                })
            })
        };
    };

    componentDidUpdate(prevProps) {
        const { logHistoryItemsFiltered, hydrateStateHandler, translations, datePicker, match } = this.props;
        // hydrate parent STATE if Table data change
        if ( logHistoryItemsFiltered !== prevProps.logHistoryItemsFiltered ) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            hydrateStateHandler(data)
        }
        // if languages change update table
        if( translations !== prevProps.translations) {
            const createTable = this.bodyData();
            const data = {
                header: createTable.header,
                body: createTable.body,
            };
            hydrateStateHandler(data)
        }
        // if user change kiosk selected fetch new data
        if ( this.props.userKiosk !== prevProps.userKiosk ) {
            this.FetchLogsHistory()
        }
        // if user change url fetch new data
        if ( match.url !== prevProps.match.url ) {
            this.FetchLogsHistory()
        }
        // fetch data if date change
        if ( datePicker !== prevProps.datePicker ) {
            this.FetchLogsHistory()
        }

    }


    openDetailHandler = (elem) => {
        this.setState({
            'showLogAlertDetail' : true,
            'logAlertSelectedElement': {...elem}
        })
    };
    closeDetailHandler = () => {
        this.setState({'showLogAlertDetail' : false})
    };
    callAndCloseDetailHandler = ( method ) => {
        const { logAlertSelectedElement } = this.state;
        const { actionsLogsHistory } = this.props;

        method && actionsLogsHistory[method]( logAlertSelectedElement.id );

        this.closeDetailHandler()
    };

    logFilterHandler = (id, isChecked) => {
        const { actionsLogsHistory } = this.props;

        actionsLogsHistory.updateLogsHistoryFilter(id, isChecked)
    };

    logFilterLevelHandler = (id, isChecked) => {
        const { actionsLogsHistory } = this.props;
        actionsLogsHistory.updateLogsHistoryFilterLevel(id, isChecked)
    };

    render() {
        const {
            translations,
            logsHistory,
            dataTable,
            refCollection,
            openDateModalHandler,
            formatDate,
            datePicker,
            initSortTableHandle,
            getDateFormat,
        } = this.props;

        const { logAlertSelectedElement } = this.state;

        return (
            <PageWrapper>
                {/* MODAL LOGS ON DETAIL START */}
                {
                    this.state.showLogAlertDetail &&

                    <ModalSummerize
                        showModal={ this.state.showLogAlertDetail }
                        closeModalHandler={ this.closeDetailHandler }
                        title={translations['modal__logDetail__title']}
                        translations={translations}
                    >
                        <ModalBody>
                            <TagImgAndText
                                idLevel={logAlertSelectedElement.logLevel.id}
                                text={translations[logAlertSelectedElement.logLevel.translationKey]}
                                width='30px'
                                bold
                            />
                            <TextModal>
                                <p>
                                    <CategoriesEmbed>
                                        {`[ ${moment( logAlertSelectedElement.dateCreated ).locale( 'fr' ).format( 'DD/MM/YY - HH:mm:ss' )} - ${logAlertSelectedElement.logEvent.label}]` }
                                    </CategoriesEmbed>
                                </p>
                                <UsersLogsList>
                                    {
                                        !isNull(logAlertSelectedElement.createdBy) &&
                                            <UserLogText>
                                                <CategoriesEmbed>
                                                    { translations['modal__logDetail__body_createdBy']+" :"}
                                                </CategoriesEmbed>
                                                {` ${logAlertSelectedElement.createdBy.firstName} ${logAlertSelectedElement.createdBy.lastName}`}
                                            </UserLogText>
                                    }
                                    {
                                        !isNull(logAlertSelectedElement.raisedBy) &&
                                        <UserLogText>
                                            <CategoriesEmbed>
                                                { translations['modal__logDetail__body_raisedBy']+" :"}
                                            </CategoriesEmbed>
                                            {` ${logAlertSelectedElement.raisedBy.firstName} ${logAlertSelectedElement.raisedBy.lastName}`}
                                        </UserLogText>
                                    }
                                    {
                                        !isNull(logAlertSelectedElement.fixedBy) &&
                                        <UserLogText>
                                            <CategoriesEmbed>
                                                { translations['modal__logDetail__body_fixedBy']+" :"}
                                            </CategoriesEmbed>
                                            {` ${logAlertSelectedElement.fixedBy.firstName} ${logAlertSelectedElement.fixedBy.lastName}`}
                                        </UserLogText>
                                    }
                                </UsersLogsList>
                                <CategoriesWrapper>
                                    <p>
                                        <CategoriesEmbed>{translations['modal__logDetail__body_category']+" :"}</CategoriesEmbed>
                                        {translations[logAlertSelectedElement.logCategorie.translationKey]}
                                    </p>
                                    <p>
                                        <CategoriesEmbed>{translations['modal__logDetail__body_subCategory']+" :"}</CategoriesEmbed>
                                        {translations[logAlertSelectedElement.logSubCategorie.translationKey]}
                                    </p>
                                </CategoriesWrapper>
                                <p>{`${logAlertSelectedElement.message}`}</p>
                            </TextModal>
                            <ButtonWrapper>

                                <Button
                                    actionHandler={this.closeDetailHandler}
                                    text={translations['common__text__cancel']}
                                    bgColor={ theme.colors.error }
                                    margin='true'
                                />

                                {
                                    (
                                        logAlertSelectedElement.logLevel.id === 6
                                    ) &&
                                    (
                                        logAlertSelectedElement.logStatus.subFlow > '001' &&
                                        logAlertSelectedElement.logStatus.subFlow < '030'
                                    ) &&

                                    <Button
                                        actionHandler={ () => this.callAndCloseDetailHandler('putResolvedLogAlert')}
                                        text={
                                            // translations['modal__logDetail__button_issueSolved']
                                            'Clore la notification'
                                        }
                                        margin='true'
                                        bgColor={ theme.colors.validation }
                                    />
                                }

                                {
                                    (
                                        logAlertSelectedElement.logLevel.id === 2 ||
                                        logAlertSelectedElement.logLevel.id === 3
                                    ) &&
                                    (
                                        logAlertSelectedElement.logStatus.subFlow > '001' &&
                                        logAlertSelectedElement.logStatus.subFlow < '030'
                                    ) &&

                                        <Button
                                            actionHandler={ () => this.callAndCloseDetailHandler('putResolvedLogAlert')}
                                            text={translations['modal__logDetail__button_issueSolved']}
                                            margin='true'
                                            bgColor={ theme.colors.validation }
                                        />
                                }

                                {
                                    (
                                        logAlertSelectedElement.logLevel.id === 2 ||
                                        logAlertSelectedElement.logLevel.id === 3
                                    ) &&
                                    (
                                        logAlertSelectedElement.logStatus.subFlow === '010' ||
                                        logAlertSelectedElement.logStatus.subFlow === '110'
                                    ) &&

                                        <Button
                                            actionHandler={ () => this.callAndCloseDetailHandler('putRemotedLogAlert')}
                                            text={translations['modal__logDetail__button_raiseIssue']}
                                            bgColor={ theme.colors.warning }
                                        />
                                }

                            </ButtonWrapper>
                        </ModalBody>
                    </ModalSummerize>
                }
                {/* MODAL END */}

                <LineTableHeader>
                    <TitleHeader>

                        {
                            this.state.scope === "kiosks" &&
                                translations['page__logHistory__logTable_title']
                        }
                        {
                            this.state.scope === "user" &&
                            translations['page__userLog__title']
                        }
                        {
                            this.state.scope === "users" &&
                            translations['page__usersLog__title']
                        }
                    </TitleHeader>
                    <ActionWrapper>
                        <UserAuthorizationLayout
                            name={userPrivileges.export[6]}
                        >
                            <ExportData
                                text={ translations['common__text__exportData'] }
                                scope={ this.state.exportData.scope }
                                getDateFormat={ getDateFormat }
                                idInput={this.state.exportData.idUser}
                            />
                        </UserAuthorizationLayout>
                    </ActionWrapper>
                </LineTableHeader>
                <ContentTableWrapper>
                    <LineTableHeader>
                        <TitleHeaderTable>
                            <CalendarButton
                                onClick={openDateModalHandler}
                            >
                                <CalendarImage src={Calendar} />
                            </CalendarButton>
                            <CalendarText>
                                {StringFormater.ResponseMessageParser(translations['common__text__datePeriod_noHours'], [formatDate(datePicker.startDate),formatDate(datePicker.endDate)])}
                            </CalendarText>
                        </TitleHeaderTable>
                        <ActionWrapper>
                            <DropdownCheckbox
                                data={logsHistory.filter}
                                selectActionsHandler={this.logFilterHandler}
                                title={translations['page__logHistory__logTable_filter_allLogs']}
                                PaperTopPosition='40'
                                rtl
                            />
                            <DropdownCheckbox
                                width='102'
                                PaperTopPosition='50'
                                data={logsHistory.filterLevel}
                                selectActionsHandler={this.logFilterLevelHandler}
                                title={translations['common__text__status']}
                                translations={translations}
                            />
                        </ActionWrapper>
                    </LineTableHeader>
                    <TableLayout
                        headerData={dataTable.headerData}
                        bodyData={dataTable.bodyData}
                        column={dataTable.headerData.length}
                        refCollection={refCollection}
                        initSortTableHandle={initSortTableHandle}
                        translations={translations}
                    />
                </ContentTableWrapper>

            </PageWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        logsHistory: state.logsHistory,
        userKiosk: selectIdsUser(state),
        logHistoryItemsFiltered : selectLogHistoryFiltered(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionsLogsHistory: bindActionCreators(logsActionCreators, dispatch),
        actionsExportData: bindActionCreators(exportDataActionCreators, dispatch),
    };
};

export default TableNested(ModalDatePicker(connect(mapStateToProps, mapDispatchToProps)(LogsHistory)))
