import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// helpers
import { isArray, map, merge } from 'lodash'

// components
import TableLayout from '../'
import { OpenNestedAnimation } from "../../../theme/GlobalCss";
/////////////////////////////
//  START style
////////////////////////////
const TableBody = styled.div`
    display: flex;
    color: ${ props => props.color ? props.color : props.theme.colors.menu.link };
    background-color: ${ props => props.nested ? props.theme.colors.white : props.theme.colors.tableHeader.white2 };
    background-color: ${ props => props.bcg };
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    height: ${ /* props => props.nested ? '30px' : '30px' */ '30px' };
    border-bottom: 1px solid ${ props => props.theme.colors.borderTable };
    cursor: ${ props => props.cursor };
    grid-column: ${ props => props.size ? props.size : 'auto' };
`;
const WrappedNested = styled.div`
    grid-column: 1 / -1;
    padding: 20px 20px;
    border: 1px solid ${ props => props.theme.colors.borderTable };
    display: ${ props => props.display };
    animation: ${OpenNestedAnimation} .5s ease ;
`;
const TextContent = styled.div`
    display: ${ props => props.display };
    justify-content: center;
    align-items: stretch;
    width: 100%;
    padding: 8px ${ props => props.bcg ? '5px' : 0 };
    background-color: ${ props => props.bcg ? props.bcg : 'inherit' };
    font-weight: ${ props => props.bold ? 'bold' : 'none' }
`;
const EditableElementNumber = styled.input`
    -webkit-appearance: none;
    border: 1px solid ${ props => props.theme.colors.cancel};
    border-radius: 3px;
    padding: 10px 0;
    text-align: center;
    width: 5em;
    height: 10px;
`;
const EditableElementText = styled.input`
    -webkit-appearance: none;
    border: 1px solid ${ props => props.theme.colors.cancel};
    border-radius: 3px;
    padding: 10px 0;
    text-align: center;
    max-width: 200px;
`;
const PaperCheckbox = styled.input`
    cursor: pointer;
    height: 17px;
    width: 17px;
    border: 1px solid ${ props => props.theme.colors.borderList};
    border-radius: 2px;
    background-color: ${ props => props.theme.colors.white };
`;
const TitleNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const TitleName = styled.p`
    margin: 0;
    padding: 0;
    text-align: center;
`;
/////////////////////////////
//  END style
////////////////////////////

const TableLayoutRows =
    ( {
          data,
          refCollection,
          editInputHandler,
          checkboxChangeHandler,
          nested,
      } ) => {

        return (
            data && (
                map( data, ( elem, ) => {
                    return map( elem.elements, ( item, key ) => {
                        const layoutValue = item.price ?
                            item.value === '-' ?
                                item.value
                                :
                                item.active ?
                                    item.value
                                    :
                                    `${ item.value }${ item.currency }`
                            :
                            item.value;
                        if ( item.nested ) {

                            return (
                                <WrappedNested
                                    key={ key }
                                    id={ elem.id }
                                    display={ item.display }
                                >
                                    <TableLayout
                                        headerData={ item.header }
                                        bodyData={ item.body }
                                        nested
                                    />
                                </WrappedNested>
                            )
                        } else {
                            return (

                                item.name === 'checkBox' ?

                                    <TableBody
                                        key={ key }
                                        id={ elem.id }
                                        name={ item.name }
                                        color={ item.color }
                                        cursor={ item.name === 'detail' ? 'pointer' : 'default' }
                                        size={ item.column }
                                    >
                                        <TextContent
                                            bcg={ item.bcg }
                                            display={ item.name === 'actions' ? item.display : 'flex' }
                                            ref={ ( element ) => {
                                                merge( refCollection, { [elem.id]: { [item.name]: element } } )
                                            } }
                                        >
                                            <PaperCheckbox
                                                id={ elem.id }
                                                type='checkbox'
                                                checked={ item.isChecked }
                                                onChange={ checkboxChangeHandler }
                                            />
                                        </TextContent>
                                    </TableBody>
                                    :
                                    <TableBody
                                        key={ key }
                                        id={ elem.id }
                                        name={ item.name }
                                        color={ item.color }
                                        cursor={
                                            item.cursorStyle
                                                ? item.cursorStyle
                                                : item.name === 'detail' ? 'pointer' : 'default'
                                        }
                                        nested={ nested }
                                        bcg={ item.bcgAll }
                                        size={ item.column }

                                    >
                                        <TextContent
                                            bcg={ item.bcg }
                                            bold={ item.bold }
                                            display={ item.name === 'actions' ? item.display : 'flex' }
                                            ref={ ( element ) => {
                                                merge( refCollection, { [elem.id]: { [item.name]: element } } )
                                            } }
                                        >
                                            {
                                                item.active ?
                                                    item.type === 'text' ?
                                                        <EditableElementText
                                                            value={ layoutValue }
                                                            size={ item.value.length ? item.value.length : 1 }
                                                            type="text"
                                                            onChange={ editInputHandler }
                                                            id={ elem.id }
                                                            name={ item.name }
                                                            maxLength={item.maxLength}
                                                        />
                                                    :
                                                        <EditableElementNumber
                                                            value={ layoutValue }
                                                            onBlur={ item.onBlur || null }
                                                            size={ item.value.length }
                                                            type="number"
                                                            onChange={ editInputHandler }
                                                            id={ elem.id }
                                                            step="any"
                                                            name={ item.name }
                                                            max='99999'
                                                            min='0'
                                                            required
                                                        />
                                                    :

                                                        isArray( layoutValue )
                                                            ?
                                                                <TitleNameWrapper>
                                                                {
                                                                    map( layoutValue, ( elem, id ) => (
                                                                        <TitleName
                                                                            key={ id }
                                                                        >
                                                                            { elem }
                                                                        </TitleName>
                                                                    ) )
                                                                }
                                                                </TitleNameWrapper>

                                                            :
                                                                layoutValue
                                            }
                                        </TextContent>
                                    </TableBody>
                            )
                        }

                    } )
                } )
            )
        )
    };

TableLayoutRows.propTypes = {
    data: PropTypes.array,
    refCollection: PropTypes.array,
    editInputHandler: PropTypes.func,
    checkboxChangeHandler: PropTypes.func,
    nested: PropTypes.bool,
};

export default TableLayoutRows
