import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// assets
import Magnifer from '../../../assets/icones/global/loupe.png'


/////////////////////////////
//  START style
////////////////////////////
const InputWrapper = styled.div`
    position: relative;
`;
const InputSearch = styled.input`
    width: 199px;
    height: 44px;
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 3px;
    display: flex;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    ::first-letter{
        text-transform: capitalize;
    }
    font-size: 16px;
    color: #323232;
`;
const InputIMG = styled.img`
    position: absolute;
    top: 13px;
    right: 9px;
`;
/////////////////////////////
//  END style
////////////////////////////

const InputFilter = ({ onChangeHandler, value }) => {

    return (
        <InputWrapper>
            <InputSearch 
                onChange={onChangeHandler}
                value={value}
            />
            <InputIMG src={Magnifer}/>
        </InputWrapper>
    )
};

InputFilter.propTypes = {
    onChangeHandler: PropTypes.func,
    value: PropTypes.string,
};

export default InputFilter
