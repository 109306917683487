import { fork } from 'redux-saga/effects';
import login from './login';
import brands from './brands';
import families from './families';
import itemsKiosk from './itemsKiosk';
import salesOrders from './salesOrders';
import salesHistories from './salesHistories';
import codesHistories from './codesHistories';
import itemsStorePrices from './itemsStorePrices';
import promoItemStore from './promoItemStore';
import kiosks from './kiosks';
import myAccount from './myAccount';
import createPromo from './createPromo';
import paymentCollection from './paymentCollection';
import stockManager from './stockManager'
import languages from './languages'
import logs from './logs'
import errorManager from './errorManager'
import dashBoard from './dashBoard'
import user from './user'
import adminUser from './adminUser'
import userDetail from './userDetail'
import exportData from './exportData'
/* import wareHouseStockManagement from './wareHouseStockManagement' */


const sagas = function* () {
  yield fork(login);
  yield fork(brands);
  yield fork(families);
  yield fork(itemsKiosk);
  yield fork(salesOrders);
  yield fork(salesHistories);
  yield fork(codesHistories);
  yield fork(itemsStorePrices);
  yield fork(promoItemStore);
  yield fork(kiosks);
  yield fork(myAccount);
  yield fork(createPromo);
  yield fork(paymentCollection);
  yield fork(stockManager);
  yield fork(languages);
  yield fork(errorManager);
  yield fork(logs);
  yield fork(dashBoard);
  yield fork(user);
  yield fork(adminUser);
  yield fork(userDetail);
  yield fork(exportData);
/*   yield fork(wareHouseStockManagement); */
};

export default sagas;
