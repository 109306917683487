import {
    put,
    takeLatest,
} from 'redux-saga/effects';
import {isUndefined} from 'lodash'

import ActionsTypes from '../../constants/ActionsTypes';
import { globalLoadingActionCreators, modalsActionCreators } from '../actions';

const createError = function* ({payload}) {
    const error =  payload.error;
    const redirectPath = payload.redirectPath
    let modal;
    yield put(globalLoadingActionCreators.endLoading());
    if(!isUndefined(error)){
        if(!isUndefined(error.status)){
            switch(error.status) {

                case 500:
                    modal = {
                        level: 'error',
                        title: 'modal__errorDefault__title',
                        key: 'modal__errorDefault__body',
                        show: true,
                        path: redirectPath
                    };

                    yield put(modalsActionCreators.hydrateModal(modal));
                    break;

                case 404:
                    modal = {
                        level: 'warning',
                        title: 'modal__error404__title',
                        key: 'modal__error404__body',
                        show: true,
                        path: redirectPath
                    };

                    yield put(modalsActionCreators.hydrateModal(modal));
                    break;

                case 200:
                    modal = {
                        level: 'warning',
                        title: 'modal__error200PartialSuccess__title',
                        key: 'modal__error200PartialSuccess__body',
                        show: true,
                        path: redirectPath
                    };

                    yield put(modalsActionCreators.hydrateModal(modal));
                    break;

                case 400:
                        modal = {
                            level: 'warning',
                            title: 'modal__error400__title',
                            key: error.data.traductionKey,
                            errorMsgParams: error.data.msgParameters,
                            show: true,
                            path: redirectPath
                        };
                        yield put(modalsActionCreators.hydrateModal(modal));
                        break;

                case 401:
                    modal = {
                        level: 'error',
                        title: 'modal__error401__title',
                        key: error.data.traductionKey,
                        errorMsgParams: error.data.msgParameters,
                        show: true,
                        path: redirectPath
                    };
                    yield put(modalsActionCreators.hydrateModal(modal));
                    break;


                default:
                    modal = {
                        level: 'error',
                        title: 'modal__errorDefault__title',
                        key: 'modal__errorDefault__body',
                        show: true,
                        path: redirectPath
                    };

                    yield put(modalsActionCreators.hydrateModal(modal));
                    break;
            }
        }else{
            modal = {
                level: 'error',
                title: 'modal__errorDefault__title',
                key: 'modal__errorDefault__body',
                show: true,
                path: redirectPath
            };
            yield put(modalsActionCreators.hydrateModal(modal))
        }

    }else{
        modal = {
            level: 'error',
            title: 'modal__errorDefault__title',
            key: 'modal__errorDefault__body',
            show: true,
            path: redirectPath
        };
        yield put(modalsActionCreators.hydrateModal(modal))
    }
};

const sagas = function* () {
    yield takeLatest(ActionsTypes.CREATE_ERROR, createError);
};

export default sagas