import React  from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'


// assets
import ValidationTag from "../../../assets/icones/tag/circle-green.svg";
import InformationTag from "../../../assets/icones/tag/exlam-circle-yellow.svg";
import UrgentTag from "../../../assets/icones/tag/cross-circle-red.svg";

/// //////////////////////////
//  START style
/// /////////////////////////
const ImgTag = styled.img`
    max-width: ${ props => props.width };
    margin-right: ${ props => props.margin ? props.margin :  '10px' };`;
const TagAndText = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;
const CategoriesEmbed = styled.span`
  font-weight: 900;
`;
const Text = styled.p`
    margin: 0;
    padding: 0;
`;
/// //////////////////////////
//  END style
/// /////////////////////////

/**
 *
 * @param {number} idLevel           id level of target ( e.g. ( 1, 2, 3 )
 * @param {string} width             size off image
 * @param {string} text              text to layout
 * @param {bool} bold
 * @param {string} margin
 */
const TagImgAndText =
    ( {
        idLevel,
        width,
        text,
        bold,
        margin
      } ) => {
        let imgTag;

        switch(idLevel){
            case 1:
                imgTag = ValidationTag;
                break;
            case 2:
                imgTag = InformationTag;
                break;
            case 3:
                imgTag = UrgentTag;
                break;

            default:
                imgTag = ValidationTag;
                break
        }

        return (

            <TagAndText>
                <ImgTag
                    width={width}
                    src={imgTag}
                    margin={margin}
                />
                {
                    text &&
                    <Text>
                        {
                            bold ?
                                <CategoriesEmbed>
                                    {text}
                                </CategoriesEmbed>
                                :
                                text
                        }
                    </Text>
                }

            </TagAndText>

        )
    };

TagImgAndText.propTypes = {
    idLevel: PropTypes.number,
    width: PropTypes.string,
    text: PropTypes.string,
    bold: PropTypes.bool,
    margin: PropTypes.string,
};

export default TagImgAndText
