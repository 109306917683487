import {
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';
import { selectIdsUser } from '../selectors/user'
import {
    errorManagerActionCreators,
    paymentCollectionActionCreators
} from '../actions';
import API from '../../constants/ApiUrl'
import { selectSalesOrderToSend } from '../selectors/paymentCollection'

import * as R from '../../constants/ApiConstants'

const getPaymentCollection = function* ( { payload } ) {

    const user      = yield select( selectIdsUser );
    const dateTime  = {
        'dateStart': payload.date.dateStart ,
        'dateEnd': payload.date.dateEnd
    };

    try {
        const data = yield API(user.jwt).post( R.GET_PAYMENT_COLLECTION( user ), dateTime );

        yield put( paymentCollectionActionCreators.getPaymentCollectionSuccess( data.data ) );
    } catch ( e ) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put( paymentCollectionActionCreators.getPaymentCollectionFailure( e.response ) );
    }
};
const getPaymentCollectionDetail = function* ( { payload } ) {

    const user                  = yield select( selectIdsUser );
    const idPaymentCollection   = payload.id;

    try {
        const data = yield API(user.jwt).get( R.GET_PAYMENT_COLLECTION_DETAIL( user, idPaymentCollection ) );

        yield put( paymentCollectionActionCreators.getPaymentCollectionDetailSuccess( data.data ) );
    } catch ( e ) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put( paymentCollectionActionCreators.getPaymentCollectionDetailFailure( e.response ) );
    }
};
const getSalesOrderExcluded = function* ( { payload } ) {

    const user                  = yield select( selectIdsUser );
    const idPaymentCollection   = payload.id;

    try {
        const data = yield API(user.jwt).get( R.GET_SALES_ORDER_EXCLUDED( user, idPaymentCollection ) );

        yield put( paymentCollectionActionCreators.getSalesOrderExcludedSuccess( data.data ) );
    } catch ( e ) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put( paymentCollectionActionCreators.getSalesOrderExcludedFailure( e.response ) );
    }
};
const updatePaymentCollectionSalesOrder = function* ( { payload } ) {

    const user                  = yield select( selectIdsUser );
    const idPaymentCollection   = payload.id;
    const salesOrder            = yield select( selectSalesOrderToSend );

    try {
        const data = yield API(user.jwt).put( R.UPDATE_PAYMENT_COLLECTION_SALES_ORDER( user, idPaymentCollection ), salesOrder );

        yield put( paymentCollectionActionCreators.updatePaymentCollectionSalesOrderSuccess( data.data ) );
    } catch ( e ) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put( paymentCollectionActionCreators.updatePaymentCollectionSalesOrderFailure( e.response ) );
    }
};

const getSalesOrderPaymentCollection = function* ( { payload } ) {

    const user                  = yield select( selectIdsUser );
    user['idPaymentCollection']   = payload.id;
    try {
        const paymentCollectionHeader = yield API(user.jwt).get( R.GET_SALES_ORDER_PAYMENT_COLLECTION_HEADER( user ) );

        yield put( paymentCollectionActionCreators.getPaymentCollectionSuccess( [paymentCollectionHeader.data] ) );

        yield put( paymentCollectionActionCreators.updatePaymentCollectionSelected( payload.id ) );

    } catch ( e ) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put( paymentCollectionActionCreators.getPaymentCollectionFailure( e.response ) );
    }
};

const sagas = function* () {
    yield takeLatest( ActionsTypes.GET_PAYMENT_COLLECTION, getPaymentCollection );
    yield takeLatest( ActionsTypes.GET_PAYMENT_COLLECTION_DETAIL, getPaymentCollectionDetail );
    yield takeLatest( ActionsTypes.GET_SALES_ORDER_EXCLUDED, getSalesOrderExcluded );
    yield takeLatest( ActionsTypes.UPDATE_PAYMENT_COLLECTION_SALES_ORDER, updatePaymentCollectionSalesOrder );
    yield takeLatest( ActionsTypes.GET_SALES_ORDER_PAYMENT_COLLECTION_DETAIL, getSalesOrderPaymentCollection );
};

export default sagas