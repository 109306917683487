import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { isEmpty } from 'lodash'

// redux import
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    loginActionCreators,
} from '../../../state/actions';
import { ReactComponent as IconePortrait } from "../../../assets/icones/login/loginPortrait.svg";
import theme from "../../../theme/theme";

/////////////////////////////
//  START style
////////////////////////////
const UserWrapperForm = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 23px;
    grid-row-gap: 5px;
    align-items: center;
    color: ${props => props.theme.colors.menu.link};
    box-sizing: border-box;
`;
const InputLabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;
const InputLogin = styled.input`
    height: 45px;
    width: 208px;
    border-radius: 0 3px 3px 0;
    border: ${props => props.touched ? props.validated ? `1px solid ${props.theme.colors.validation}` : `1px solid ${props.theme.colors.error}` : `1px solid ${props.theme.colors.cancel}`};
    box-sizing: border-box;
    font-size: 14px;
    padding-left: 13px;
    color: ${props => props.theme.colors.cancel};
    background-color: ${props => props.theme.colors.white};
    :focus{
        outline: none !important;
        border-color: ${props => props.theme.colors.primary};
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        box-shadow: 0 0 0 1000px ${props => props.theme.colors.white} inset;
    }
`;
const LabelText = styled.p`
    margin: 0;
    font-size: 13px;
    color: ${props => props.theme.colors.cancel};
`;
const ErrorInputMsg = styled.div`
    position: absolute;
    top: 55px;
    left: 5px;
    width: 200px;
    color: ${props => props.theme.colors.error};
`;
const InputWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ButtonWrapper = styled.div`
    width: 100%;
    height: 40px;
    margin-top: 52px;
    display: flex;
    justify-content: flex-end;
`;
const Button = styled.button`
    padding: 11px 10px;
    background-color: ${props => props.color};
    color: ${props => props.theme.colors.white};
    border: none;
    border-radius: 3px;
    margin-right: ${props => props.margin};
    cursor: pointer;
`;
const IconeLogin = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 3px 0 0 3px;
    background-color: ${props => props.theme.colors.primary};
`;
const LoginWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
`;
/////////////////////////////
//  END style
////////////////////////////

export class ForgotPassword extends Component {
    static propTypes = {
        closeModalHandler: PropTypes.func,
        translations: PropTypes.object,
        actionsLogin: PropTypes.object,
        forgotPasswordSubmitedHandler: PropTypes.func,
    };

    state = {
        login: '',
        errors: '',
    };

    /**
     * check validation of field enter by user
     * @return  {Object}        errors object
     */
    validateFormField = () => {
        const { translations } = this.props;
        const values = {
            'identifiant': this.state.login
        };
        const errors = {};
        if (!values.identifiant) {
            errors.identifiant = translations['common__text__requiredField']
        } else {
            errors.identifiant = ''
        }
        this.setState({
            errors: { ...errors }
        })
    };// end function

    forgotPasswordSubmit = async () => {
        await Promise.resolve(this.validateFormField());

        const { login, errors } = this.state;
        const { actionsLogin, forgotPasswordSubmitedHandler } = this.props;

        if (isEmpty(errors.identifiant)) {
            actionsLogin.postForgotPassword(login);
            forgotPasswordSubmitedHandler()
        }
    };

    loginChangeHandler = (evt) => {
        const input = evt.target.value;
        this.setState(
            {
                login: input
            }, () => this.validateFormField()
        )
    };

    render() {
        const { closeModalHandler, translations } = this.props;
        return (
            <LoginWrapper>
                <UserWrapperForm>
                    <InputLabelWrapper>
                        <LabelText>
                            {translations['modal__customerInfo__form_name'] + " :"}
                        </LabelText>
                        <InputWrapper>
                            <IconeLogin>
                                <IconePortrait />
                            </IconeLogin>

                            <InputLogin
                                onFocus={this.validateFormField}
                                onChange={this.loginChangeHandler}
                                onBlur={this.validateFormField}
                                type="text"
                                placeholder={translations['page__login__form_username']}
                            />

                            <ErrorInputMsg>
                                {
                                    this.state.errors.identifiant
                                }

                            </ErrorInputMsg>
                        </InputWrapper>
                    </InputLabelWrapper>

                </UserWrapperForm>

                <ButtonWrapper>
                    <Button
                        color={theme.colors.error}
                        margin='21px'
                        onClick={closeModalHandler}
                    >
                        {translations['common__text__cancel']}
                    </Button>
                    <Button
                        color={theme.colors.validation}
                        onClick={this.forgotPasswordSubmit}
                    >
                        {
                            translations['modal__forgotPassword__button_sendEmail']
                        }
                    </Button>
                </ButtonWrapper>
            </LoginWrapper>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actionsLogin: bindActionCreators(loginActionCreators, dispatch),
    }
};

export default connect(null, mapDispatchToProps)(ForgotPassword)
