import update from "immutability-helper";
import extendUpdate from "../../helpers/extendUpdate";
import ActionsTypes from "../../constants/ActionsTypes";

extendUpdate(update);

const languages = {
  [ActionsTypes.GET_TRANSLATIONS_BY_LANGUAGES]: (state) => {
    return update(state, {
      languages: {
        isLoading: {
          $set: true,
        },
        error: {
          $set: false,
        },
      },
    });
  },

  [ActionsTypes.GET_TRANSLATIONS_BY_LANGUAGES_SUCCESS]: (state, payload) => {
    return update(state, {
      languages: {
        isLoading: {
          $set: false,
        },
        error: {
          $set: false,
        },
        translations: {
          $set: payload.translations,
        },
        scope: {
          $set: "private",
        },
        selectedLanguage: {
          $set: payload.idLanguage,
        },
      },
    });
  },

  [ActionsTypes.GET_TRANSLATIONS_BY_LANGUAGES_FAILURE]: (state, payload) => {
    return update(state, {
      languages: {
        isLoading: {
          $set: false,
        },
        error: {
          $set: payload.error,
        },
      },
    });
  },
  [ActionsTypes.GET_LANGUAGES_ENABLED]: (state) => {
    return update(state, {
      languages: {
        isLoading: {
          $set: true,
        },
        error: {
          $set: false,
        },
      },
    });
  },
  [ActionsTypes.GET_LANGUAGES_ENABLED_SUCCESS]: (state, payload) => {
    return update(state, {
      languages: {
        isLoading: {
          $set: false,
        },
        error: {
          $set: false,
        },
        items: {
          $set: payload.languages,
        },
      },
    });
  },
  [ActionsTypes.GET_LANGUAGES_ENABLED_FAILURE]: (state, payload) => {
    return update(state, {
      languages: {
        isLoading: {
          $set: false,
        },
        error: {
          $set: payload.error,
        },
      },
    });
  },
  [ActionsTypes.GET_TRANSLATIONS_PUBLIC_PAGE]: (state) => {
    return update(state, {
      languages: {
        isLoading: {
          $set: true,
        },
        error: {
          $set: false,
        },
      },
    });
  },
  [ActionsTypes.GET_TRANSLATIONS_PUBLIC_PAGE_SUCCESS]: (state, payload) => {
    return update(state, {
      languages: {
        isLoading: {
          $set: false,
        },
        error: {
          $set: false,
        },
        translations: {
          $set: payload.translations,
        },
        scope: {
          $set: "public",
        },
        selectedLanguage: {
          $set: payload.idLanguage,
        },
      },
    });
  },
  [ActionsTypes.GET_TRANSLATIONS_PUBLIC_PAGE_FAILURE]: (state, payload) => {
    return update(state, {
      languages: {
        isLoading: {
          $set: false,
        },
        error: {
          $set: payload.error,
        },
      },
    });
  },
};

export default languages;
