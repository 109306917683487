import React, { Component } from 'react';
import  Chart from 'chart.js'
import ColorsCharts from '../../../constants/ChartsColorsList'

import styled from 'styled-components';
import { forEach, map } from "lodash";

const ChartWrapper = styled.div`
    width: 1fr;
    grid-column: ${ props => props.isFull ? '1 / 3': 'auto'};
    height: min-content;
    background-color: ${ props => props.theme.colors.white };
    padding: 5px;
    border: 1px solid ${ props => props.theme.colors.white };
`;

class BarChart extends Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    componentDidUpdate(prevProps) {

        if( prevProps.data !== this.props.data){
            this.createChart()
        }

        if(prevProps.translations !== this.props.translations) {
            this.myChart.data.datasets = this.makeDataSets();
            this.myChart.options.title.text = this.props.translations[this.props.data.tradKeyTitle];
            this.myChart.update();
        }
    }

    makeDataSets = () => {
        const { data, translations } = this.props;
        const dataToTable = map(data.contents, (elem, id) => (
            {
                label: translations[elem.tradKeyTitle],
                backgroundColor: ColorsCharts[id],
                data: map(elem.contents, ( elem => elem.value)),
            }
        ));
        return dataToTable
    };

    createChart = () => {
        const { data } = this.props;
        const formatLabel = []
        forEach(data.contents, (elem => (
            forEach(elem.contents, (item => formatLabel.push(item.label)))
        )));        const dataToTable = this.makeDataSets();

        this.myChart = new Chart(this.chartRef.current, {
            type: 'bar',
            options: {
                title: {
                    display: true,
                    text: 'test chart title',
                    position: 'top',
                    fontSize: '16',
                    fontFamily: 'Lato'
                },
                legend:{
                    position: 'bottom',
                    align: 'center',
                    fontFamily: 'Lato',
                    labels:{
                        boxWidth: 25,
                        padding: 20,
                    }
                },
                maintainAspectRatio: true,
            },
            data: {
                labels: formatLabel,
                datasets: dataToTable,
            }
        });

    };

    componentDidMount() {
        this.createChart()
    }

    render() {
        const { data } = this.props;
        return (
            <ChartWrapper
                isFull={data.fullWidth}
            >
                <canvas ref={this.chartRef} />
            </ChartWrapper>
        );
    }
}

export default BarChart;
