import ActionsTypes from '../../constants/ActionsTypes';

const errorManager = {
    createError: ( error, redirectPath = '' ) => ({
        type: ActionsTypes.CREATE_ERROR,
        payload: {
            error,
            redirectPath,
        },
    }),
    criticalError: ( error ) => ({
        type: ActionsTypes.CREATE_CRITICAL_ERROR,
        payload: {
            error
        },
    }),

};

export default errorManager;