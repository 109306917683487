import CR from "./RouteConstants";
import Auth from "./RoleList";


import Graph from "../assets/icones/global/graph.svg";
import Products from "../assets/icones/menu/products.svg";
import SalesHistory from "../assets/icones/menu/sales-history.png";
import CodeHistory from "../assets/icones/menu/note-history.svg";
import StockManagement from "../assets/icones/menu/stock-management.svg";
//import WareHouseStockManagement from "../assets/icones/menu/warehouse-stock-management.png";
import ValidationDelivery from "../assets/icones/menu/validation-delivery.svg";
import StockCorrection from "../assets/icones/menu/stock-correction.png";
import ManualRestock from "../assets/icones/menu/manual-restock.png";
import Settings from "../assets/icones/menu/settings.svg";
import ManageUser from "../assets/icones/menu/manage-user.svg";
import LogHistory from "../assets/icones/menu/logs-history.svg";

const MenuItems = {
// 	DASHBOARD
// ========================================
	dashBoard: {
		linkTo: CR.home,
		id: '',
		image: Graph,
		text: 'menu__dashboard',
		auth: Auth.view[11],
		expend: false,
	},
// ========================================
// 	PRODUCTS
// ========================================
	products: {
		linkTo: false,
		id: 'products',
		image: Products,
		text: 'menu__products',
		auth: Auth.view[28],
		expend: false ,
	},
	productsCatalogue: {
		linkTo: CR.kioskProduct,
		id: 'products',
		image: Products,
		text: 'menu__submenu_productsCatalogue',
		auth: Auth.view[9],
		expend: true,
	},
	/* pricesManagement: {
		linkTo: CR.priceManagment,
		id: 'products',
		image: Tag,
		text: 'menu__submenu_pricesManagement',
		auth: Auth.view[12],
		expend: true,
	}, */
// ========================================
// 	SALES
// ========================================
	sales: {
		linkTo: false,
		id: 'sales',
		image: SalesHistory,
		text: 'menu__sales',
		auth: Auth.view[27],
		expend: false ,
	},
	salesHistory: {
		linkTo: CR.salesHistories,
		id: 'sales',
		image: SalesHistory,
		text: 'menu__submenu_salesHistory',
		auth: Auth.view[0],
		expend: true,
	},
	codesHistory: {
		linkTo: CR.codeHistories,
		id: 'sales',
		image: CodeHistory,
		text: 'menu__submenu_salesByCodeHistory',
		auth: Auth.view[8],
		expend: true,
	},
	/* paymentCollection: {
		linkTo: CR.telecollection,
		id: 'sales',
		image: Telecollecte,
		text: 'menu__submenu_paymentCollections',
		auth: Auth.view[4],
		expend: true,
	}, */
// ========================================
// 	STOCK
// ========================================
	stocks: {
		linkTo: false,
		id: 'stocks',
		image: StockManagement,
		text: 'menu__stocks',
		auth: Auth.view[29],
		expend: false ,
	},
	mangeStockKiosk: {
		linkTo: CR.mangeStockKiosk,
		id: 'stocks',
		image: StockManagement,
		text: 'menu__submenu_kioskStockManagement',
		auth: Auth.view[13],
		expend: true,
	},
	/* wareHouseStock: {
		linkTo: CR.warehouseStockManagement,
		id: 'stocks',
		image: WareHouseStockManagement,
		text: 'menu__submenu_warehouseStockManagement',
		auth: Auth.view[14],
		expend: true,
	}, */
	deliveryValidation: {
		linkTo: CR.deliveryValidation,
		id: 'stocks',
		image: ValidationDelivery,
		text: 'menu__submenu_deliveriesValidation',
		auth: Auth.view[15],
		expend: true,
	},
	manualRestock: {
		linkTo: CR.manualRestock,
		id: 'stocks',
		image: ManualRestock,
		text: 'menu__submenu_manualRestock',
		auth: Auth.view[17],
		expend: true,
	},
	stockCorrection: {
		linkTo: CR.stockCorrection,
		id: 'stocks',
		image: StockCorrection,
		text: 'menu__submenu_stocksCorrection',
		auth: Auth.view[18],
		expend: true,
	},
// ========================================
// 	ADMIN
// ========================================
	admin: {
		linkTo: false,
		id: 'admin',
		image: Settings,
		text: 'menu__admin',
		auth: Auth.view[30],
		expend: false ,
	},
	kioskManagement: {
		linkTo: CR.kioskManagment,
		id: 'admin',
		image: Settings,
		text: 'menu__submenu_kiosksManagement',
		auth: Auth.view[20],
		expend: true,
	},
	adminUser: {
		linkTo: CR.adminUser,
		id: 'admin',
		image: ManageUser,
		text: 'menu__submenu_usersManagement',
		auth: Auth.view[22],
		expend: true,
	},
	logHistory: {
		linkTo: CR.logHistory,
		id: 'admin',
		image: LogHistory,
		text: 'menu__submenu_logsHistory',
		auth: Auth.view[24],
		expend: true,
	},
// ========================================

}


export const MenuList = [
	{ ...MenuItems.dashBoard, nested: false },
	{
		...MenuItems.products,
		nested: true,
		menuNested: [
			{ ...MenuItems.productsCatalogue, nested: false},
			{ ...MenuItems.pricesManagement, nested: false},
		]
	},
	{
		...MenuItems.sales,
		nested: true,
		menuNested: [
			{ ...MenuItems.salesHistory, nested: false},
			{ ...MenuItems.codesHistory, nested: false},
			{ ...MenuItems.paymentCollection, nested: false},
		]
	},
	{
		...MenuItems.stocks,
		nested: true,
		menuNested: [
			{ ...MenuItems.mangeStockKiosk, nested: false},
			/* { ...MenuItems.wareHouseStock, nested: false}, */
			{ ...MenuItems.deliveryValidation, nested: false},
			{ ...MenuItems.manualRestock, nested: false},
			{ ...MenuItems.stockCorrection, nested: false},
		]
	},
	{
		...MenuItems.admin,
		nested: true,
		menuNested: [
			{ ...MenuItems.kioskManagement, nested: false},
			{ ...MenuItems.adminUser, nested: false},
			{ ...MenuItems.logHistory, nested: false},
		]
	},
]



