import { put, select, takeLatest } from "redux-saga/effects";

import ActionsTypes from "../../constants/ActionsTypes";
import {
  languagesActionCreators,
  globalLoadingActionCreators,
  errorManagerActionCreators,
} from "../actions";
import API_TOKEN, { API } from "../../constants/ApiUrl";
import * as R from "../../constants/ApiConstants";
import { selectIdsUser } from "../selectors/user";

const getTranslationByLanguages = function* ({ payload }) {
  const idLanguage = payload.idLanguage;
  const user = yield select(selectIdsUser);

  yield put(globalLoadingActionCreators.startLoading());

  try {
    const data = yield API_TOKEN(user.jwt).get(
      R.GET_TRANSLATION_LANGUAGES_BY_ID(idLanguage)
    );

    yield put(
      languagesActionCreators.getTranslationByLanguagesSuccess(
        data.data,
        idLanguage
      )
    );
  } catch (e) {
    yield put(errorManagerActionCreators.createError(e.response));

    yield put(
      languagesActionCreators.getTranslationByLanguagesFailure(e.response)
    );
  }
  yield put(globalLoadingActionCreators.endLoading());
}; // END FUNCTION

const getTranslationPublicPage = function* ({ payload }) {
  const idLanguage = payload.idLanguage;

  yield put(globalLoadingActionCreators.startLoading());

  try {
    const data = yield API.get(
      R.GET_TRANSLATION_LANGUAGES_PUBLIC_BY_ID(idLanguage)
    );

    yield put(
      languagesActionCreators.getTranslationPublicPageSuccess(
        data.data,
        idLanguage
      )
    );
  } catch (e) {
    yield put(errorManagerActionCreators.criticalError(e.response));

    yield put(errorManagerActionCreators.createError(e.response));

    yield put(
      languagesActionCreators.getTranslationPublicPageFailure(e.response)
    );
  }

  yield put(globalLoadingActionCreators.endLoading());
}; // END FUNCTION

const getLanguagesEnabled = function* () {
  yield put(globalLoadingActionCreators.startLoading());

  try {
    const data = yield API.get(R.GET_LANGUAGE_ENABLED());

    yield put(languagesActionCreators.getLanguagesEnabledSuccess(data.data));
  } catch (e) {
    yield put(errorManagerActionCreators.criticalError(e.response));

    yield put(languagesActionCreators.getLanguagesEnabledFailure(e.response));
  }
  yield put(globalLoadingActionCreators.endLoading());
}; // END FUNCTION

const sagas = function* () {
  yield takeLatest(
    ActionsTypes.GET_TRANSLATIONS_BY_LANGUAGES,
    getTranslationByLanguages
  );
  yield takeLatest(ActionsTypes.GET_LANGUAGES_ENABLED, getLanguagesEnabled);
  yield takeLatest(
    ActionsTypes.GET_TRANSLATIONS_PUBLIC_PAGE,
    getTranslationPublicPage
  );
};

export default sagas;
