import { map } from 'lodash'
import {
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import { isUndefined } from 'lodash'
import ActionsTypes from '../../constants/ActionsTypes';
import {
    createPromoActionCreators,
    globalLoadingActionCreators,
    promoItemStoreActionCreators,
    modalsActionCreators, errorManagerActionCreators
} from '../actions';
import { selectIdsUser } from '../selectors/user'
import { selectItemsStorePromoIds } from '../selectors/itemsKiosk'

import API from '../../constants/ApiUrl'
import * as R from '../../constants/ApiConstants'
import CR from '../../constants/RouteConstants'

const checkCreatePromoValidation = function* ({payload}) {
    const user              = yield select(selectIdsUser);
    const itemsStore        = yield select(selectItemsStorePromoIds);
    const promoToCheck      = {...payload.itemsPromo};
    promoToCheck.itemsStore = [...itemsStore];
    const promoId           = payload.id;
    let callUrl;

    yield put(globalLoadingActionCreators.startLoading());

    if (isUndefined(promoId)) {
        callUrl = R.CHECK_ITEMS_STORE_PROMOTION(user)
    }else{
        user['promotion'] = promoId;
        callUrl = R.CHECK_ITEMS_STORE_PROMOTION_UPDATE(user)
    }

    try{

        const data = yield API(user.jwt).post(callUrl, promoToCheck);
        yield put(createPromoActionCreators.checkCreatePromoValidationSuccess(data.data));

    } catch (e) {
        if(e.response.status === 400) {
            yield put(createPromoActionCreators.checkCreatePromoValidationFailure(e.response));
        }else{
            yield put(errorManagerActionCreators.createError(e.response));

            yield put(createPromoActionCreators.checkCreatePromoValidationFailure(e.response));
        }
    }
    yield put(globalLoadingActionCreators.endLoading());
};


const createPromo = function* ({payload}) {
    const user              = yield select(selectIdsUser);
    const data              = payload;
    const promotion = {
        promoLabel      : data.label,
        dateStart       : data.date.dateStart,
        dateEnd         : data.date.dateEnd,
        itemsStore      : map(data.itemsStore, ( elem ) => {
            return {
                "id": elem.id,
                "withDeposit": elem.withDeposit,
                "promoPrice": elem.standardPrice,
                "promoDepositPrice": elem.depositPrice === '-' ? null : elem.depositPrice,
                "allocatedStock": elem.advailableStock
            }
        })
    };

    yield put(globalLoadingActionCreators.startLoading());

    try{
        yield API(user.jwt).post(R.POST_PROMTION(user), promotion);
        const modal = {
            key: 'page__promoDetail__modalGenerateSuccess_body',
            title: 'page__promoDetail__modalGenerateSuccess_title',
            level: 'validation',
            show: true,
            path: CR.priceManagment,
        };
        yield put( modalsActionCreators.hydrateModal(modal));

    } catch (e) {
        if(e.response.status === 400) {
            yield put(createPromoActionCreators.checkCreatePromoValidationSuccess(e.response.data));
        }else{
            yield put(errorManagerActionCreators.createError(e.response));

            yield put(createPromoActionCreators.createPromoFailure(e.response));
        }
    }
    yield put(globalLoadingActionCreators.endLoading());
};
const updatePromo = function* ({payload}) {
    const user              = yield select(selectIdsUser);
    const data              = payload;
    user['promotion']       = payload.idPromo;

    const promotion = {
        promoLabel      : data.label,
        dateStart       : data.date.dateStart,
        dateEnd         : data.date.dateEnd,
        itemsStore      : map(data.itemsStore, ( elem ) => {
            return {
                "id": elem.id,
                "withDeposit": elem.withDeposit,
                "promoPrice": elem.standardPrice,
                "promoDepositPrice": elem.depositPrice === '-' ? null : elem.depositPrice,
                "allocatedStock": elem.advailableStock
            }
        })
    };

    yield put(globalLoadingActionCreators.startLoading());

    try{
        yield API(user.jwt).put(R.PUT_UPDATE_PROMO(user), promotion);
        yield put(createPromoActionCreators.updatePromoSuccess());

        const modal = {
            key: 'page__promoDetail__modalUpdateSuccess_body',
            title: 'page__promoDetail__modalUpdateSuccess_title',
            level: 'validation',
            show: true,
            path: CR.priceManagment,
        };

        yield put( modalsActionCreators.hydrateModal(modal));

    } catch (e) {
        if(e.response.status === 400) {
            yield put(createPromoActionCreators.checkCreatePromoValidationSuccess(e.response.data));
        }else{
            yield put(errorManagerActionCreators.createError(e.response));

            yield put(createPromoActionCreators.updatePromoFailure(e.response));
        }
    }
    yield put(globalLoadingActionCreators.endLoading());
};


const initUpdatePromo = function* ({payload}) {

    const user      = yield select(selectIdsUser);
    const promoId   = payload.id;

    yield put(globalLoadingActionCreators.startLoading());

    try{
        const data = yield API(user.jwt).get(R.GET_PROMO_ITEM_DETAIL(user, promoId));

        yield put(promoItemStoreActionCreators.getPromoItemDetailSuccess(data.data));
        yield put(createPromoActionCreators.initUpdatePromoSuccess(data.data.promoHeader.id));

    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(createPromoActionCreators.initUpdatePromoFailure(e.response));
    }
    yield put(globalLoadingActionCreators.endLoading());
};


const sagas = function* () {
    yield takeLatest(ActionsTypes.CHECK_CREATE_PROMO_VALIDATION, checkCreatePromoValidation);
    yield takeLatest(ActionsTypes.CREATE_PROMO, createPromo);
    yield takeLatest(ActionsTypes.INIT_UPDATE_PROMO, initUpdatePromo);
    yield takeLatest(ActionsTypes.UPDATE_PROMO, updatePromo);
};

export default sagas