import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { map, filter, isNull } from 'lodash'

// import component
import ActionButton from '../../ActionButton'

// import helper
import imageImport from '../../../../helpers/imagesImport'

// constant
import CR from '../../../../constants/RouteConstants'
import theme from '../../../../theme/theme'

/// //////////////////////////
//  START style
/// /////////////////////////
const ItemsCardWrapper = styled.div`
    display: grid;
    grid-template-columns: 51px 171px 60px 1fr 260px 65px;
    grid-template-rows: 65px;
    grid-column-gap: 3px;
    background-color: ${ props => props.theme.colors.white };
    border: 1px solid ${ props => props.theme.colors.borderTable };
    padding: 9px 13px;
    box-sizing: border-box;
`;
const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ImageBottle = styled.img`
    max-height: 69px;
    max-width: 51px;
`;
const TextContentTitle = styled.p`
    color: ${ props => props.color ? props.color : 'inherit' };
    margin: 2px 0;
    font-weight: 900;
`;
const StdPromPriceWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-items: center;
    font-weight: 900;
    text-decoration: ${ props => props.promo ? 'none' : 'line-through' };
    background-color: ${ props => props.promo ? props.theme.colors.primary : 'none' };
    color: ${ props => props.promo ? props.theme.colors.white : props.theme.colors.cancel };
    padding: 4px 8px;
`;
const FeaturesPara = styled.p`
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 260px;
    color: ${ props => props.theme.colors.menu.link };
`;
const AccessoriesLabel = styled.div`
    display: flex;
    width: 1fr;
`;
const MainAccessory = styled.div`
    display: flex;
    color: ${ props => props.theme.colors.primary };
    margin: 0;
`;
const TextEllipsis = styled.p`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
    margin: 0 5px 0 0;
`;
const AccessoryPrice = styled( MainAccessory )`
    font-weight: 900;
    margin-top: 2px;
`;
const PromoTag = styled.div`
    background-color: ${ props => props.theme.colors.promoTag };
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 60px;
    color: ${ props => props.theme.colors.white };
`;
const StockTag = styled.div`
    background-color: ${ props => props.stockAlerte };
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 14px;
    color: ${ props => props.theme.colors.white };
`;
const TwoLinesTextWrapper = styled.div`
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   line-height: 19px;     /* fallback */
   height: 38px;      /* fallback */
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   justify-content: flex-end;
`;
const OneLinesTextWrapper = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
`;
const PromoPriceAndStockWrapper = styled.div`
    display: grid;
    grid-template-columns: max-content max-content;
    grid-column-gap: 7px;
`;
const ColumnStartAndEnd = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;
const ColumnElement = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;
const ColumnElementRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
`;
/// //////////////////////////
//  END style
/// /////////////////////////
/**
 *
 * @param {array} data
 * @param {function} getItem
 * @param {object} translations
 * @returns {jsx}
 * @constructor
 */
const ItemsList = ( { data, getItem, translations } ) => {
    // get 3 accessories to display in card
    const mainFeature = filter( data.itemFeatures, [ 'mainItemFeature', true ] );

    let tagStockColor = '';
    let tagText = '';
    if ( data.lockerStock <= data.stockAlert && data.lockerStock > 0 ) {
        tagStockColor = theme.colors.warning;
        tagText = ` ${ translations['page__itemCatalogue__itemCard_inStock'] } ${ data.lockerStock } / ${ data.kioskCapacity }`
    } else if ( data.lockerStock <= 0 ) {
        tagStockColor = theme.colors.error;
        tagText = ` ${ translations['page__itemCatalogue__itemCard_noStock'] } ${ data.lockerStock } / ${ data.kioskCapacity }`
    } else if ( data.lockerStock > data.stockAlert ) {
        tagStockColor = theme.colors.validation;
        tagText = ` ${ translations['page__itemCatalogue__itemCard_inStock'] } ${ data.lockerStock } / ${ data.kioskCapacity }`
    }

    return (
        <ItemsCardWrapper>

            <ImageWrapper>

                <ImageBottle
                    src={ imageImport( data.picture ) }
                />


            </ImageWrapper>
            <ColumnStartAndEnd>
                <TextContentTitle
                    color={ theme.colors.primary }
                >
                    { data.itemBrand.label }
                </TextContentTitle>
                <TwoLinesTextWrapper>
                    <TextContentTitle>
                        { data.label }
                    </TextContentTitle>
                </TwoLinesTextWrapper>
            </ColumnStartAndEnd>
            <ColumnElementRight>
                {
                    !isNull( data.promoPrice )
                        ? <Fragment>
                            <StdPromPriceWrapper promo>
                                { data.promoPrice.toFixed( 2 ) + data.currency.symbol }
                            </StdPromPriceWrapper>
                            <StdPromPriceWrapper>
                                { data.standardPrice.toFixed( 2 ) + data.currency.symbol }
                            </StdPromPriceWrapper>
                        </Fragment>
                        : <StdPromPriceWrapper promo>
                            { data.standardPrice.toFixed( 2 ) + data.currency.symbol }
                        </StdPromPriceWrapper>
                }
            </ColumnElementRight>

            <ColumnStartAndEnd>
                <div>
                    { data.mainAccessory &&
                    <MainAccessory>
                        <AccessoriesLabel>
                            <TextEllipsis>
                                { data.mainAccessory.label }
                            </TextEllipsis>
                        </AccessoriesLabel>
                        {
                            <AccessoriesLabel>
                                <AccessoryPrice>
                                    { data.mainAccessory.promoPrice
                                        ? data.mainAccessory.promoPrice.toFixed( 2 ) + data.mainAccessory.currency.symbol
                                        : data.mainAccessory.standardPrice.toFixed( 2 ) + data.mainAccessory.currency.symbol
                                    }
                                </AccessoryPrice>
                            </AccessoriesLabel>
                        }
                    </MainAccessory>
                    }
                    { data.withDeposit &&
                    <MainAccessory>
                        <AccessoriesLabel>
                            <TextEllipsis>
                                {/* Consigne */ }
                                { translations['common__text__deposit'] }
                            </TextEllipsis>
                        </AccessoriesLabel>
                        <AccessoriesLabel>
                            <AccessoryPrice>
                                {
                                    !isNull( data.promoDepositPrice )
                                        ? `${ data.promoDepositPrice.toFixed( 2 ) }${ data.currency.symbol }`
                                        : `${ data.depositPrice.toFixed( 2 ) }${ data.currency.symbol }`
                                }
                            </AccessoryPrice>
                        </AccessoriesLabel>
                    </MainAccessory>
                    }
                </div>
                <PromoPriceAndStockWrapper>
                    <StockTag
                        stockAlerte={ tagStockColor }
                    >
                        { tagText }
                    </StockTag>
                    {
                        !isNull( data.promoPrice ) &&
                        <PromoTag>
                            {/* promo */ }
                            { translations['page__itemCatalogue__itemCard_promoTag'] }
                        </PromoTag>
                    }
                </PromoPriceAndStockWrapper>
            </ColumnStartAndEnd>
            <ColumnElement>
                {
                    <OneLinesTextWrapper>
                        {
                            map( mainFeature, ( elem, id ) => (
                                <FeaturesPara key={ id }>
                                    { `${ elem.label } : ` }

                                    { elem.value }
                                </FeaturesPara>

                            ) )
                        }
                    </OneLinesTextWrapper>
                }
            </ColumnElement>
            <ActionButton
                link={ `${ CR.kioskProduct }${ CR.modal.kioskProduct }` }
                actionHandler={ () => getItem( data ) }
            />
        </ItemsCardWrapper>
    )
};

ItemsList.propTypes = {
    data: PropTypes.object,
    getItem: PropTypes.func,
    translations: PropTypes.object,
};

export default ItemsList
