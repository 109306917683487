import ActionsTypes from '../../constants/ActionsTypes';

const user = {
    updateKioskSelected: (user, id) => ({
        type: ActionsTypes.UPDATE_USER_KIOSK_SELECTED,
        payload: {
            user,
            id
        },
    }),
    getUserSettings: () => ({
        type: ActionsTypes.GET_USER_SETTINGS,
        payload: {},
    }),
    getUserSettingsSuccess: ( settings ) => ({
        type: ActionsTypes.GET_USER_SETTINGS_SUCCESS,
        payload: { settings },
    }),
    getUserSettingsFailure: ( error ) => ({
        type: ActionsTypes.GET_USER_SETTINGS_FAILURE,
        payload: { error },
    }),
    putUserSettings: ( id, checked ) => ({
        type: ActionsTypes.PUT_USER_SETTINGS,
        payload: { id, checked },
    }),
    putUserSettingsSuccess: ( settings ) => ({
        type: ActionsTypes.PUT_USER_SETTINGS_SUCCESS,
        payload: { settings },
    }),
    putUserSettingsFailure: () => ({
        type: ActionsTypes.PUT_USER_SETTINGS_FAILURE,
        payload: {},
    }),
    putUserNotifSettings: ( id, checked ) => ({
        type: ActionsTypes.PUT_USER_NOTIF_SETTINGS,
        payload: { id, checked },
    }),
    putUserNotifSettingsSuccess: ( settings ) => ({
        type: ActionsTypes.PUT_USER_NOTIF_SETTINGS_SUCCESS,
        payload: { settings },
    }),
    putUserNotifSettingsFailure: () => ({
        type: ActionsTypes.PUT_USER_NOTIF_SETTINGS_FAILURE,
        payload: {},
    }),
};

export default user;