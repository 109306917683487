import {
    put,
    select,
    debounce,
    takeLatest,
} from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';
import {
    itemsKioskActionCreators,
    globalLoadingActionCreators,
    errorManagerActionCreators
} from '../actions';
import { selectIdsUser } from '../selectors/user'
import { selectFamilySelected } from '../selectors/itemsKiosk'

import API from '../../constants/ApiUrl'
import * as R from '../../constants/ApiConstants'
import CR from '../../constants/RouteConstants'

const getItemKiosk = function* ({payload}) {
    
    const user = yield select(selectIdsUser);
    user['item'] = payload.id;

    yield put(globalLoadingActionCreators.startLoading());

    try{

        const data = yield API(user.jwt).get(R.GET_ITEM_KIOSK(user));
        
        yield put(itemsKioskActionCreators.getItemKioskSuccess(data.data));
    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(itemsKioskActionCreators.getItemKioskFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());

};

const postItemKioskCheckOrderValidation = function* ({payload}) {

    const user = yield select(selectIdsUser);
    user['item'] = payload.id;
    const idPromo = {
        "idCurrentClassicPromotionLineInProgres" : payload.idPromo
    }

    yield put(globalLoadingActionCreators.startLoading());

    try{

        const data = yield API(user.jwt).post(R.POST_ITEM_KIOSK_CHECK_ORDER_VALIDATION(user), { ...idPromo });

        yield put(itemsKioskActionCreators.postItemKioskCheckOrderValidationSuccess(data.data));
    } catch (e) {
        yield put(errorManagerActionCreators.createError( e.response ));

        yield put(itemsKioskActionCreators.postItemKioskCheckOrderValidationFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());

};

const getItemsKioskByFamily = function* () {
    
    const user = yield select(selectIdsUser);
    const family = yield select(selectFamilySelected);
    yield put(globalLoadingActionCreators.startLoading());

    try{
        
        const data = yield API(user.jwt).get(R.GET_ITEMS_KIOSK_BY_FAMILY(user, family.id));
        
        yield put(itemsKioskActionCreators.getItemsKioskByFamilySuccess(data.data));
    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(itemsKioskActionCreators.getItemsKioskByFamilyFailure(e.response));
    }
    yield put(globalLoadingActionCreators.endLoading());
};

const startUpdateItemsFilterByInput = function* ({payload}) {
    
    yield put(itemsKioskActionCreators.updateItemsFilterByInput(payload.text));
 
};


const sagas = function* () {
    yield debounce(400, ActionsTypes.START_UPDATE_ITEMS_FILTER_BY_INPUT, startUpdateItemsFilterByInput);
    yield takeLatest(ActionsTypes.GET_ITEMS_KIOSK_BY_FAMILY, getItemsKioskByFamily);
    yield takeLatest(ActionsTypes.GET_ITEM_KIOSK, getItemKiosk);
    yield takeLatest(ActionsTypes.POST_ITEM_KIOSK_CHECK_ORDER_VALIDATION, postItemKioskCheckOrderValidation);
};

export default sagas