////////LIBRARY/////////
import React, { Component } from 'react'
import  Chart from 'chart.js'
import { map, forEach, find, isUndefined } from "lodash"
import styled from 'styled-components'
///////COMPONENTS///////
import ColorsCharts from '../../../constants/ChartsColorsList'
import theme from "../../../theme/theme"

/////////STYLED/////////
const ChartWrapper = styled.div`
    width: 1fr;
    grid-column: ${ props => props.isFull ? '1 / 3': 'auto'};
    background-color: ${ props => props.theme.colors.white };
    padding: 10px;
    border: 1px solid ${ props => props.theme.colors.borderTable };
`
/////////STYLED/////////

class LineChart extends Component {
    constructor(props) {
        super(props);
        this.canvasRefLine = React.createRef();
    }

    componentDidUpdate(prevProps) {

        if( prevProps.data !== this.props.data){
            this.myChart.destroy()
            this.createChart()
        }

        if(prevProps.translations !== this.props.translations) {
            this.myChart.data.datasets = this.makeDataSets();
            this.myChart.options.title.text = this.props.translations[this.props.data.tradKeyTitle]
            this.myChart.update();
        }
    }

    ///////////////////////////////// HANDLE ///////////////////////////////////////

    makeDataSets = () => {
        const { data, translations } = this.props
        let incr_color
        const dataSalesAmount = map(data.contentsSalesAmount, (elem, id) => (
            {
                label: translations[elem.tradKeyTitle],
                fill: false,
                strokeColor: ColorsCharts[id],
                pointStyle: 'circle',
                borderWidth: 2,
                radius: 3,
                pointRadius: 5,
                backgroundColor: theme.colors.white,
                borderColor:  ColorsCharts[id] ,
                data: map(elem.contents, ( elem => elem.value )),
                yAxisID: "SalesAmount"
            }
        ))
        incr_color = data.contentsSalesAmount.length
        const dataSalesCount = map(data.contentsSalesCount, (elem, id) => (
            {
                label: translations[elem.tradKeyTitle],
                fill: false,
                strokeColor: ColorsCharts[incr_color+id],
                pointStyle: 'circle',
                borderWidth: 2,
                radius: 3,
                pointRadius: 5,
                backgroundColor: theme.colors.white,
                borderColor:  ColorsCharts[incr_color+id] ,
                data: map(elem.contents, ( elem => elem.value )),
                yAxisID: "SalesCount"
            }
        ))
        return [...dataSalesAmount, ...dataSalesCount]
    }

    createChart = () => {
        const { data, translations } = this.props
        const formatLabel = []
        forEach(data.contentsSalesAmount, (elem => (
            forEach(elem.contents, (item => {
                const rst = find(formatLabel,function(o){return o === item.label})
                if(isUndefined(rst)) {
                    formatLabel.push(item.label)
                }
            }))
        )))

        const dataToTable =this.makeDataSets();

        this.myChart = new Chart(this.canvasRefLine.current, {
            type: 'line',
            options: {
                title: {
                    display: true,
                    text: translations[data.tradKeyTitle],
                    position: 'top',
                    fontSize: '16',
                    fontFamily: 'Lato'
                },
                legend:{
                    position: 'bottom',
                    align: 'center',
                    fontFamily: 'Lato',
                    labels:{
                        boxWidth: 25,
                        padding: 20,
                    }
                },
                maintainAspectRatio: true,
                scales: {
                    yAxes: [
                    {
                     position: 'right',
                     ticks: {
                      beginAtZero: true,
                      stepSize:1
                     },
                     id:'SalesCount',
                     scaleLabel: {
                        display: true,
                        labelString: translations['page__dashboard__element_salesOrderStats_countLegend']
                      }
                    }
                    ,
                    {
                    position: 'left',
                    ticks: {
                     beginAtZero: true
                    },
                    id:'SalesAmount',
                    scaleLabel: {
                        display: true,
                        labelString: translations['page__dashboard__element_salesOrderStats_amoutLegend']
                      }
                    }
                    ]
                  }
            },
            data: {
                labels: formatLabel,
                datasets: dataToTable,

            }
        })

    }

    componentDidMount() {
        this.createChart()
    }

    render() {
        const { data } = this.props;
	    
        ///////////////////////////////// RENDER ///////////////////////////////////////

        return (
            <ChartWrapper isFull={data.fullWidth} >
                <canvas ref={this.canvasRefLine} />
            </ChartWrapper>
        )
    }
}
export default LineChart;
