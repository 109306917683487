import {
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';

import ActionsTypes from '../../constants/ActionsTypes';
import {
    codesHistoriesActionCreators, errorManagerActionCreators,
    globalLoadingActionCreators,
} from '../actions';
import API from '../../constants/ApiUrl'
import * as R from '../../constants/ApiConstants'
import { selectIdsUser } from '../selectors/user'


const getCodesHistories = function* ({payload}) {

    yield put(globalLoadingActionCreators.startLoading());

    const strDate   = payload.date;
    const user      = yield select(selectIdsUser);
    const apiCall   = R.GET_CODES_HISTORIES(user);
    try{
        const data = yield API(user.jwt).post(apiCall, strDate);
        
        yield put(codesHistoriesActionCreators.getCodesHistoriesSuccess(data.data));

    } catch (e) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put(codesHistoriesActionCreators.getCodesHistoriesFailure(e.response));
    }

    yield put(globalLoadingActionCreators.endLoading());
};// END FUNCTION

const deleteCodesHistoriesItem = function* ( { payload } ) {

    const itemId    = payload.id;
    const user      = yield select( selectIdsUser );
    user['itemId']  = itemId;
    const apiCall   = R.DELETE_CODES_HISTORY( user );

    yield put( globalLoadingActionCreators.startLoading() );

    try {
        const data = yield API( user.jwt ).put( apiCall );

        yield put( codesHistoriesActionCreators.deleteCodesHistoriesItemSuccess( data.data ) );
    } catch ( e ) {
        yield put(errorManagerActionCreators.createError(e.response));

        yield put( codesHistoriesActionCreators.deleteCodesHistoriesItemFailure( e.response ) );
    }

    yield put( globalLoadingActionCreators.endLoading() );
};// END FUNCTION

const sagas = function* () {
    yield takeLatest(ActionsTypes.GET_CODE_HISTORIES, getCodesHistories);
    yield takeLatest(ActionsTypes.DELETE_CODE_HISTORIES_ITEM, deleteCodesHistoriesItem);
};

export default sagas