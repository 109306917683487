import ActionsTypes from '../../constants/ActionsTypes';

const kiosks = {
    getKiosks: () => ({
        type: ActionsTypes.GET_KIOSKS,
        payload: {},
    }),
    getKiosksSuccess: (kiosks) => ({
        type: ActionsTypes.GET_KIOSKS_SUCCESS,
        payload: {
            kiosks,
        },
    }),
    getKiosksFailure: (error) => ({
        type: ActionsTypes.GET_KIOSKS_FAILURE,
        payload: {
            error,
        },
    }),
    updateKiosks: (kiosksList) => ({
        type: ActionsTypes.UPDATE_KIOSKS_SELECTED,
        payload: {
            kiosksList,
        }
    }),
    updateKiosksSuccess: (kiosksList) => ({
        type: ActionsTypes.UPDATE_KIOSKS_SELECTED_SUCCESS,
        payload: {
            kiosksList,
        }
    }),
    updateKiosksFailure: (error) => ({
        type: ActionsTypes.UPDATE_KIOSKS_SELECTED_FAILURE,
        payload: {
            error,
        }
    })
};

export default kiosks;