import update from 'immutability-helper';
import extendUpdate from '../../helpers/extendUpdate';
import ActionsTypes from '../../constants/ActionsTypes';
import { find, assign, reject } from 'lodash';

extendUpdate(update);

const paymentCollection = {

    [ActionsTypes.GET_PAYMENT_COLLECTION]: (state) => update(state, {
        paymentCollection: {
            isLoading: {
                $set: true,
            },
            error: {
                $set: false,
            },
            detail: {
                $set: null,
            },
            selected: {
                $set: null,
            }
        }
    }),
    [ActionsTypes.GET_PAYMENT_COLLECTION_SUCCESS]: (state, payload) => {
        return update(state, {
            paymentCollection: {
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: false,
                },
                items: {
                    $set: payload.paymentCollection
                }
            }
        })
    },
    [ActionsTypes.GET_PAYMENT_COLLECTION_FAILURE]: (state, payload) => update(state, {
        paymentCollection: {
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
        }
    }),
    [ActionsTypes.GET_PAYMENT_COLLECTION_DETAIL]: (state) => update(state, {
        paymentCollection: {
            isLoading: {
                $set: true,
            },
            detail: {
                $set: null,
            }
        }
    }),
    [ActionsTypes.GET_PAYMENT_COLLECTION_DETAIL_SUCCESS]: (state, payload) => {
        const data = payload.paymentCollectionDetail;

        assign(data, {
            buyQty: 0,
            cancelQty: 0,
            repaymentQty: 0,
            validation: false,
        });

        return update(state, {
            paymentCollection: {
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: false,
                },
                detail: {
                    $set: {...data}
                }
            }
        })
    },
    [ActionsTypes.GET_PAYMENT_COLLECTION_DETAIL_FAILURE]: (state, payload) => update(state, {
        paymentCollection: {
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
        }
    }),
    [ActionsTypes.GET_SALES_ORDER_EXCLUDED]: (state) => update(state, {
        paymentCollection: {
            isLoading: {
                $set: true,
            },
            excluded: {
                $set: null,
            }
        }
    }),
    [ActionsTypes.GET_SALES_ORDER_EXCLUDED_SUCCESS]: (state, payload) => {
        const data = payload.salesOrderExcluded;
        
        assign(data, {
            buyQty: 0,
            cancelQty: 0,
            repaymentQty: 0
        });

        return update(state, {
            paymentCollection: {
                isLoading: {
                    $set: false,
                },
                error: {
                    $set: false,
                },
                excluded: {
                    $set: {...data}
                }
            }
        })
    },
    [ActionsTypes.GET_SALES_ORDER_EXCLUDED_FAILURE]: (state, payload) => update(state, {
        paymentCollection: {
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
        }
    }),

    [ActionsTypes.UPDATE_PAYMENT_COLLECTION_SELECTED]: (state, payload) => {
        const idPaymentCollection = payload.id;

        return update(state, {
            paymentCollection: {
                selected: {
                    $set: idPaymentCollection
                }
            }
        })
    },
    [ActionsTypes.UPDATE_SALES_ORDER_EXCLUDED_AND_INCLUDED]: (state, payload) => {
        const idSaleOrder   = payload.id;
        const type          = payload.type;
        let excluded        =  [...state.paymentCollection.excluded.salesOrdersExclused];
        let included        = [...state.paymentCollection.detail.salesOrdersIncluded];
        
        if(type) {
            const salesOrdersSelected   = find(excluded, {id: idSaleOrder});
            excluded                    = reject(excluded, {id: idSaleOrder});
            included.push(salesOrdersSelected)
        }else{
            const salesOrdersSelected   = find(included, {id: idSaleOrder});
            included                    = reject(included, {id: idSaleOrder});
            excluded.push(salesOrdersSelected)
        }
        return update(state, {
            paymentCollection: {
                excluded: {
                    salesOrdersExclused: {
                        $set: [...excluded]
                    }
                },
                detail: {
                    salesOrdersIncluded: {
                        $set: [...included]
                    }
                }
            }
        })
    },
    [ActionsTypes.UPDATE_PAYMENT_COLLECTION_SALES_ORDER]: (state) => update(state, {
        paymentCollection: {
            isLoading: {
                $set: true,
            },
        }
    }),
    [ActionsTypes.UPDATE_PAYMENT_COLLECTION_SALES_ORDER_SUCCESS]: (state, payload) => {
        const detailPaymentCollection   = {...payload.data};
        
        assign(detailPaymentCollection, {
            buyQty: 0,
            cancelQty: 0,
            repaymentQty: 0,
            validation: true,
        });

        return update(state, {
            paymentCollection: {
                detail: {
                    $set: {...detailPaymentCollection},
                },
            }
        })
    },
    [ActionsTypes.UPDATE_PAYMENT_COLLECTION_SALES_ORDER_FAILURE]: (state, payload) => update(state, {
        paymentCollection: {
            isLoading: {
                $set: false,
            },
            error: {
                $set: payload.error,
            },
        }
    }),

    [ActionsTypes.GET_SALES_ORDER_PAYMENT_COLLECTION_DETAIL]: (state) => update(state, {
        paymentCollection: {
            isLoading: {
                $set: true,
            },
            error: {
                $set: false,
            },
            detail: {
                $set: null,
            },
            selected: {
                $set: null,
            }
        }
    }),
};

export default paymentCollection