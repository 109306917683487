import React, { Component } from 'react'
import PropTypes from 'prop-types'

// import components
import ModalActionWrapper from '../ModalActionsWrapper'
import UserAuthorizationLayout
    from '../../../../components/UserAuthorizationLayout'
import userPrivileges from '../../../../constants/RoleList'
import Button from "../../../../components/Ui/Buttons";

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { promoItemStoreActionCreators } from '../../../../state/actions';

// constant
import CR from '../../../../constants/RouteConstants'

/////////////////////////////
//  START style
////////////////////////////

/////////////////////////////
//  END style
////////////////////////////

export class ModalActionsPricePromo extends Component {
    static propTypes = {
        actionsPromoItemStore: PropTypes.object,
        elem: PropTypes.object,
        closeModalHandler: PropTypes.func,
    };

    deleteItemStoreHandler = () => {
        const { actionsPromoItemStore, elem, closeModalHandler } = this.props;

        actionsPromoItemStore.deletePromoItemStore( elem.id );
        closeModalHandler()
    };

    render() {
        const { elem, closeModalHandler, translations } = this.props;

        const disabledDeletion =
            !( elem.promoStatus.subFlow < 100 && elem.promoStatus.subFlow !== '050' );
        const disabledEdition =
            elem.promoStatus.subFlow >= 100;

        return (
            <ModalActionWrapper
                title={ elem.promoLabel }
                closeModal={ closeModalHandler }
            >
                <UserAuthorizationLayout
                    name={ userPrivileges.manage[5] }
                >
                    <Button
                        link={ `${ CR.updatePromoManager.text }/${ elem.id }` }
                        disabled={ disabledEdition }
                        text={ translations['page__priceManagement__tablePromo_modalAction_button_editPromo'] }
                        caps
                        bold
                    />
                </UserAuthorizationLayout>
                <UserAuthorizationLayout
                    name={ userPrivileges.manage[5] }
                >
                    <Button
                        disabled={ disabledDeletion }
                        actionHandler={ disabledDeletion ? null : this.deleteItemStoreHandler }
                        text={ translations['page__priceManagement__tablePromo_modalAction_button_deletePromo'] }
                        caps
                        bold
                    />
                </UserAuthorizationLayout>
            </ModalActionWrapper>
        )
    }
}

const mapDispatchToProps = ( dispatch ) => {
    return {
        actionsPromoItemStore: bindActionCreators( promoItemStoreActionCreators, dispatch ),
    };
};

export default connect( null, mapDispatchToProps )( ModalActionsPricePromo )
