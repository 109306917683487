import React, { Component, Fragment } from 'react'
import { Form, Field } from 'react-final-form'
import { map, forEach } from 'lodash'

// redux import
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { userDetailActionCreators } from '../../state/actions';


// component import
import Button from '../../components/Ui/Buttons'
import UserAuthorizationLayout
    from "../../components/UserAuthorizationLayout/UserAuthorizationLayout";
import userPrivileges from "../../constants/RoleList";

// style
import {
    PageWrapper,
    LineTableHeader,
    TitleHeader,
    ActionWrapper,
    TitleHeaderTable,
    ContentTableWrapper,
    UserWrapperForm,
    UserInfoLeftWrapper,
    UserInfoRightWrapper,
    InputWrapper,
    KioskCheckBoxWrapper,
    LabelCheckbox,
    InputLogin,
    RadioElementWrapper,
    RadioItem,
    RadioInput,
    RadioButtonLabel,
    TextLabel,
    RadioCustom,
    SelectInput,
    ErrorInputMsg,
    ErrorFormMsg,
    LabelText,
    LabelTextKiosks,
} from "../../theme/GlobalCss";
import theme from "../../theme/theme";
import { userDetailValidator } from "../../helpers/FormValidators";

/////////////////////////////
//  START style
/////////////////////////////

/////////////////////////////
//  END style
/////////////////////////////

export class UserDetail extends Component {
    static propTypes = {

    };

    componentDidMount = () => {
        this.getMyAccount()
    };

    getMyAccount = () => {
        const { actionsUserDetail, match } = this.props;
        const idUser = match.params.id;
        actionsUserDetail.getUserDetail(idUser);
    };

    onSubmitHandler = (values) => {
        const { actionsUserDetail, match } = this.props;

        const idUser = match.params.id;
        actionsUserDetail.updateUserDetail(values, idUser)
    };
    resetPasswordHandler= () => {
        const { actionsUserDetail, match } = this.props;
        const idUser = match.params.id;
        actionsUserDetail.UserDetailResetPassword(idUser);
    };

    componentDidUpdate( prevProps) {
        const { userDetail } = this.props;
        if(userDetail.items !== prevProps.userDetail.items){
            return true
        }
    }

    render() {
        const { history, userDetail, translations, languagesList, idCurrentUser } = this.props;
        let submit;
        let resetForm;
        if( userDetail.isLoading || !userDetail.items) {
            return <div></div>
        }
        let userKioskSelected = [];
        forEach(userDetail.items.kiosks, (elem) => elem.isSelected ? userKioskSelected.push(elem.id) : null);
        return (
            <PageWrapper>
                <LineTableHeader>
                    <ActionWrapper>
                        <Button
                            goBack
                            weight='bold'
                            actionHandler={history.goBack}
                            text={translations['common__text__previousPage']}
                        />
                    </ActionWrapper>
                </LineTableHeader>

                <LineTableHeader>
                    <TitleHeader>
                        {/* Fiche utilisateur */}
                        {translations['page__userUpdate__title']}
                    </TitleHeader>
                    <ActionWrapper>
                        <Button
                            bgColor={theme.colors.validation}
                            padding='37'
                            type="submit"
                            actionHandler={(event) => {
                                submit(event)
                            }}
                            text={translations['common__text__save']}
                        />
                        <Button
                            actionHandler={() => resetForm()}
                            bgColor={theme.colors.cancel}
                            text={translations['common__text__cancel']}
                        />
                    </ActionWrapper>
                </LineTableHeader>
                <ContentTableWrapper>
                    <LineTableHeader>
                        <TitleHeaderTable color={theme.colors.primary}>
                            { `${userDetail.items.firstName} ${userDetail.items.lastName} [ ${userDetail.items.role.label || 'default'} ]`}
                        </TitleHeaderTable>
                    </LineTableHeader>
                    <Form
                        onSubmit={this.onSubmitHandler}
                        initialValues={{
                            lastName: userDetail.items.lastName,
                            firstName: userDetail.items.firstName,
                            phoneNumber: userDetail.items.phoneNumber,
                            email: userDetail.items.email,
                            kiosks: userKioskSelected,
                            idPreferedLanguage: userDetail.items.preferedLanguage.id || 1,
                            login: userDetail.items.login,
                            enabled: `${userDetail.items.enabled}`,
                            idRole: userDetail.items.role.id,
                        }}
                        validate={userDetailValidator}
                        render={({
                            handleSubmit,
                            values,
                            form,
                        }) => {
                            submit = handleSubmit;
                            resetForm = form.reset;
                            return (
                                // USER DETAIL 
                                <UserWrapperForm>
                                    {userDetail.error.data && <ErrorFormMsg>{userDetail.error.data.error}</ErrorFormMsg>}

                                    {/*LEFT SIDE START*/}
                                    <UserInfoLeftWrapper
                                        password
                                    >
                                        <LabelText>
                                            {/* Langue par défaut : */}
                                            {translations['page__userDetail__userSheet_form_defaultLanguage']+" :"}
                                        </LabelText>
                                        <Field name="idPreferedLanguage">
                                            {({ input, meta }) => (
                                            <InputWrapper>
                                                <SelectInput {...input} >
                                                    {
                                                        map(languagesList, (elem, id ) => (
                                                            <option
                                                                key={id}
                                                                value={elem.id}
                                                            >
                                                                {elem.labelEnglish}
                                                            </option>
                                                        ))
                                                    }

                                                </SelectInput>
                                                {meta.error && meta.touched && <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                            </InputWrapper>
                                            )}
                                        </Field>
                                        <LabelText>
                                            {/* Nom : */}
                                            {translations['page__userDetail__userSheet_form_lastname']+"* :"}
                                        </LabelText>
                                        <Field name="lastName"
                                        >
                                            {({ input, meta }) => (
                                            <InputWrapper>
                                                <InputLogin
                                                    {...input}
                                                    type="text"
                                                    // placeholder="Nom"
                                                    placeholder={translations['page__userDetail__userSheet_form_lastname_prefilled']}
                                                    maxLength='150'
                                                    touched={meta.touched}
                                                    validated={!meta.error}
                                                    />
                                                {meta.error && meta.touched && <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                            </InputWrapper>
                                            )}
                                        </Field>
                                        <LabelText>
                                            {/* Prénom : */}
                                            {translations['page__userDetail__userSheet_form_firstname']+"* :"}
                                        </LabelText>
                                        <Field name="firstName"
                                        >
                                            {({ input, meta }) => (
                                            <InputWrapper>
                                                <InputLogin
                                                    {...input}
                                                    type="text"
                                                    // placeholder="Prénom"
                                                    placeholder={translations['page__userDetail__userSheet_form_firstname_prefilled']}
                                                    maxLength='150'
                                                    touched={meta.touched}
                                                    validated={!meta.error}
                                                />
                                                {(meta.error || meta.submitError) && meta.touched && (
                                                <ErrorInputMsg>{meta.error || meta.submitError}</ErrorInputMsg>
                                                )}
                                            </InputWrapper>
                                            )}
                                        </Field>
                                        <LabelText>
                                            {/* Téléphone : */}
                                            {translations['page__userDetail__userSheet_form_phone']+"* :"}
                                        </LabelText>
                                        <Field name="phoneNumber"
                                        >
                                            {({ input, meta }) => (
                                            <InputWrapper>

                                                <InputLogin
                                                    {...input}
                                                    type="tel"
                                                    placeholder={translations['page__userDetail__userSheet_form_phone_prefilled']}
                                                    maxLength='150'
                                                    touched={meta.touched}
                                                    validated={!meta.error}
                                                />
                                                {meta.error && meta.touched && <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                            </InputWrapper>
                                            )}
                                        </Field>
                                        <LabelText>
                                            {/* Identifiant : */}
                                            {translations['page__userDetail__userSheet_form_login']+"* :"}
                                        </LabelText>
                                        <Field name="login"
                                        >
                                            {({ input }) => (
                                            <InputWrapper>
                                                <InputLogin
                                                    {...input}
                                                    type="text"
                                                     placeholder={translations['page__userDetail__userSheet_form_login_prefilled']}
                                                    maxLength='150'
                                                    disabled
                                                />
                                            </InputWrapper>
                                            )}
                                        </Field>
                                        <LabelText>
                                            {/* Email : */}
                                            {translations['page__userDetail__userSheet_form_email']+"* :"}
                                        </LabelText>
                                        <Field name="email"
                                        >
                                            {({ input, meta }) => (
                                            <InputWrapper>

                                                <InputLogin
                                                    {...input}
                                                    type="text"
                                                    placeholder={translations['page__userDetail__userSheet_form_email_prefilled']}
                                                    touched={meta.touched}
                                                    validated={!meta.error}
                                                    maxLength='150'
                                                />
                                                {meta.error && meta.touched && <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                            </InputWrapper>
                                            )}
                                        </Field>
                                        <LabelText></LabelText>
                                        {
                                            idCurrentUser !== userDetail.items.id &&
                                                <UserAuthorizationLayout
                                                    name={userPrivileges.manage[17]}
                                                >
                                                    <Button
                                                        bgColor={ theme.colors.cancel }
                                                        actionHandler={this.resetPasswordHandler}
                                                        text={translations['page__userDetail__userSheet_form_button_renewPassword']}
                                                    />
                                                </UserAuthorizationLayout>

                                        }

                                    </UserInfoLeftWrapper>
                                    {/*LEFT SIDE END*/}


                                    {/*RIGHT SIDE START*/}
                                    <UserInfoRightWrapper>
                                        {
                                            idCurrentUser !== userDetail.items.id &&
                                                <Fragment>
                                                    {/* User activation : */}
                                                    <LabelText>
                                                        {translations['page__userDetail__userSheet_form_activeDesactive']+" :"}
                                                    </LabelText>
                                                    <div>
                                                        <RadioElementWrapper>
                                                            <RadioItem>
                                                                <RadioButtonLabel>
                                                                    <Field
                                                                        type="radio"
                                                                        name="enabled"
                                                                        value='true'
                                                                    >
                                                                        {({ input, }) => (
                                                                            <RadioCustom {...input}/>
                                                                        )}
                                                                    </Field>
                                                                    <RadioInput />
                                                                    <TextLabel>
                                                                        {/*TODO : traduction*/}
                                                                        Activé
                                                                    </TextLabel>
                                                                </RadioButtonLabel>
                                                            </RadioItem>
                                                            <RadioItem>
                                                                <RadioButtonLabel>
                                                                    <Field
                                                                        type="radio"
                                                                        name="enabled"
                                                                        value='false'
                                                                    >
                                                                        {({ input, }) => (
                                                                            <RadioCustom {...input}/>
                                                                        )}
                                                                    </Field>
                                                                    <RadioInput />
                                                                    <TextLabel>
                                                                        {/*TODO : traduction*/}
                                                                        Désactivé
                                                                    </TextLabel>
                                                                </RadioButtonLabel>
                                                            </RadioItem>
                                                        </RadioElementWrapper>
                                                    </div>


                                                    {/* User Role : */}
                                                    <LabelText>
                                                        {translations['page__userDetail__userSheet_form_role']+" :"}
                                                    </LabelText>
                                                    <Field
                                                        name="idRole"
                                                    >
                                                        {({ input, meta }) => (
                                                            <InputWrapper>
                                                                <SelectInput {...input} >
                                                                    {
                                                                        map(userDetail.items.roles, (elem, id ) => (
                                                                            <option
                                                                                key={id}
                                                                                value={elem.id}
                                                                            >
                                                                                {elem.label}
                                                                            </option>
                                                                        ))
                                                                    }
                                                                </SelectInput>
                                                                {meta.error && meta.touched && <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                                            </InputWrapper>
                                                        )}
                                                    </Field>
                                                </Fragment>
                                        }

                                        {/* User Kiosks : */}
                                        <LabelTextKiosks>
                                            {translations['page__userDetail__userSheet_form_kioskSelected']+" :"}
                                        </LabelTextKiosks>
                                        <Field
                                            name="kiosks"
                                        >
                                            {({ meta }) => (
                                                <KioskCheckBoxWrapper>
                                                    {
                                                        map(userDetail.items.kiosks, (elem, id ) => (
                                                            <LabelCheckbox
                                                                key={ id }
                                                            >
                                                                <Field
                                                                    name='kiosks'
                                                                    component="input"
                                                                    type="checkbox"
                                                                    value={ elem.id }
                                                                    // disabled last input checked
                                                                    disabled={
                                                                        values.kiosks.length === 1 &&
                                                                        values.kiosks[0] === elem.id
                                                                    }
                                                                />{ ' ' }
                                                                { `${ elem.description } - ${ elem.model }` }
                                                            </LabelCheckbox>
                                                        ))
                                                    }
                                                    {meta.error && meta.touched && <ErrorInputMsg>{meta.error}</ErrorInputMsg>}
                                                </KioskCheckBoxWrapper>

                                            )}
                                        </Field>
                                    </UserInfoRightWrapper>
                                    {/*RIGHT SIDE END*/}

                                </UserWrapperForm>
                            )
                        }}
                    />
                </ContentTableWrapper>
            </PageWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        userDetail: state.userDetail,
        languagesList: state.languages.items,
        idCurrentUser: state.user.identity.id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actionsUserDetail: bindActionCreators(userDetailActionCreators, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail)
